import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Box, makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'

import { isAdmin } from '../../store/accountSlice'
import { generateMonogram } from '../../utils'
import useUpgrade from '../../utils/useUpgrade'

import CardLabel from './CardLabel'
import Score from './Score'

function IntroItem(props) {
  const classes = useStyles({ status: props.status, color: props.color })
  const isAdminRole = useSelector(isAdmin)
  const assignHandler = useUpgrade(props.onAssign)

  const getAssignedUser = () => {
    if (props.assignedTo?.id)
      return (
        <>
          <Avatar className={classes.assignedAvatar}>
            {generateMonogram(props.assignedTo?.name)}
          </Avatar>
          Assigned
        </>
      )

    if (props.assignedTo) return 'Assigned'

    return 'Assign to team member'
  }

  return (
    <div className={classes.root}>
      <CardLabel status={props.status} />

      <Box display="flex" marginBottom={2}>
        <div>
          {props.icon && props.icon.url ? (
            <img
              src={props.icon.url}
              alt={props.title}
              className={classes.icon}
            />
          ) : null}
        </div>
        <div>
          <Box display="flex" className={classes.titleContainer}>
            <Typography
              component="h1"
              variant="h5"
              className={classes.title}
              style={{ color: props.color }}
            >
              {props.title}
            </Typography>
            {props.status === 'FINISHED' ? (
              <div className={classes.scoreContainer}>
                <Score score={props.score / 20} color={props.color} />
              </div>
            ) : null}
          </Box>
          {props.text ? (
            <Typography className={classes.text}>{props.text}</Typography>
          ) : null}
        </div>
      </Box>
      <Box className={classes.meta}>
        {props.status === 'SKIPPED' ? (
          <>
            <div className={classes.mutedButtons}>
              <Button
                variant="outlined"
                size="large"
                onClick={assignHandler}
                className={classes.mutedButton}
                style={{ padding: 12 }}
                disabled={!isAdminRole}
              >
                {getAssignedUser()}
              </Button>
              <Button
                variant="outlined"
                size="large"
                onClick={props.onSaveLater}
                className={classes.mutedButton}
                disabled={!isAdminRole && !props.canEdit}
              >
                Save for later
              </Button>
            </div>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={props.onStart}
              disabled={!isAdminRole && !props.canEdit}
            >
              Start
            </Button>
          </>
        ) : null}
        {props.status === 'LATER' ? (
          <>
            <Button
              variant="outlined"
              size="large"
              onClick={props.onReinstate}
              className={classes.mutedButton}
              style={{ marginRight: 0 }}
              disabled={!isAdminRole && !props.canEdit}
            >
              Reinstate task
            </Button>
          </>
        ) : null}
        {props.status === 'FINISHED' ? (
          <>
            <div className={classes.mutedButtons}>
              <Button
                variant="outlined"
                size="large"
                onClick={assignHandler}
                className={classes.mutedButton}
                style={{ padding: 12 }}
                disabled={!isAdminRole}
              >
                {getAssignedUser()}
              </Button>
              <Button
                variant="outlined"
                size="large"
                onClick={props.onResult}
                className={classes.mutedButton}
              >
                View result
              </Button>
            </div>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={props.onStart}
              style={{ boxShadow: 'none', borderWidth: 2 }}
              disabled={!isAdminRole && !props.canEdit}
            >
              Retake this section
            </Button>
          </>
        ) : null}
      </Box>
    </div>
  )
}

IntroItem.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  color: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  assignedTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onStart: PropTypes.func.isRequired,
  onAssign: PropTypes.func.isRequired,
  onSaveLater: PropTypes.func.isRequired,
  onResult: PropTypes.func.isRequired,
  onReinstate: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
    borderRadius: 12,
    boxShadow: '0 2px 10px rgb(0 0 0 / 0.1)',
    padding: theme.spacing(4),
    marginBottom: theme.spacing(4),
    position: 'relative',
    border: ({ status }) => {
      if (status === 'FINISHED') return 'solid 5px #62F47F'
      if (status === 'LATER') return 'solid 5px #FDD9B7'

      return 'none'
    },
  },
  text: {
    fontSize: 14,
  },
  title: {
    fontSize: 23,
    fontWeight: 600,
  },
  icon: {
    width: 64,
    height: 64,
    marginRight: theme.spacing(4),
  },
  mutedButtons: {
    display: 'flex',
    '& button:not(:last-child)': {
      [theme.breakpoints.down('sm')]: {
        marginRight: 12,
      },
    },
  },
  mutedButton: {
    boxShadow: 'none',
    backgroundColor: '#F3F3F3',
    borderColor: '#F3F3F3',
    marginBottom: theme.spacing(3),
    flex: 1,
    padding: '12px',
    [theme.breakpoints.up('md')]: {
      padding: '12px 48px',
      marginBottom: 0,
      marginRight: theme.spacing(3),
      flex: 'inherit',
    },
  },
  meta: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  titleContainer: {
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  scoreContainer: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto',
    },
  },
  assignedAvatar: {
    width: 31,
    height: 31,
    backgroundColor: '#04D5D7',
    marginRight: 16,
    color: '#000',
    fontSize: 16,
  },
}))

export default IntroItem
