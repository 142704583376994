import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { makeStyles } from '@material-ui/core'
import { Controller } from 'react-hook-form'

import Label from './Label'

const useStyles = makeStyles(theme => ({
  root: {
    transition: theme.transitions.create('opacity'),
    opacity: disabled => (disabled ? 0.2 : 1),
    marginBottom: theme.spacing(2),
  },
  checkbox: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
    borderRadius: 6,
    boxShadow: '0 2px 10px rgb(0 0 0 / 0.12)',
    padding: theme.spacing(4 / 3 / 2, 1),
    margin: 0,
  },
  labelMuted: { fontWeight: 400, color: 'black' },
  selectAll: {
    margin: 0,
    paddingLeft: theme.spacing(1),
  },
}))

const CheckBoxInput = ({
  label,
  control,
  disabled,
  options,
  row,
  name,
  error,
  rules,
  setValue,
  selectAll,
  values,
}) => {
  const classes = useStyles(disabled)
  const [allSelected, setAllSelected] = useState(!!values.length)
  useEffect(() => {
    if (options.length === values.length) {
      setAllSelected(true)
    } else {
      setAllSelected(false)
    }
  }, [values, options])

  const handleCheck = (checkedValues, checkedName) => {
    return checkedValues?.includes(checkedName)
      ? checkedValues?.filter(name => name !== checkedName)
      : [...(checkedValues ?? []), checkedName]
  }

  const handleSelectAll = () => {
    if (allSelected) {
      setValue(name, [], { shouldDirty: true })
    } else {
      setValue(name, options, { shouldDirty: true })
    }
    setAllSelected(!allSelected)
  }

  return (
    <div className={classes.root}>
      <Label>
        {label}
        <span className={classes.labelMuted}> (Select all that apply)</span>
      </Label>
      {error ? error.message : null}
      {selectAll ? (
        <FormControlLabel
          disabled={disabled}
          label="Select all"
          className={classes.selectAll}
          control={
            <Checkbox
              color="primary"
              checked={allSelected}
              onChange={handleSelectAll}
            />
          }
        />
      ) : null}
      <Grid container spacing={2}>
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={props =>
            options.map(value => (
              <Grid item xs={12} sm={row ? 12 : 4} key={value}>
                <FormControlLabel
                  className={classes.checkbox}
                  disabled={disabled}
                  label={value}
                  control={
                    <Checkbox
                      color="primary"
                      checked={props.value.some(a => a === value)}
                      onChange={() =>
                        props.onChange(handleCheck(props.value, value))
                      }
                    />
                  }
                />
              </Grid>
            ))
          }
        />
      </Grid>
    </div>
  )
}

CheckBoxInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.array.isRequired,
  row: PropTypes.bool,
  control: PropTypes.object.isRequired,
  error: PropTypes.object,
  rules: PropTypes.object,
  selectAll: PropTypes.bool,
  values: PropTypes.array,
}

CheckBoxInput.defaultProps = {
  disabled: false,
  options: [],
  row: false,
  selectAll: false,
  values: [],
}

export default CheckBoxInput
