import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { SnackbarProvider } from 'notistack'
import ReactGA from 'react-ga'

import App from './App'
import * as serviceWorker from './serviceWorker'
import store from './store'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn:
      'https://87cc182adced4b209c25877406ed07db@o448420.ingest.sentry.io/5438313',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.5,
  })
}

ReactGA.initialize('UA-155560739-2')

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <SnackbarProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </SnackbarProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
