import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from 'react-query'

import {
  Avatar,
  Dialog,
  DialogContent,
  makeStyles,
  Typography,
} from '@material-ui/core'
import PersonOutline from '@material-ui/icons/PersonOutline'

import { closeAssignModal } from '../store/uiSlice'
import companyService from '../services/companyService'

import LoadingScreen from './LoadingScreen'
import AssignSectionForm from './AssignSectionForm'
import useAssignToSection from '../utils/useAssignToSection'

function AssignSectionModal() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const modal = useSelector(state => state.ui.assign_modal)
  const { isLoading, data } = useQuery('team', companyService.getTeamMembers)
  const assignMutation = useAssignToSection()

  const onClose = () => dispatch(closeAssignModal())

  const handleSubmit = async data => {
    let additionalInfo = {}

    if (data.email) {
      additionalInfo.email = data.email
    }

    if (data.user) {
      additionalInfo.user = data.user
    }

    await assignMutation.mutateAsync({
      type: 'section',
      content_id: modal.sectionId,
      ...additionalInfo,
    })
    onClose()
  }

  const filteredData = data?.filter(user => {
    if (user.role.id === 5 || user.role.id === 3) return true
    return false
  })

  return (
    <Dialog
      open={modal.status}
      onClose={onClose}
      classes={{ paper: classes.root }}
      maxWidth="lg"
      disableBackdropClick={assignMutation.isLoading}
      disableEscapeKeyDown={assignMutation.isLoading}
    >
      <DialogContent className={classes.content}>
        <div className={classes.header}>
          <Avatar className={classes.avatar} color="primary">
            <PersonOutline />
          </Avatar>
        </div>
        <Typography className={classes.title}>Assign to team member</Typography>
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <AssignSectionForm handleSubmit={handleSubmit} team={filteredData} />
        )}
      </DialogContent>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    overflowY: 'visible',
    maxWidth: 524,
    width: '100%',
  },
  content: {
    marginTop: theme.spacing(3),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    width: 56,
    height: 56,
    margin: '0 auto',
    '& > svg': {
      fontSize: 40,
    },
  },
  header: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    transform: 'translateY(-50%)',
  },
  title: {
    // marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    textAlign: 'center',
    fontWeight: 700,
    fontSize: 20,
  },
  inviteTitle: {
    color: theme.palette.primary.main,
    paddingTop: theme.spacing(3),
    borderTop: 'solid 1px #D8D8D8',
    fontSize: 14,
    fontWeight: 800,
  },
}))

export default AssignSectionModal
