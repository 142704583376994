import React from 'react'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles } from '@material-ui/core'
import Rating from '@material-ui/lab/Rating'

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 10,
    boxShadow: '0 2px 12px rgba(0,0,0,0.2)',
    padding: theme.spacing(3),
    backgroundColor: 'white',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  icon: {
    width: 30,
    height: 30,
    [theme.breakpoints.up('sm')]: {
      width: 46,
      height: 46,
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 900,
    marginLeft: 12,
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
    },
  },
  titleWrap: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  ratingWrap: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      alignItems: 'flex-end',
      flexDirection: 'column',
    },
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  ratingIcon: {
    color: '#ECECEC',
  },
  scoreText: {
    fontWeight: 900,
    fontSize: 18,
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      fontSize: 22,
      flex: 'auto',
    },
  },
}))

const ResultCardSkeleton = props => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <div className={classes.titleWrap}>
          <Skeleton variant="circle" className={classes.icon} />
          <Typography
            component="h3"
            color="secondary"
            variant="h3"
            className={classes.title}
          >
            <Skeleton width={200} />
            <Skeleton width={130} />
          </Typography>
        </div>
        <div className={classes.ratingWrap}>
          <Rating
            name="half-rating-read"
            value={0}
            precision={0.5}
            readOnly
            classes={{
              iconEmpty: classes.ratingIcon,
            }}
          />
          <Typography
            color="secondary"
            component="h4"
            className={classes.scoreText}
          >
            <Skeleton width={100} />
          </Typography>
        </div>
      </div>

      <React.Fragment>
        <Skeleton variant="text" height={30} />
        <Skeleton variant="text" height={30} />
        <Skeleton variant="text" height={30} />
        <Skeleton variant="text" height={30} />
      </React.Fragment>
    </div>
  )
}

export default ResultCardSkeleton
