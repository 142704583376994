import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'

import { CircularProgress, makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'

import questionService from '../../services/questionService'
import { roundHalf } from '../../utils'
import Score from './Score'
import CardLabel from './CardLabel'

function ResultModal(props) {
  const classes = useStyles()
  const { isLoading, data } = useQuery('result', questionService.getResult)

  if (!props.status) return null

  if (isLoading) {
    return (
      <Dialog
        fullWidth
        maxWidth="lg"
        open={!!props.status}
        onClose={props.onClose}
        aria-labelledby="Section result"
        classes={{
          paper: classes.root,
        }}
        scroll="body"
      >
        <DialogContent className={classes.loaderContainer}>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    )
  }

  const result = data.find(result => result.section.id === props.status)
  const calculatedScore = result.score ? result.score / 20 : 0
  return (
    <Dialog
      maxWidth="lg"
      open={!!props.status}
      onClose={props.onClose}
      aria-labelledby="Section result"
      classes={{
        paper: classes.root,
      }}
      scroll="body"
    >
      <CardLabel status={result?.status} />

      <DialogContent style={{ padding: 0 }}>
        <div className={classes.headerContainer}>
          <div className={classes.titleWrap}>
            <img
              src={result?.section?.icon?.url}
              alt={result?.section?.title}
              className={classes.icon}
            />
            <Typography
              component="h3"
              color="secondary"
              variant="h3"
              className={classes.title}
            >
              {result?.section?.title}
            </Typography>
          </div>
          <div className={classes.ratingWrap}>
            <Score color={result?.section?.color} score={calculatedScore} />
            <Typography
              color="secondary"
              component="h4"
              className={classes.scoreText}
            >
              Score: {roundHalf(calculatedScore)}
            </Typography>
          </div>
        </div>
        {result?.section?.summary ? (
          <Typography style={{ marginBottom: 16 }}>
            {result?.section?.summary}
          </Typography>
        ) : null}
        {result?.evaluation?.map(item => (
          <React.Fragment key={item.title}>
            <Typography style={{ fontWeight: 600, marginBottom: 8 }}>
              {item.title}
            </Typography>
            <Typography style={{ marginBottom: 16 }}>{item.text}</Typography>
          </React.Fragment>
        ))}
        <div className={classes.modalFooter}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ boxShadow: 'none' }}
            onClick={props.onClose}
          >
            Done
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

ResultModal.propTypes = {
  status: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.bool.isRequired,
  ]),
  onClose: PropTypes.func.isRequired,
}

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 10,
    maxWidth: 830,
    width: '100%',
    padding: theme.spacing(3),
    border: 'solid 4px #62F47F',
    overflowY: 'initial',
  },
  icon: {
    width: 30,
    height: 30,
    [theme.breakpoints.up('sm')]: {
      width: 46,
      height: 46,
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 900,
    marginLeft: 12,
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
    },
  },
  titleWrap: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  ratingWrap: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      alignItems: 'flex-end',
      flexDirection: 'column',
    },
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  ratingIcon: {
    color: '#ECECEC',
  },
  scoreText: {
    fontWeight: 900,
    fontSize: 18,
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      fontSize: 22,
      flex: 'auto',
    },
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalFooter: {
    textAlign: 'right',
  },
}))

export default ResultModal
