import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { alpha, makeStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'

import { setPledgeModal } from '../store/uiSlice'
import { savePledge } from '../store/accountSlice'
import PledgeModalForm from '../components/PledgeModal/PledgeModalForm'
import PledgeImage from '../assets/pledge.png'

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 20,
    maxWidth: '100%',
    background: `linear-gradient(90deg, ${
      theme.palette.primary.main
    } 33.33%, ${alpha(theme.palette.primary.main, 0.57)} 33.33%, ${alpha(
      theme.palette.primary.main,
      0.57
    )} 50%, ${theme.palette.primary.main} 50%, ${
      theme.palette.primary.main
    } 83.33%, ${alpha(theme.palette.primary.main, 0.57)} 83.33%, ${alpha(
      theme.palette.primary.main,
      0.57
    )} 100%)`,
    backgroundSize: '6.00px 6.00px',
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      maxWidth: 1050,
      padding: theme.spacing(2),
    },
  },
  content: {
    borderRadius: 20,
    background: 'white',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      padding: theme.spacing(0, 7, 7, 7),
    },
    '&:first-child': {
      paddingTop: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(5),
      },
    },
  },
  title: {
    fontWeight: 600,
    fontSize: 20,
    lineHeight: 1.3,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(3),
      fontSize: 22,
    },
  },
  logoContainer: {
    textAlign: 'center',
  },
  header: {
    display: 'flex',
  },
  text: {
    marginBottom: theme.spacing(1),
    fontStyle: 'italic',
  },
}))

const PledgeModal = props => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const modalStatus = useSelector(state => state.ui.show_pledge_modal)
  const history = useHistory()

  const handleClose = () => {
    dispatch(setPledgeModal(false))
  }

  const handleSuccess = async () => {
    const response = await dispatch(savePledge())
    if (response.type === savePledge.fulfilled.toString()) {
      handleClose()
      history.push('/resilience-pledge')
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={modalStatus}
      onClose={handleClose}
      aria-labelledby="Resilience Pledge Modal"
      classes={{
        paper: classes.root,
      }}
      scroll="body"
    >
      <DialogContent className={classes.content}>
        <div>
          <Typography component="h1" variant="h6" className={classes.title}>
            We acknowledge the financial, environmental and human benefits of
            being a robust and resilient company and are committed to:
          </Typography>
          <Typography className={classes.text}>Please tick</Typography>

          <PledgeModalForm
            handleClose={handleClose}
            handleSuccess={handleSuccess}
          />
        </div>
        <div className={classes.logoContainer}>
          <img src={PledgeImage} alt="Resilience Pledge" />
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default PledgeModal
