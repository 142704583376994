import axios from '../utils/axios'

class CompanyService {
  updateCompany = async ({ id, data }) => {
    if (data.avatar) {
      const formData = new FormData()
      formData.append('data', JSON.stringify({}))
      formData.append('files.avatar', data.avatar)

      return new Promise((resolve, reject) => {
        axios
          .put(`/companies/${id}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => {
            if (response.data) {
              resolve(response.data)
            } else {
              reject(response.data.error)
            }
          })
          .catch(error => reject(error))
      })
    }

    return new Promise((resolve, reject) => {
      axios
        .put(`/companies/${id}`, { ...data })
        .then(response => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.data.error)
          }
        })
        .catch(error => reject(error))
    })
  }
  getTeamMembers = async () => {
    const response = await axios.get('companies/team')

    return response.data
  }
  deleteMember = async userId => {
    const response = await axios.delete(`companies/team-member/${userId}`)

    return response.data
  }
  /**
   *
   * @param options.userId
   * @param options.data
   * @returns {Promise<any>}
   */
  updateMember = async options => {
    const response = await axios.put(
      `companies/team-member/${options.userId}`,
      options.data
    )

    return response.data
  }

  createCompany = async data => {
    const response = await axios.post('companies', data)

    return response.data
  }

  skipAllNotFinished = async () => {
    const response = await axios.post('companies/skip-all-not-finished')

    return response.data
  }
}

const companyService = new CompanyService()

export default Object.freeze(companyService)
