import axios from '../utils/axios'

class MentorService {
  getAll = async filter => {
    let specialityString = ''
    if (filter.speciality.length > 0) {
      specialityString = filter.speciality
        .map(item => `_where[categories.id]=${item}`)
        .join('&')
    }
    const response = await axios.get(`mentors?${specialityString}`)

    return response.data
  }

  getById = async id => {
    const response = await axios.get(`mentors/${id}`)

    return response.data
  }
  assignCreditToUser = async data => {
    const response = await axios.post(`mentors/assign-credit`, {
      credit: data.credit,
      userId: data.userId,
    })

    return response.data
  }
}

const mentorService = new MentorService()

export default Object.freeze(mentorService)
