import axios from '../utils/axios'
import { postsPerPage } from '../config'

class DataService {
  saveToUser = data =>
    new Promise((resolve, reject) => {
      axios
        .put('/users/me', { ...data })
        .then(response => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.data.error)
          }
        })
        .catch(error => {
          reject(error)
        })
    })

  saveQuestionnaires = data =>
    new Promise((resolve, reject) => {
      axios
        .post('/questionnaires', { ...data })
        .then(response => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.data.error)
          }
        })
        .catch(error => {
          reject(error)
        })
    })

  getUserScore = () =>
    new Promise((resolve, reject) => {
      axios
        .get('/results/score')
        .then(response => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.data.error)
          }
        })
        .catch(error => {
          reject(error)
        })
    })

  getBenchmark = () =>
    new Promise((resolve, reject) => {
      axios
        .get('/results/benchmark')
        .then(response => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.data.error)
          }
        })
        .catch(error => {
          reject(error)
        })
    })

  getPosts = ({ sectionId, start, filter }) =>
    new Promise((resolve, reject) => {
      const filterList = [
        filter.hideDone ? '&_where[marked_done_by]=0' : '',
        filter.type === 'favourite' ? '&_where[favourite_by_user]=1' : '',
        filter.type === 'show_todo' ? '&_where[marked_done_by]=0' : '',
        filter.type === 'show_done' ? '&_where[marked_done_by]=1' : '',
        filter.selected !== 'none'
          ? `&_where[type.slug]=${filter.selected}`
          : '',
      ]

      axios
        .get(
          `/posts/?_where[section.slug]=${sectionId}&_sort=created_at:DESC&_start=${start}&_limit=${postsPerPage}${filterList.join(
            ''
          )}`
        )
        .then(response => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.data.error)
          }
        })
        .catch(error => {
          reject(error)
        })
    })

  markPost = ({ type, postId }) =>
    new Promise((resolve, reject) => {
      axios
        .put(`/posts/mark/${postId}`, { [type]: true })
        .then(response => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.data.error)
          }
        })
        .catch(error => {
          reject(error)
        })
    })

  getSinglePost = ({ slug }) =>
    new Promise((resolve, reject) => {
      axios
        .get(`/posts/${slug}`)
        .then(response => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.data.error)
          }
        })
        .catch(error => {
          reject(error)
        })
    })

  getNextPost = ({ sectionId, createdAt }) =>
    new Promise((resolve, reject) => {
      axios
        .get(
          `/posts/?_where[section.slug]=${sectionId}&_limit=1&_where[created_at_gt]=${createdAt}`
        )
        .then(response => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.data.error)
          }
        })
        .catch(error => {
          reject(error)
        })
    })

  getPostTypes = () =>
    new Promise((resolve, reject) => {
      axios
        .get('/post-types')
        .then(response => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.data.error)
          }
        })
        .catch(error => {
          reject(error)
        })
    })

  getSections = () =>
    new Promise((resolve, reject) => {
      axios
        .get('/post-sections?_sort=created_at')
        .then(response => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.data.error)
          }
        })
        .catch(error => {
          reject(error)
        })
    })

  getQuestions = () =>
    new Promise((resolve, reject) => {
      axios
        .get('/questions?section_null=0')
        .then(response => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.data.error)
          }
        })
        .catch(error => {
          reject(error)
        })
    })

  saveResult = data =>
    new Promise((resolve, reject) => {
      axios
        .post('/results', { ...data })
        .then(response => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.data.error)
          }
        })
        .catch(error => {
          reject(error)
        })
    })

  uploadAvatar = ({ data = {}, avatar = null }) => {
    const formData = new FormData()

    formData.append('data', JSON.stringify(data))
    if (avatar) formData.append('files.avatar', avatar)

    return new Promise((resolve, reject) => {
      axios
        .put('/users/me', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          if (response.data.id) {
            resolve(response.data)
          } else {
            reject(response.data.error)
          }
        })
        .catch(error => reject(error))
    })
  }
}

const dataService = new DataService()

export default Object.freeze(dataService)
