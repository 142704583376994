import React from 'react'
import * as Yup from 'yup'
import { FastField, Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useMutation, useQueryClient } from 'react-query'

import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'

import { isFreePlan, setCompany } from '../../store/accountSlice'
import mentorService from '../../services/mentorService'

function AssignCreditForm(props) {
  const classes = useStyles()
  const company = useSelector(state => state?.account?.company)
  const isFree = useSelector(isFreePlan)
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  const availableCredits = company.credit || 0

  const schema = Yup.object().shape({
    credit: Yup.number()
      .typeError('Please enter a valid number!')
      .max(availableCredits)
      .required('Please enter a valid number!'),
  })

  const assignCredit = useMutation(mentorService.assignCreditToUser, {
    onSuccess: async (data, variables) => {
      dispatch(
        setCompany({ ...company, credit: company.credit - variables.credit })
      )
      await queryClient.refetchQueries(['team'])
      props.onClose()
    },
  })

  const initialValues = {
    credit: '',
  }

  const handleSubmit = async (data, formikBag) => {
    await assignCredit.mutateAsync({
      userId: props.userId,
      credit: data.credit,
    })
    formikBag.resetForm()
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors, dirty }) => (
        <Form noValidate className={classes.root}>
          {isFree ? <div className={classes.overlay} /> : null}
          <div className={classes.formContainer}>
            <div>
              <p className={classes.label}>Number to Assign</p>
              <div style={{ display: 'flex' }}>
                <span className={classes.prefix}>Credits</span>
                <FastField
                  name="credit"
                  type="phone"
                  required
                  className={errors.credit ? classes.inputError : classes.input}
                  disabled={isSubmitting}
                  autoFocus
                />
              </div>
            </div>
            <div style={{ marginLeft: 24 }}>
              <p className={classes.label}>Available</p>
              <div style={{ display: 'flex' }}>
                <span className={classes.prefix}>Credits</span>
                <input
                  type="text"
                  value={availableCredits}
                  className={classes.input}
                  disabled
                />
              </div>
            </div>
          </div>
          {errors.credit ? (
            <p className={classes.error}>{errors.credit}</p>
          ) : null}
          <div style={{ marginTop: 24 }}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              style={{ boxShadow: 'none', marginRight: 16 }}
              disabled={isSubmitting}
              onClick={props.onClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              style={{ boxShadow: 'none' }}
              disabled={isSubmitting}
            >
              Assign
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

AssignCreditForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'white',
    padding: theme.spacing(3, 0),
    position: 'relative',
  },
  formContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    border: 'solid 1px #D1D5DB',
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    fontSize: 16,
    maxWidth: 100,
    padding: '8px 12px',
  },
  inputError: {
    border: `solid 1px ${theme.palette.error.main}`,
    borderRadius: 4,
    fontSize: 14,
    padding: '8px 12px',
    maxWidth: 100,
  },
  error: {
    fontSize: 12,
    color: theme.palette.error.main,
    marginBottom: 0,
    marginTop: 6,
  },
  prefix: {
    border: 'solid 1px #D1D5DB',
    borderRight: 0,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    fontSize: 16,
    backgroundColor: '#F9FAFB',
    padding: '8px 12px',
    color: '#6B7280',
  },
  label: {
    fontSize: 14,
    color: '#374151',
    fontWeight: 600,
  },
  overlay: {
    background: '#f4f4f4b5',
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    zIndex: 1,
  },
}))

export default AssignCreditForm
