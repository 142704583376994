import axios from '../utils/axios'

class QuestionService {
  getQuestionsBySlug = async slug => {
    if (!slug) return []

    const response = await axios.get(`questions-v2?section.slug=${slug}`)

    if (response.data && response.data.length > 0) {
      return response.data[0]
    }

    return []
  }
  saveAnswerBySection = async ({ sectionId, data }) => {
    if (!sectionId) return null

    const response = await axios.put(
      `companies/save-section-answers/${sectionId}`,
      data
    )

    return response.data
  }
  getResult = async () => {
    const response = await axios.get('questions-v2/result')

    return response.data
  }
  getBenchmark = async () => {
    const response = await axios.get('questions-v2/benchmark')

    return response.data
  }
}

const questionService = new QuestionService()

export default Object.freeze(questionService)
