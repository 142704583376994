import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import Block from '@material-ui/icons/Block'
import MoreHorizOutlined from '@material-ui/icons/MoreHorizOutlined'
import RemoveCircleOutlineOutlined from '@material-ui/icons/RemoveCircleOutlineOutlined'

function Label(props) {
  const classes = useStyles()

  if (props.status === 'PROGRESS')
    return (
      <div className={classes.doneText} style={{ backgroundColor: '#FFE800' }}>
        <MoreHorizOutlined /> In progress
      </div>
    )

  if (props.status === 'LATER')
    return (
      <div className={classes.doneText} style={{ backgroundColor: '#EAEAEA' }}>
        <RemoveCircleOutlineOutlined /> To do
      </div>
    )

  if (props.status === 'FINISHED')
    return (
      <div className={classes.doneText} style={{ backgroundColor: '#62F47F' }}>
        <CheckCircleOutline /> Done
      </div>
    )

  if (props.status === 'SKIPPED')
    return (
      <div className={classes.doneText} style={{ backgroundColor: '#FDD9B7' }}>
        <Block />
        Skipped
      </div>
    )

  return null
}

Label.propTypes = {
  status: PropTypes.string.isRequired,
}

const useStyles = makeStyles(theme => ({
  doneText: {
    fontWeight: 900,
    borderRadius: 16,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0.5, 2),
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
}))

export default Label
