import React from 'react'
import { useDispatch } from 'react-redux'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import {
  DialogActions,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'

import { closeActionPlanModal } from '../../store/uiSlice'

const schema = Yup.object().shape({
  user: Yup.string(),
})

function AssignForm(props) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const initialValues = {
    user: null,
  }

  const onClose = () => {
    dispatch(closeActionPlanModal())
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={props.handleSubmit}
    >
      {({ isSubmitting, handleChange, dirty, setFieldValue, values }) => (
        <Form noValidate className={classes.form}>
          <FormControl component="fieldset" className={classes.formControl}>
            <RadioGroup
              aria-label="user"
              name="user"
              value={values.user}
              onChange={handleChange}
            >
              {props.team?.map(user => (
                <FormControlLabel
                  key={user.id}
                  value={user.id.toString()}
                  control={<Radio />}
                  classes={{
                    root: classes.labelRoot,
                    label: classes.labelText,
                  }}
                  label={
                    <Typography
                      style={{
                        color:
                          values?.user?.toString() === user.id.toString()
                            ? '#000'
                            : '#969191',
                      }}
                    >
                      {user.name}
                      <Typography component="span">
                        {user.company_role}
                      </Typography>
                    </Typography>
                  }
                />
              ))}
            </RadioGroup>
          </FormControl>
          <DialogActions className={classes.dialogActions}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={onClose}
              style={{ boxShadow: 'none' }}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              disabled={isSubmitting || !dirty}
            >
              Assign
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  )
}

AssignForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  team: PropTypes.array.isRequired,
}

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(3),
  },
  labelRoot: {
    width: '100%',
  },
  labelText: {
    flexGrow: 1,
    width: '100%',
    '& > p': {
      fontWeight: 700,
      fontSize: 14,
      display: 'flex',
    },
    '& > p > span': {
      fontSize: 14,
      fontWeight: 500,
      marginLeft: 'auto',
    },
  },
  dialogActions: {
    justifyContent: 'center',
    padding: theme.spacing(0, 0, 3, 0),
  },
}))

export default AssignForm
