import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FastField, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Button, makeStyles, Typography } from '@material-ui/core'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import {
  COMPANY_ACTIVITY,
  COMPANY_LOCATIONS,
  COMPANY_MOTIVATION,
  COMPANY_SIZE,
} from '../../constans'
import { generateMonogram } from '../../utils'
import { companyUpdate, selectCompanyAvatar } from '../../store/accountSlice'

import Page from '../../layout/Page'
import Title from '../../components/Setting/Title'
import SettingContainer from '../../components/Setting/SettingContainer'
import ProfilePicture from '../../components/Setting/ProfilePicture'
import FormikTextField from '../../components/FormikTextField'

const schema = Yup.object().shape({
  name: Yup.string().required(),
  size: Yup.string().required(),
  locations: Yup.array()
    .of(Yup.string())
    .min(1, 'You must select at least one location'),
  activities: Yup.array()
    .of(Yup.string())
    .min(1, 'Select at least one activity!'),
})

const CompanyProfile = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const company = useSelector(state => state?.account?.company)
  const avatar = useSelector(selectCompanyAvatar)

  const monogram = generateMonogram(company?.name)
  const [isAvatarUploading, setIsAvatarUploading] = useState(false)

  const initialValues = {
    name: company.name,
    size: company.size,
    locations: company.locations ? company.locations.split(';') : [],
    activities: company.activities ? company.activities.split(';') : [],
    motivations: company.motivations ? company.motivations.split(';') : [],
  }

  const handleSubmit = async (data, formikBag) => {
    const { locations, activities, motivations, ...otherData } = data
    await dispatch(
      companyUpdate({
        id: company.id,
        data: {
          locations: locations.join(';'),
          activities: activities.join(';'),
          motivations: motivations.join(';'),
          ...otherData,
        },
      })
    )
    formikBag.resetForm()
  }

  const handleAvatarChange = async e => {
    const { files } = e.target
    if (!files || files[0] === undefined) {
      return
    }

    setIsAvatarUploading(true)
    await dispatch(
      companyUpdate({ id: company.id, data: { avatar: e.target.files[0] } })
    )
    setIsAvatarUploading(false)
  }

  const removeProfilePicture = async () => {
    await dispatch(companyUpdate({ id: company.id, data: { avatar: null } }))
  }

  return (
    <Page title="Company profile">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, dirty, setFieldValue, values, submitForm }) => (
          <Form noValidate>
            <Title
              title="Manage your company profile"
              description="Some description goes here"
              actionHandler={submitForm}
              actionTitle="Update"
              actionDisabled={isSubmitting || !dirty}
            />
            <SettingContainer title="Profile">
              <ProfilePicture
                name={company?.name}
                short={monogram}
                avatar={avatar}
                addHandler={handleAvatarChange}
                removeHandler={removeProfilePicture}
                loading={isAvatarUploading}
              />
              <FastField
                label="Name"
                component={FormikTextField}
                name="name"
                type="text"
                InputLabelProps={{ required: false }}
                InputProps={{ disableUnderline: true }}
                color="secondary"
                variant="filled"
                margin="normal"
                required
                fullWidth
                autoComplete="name"
                disabled={isSubmitting}
              />
            </SettingContainer>

            <div style={{ maxWidth: 930, margin: '0 auto' }}>
              <Typography className={classes.label}>
                Where your company does work
              </Typography>
              <Grid container spacing={2}>
                {COMPANY_LOCATIONS.map(value => (
                  <Grid item xs={12} sm={4} key={value}>
                    <FormControlLabel
                      className={classes.checkbox}
                      label={value}
                      disabled={isSubmitting}
                      control={
                        <Checkbox
                          color="primary"
                          checked={values?.locations?.some(a => a === value)}
                          onChange={() => {
                            // whet its checked, remove
                            if (values?.locations?.some(a => a === value)) {
                              setFieldValue(
                                'locations',
                                values?.locations?.filter(l => l !== value)
                              )
                            } else {
                              setFieldValue('locations', [
                                ...values.locations,
                                value,
                              ])
                            }
                          }}
                        />
                      }
                    />
                  </Grid>
                ))}
              </Grid>

              <Typography className={classes.label}>
                The size of your company?
              </Typography>
              <RadioGroup
                value={values.size}
                onChange={e => setFieldValue('size', e.target.value)}
              >
                <Grid container spacing={2}>
                  {COMPANY_SIZE.map(option => (
                    <Grid item xs={12} md={4} key={option}>
                      <FormControlLabel
                        value={option}
                        className={classes.checkbox}
                        control={<Radio color="primary" />}
                        label={option}
                        disabled={isSubmitting}
                      />
                    </Grid>
                  ))}
                </Grid>
              </RadioGroup>

              <Typography className={classes.label}>
                Your main areas of activity
              </Typography>
              <Grid container spacing={2}>
                {COMPANY_ACTIVITY.map(value => (
                  <Grid item xs={12} key={value}>
                    <FormControlLabel
                      className={classes.checkbox}
                      label={value}
                      disabled={isSubmitting}
                      control={
                        <Checkbox
                          color="primary"
                          checked={values?.activities?.some(a => a === value)}
                          onChange={() => {
                            // whet its checked, remove
                            if (values?.activities?.some(a => a === value)) {
                              setFieldValue(
                                'activities',
                                values?.activities?.filter(l => l !== value)
                              )
                            } else {
                              setFieldValue('activities', [
                                ...values.activities,
                                value,
                              ])
                            }
                          }}
                        />
                      }
                    />
                  </Grid>
                ))}
              </Grid>

              <Typography className={classes.label}>
                Your main motivation for wanting to improve your business
                resilience
              </Typography>
              <Grid container spacing={2}>
                {COMPANY_MOTIVATION.map(value => (
                  <Grid item xs={12} key={value}>
                    <FormControlLabel
                      className={classes.checkbox}
                      label={value}
                      disabled={isSubmitting}
                      control={
                        <Checkbox
                          color="primary"
                          checked={values?.motivations?.some(a => a === value)}
                          onChange={() => {
                            // whet its checked, remove
                            if (values?.motivations?.some(a => a === value)) {
                              setFieldValue(
                                'motivations',
                                values?.motivations?.filter(l => l !== value)
                              )
                            } else {
                              setFieldValue('motivations', [
                                ...values.motivations,
                                value,
                              ])
                            }
                          }}
                        />
                      }
                    />
                  </Grid>
                ))}
              </Grid>
              <div style={{ marginTop: 16, textAlign: 'center' }}>
                <Button
                  type="submit"
                  disabled={isSubmitting || !dirty}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Update
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    transition: theme.transitions.create('opacity'),
    opacity: disabled => (disabled ? 0.2 : 1),
    marginBottom: theme.spacing(2),
  },
  checkbox: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
    borderRadius: 6,
    boxShadow: '0 2px 10px rgb(0 0 0 / 0.12)',
    padding: theme.spacing(4 / 3 / 2, 1),
    margin: 0,
  },
  labelMuted: { fontWeight: 400, color: 'black' },
  selectAll: {
    margin: 0,
    paddingLeft: theme.spacing(1),
  },
  label: {
    color: theme.palette.primary.dark,
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(6),
  },
}))

export default CompanyProfile
