import React from 'react'
import { useSelector } from 'react-redux'
import { useQuery } from 'react-query'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'
import Rating from '@material-ui/lab/Rating'
import ViewList from '@material-ui/icons/ViewList'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import { selectActiveSection } from '../../store/postSlice'
import questionService from '../../services/questionService'
import ResponsiveImage from '../ResponsiveImage'

const ArchiveHeader = () => {
  const classes = useStyles()
  const activeSection = useSelector(selectActiveSection)
  const { isLoading, data } = useQuery('result', questionService.getResult)

  if (!activeSection || isLoading) return null

  const result = data.find(
    resultItem => resultItem.section.id === activeSection.id
  )

  const reportCreatedAt = new Date(result.created_at).toLocaleDateString(
    'en-US',
    {
      day: 'numeric',
      month: 'long',
    }
  )
  const calculatedScore = result.score / 20

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12} md={6} lg={8}>
        <Box className={classes.description}>
          <Typography>{activeSection?.description}</Typography>
          {activeSection.sponsor_logo ? (
            <Box className={classes.sponsor}>
              <Typography className={classes.sponsorTitle}>
                Sponsored by
              </Typography>
              <a
                href={activeSection?.sponsor_url}
                title="Open sponsor's website"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ResponsiveImage
                  image={activeSection.sponsor_logo}
                  size="thumbnail"
                  className={classes.thumbnail}
                  alt="sponsor logo"
                />
              </a>
            </Box>
          ) : null}
        </Box>
      </Grid>
      <Grid xs={12} md={6} lg={4} item>
        <Box className={classes.sectionBox}>
          <Typography className={classes.title}>
            {activeSection.title} score
          </Typography>
          <Box marginBottom={2} display="flex" justifyContent="space-between">
            <Rating
              name="half-rating-read"
              value={calculatedScore}
              precision={0.5}
              size="medium"
              readOnly
              classes={{
                iconEmpty: classes.ratingIcon,
                iconFilled: classes.ratingIconFilled,
              }}
            />
            <Typography
              component={Link}
              to="/resilience-report"
              className={classes.link}
            >
              View {reportCreatedAt} report
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            size="large"
            component={Link}
            to="knowledge-hub/action-plan"
            fullWidth
          >
            <ViewList /> Action plan
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(4),
    },
  },
  title: {
    fontWeight: 900,
    fontSize: 14,
    marginBottom: theme.spacing(2),
  },
  ratingIcon: {
    color: '#fff',
  },
  ratingIconFilled: {
    color: theme.palette.primary.main,
  },
  link: {
    fontWeight: 600,
    color: '#000',
    fontSize: 14,
    textDecoration: 'underline',
    [theme.breakpoints.up('md')]: {
      marginLeft: 10,
    },
  },
  description: {
    borderRadius: 12,
    padding: theme.spacing(2),
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      padding: theme.spacing(3, 4),
    },
    '& p': {
      fontSize: 14,
    },
  },
  sectionBox: {
    backgroundColor: '#F0F0F0',
    borderRadius: 12,
    padding: theme.spacing(3),
    height: '100%',
  },
  sponsor: {
    marginBottom: theme.spacing(2),
    '& img': {
      maxWidth: 190,
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      marginLeft: theme.spacing(3),
    },
  },
  sponsorTitle: {
    fontSize: 20,
    textAlign: 'center',
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
}))

export default React.memo(ArchiveHeader)
