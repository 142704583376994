import React from 'react'
import { makeStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { isAdmin } from '../../store/accountSlice'

const HowToBook = () => {
  const classes = useStyles()
  const isAdminRole = useSelector(isAdmin)

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <div className={classes.container}>
          <Typography component="h2" className={classes.title} variant="h4">
            How to book
          </Typography>
          <Typography variant="body1">
            Booking a call with one of our experts is straightforward. Simply
            select the mentor you’d like to schedule a call with, chose your
            payment method and select a time that suits you.
          </Typography>
          {isAdminRole ? (
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/setting/team-members"
              className={classes.btn}
            >
              Purchase Credits
            </Button>
          ) : null}
        </div>
      </Container>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 10,
    transform: 'translateY(-42px)',
  },
  container: {
    borderRadius: 12,
    padding: theme.spacing(3),
    backgroundColor: 'white',
    width: '100%',
  },
  title: {
    fontWeight: 700,
    fontSize: 18,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      fontSize: 20,
    },
  },
  btn: {
    marginTop: theme.spacing(2),
  },
}))

export default HowToBook
