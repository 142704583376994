import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import dataService from '../../services/dataService'

const NextButton = props => {
  const [url, setUrl] = useState(null)

  useEffect(() => {
    async function getLink() {
      const options = {
        createdAt: props.createdAt,
      }

      if (props.section) {
        options['sectionId'] = props.section
      }

      const [nextArticle] = await dataService.getNextPost(options)
      if (nextArticle) {
        setUrl(nextArticle.slug)
      }
    }

    try {
      getLink()
    } catch (e) {
      Sentry.captureException(e)
    }
  }, [props.createdAt, props.section])

  if (!url) {
    return null
  }

  return (
    <Button
      className={props.className}
      component={Link}
      to={`/knowledge-hub/${url}`}
    >
      Next content
    </Button>
  )
}

export default React.memo(NextButton)
