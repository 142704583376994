import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import FilterList from '@material-ui/icons/FilterList'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'
import Collapse from '@material-ui/core/Collapse'
import ButtonBase from '@material-ui/core/ButtonBase'

import FilterContent from './FilterContent'
import { toggleFilter } from '../../store/mentorSlice'

const MentorFilter = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const filterStatus = useSelector(state => state.mentor.openFilter)

  const toggleFilterStatus = () => {
    dispatch(toggleFilter())
  }

  return (
    <div className={classes.root}>
      <ButtonBase className={classes.button} onClick={toggleFilterStatus}>
        <FilterList className={classes.filterIcon} /> Filter Speciality
        {filterStatus ? (
          <ArrowDropUp className={classes.filterArrowIcon} />
        ) : (
          <ArrowDropDown className={classes.filterArrowIcon} />
        )}
      </ButtonBase>
      <Collapse in={filterStatus}>
        <FilterContent />
      </Collapse>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(3, 0),
  },
  button: {
    backgroundColor: '#000000',
    color: '#ffffff',
    fontSize: 16,
    padding: theme.spacing(1),
  },
  filterIcon: {
    marginRight: theme.spacing(1),
  },
  filterArrowIcon: {
    marginLeft: theme.spacing(1),
  },
}))

export default React.memo(MentorFilter)
