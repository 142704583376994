import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation, useQueryClient } from 'react-query'
import { Grid, makeStyles, Typography } from '@material-ui/core'

import { generateStatusForActionPlanTopic } from '../../utils'
import actionPlanService from '../../services/actionPlanService'
import { setActionPlanModal } from '../../store/uiSlice'
import Label from './Label'
import Task from './Task'

function TopicItem(props) {
  const classes = useStyles({ status: props.status })
  const user = useSelector(state => state.account.user)
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const mutateAssignment = useMutation(actionPlanService.putEdit, {
    onMutate: async ({ id, data }) => {
      await queryClient.cancelQueries('action_plan_assignments')
      const previousValue = queryClient.getQueryData('action_plan_assignments')

      const newValue = previousValue.map(item => {
        if (item.id === id) {
          return {
            ...item,
            ...data,
          }
        }
        return item
      })
      queryClient.setQueryData('action_plan_assignments', newValue)

      return { previousValue }
    },
    onError: (err, variables, previousValue) => {
      queryClient.setQueryData('action_plan_assignments', previousValue)
    },
  })

  const onMutate = async tasks => {
    await mutateAssignment.mutateAsync({
      id: props.id,
      data: {
        task_to_user: tasks,
        status: generateStatusForActionPlanTopic(tasks),
      },
    })
  }

  const onAssign = id => () => {
    dispatch(
      setActionPlanModal({
        status: true,
        taskId: id,
        topicId: props.id,
        tasks: props.tasks,
        sectionId: props.sectionId,
      })
    )
  }
  const onUnAssign = id => async () => {
    const modifiedTask = props.tasks.map(task => {
      if (task.id === id) {
        return {
          ...task,
          status: 'LATER',
          user: null,
        }
      }
      return task
    })

    await onMutate(modifiedTask)
  }
  const onSkip = id => async () => {
    const modifiedTask = props.tasks.map(task => {
      if (task.id === id) {
        return {
          ...task,
          status: 'SKIPPED',
        }
      }
      return task
    })

    await onMutate(modifiedTask)
  }
  const onReinstate = id => async () => {
    const modifiedTask = props.tasks.map(task => {
      if (task.id === id) {
        return {
          ...task,
          status: 'LATER',
        }
      }
      return task
    })

    await onMutate(modifiedTask)
  }
  const onFinish = id => async () => {
    const modifiedTask = props.tasks.map(task => {
      if (task.id === id) {
        return {
          ...task,
          status: 'FINISHED',
          user: user,
          finished_at: new Date(),
        }
      }
      return task
    })

    await onMutate(modifiedTask)
  }
  const onRemoveFinish = id => async () => {
    const modifiedTask = props.tasks.map(task => {
      if (task.id === id) {
        return {
          ...task,
          status: 'ASSIGNED',
          finished_at: null,
        }
      }
      return task
    })

    await onMutate(modifiedTask)
  }

  return (
    <div className={classes.root}>
      <Grid className={classes.topicHeader} container>
        <Grid md={10} xs={12} item>
          <Typography className={classes.title}>{props.title}</Typography>
          <Typography>{props.text}</Typography>
          <div style={{ marginTop: 32 }}>
            {props.tasks.map((task, index) => {
              return (
                <Task
                  key={task.id}
                  task={`Task ${index + 1} - ${task?.action_plan_task?.task}`}
                  status={task.status}
                  finishedAt={task.finished_at}
                  assignedUser={task.user}
                  onReinstate={onReinstate(task.id)}
                  onSkip={onSkip(task.id)}
                  onUnAssign={onUnAssign(task.id)}
                  onAssign={onAssign(task.id)}
                  onFinish={onFinish(task.id)}
                  onRemoveFinish={onRemoveFinish(task.id)}
                />
              )
            })}
          </div>
        </Grid>
        <Grid md={2} xs={12} className={classes.metaContainer} item>
          <div className={classes.metaWrapper}>
            <Label status={props.status} />
            <Typography className={classes.counter}>
              {props.finishedCounter} of {props.counter} completed
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

TopicItem.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  text: PropTypes.string,
  tasks: PropTypes.array.isRequired,
  counter: PropTypes.number.isRequired,
  finishedCounter: PropTypes.number.isRequired,
  sectionId: PropTypes.number.isRequired,
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
    borderRadius: 12,
    boxShadow: '0 2px 10px rgb(0 0 0 / 0.1)',
    padding: theme.spacing(4),
    marginBottom: theme.spacing(4),
    position: 'relative',
    border: ({ status }) => {
      if (status === 'FINISHED') return 'solid 5px #62F47F'
      if (status === 'SKIPPED') return 'solid 5px #FDD9B7'

      return 'none'
    },
  },
  topicHeader: {
    display: 'flex',
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  title: {
    color: theme.palette.secondary.main,
    fontSize: 20,
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },
  counter: {
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
    },
  },
  metaContainer: {
    display: 'flex',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
      marginBottom: 0,
    },
  },
  metaWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      flexGrow: 'initial',
      flexDirection: 'column',
    },
  },
}))

export default memo(TopicItem)
