import axios from '../utils/axios'

class InviteService {
  sendInvite = async ({ email, companyId, assigned_sections = '' }) => {
    const response = await axios.post('/invites', {
      email,
      company: companyId,
      assigned_sections,
    })

    return response.data
  }

  getByCode = async code => {
    const response = await axios.get(`/invites/${code}`)

    return response.data
  }
}

const inviteService = new InviteService()

export default Object.freeze(inviteService)
