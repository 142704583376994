import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Rating from '@material-ui/lab/Rating'
import * as ReactGA from 'react-ga'

import { roundHalf } from '../../utils'

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 10,
    boxShadow: '0 2px 12px rgba(0,0,0,0.2)',
    padding: theme.spacing(3),
    backgroundColor: 'white',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  icon: {
    width: 30,
    height: 30,
    [theme.breakpoints.up('sm')]: {
      width: 46,
      height: 46,
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 900,
    marginLeft: 12,
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
    },
  },
  titleWrap: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  ratingWrap: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      alignItems: 'flex-end',
      flexDirection: 'column',
    },
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  ratingIcon: {
    color: '#ECECEC',
  },
  ratingIconFilled: {
    color: props => props.color,
  },
  scoreText: {
    fontWeight: 900,
    fontSize: 18,
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      fontSize: 22,
      flex: 'auto',
    },
  },
  readMore: {
    fontWeight: 600,
    cursor: 'pointer',
    textTransform: 'uppercase',
    fontSize: 12,
  },
  textContainer: {
    overflow: 'hidden',
  },
}))

const CardHeader = ({ icon, title, color, score }) => {
  const classes = useStyles({ color })
  return (
    <div className={classes.headerContainer}>
      <div className={classes.titleWrap}>
        <img src={icon} alt={title} className={classes.icon} />
        <Typography
          component="h3"
          color="secondary"
          variant="h3"
          className={classes.title}
        >
          {title}
        </Typography>
      </div>
      <div className={classes.ratingWrap}>
        <Rating
          name="half-rating-read"
          value={score}
          precision={0.5}
          readOnly
          classes={{
            iconEmpty: classes.ratingIcon,
            iconFilled: classes.ratingIconFilled,
          }}
        />
        <Typography
          color="secondary"
          component="h4"
          className={classes.scoreText}
        >
          Score: {roundHalf(score)}
        </Typography>
      </div>
    </div>
  )
}

const ResultCard = props => {
  const [readMore, setReadMore] = useState(false)
  const classes = useStyles()

  if (!props.section) {
    return null
  }

  const calculatedScore =
    props.result && props.result.score ? props.result.score / 20 : 0
  const evaluation = props.result ? props.result.evaluation : []

  const toggleReadMore = () => {
    setReadMore(!readMore)
    ReactGA.event({
      category: 'ResilienceReport',
      action: 'Read More',
      label: props.section.title,
    })
  }

  return (
    <div className={classes.root}>
      <CardHeader
        icon={props.section.icon.url}
        title={props.section.title}
        color={props.section.color}
        score={calculatedScore}
      />
      <div
        className={classes.textContainer}
        style={{ maxHeight: readMore ? 'none' : 266 }}
      >
        {props.result.summary ? (
          <Typography style={{ marginBottom: 16 }}>
            {props.result.summary}
          </Typography>
        ) : null}
        {evaluation.map(item => (
          <React.Fragment key={item.title}>
            <Typography style={{ fontWeight: 600, marginBottom: 8 }}>
              {item.title}
            </Typography>
            <Typography style={{ marginBottom: 16 }}>{item.text}</Typography>
          </React.Fragment>
        ))}
      </div>
      <Typography onClick={toggleReadMore} className={classes.readMore}>
        {readMore ? 'Show less' : 'Read more'}
      </Typography>
    </div>
  )
}

ResultCard.propTypes = {
  section: PropTypes.shape({
    id: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    icon: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
    summary: PropTypes.arrayOf(
      PropTypes.shape({
        score: PropTypes.number.isRequired,
        description: PropTypes.string.isRequired,
      })
    ),
  }),
  result: PropTypes.shape({
    evaluation: PropTypes.array.isRequired,
    summary: PropTypes.string,
    score: PropTypes.number,
  }),
}

export default ResultCard
