import React from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

import { makeStyles, Typography } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import companyService from '../../services/companyService'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

function ViewReportModal(props) {
  const classes = useStyles()
  const history = useHistory()
  const queryClient = useQueryClient()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  const mutation = useMutation(companyService.skipAllNotFinished, {
    onSettled: async () => {
      await queryClient.refetchQueries(['result'])
      props.onClose()
      history.push('/resilience-report')
    },
  })
  const handleYesClick = async () => {
    await mutation.mutateAsync({})
  }

  return (
    <Dialog
      open={!!props.status}
      onClose={props.onClose}
      disableEscapeKeyDown={mutation.isLoading}
      disableBackdropClick={mutation.isLoading}
      fullScreen={!isDesktop}
      aria-labelledby="Some sections haven’t been completed yet."
      classes={{
        paper: classes.root,
      }}
    >
      <DialogContent style={{ padding: 0, flex: 'initial' }}>
        <Typography className={classes.title}>
          Some sections haven’t been completed yet.
        </Typography>
        <Typography className={classes.subTitle}>
          Are you sure you want to launch your results?
        </Typography>
        <Typography className={classes.text}>
          Any incomplete sections will be moved to save for later status and
          excluded from the results for now.
        </Typography>
        <div className={classes.modalFooter}>
          {mutation.isLoading ? (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          ) : null}
          <Button
            onClick={props.onClose}
            variant="outlined"
            color="primary"
            size="large"
            fullWidth={!isDesktop}
            className={classes.backBtn}
          >
            No, go back
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ boxShadow: 'none' }}
            onClick={handleYesClick}
            fullWidth={!isDesktop}
          >
            Yes, launch results
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

ViewReportModal.propTypes = {
  status: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 622,
    width: '100%',
    padding: theme.spacing(2),
    overflowY: 'initial',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      borderRadius: 10,
      padding: theme.spacing(4),
    },
  },
  modalFooter: {
    textAlign: 'center',
    marginTop: theme.spacing(3),
    position: 'relative',
  },
  text: {
    color: '#525252',
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  title: {
    fontSize: 20,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      fontSize: 24,
    },
  },
  subTitle: {
    fontSize: 20,
    fontWeight: 900,
    color: theme.palette.primary.main,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      fontSize: 24,
    },
  },
  backBtn: {
    boxShadow: 'none',
    borderWidth: 2,
    marginRight: 0,
    marginBottom: 16,
    [theme.breakpoints.up('md')]: {
      marginRight: 16,
      marginBottom: 0,
    },
  },
  loading: {
    position: 'absolute',
    right: 0,
    left: 0,
    bottom: 0,
    top: 0,
    background: '#ffffffc7',
    zIndex: 1,
  },
}))

export default ViewReportModal
