import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import dataService from '../services/dataService'

const fetchSinglePost = createAsyncThunk(
  'post/fetchSinglePost',
  async payload => {
    return await dataService.getSinglePost({
      slug: payload.slug,
    })
  }
)

const logPostView = createAsyncThunk(
  'post/logPostView',
  async (payload, { getState }) => {
    const { account } = getState()
    return await dataService.saveToUser({
      article_views: (parseInt(account.user.article_views) || 0) + 1,
    })
  }
)

const initialFilterStatus = {
  level: [],
  postType: [],
  status: [],
}

const postSlice = createSlice({
  name: 'post',
  initialState: {
    showPopover: null,
    openFilter: false,
    filter: initialFilterStatus,
    activeSection: null,
    showCategoryNav: false,
  },
  reducers: {
    setActiveSection: (state, action) => {
      state.activeSection = action.payload
    },

    setShowPopover: (state, action) => {
      state.showPopover = action.payload.id
    },
    setFilter: (state, action) => {
      state.filter = action.payload
    },
    setCategoryNavStatus: (state, action) => {
      state.showCategoryNav = action.payload
    },
    toggleFilter: state => {
      state.openFilter = !state.openFilter
    },
    resetFilter: (state, action) => {
      state.filter = initialFilterStatus
    },
  },
  extraReducers: {
    [fetchSinglePost.fulfilled]: (state, action) => {
      state.posts.push(action.payload)
    },
    [fetchSinglePost.rejected]: () => {
      window.location = '/404'
    },
  },
})

export const {
  setActiveSection,
  setShowPopover,
  setFilter,
  setCategoryNavStatus,
  toggleFilter,
  resetFilter,
} = postSlice.actions
export default postSlice.reducer

const selectActiveSection = state => state.post.activeSection

export { fetchSinglePost, logPostView, selectActiveSection }
