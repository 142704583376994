import React from 'react'
import { NavLink } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import { alpha, makeStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    textAlign: 'center',
    zIndex: 1,
    padding: theme.spacing(4, 0),
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
      padding: theme.spacing(4, 0, 8, 0),
    },
  },
  text: {
    color: alpha(theme.palette.common.white, 0.6),
    textAlign: 'center',
    fontSize: 14,
  },
  menu: {
    marginBottom: theme.spacing(3),
    listStyleType: 'none',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    '& > li': {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        marginRight: theme.spacing(2),
        marginBottom: 0,
      },
      '& > a': {
        fontSize: 14,
        color: theme.palette.common.white,
        textDecoration: 'none',
      },
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
  },
  active: {
    fontWeight: 600,
  },
  social: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    '& > a': {
      color: 'white',
    },
  },
}))

const Footer = props => {
  const classes = useStyles()

  const menu = [
    { text: 'Terms and Conditions', link: '/terms-and-conditions' },
    {
      text: 'Privacy Policy',
      link: '/privacy-policy',
    },
    // {
    //   text: 'Contact',
    //   link: '/privacy-policy-2',
    // },
  ]

  return (
    <footer className={classes.root}>
      <Container>
        <ul className={classes.menu}>
          {menu.map(item => (
            <li key={item.link}>
              <Typography
                component={NavLink}
                to={item.link}
                title={item.text}
                activeClassName={classes.active}
              >
                {item.text}
              </Typography>
            </li>
          ))}
          <li>
            <Typography
              component="a"
              href="https://www.digitalconstructionskills.com/?utm_source=ric&utm_medium=footer&utm_campaign=find_out_more_link"
              target="_blank"
              title="Find out more"
            >
              Find out more
            </Typography>
          </li>
        </ul>
        <Typography className={classes.text}>
          272 Bath Street, GLASGOW, G2 4JR
        </Typography>
        <Typography className={classes.text}>
          Copyright © {new Date().getFullYear()} Digital Construction Skills
        </Typography>
      </Container>
    </footer>
  )
}

export default Footer
