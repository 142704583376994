import React from 'react'
import { FastField, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles, MenuItem, Select, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'

import {
  removeAvatar,
  selectUserAvatar,
  updateUser,
  uploadAvatar,
} from '../../store/accountSlice'
import { generateMonogram } from '../../utils'

import SettingContainer from './SettingContainer'
import ProfilePicture from './ProfilePicture'
import FormikTextField from '../FormikTextField'

const schema = Yup.object().shape({
  first_name: Yup.string().required(),
  last_name: Yup.string().required(),
  email: Yup.string()
    .email('Must be a valid email')
    .required('Email is required'),
  content_notification: Yup.number().required(),
})

function ProfileForm() {
  const classes = useStyles()
  const dispatch = useDispatch()

  const user = useSelector(state => state?.account?.user)
  const avatar = useSelector(selectUserAvatar)
  const monogram = generateMonogram(user?.name)
  const [isAvatarUploading, setIsAvatarUploading] = React.useState(false)

  const initialValues = {
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    company_role: user.company_role,
    content_notification: user.content_notification,
  }

  const handleAvatarChange = async e => {
    const { files } = e.target
    if (!files || files[0] === undefined) {
      return
    }

    setIsAvatarUploading(true)
    await dispatch(uploadAvatar({ avatar: e.target.files[0] }))
    setIsAvatarUploading(false)
  }

  const removeProfilePicture = async () => {
    await dispatch(removeAvatar())
  }

  const handleSubmit = async (data, formikBag) => {
    await dispatch(updateUser(data))
    formikBag.resetForm()
  }

  return (
    <SettingContainer title="Profile">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue, values, dirty }) => (
          <Form noValidate>
            <ProfilePicture
              name={user?.name}
              short={monogram}
              avatar={avatar}
              addHandler={handleAvatarChange}
              removeHandler={removeProfilePicture}
              loading={isAvatarUploading}
            />
            <FastField
              label="First Name"
              component={FormikTextField}
              name="first_name"
              type="text"
              InputLabelProps={{ required: false }}
              InputProps={{ disableUnderline: true }}
              color="secondary"
              variant="filled"
              margin="normal"
              required
              fullWidth
              disabled={isSubmitting}
            />
            <FastField
              label="Last Name"
              component={FormikTextField}
              name="last_name"
              type="text"
              InputLabelProps={{ required: false }}
              InputProps={{ disableUnderline: true }}
              color="secondary"
              variant="filled"
              margin="normal"
              required
              fullWidth
              disabled={isSubmitting}
            />
            <FastField
              label="Email"
              component={FormikTextField}
              name="email"
              type="email"
              InputLabelProps={{ required: false }}
              InputProps={{ disableUnderline: true }}
              color="secondary"
              variant="filled"
              margin="normal"
              required
              fullWidth
              autoComplete="email"
              disabled={isSubmitting}
            />
            <FastField
              label="Role"
              component={FormikTextField}
              name="company_role"
              type="text"
              InputLabelProps={{ required: false }}
              InputProps={{ disableUnderline: true }}
              color="secondary"
              variant="filled"
              margin="normal"
              required
              fullWidth
              disabled={isSubmitting}
            />
            <Typography className={classes.title}>Email me when</Typography>
            <div className={classes.selectContainer}>
              <p>A team member updates a document</p>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  labelId="update-notification"
                  name="content_notification"
                  value={values.content_notification}
                  onChange={e =>
                    setFieldValue('content_notification', e.target.value)
                  }
                  classes={{ outlined: classes.input }}
                  disabled={isSubmitting}
                >
                  <MenuItem value={1}>Notify me</MenuItem>
                  <MenuItem value={0}>Never</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className={classes.submitContainer}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                disabled={isSubmitting || !dirty}
              >
                Update
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </SettingContainer>
  )
}

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(5),
    color: theme.palette.secondary.main,
    fontSize: 16,
    [theme.breakpoints.up('md')]: {
      fontSize: 20,
    },
  },
  input: {
    padding: '10.5px 14px',
    fontSize: 14,
  },
  formControl: {
    marginLeft: 'auto',
    maxWidth: 270,
    width: '100%',
  },
  selectContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  submitContainer: {
    marginTop: theme.spacing(2),
    textAlign: 'right',
  },
}))

export default ProfileForm
