import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import get from 'lodash/get'

import authService from '../services/authService'
import dataService from '../services/dataService'
import { logPostView } from './postSlice'
import { submitQuestionnaire } from './questionnaireSlice'
import userService from '../services/userService'
import { getUserAndCompany } from '../utils'
import companyService from '../services/companyService'
import { queryClient } from '../utils/react-query'

const login = createAsyncThunk('account/login', loginHandler)

/**
 *
 * @param payload
 * @param payload.identifier
 * @param payload.password
 * @returns {Promise<>}
 */
async function loginHandler(payload, { rejectWithValue }) {
  try {
    return await authService.loginWithEmailAndPassword(
      payload.identifier,
      payload.password
    )
  } catch (e) {
    return rejectWithValue(e.response.data.message)
  }
}

const signup = createAsyncThunk('account/signup', registerHandler)

/**
 *
 * @param payload
 * @param payload.first_name
 * @param payload.last_name
 * @param payload.email
 * @param payload.password
 * @param payload.marketing
 * @param payload.companyId
 * @param payload.company_role
 * @returns {Promise<>}
 */
async function registerHandler(payload, { rejectWithValue }) {
  try {
    return await authService.register(
      payload.first_name,
      payload.last_name,
      payload.email,
      payload.password,
      payload.marketing,
      payload.companyId,
      payload.company_role
    )
  } catch (e) {
    return rejectWithValue(e.response.data.message)
  }
}

const forgotPassword = createAsyncThunk(
  'account/forgotPassword',
  forgotPasswordHandler
)

/**
 *
 * @param payload
 * @param payload.email
 * @returns {Promise<>}
 */
async function forgotPasswordHandler(payload, { rejectWithValue }) {
  try {
    return await authService.sendForgotPasswordEmail(payload.email)
  } catch (e) {
    return rejectWithValue(e.response.data.message)
  }
}

const resetPassword = createAsyncThunk(
  'account/resetPassword',
  resetPasswordHandler
)

/**
 *
 * @param payload
 * @param payload.password
 * @param payload.code
 * @returns {Promise<>}
 */
async function resetPasswordHandler(payload, { rejectWithValue }) {
  try {
    return await authService.resetPassword(payload.password, payload.code)
  } catch (e) {
    return rejectWithValue(e.response.data.message)
  }
}

const saveSurvey = createAsyncThunk(
  'account/saveSurvey',
  async (payload, { rejectWithValue }) => {
    const newPayload = {
      ...payload,
      company_activities: payload.activities.map(activity => ({
        name: activity,
      })),
      company_motivations: payload.motivations.map(motivation => ({
        name: motivation,
      })),
      company_locations: payload.locations.map(location => ({
        name: location,
      })),
    }
    try {
      return await dataService.saveToUser({
        ...newPayload,
        isSurveyDone: true,
      })
    } catch (e) {
      return rejectWithValue(e.response.data.message)
    }
  }
)

const savePledge = createAsyncThunk(
  'account/savePledge',
  async (payload, { rejectWithValue }) => {
    try {
      return await dataService.saveToUser({
        pledge: true,
      })
    } catch (e) {
      return rejectWithValue(e.response.data.message)
    }
  }
)
const logPledgeCTAView = createAsyncThunk(
  'account/logPledgeCTAView',
  async (payload, { getState }) => {
    const { account } = getState()
    return await dataService.saveToUser({
      pledge_cta_counter: (parseInt(account.user.pledge_cta_counter) || 0) + 1,
    })
  }
)

const uploadAvatar = createAsyncThunk('account/upload-avatar', async function (
  payload,
  { rejectWithValue }
) {
  try {
    return await dataService.uploadAvatar({
      ...payload,
    })
  } catch (e) {
    return rejectWithValue(e.response.data.message)
  }
})

const removeAvatar = createAsyncThunk('account/remove-avatar', async function (
  payload,
  { rejectWithValue }
) {
  try {
    return await dataService.saveToUser({
      avatar: null,
    })
  } catch (e) {
    return rejectWithValue(e.response.data.message)
  }
})

const changePassword = createAsyncThunk(
  'account/change-password',
  async (payload, { rejectWithValue }) => {
    try {
      return await userService.changePassword(
        payload.password,
        payload.oldPassword
      )
    } catch (e) {
      return rejectWithValue(e.response.data.message)
    }
  }
)

const updateUser = createAsyncThunk(
  'account/update-user',
  async (payload, { rejectWithValue }) => {
    try {
      return await dataService.saveToUser(payload)
    } catch (e) {
      return rejectWithValue(e.response.data.message)
    }
  }
)

const companyUpdate = createAsyncThunk(
  'account/update-company',
  async (payload, { rejectWithValue }) => {
    try {
      return await companyService.updateCompany(payload)
    } catch (e) {
      return rejectWithValue(e.response.data.message)
    }
  }
)

const accountSlice = createSlice({
  name: 'account',
  initialState: {
    user: null,
    company: null,
  },
  reducers: {
    setUserData: (state, action) => {
      const { user, company } = getUserAndCompany(action.payload)

      state.user = user
      state.company = company
    },
    setCompany: (state, action) => {
      state.company = action.payload
    },
    setUser: (state, action) => {
      state.user = action.payload
    },
    logout: state => {
      authService.logout()
      queryClient.clear()
      state.user = null
    },
  },
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      const { user, company } = getUserAndCompany(action.payload)

      state.user = user
      state.company = company
    },
    [signup.fulfilled]: (state, action) => {
      const { user, company } = getUserAndCompany(action.payload)

      state.user = user
      state.company = company
    },
    [resetPassword.fulfilled]: (state, action) => {
      const { user, company } = getUserAndCompany(action.payload)

      state.user = user
      state.company = company
    },
    [saveSurvey.fulfilled]: (state, action) => {
      const { user, company } = getUserAndCompany(action.payload)

      state.user = user
      state.company = company
    },
    [savePledge.fulfilled]: (state, action) => {
      const { user, company } = getUserAndCompany(action.payload)

      state.user = user
      state.company = company
    },
    [logPostView.pending]: (state, action) => {
      if (state.user) {
        state.user.article_views = (parseInt(state.user.article_views) || 0) + 1
      }
    },
    [logPostView.rejected]: state => {
      if (state.user) {
        const num = parseInt(state.user.article_views)

        state.user.article_views = num >= 0 ? num - 1 : 0
      }
    },
    [logPledgeCTAView.pending]: state => {
      const num = parseInt(state.user.pledge_cta_counter)
      state.user.pledge_cta_counter = num >= 0 ? num + 1 : 0
    },
    [logPledgeCTAView.rejected]: state => {
      const num = parseInt(state.user.pledge_cta_counter)
      state.user.pledge_cta_counter = num >= 0 ? num - 1 : 0
    },
    [submitQuestionnaire.fulfilled]: state => {
      state.user.isQuestionnaireDone = true
      state.user.questionnaire_draft = true
      state.user.questionnaire_counter =
        (parseInt(state.user.questionnaire_counter) || 0) + 1
    },
    [removeAvatar.pending]: state => {
      state.user.avatar = null
    },
    [uploadAvatar.fulfilled]: (state, action) => {
      state.user.avatar = action.payload.avatar
    },
    [changePassword.fulfilled]: (state, action) => {
      authService.setSession(action.payload.jwt)
    },
    [companyUpdate.fulfilled]: (state, action) => {
      state.company = action.payload
    },
    [updateUser.fulfilled]: (state, action) => {
      const { user } = getUserAndCompany(action.payload)
      state.user = user
    },
  },
})

export {
  login,
  signup,
  forgotPassword,
  resetPassword,
  saveSurvey,
  savePledge,
  logPledgeCTAView,
  uploadAvatar,
  removeAvatar,
  changePassword,
  updateUser,
  companyUpdate,
}

export const selectUserAvatar = state => {
  const avatar = get(state, 'account.user.avatar')

  if (!avatar) return null
  const thumbnail = get(avatar, 'formats.thumbnail.url')

  if (thumbnail) return thumbnail
  return avatar.url
}

export const selectCompanyAvatar = state => {
  const avatar = get(state, 'account.company.avatar')

  if (!avatar) return null
  const thumbnail = get(avatar, 'formats.thumbnail.url')

  if (thumbnail) return thumbnail
  return avatar.url
}

export const isAdmin = state => {
  if (!state?.account?.user?.role) return false

  return state?.account?.user?.role?.type === 'admin'
}

export const isManager = state => {
  if (!state?.account?.user?.role) return false

  return (
    state?.account?.user?.role?.type === 'admin' ||
    state?.account?.user?.role?.type === 'manager'
  )
}
//TODO: removed temporarily
// export const isFreePlan = state => state.account.company.plan === 'free'
export const isFreePlan = state => false

export const { setUserData, setCompany, logout, setUser } = accountSlice.actions
export default accountSlice.reducer
