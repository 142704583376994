import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import Close from '@material-ui/icons/Close'

function ExitModal() {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const history = useHistory()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  const handleExitSection = () => {
    history.push('/questionnaire/intro')
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Are you sure you want to exit the section?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your previously answered questions will not be saved.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleExitSection}
            variant="outlined"
            color="primary"
            size="large"
            style={{ boxShadow: 'none' }}
          >
            Exit
          </Button>
          <Button
            onClick={() => setOpen(false)}
            color="primary"
            variant="contained"
            autoFocus
            size="large"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ButtonBase className={classes.button} onClick={() => setOpen(true)}>
        <Close />
        {isDesktop ? 'Exit section' : null}
      </ButtonBase>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: '#838383',
    fontSize: 14,
    fontWeight: 700,
    color: 'white',
    borderRadius: 8,
    padding: theme.spacing(1),
  },
}))

export default ExitModal
