import React, { useLayoutEffect } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider, createStyles, makeStyles } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import * as ReactGA from 'react-ga'
import { QueryClientProvider } from 'react-query'

import theme, { globalStyle } from './config/theme'
import { queryClient } from './utils/react-query'

import Routes from './Routes'
import Auth from './components/Auth/Auth'
import Notifier from './components/Notifier'
import ErrorBoundary from './components/ErrorBoundary'

const useStyles = makeStyles(() => createStyles(globalStyle))

function App() {
  useStyles()
  const location = useLocation()
  // Scroll to top if path changes
  useLayoutEffect(() => {
    const newPath = location.pathname || window.location.pathname
    ReactGA.pageview(newPath)

    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <Auth>
            <CssBaseline />
            <Routes />
            <Notifier />
          </Auth>
        </QueryClientProvider>
      </ErrorBoundary>
    </ThemeProvider>
  )
}

export default App
