import React from 'react'
import PropTypes from 'prop-types'
import Facebook from '@material-ui/icons/Facebook'
import Twitter from '@material-ui/icons/Twitter'
import LinkedIn from '@material-ui/icons/LinkedIn'
import Reply from '@material-ui/icons/Reply'
import IconButton from '@material-ui/core/IconButton'
import { alpha, makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { getPostUrl } from '../../utils'
import * as ReactGA from 'react-ga'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#000',
    color: '#fff',
    width: 40,
    height: 40,
    '&:hover': {
      backgroundColor: alpha('#000', 0.8),
    },
  },
  wrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(3),
    '& div > :not(:last-child)': {
      marginRight: 14,
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(5),
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(7),
    },
  },
  title: {
    fontWeight: 600,
    marginBottom: 12,
  },
}))

const SocialShareItem = ({ icon, title, onClick }) => {
  const classes = useStyles()
  return (
    <IconButton className={classes.root} title={title} onClick={onClick}>
      {icon}
    </IconButton>
  )
}

const SocialShare = ({ post }) => {
  const classes = useStyles()
  const makeShareDialog = (url, via) => () => {
    const y = window.top.outerHeight / 2 + window.top.screenY - 500 / 2
    const x = window.top.outerWidth / 2 + window.top.screenX - 360 / 2

    ReactGA.event({
      category: 'Post',
      action: 'Share',
      value: via,
    })

    window.open(
      url,
      'ric-window',
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=360, height=500, top=${y}, left=${x}`
    )
  }

  const share = [
    {
      title: 'Share this article on Facebook',
      icon: <Facebook />,
      onClick: makeShareDialog(
        `https://www.facebook.com/sharer/sharer.php?u=${
          process.env.REACT_APP_CLIENT_URL
        }${getPostUrl(post)}`,
        'Facebook'
      ),
    },
    {
      title: 'Share this article on Twitter',
      icon: <Twitter />,
      onClick: makeShareDialog(
        `https://twitter.com/home?status=${
          process.env.REACT_APP_CLIENT_URL
        }${getPostUrl(post)}`,
        'Twitter'
      ),
    },
    {
      title: 'Share this article on LinkedIn',
      icon: <LinkedIn />,
      onClick: makeShareDialog(
        `https://www.linkedin.com/shareArticle?mini=true&url=${
          process.env.REACT_APP_CLIENT_URL
        }${getPostUrl(post)}&title=${post.title}&summary=${
          post.excerpt
        }&source=`,
        'LinkedIn'
      ),
    },
    {
      title: 'Share this article in email',
      icon: <Reply />,
      onClick: () => {
        window.open(
          `mailto:?subject=I'd like to share a link with you&body=${
            process.env.REACT_APP_CLIENT_URL
          }${getPostUrl(post)}`,
          '_blank',
          'Email'
        )
      },
    },
  ]

  return (
    <div className={classes.wrap}>
      <div>
        <Typography className={classes.title}>Share</Typography>
        {share.map(item => (
          <SocialShareItem {...item} key={item.title} />
        ))}
      </div>
    </div>
  )
}

SocialShare.propTypes = {
  post: PropTypes.object.isRequired,
}

export default SocialShare
