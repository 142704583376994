import React from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { FastField, Field, Form, Formik } from 'formik'

import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'

import { signup } from '../../store/accountSlice'
import { getErrorMessage, setFormErrors } from '../../utils'

import FormikTextField from '../FormikTextField'

const schema = Yup.object().shape({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Must be a valid email')
    .required('Email is required'),
  password: Yup.string()
    .min(6, 'Password must contain at least 6 characters')
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
      'Password must contain at least 6 characters, one number, one uppercase letter'
    ),
  tcs: Yup.bool().oneOf([true], 'You have to accept Terms & Conditions'),
  marketing: Yup.bool(),
  company_role: Yup.string(),
})

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const SignUpForm = ({ onSuccess, email, companyId }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [showPassword, setShowPassword] = React.useState(false)

  const initialValues = {
    first_name: '',
    last_name: '',
    email: email,
    password: '',
    tcs: false,
    marketing: false,
    companyId: companyId,
    company_role: '',
  }

  const handleSubmit = async (data, formikBag) => {
    const response = await dispatch(signup(data))

    if (response.type === signup.rejected.toString()) {
      const message = getErrorMessage(response)
      if (message) {
        setFormErrors(message, formikBag.setFieldError)
      }
    } else {
      onSuccess()
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors, dirty, setFieldValue, values }) => (
        <Form noValidate className={classes.form}>
          <Field
            component={FormikTextField}
            label="First name"
            name="first_name"
            type="text"
            InputLabelProps={{ required: false }}
            InputProps={{ disableUnderline: true }}
            color="secondary"
            variant="filled"
            margin="normal"
            required
            fullWidth
            autoComplete="first_name"
            autoFocus
          />
          <Field
            component={FormikTextField}
            label="Last name"
            name="last_name"
            type="text"
            InputLabelProps={{ required: false }}
            InputProps={{ disableUnderline: true }}
            color="secondary"
            variant="filled"
            margin="normal"
            required
            fullWidth
            autoComplete="last_name"
          />
          {!email ? (
            <FastField
              component={FormikTextField}
              label="Email address"
              name="email"
              type="email"
              InputLabelProps={{ required: false }}
              InputProps={{ disableUnderline: true }}
              color="secondary"
              variant="filled"
              margin="normal"
              required
              fullWidth
              autoComplete="email"
            />
          ) : null}
          <Field
            component={FormikTextField}
            label="Choose a password"
            name="password"
            type={showPassword ? 'text' : 'password'}
            autoComplete="new-password"
            InputLabelProps={{ required: false }}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            color="secondary"
            variant="filled"
            margin="normal"
            required
            fullWidth
          />
          {email ? (
            <Field
              component={FormikTextField}
              label="What's your role at the company?"
              name="company_role"
              type="text"
              InputLabelProps={{ required: false }}
              InputProps={{ disableUnderline: true }}
              color="secondary"
              variant="filled"
              margin="normal"
              required
              fullWidth
            />
          ) : null}
          <FormControl
            required
            error={!!errors.tcs}
            aria-errormessage={errors.tcs}
            aria-invalid={errors.tcs ? 'true' : 'false'}
            onChange={() => setFieldValue('tcs', !values.tcs)}
          >
            <FormControlLabel
              control={
                <Checkbox
                  classes={{
                    colorPrimary: classes.checkbox,
                  }}
                  color="primary"
                />
              }
              label={
                <p>
                  I accept the{' '}
                  <Link to="/terms-and-conditions">Terms & Conditions</Link>
                </p>
              }
              name="tcs"
            />
            <FormHelperText>{errors.tcs}</FormHelperText>
          </FormControl>
          <FormControlLabel
            onChange={() => setFieldValue('marketing', !values.marketing)}
            control={
              <Checkbox
                classes={{
                  colorPrimary: classes.checkbox,
                }}
                color="primary"
              />
            }
            label="Yes, please keep me updated on Digital Construction Skills news, events and offers."
            name="marketing"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            className={classes.submit}
            disabled={isSubmitting || !dirty}
          >
            Sign Up
          </Button>
          <Button
            size="small"
            color="secondary"
            component={Link}
            to="/login"
            fullWidth
          >
            Existing user? Login
          </Button>
        </Form>
      )}
    </Formik>
  )
}

SignUpForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  email: PropTypes.string,
  companyId: PropTypes.number,
}

export default SignUpForm
