import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/react'
import ReactGA from 'react-ga'

import LoadingScreen from '../LoadingScreen'
import authService from '../../services/authService'
import { logout, setUserData } from '../../store/accountSlice'

const Auth = ({ children }) => {
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    const initAuth = async () => {
      authService.setAxiosInterceptors({
        onLogout: () => dispatch(logout()),
      })

      authService.handleAuthentication()

      if (authService.isAuthenticated()) {
        try {
          const user = await authService.loginInWithToken()
          Sentry.setUser({
            id: user.id,
            email: user.email,
          })
          ReactGA.set({ userId: user.id })

          await dispatch(setUserData(user))
        } catch (e) {
          Sentry.captureException(e)
        }
      }

      setLoading(false)
    }

    initAuth()
  }, [dispatch])

  if (isLoading) {
    return <LoadingScreen />
  }

  return children
}

Auth.propTypes = {
  children: PropTypes.any,
}

export default Auth
