import React, { useEffect } from 'react'
import Container from '@material-ui/core/Container'
import { Redirect, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useQuery, useQueryClient } from 'react-query'
import ReactMarkdown from 'react-markdown/with-html'
import { makeStyles } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import _ from 'lodash'

import Page from '../layout/Page'
import HeroTitle from '../components/Article/HeroTitle'
import { logPostView } from '../store/postSlice'
import postService from '../services/postService'
import usePostQueryKey from '../utils/usePostsQueryKey'

import SocialShare from '../components/Article/SocialShare'
import NextLine from '../components/Article/PostNavLine'
import HeroTitleSkeleton from '../components/Article/HeroTitleSkeleton'
import BackButton from '../components/Article/BackButton'

const Article = props => {
  const classes = useStyles()
  const { slug } = useParams()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const queryKey = usePostQueryKey()

  const { data: post, isLoading, isError } = useQuery(
    ['post', slug],
    () => postService.getPostBySlug(slug),
    {
      initialData: () => {
        const pages = queryClient.getQueryData(queryKey)?.pages
        if (!pages) return

        const allPost = _.flatten(pages)

        return allPost?.find(d => d.slug === slug)
      },
    }
  )

  const prettifyContent = content => {
    if (!content) {
      return null
    }

    const iframeRegexp = /<iframe(.+)<\/iframe>/g

    const results = content.match(iframeRegexp)
    if (!results) {
      return content
    }

    const iframes = [...results]

    if (iframes.length) {
      let newContent = content
      iframes.forEach(iframe => {
        const modifiedIframe = iframe.replace(/(width|height)="([0-9]*)"/g, '')
        newContent = newContent.replace(
          iframe,
          `<div className=${classes['embed-responsive']}>${modifiedIframe}</div>`
        )
      })

      return newContent
    }

    return content
  }

  useEffect(() => {
    dispatch(logPostView())
  }, [dispatch])

  if (isLoading) {
    return (
      <div style={{ backgroundColor: '#fff', height: '100%' }}>
        <HeroTitleSkeleton />
        <Container maxWidth="md" className={classes.content}>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </Container>
      </div>
    )
  }

  if (isError) {
    return <Redirect to="/" />
  }

  return (
    <Page title={post.title} post={post}>
      <div style={{ backgroundColor: '#fff' }}>
        <HeroTitle post={post} />
        <Container maxWidth="md">
          <BackButton post={post} className={classes.button} />
          <ReactMarkdown
            source={prettifyContent(post.content)}
            escapeHtml={false}
            className={classes.content}
          />
          <SocialShare post={post} />
          <NextLine post={post} />
        </Container>
      </div>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  content: {
    fontSize: 18,
    marginTop: theme.spacing(2),
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3),
      fontSize: 20,
    },
    '& p': {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(4),
      },
    },
    '& iframe': {
      margin: '0 auto',
      maxWidth: '100%',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(4),
      },
    },
    '& div': {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(4),
      },
    },
    '& img': {
      maxWidth: '100%',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(4),
      },
    },
    '& h2': {
      fontSize: 18,
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        fontSize: 20,
        marginBottom: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 24,
      },
    },
    '& ul': {
      marginLeft: theme.spacing(4),
    },
    '& a:link, & a:visited': {
      color: theme.palette.primary.main,
    },
    '& a:hover, & a:focus': {
      fontSize: 18,
      marginTop: theme.spacing(2),
      overflow: 'hidden',
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(3),
        fontSize: 20,
      },
      '& p': {
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          marginBottom: theme.spacing(4),
        },
      },
      '& iframe': {
        margin: '0 auto',
        maxWidth: '100%',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          marginBottom: theme.spacing(4),
        },
      },
      '& div': {
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          marginBottom: theme.spacing(4),
        },
      },
      '& img': {
        maxWidth: '100%',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          marginBottom: theme.spacing(4),
        },
      },
      '& h2': {
        fontSize: 18,
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          fontSize: 20,
          marginBottom: theme.spacing(4),
        },
        [theme.breakpoints.up('md')]: {
          fontSize: 24,
        },
      },
      '& ul': {
        marginLeft: theme.spacing(4),
      },
      '& a:link, & a:visited': {
        color: theme.palette.primary.main,
      },
      '& a:hover, & a:focus': {
        textDecoration: 'none',
      },
      textDecoration: 'none',
    },
  },
  'embed-responsive': {
    position: 'relative',
    display: 'block',
    width: '100%',
    padding: 0,
    overflow: 'hidden',
    '&::before': {
      display: 'block',
      content: "' '",
      paddingTop: '56.25%',
    },
    '& iframe': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: '100%',
      height: '100%',
      border: 0,
    },
  },
  button: {
    fontSize: 12,
    borderRadius: 6,
    fontWeight: 400,
    border: 'solid 1px #6D6D6D',
    textDecoration: 'none !important',
    boxShadow: '0 0 4px rgba(0,0,0,0.2)',
    padding: 12,
    marginTop: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(10),
    },
    '&.Mui-disabled': {
      color: '#6D6D6D',
    },
  },
}))

export default Article
