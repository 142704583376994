import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useQuery } from 'react-query'

import inviteService from '../services/inviteService'

import Page from '../layout/Page'
import FormHeader from '../components/Auth/FormHeader'
import SignUpForm from '../components/Auth/SignUpForm'
import { CircularProgress } from '@material-ui/core'

const SignUp = props => {
  const history = useHistory()
  const query = new URLSearchParams(useLocation().search)
  const code = query.get('code')
  const { isLoading, data } = useQuery(
    ['invite', code],
    () => inviteService.getByCode(code),
    {
      enabled: !!code,
    }
  )

  const onSuccessHandler = () => {
    history.push('/')
  }

  if (isLoading) {
    return (
      <Page title="Sign up">
        <FormHeader
          title="Create your free account"
          text="Your details will only ever be used by us regarding your report"
        />
        <CircularProgress style={{ marginTop: 32 }} />
      </Page>
    )
  }

  if (data) {
    return (
      <Page title="Sign up">
        <FormHeader
          title="You’ve been invited! Create your free account"
          text="Your details will only ever be used by us regarding your report"
        />
        <SignUpForm
          onSuccess={onSuccessHandler}
          email={data?.email}
          companyId={data?.company?.id}
        />
      </Page>
    )
  }

  return (
    <Page title="Sign up">
      <FormHeader
        title="Create your free account"
        text="Your details will only ever be used by us regarding your report"
      />
      <SignUpForm onSuccess={onSuccessHandler} />
    </Page>
  )
}

export default SignUp
