import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import { makeStyles } from '@material-ui/core'

import Label from './Label'

const useStyles = makeStyles(theme => ({
  root: {
    transition: theme.transitions.create('opacity'),
    opacity: disabled => (disabled ? 0.2 : 1),
    marginBottom: theme.spacing(2),
  },
  checkbox: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
    borderRadius: 6,
    boxShadow: '0 2px 10px rgb(0 0 0 / 0.12)',
    padding: theme.spacing(4 / 3 / 2, 1),
    margin: 0,
  },
  labelMuted: { fontWeight: 400, color: 'black' },
}))

const RadioInput = ({
  label,
  disabled,
  options,
  row,
  name,
  error,
  control,
}) => {
  const classes = useStyles(disabled)

  return (
    <div className={classes.root}>
      <Label>{label}</Label>
      {error ? error.message : null}

      <Controller
        as={
          <RadioGroup aria-label={label}>
            <Grid container spacing={2}>
              {options.map(option => (
                <Grid item xs={12} md={row ? 12 : 4} key={option}>
                  <FormControlLabel
                    disabled={disabled}
                    value={option}
                    className={classes.checkbox}
                    control={<Radio color="primary" />}
                    label={option}
                  />
                </Grid>
              ))}
            </Grid>
          </RadioGroup>
        }
        name={name}
        control={control}
      />
    </div>
  )
}

RadioInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.array.isRequired,
  row: PropTypes.bool,
  error: PropTypes.object,
}

RadioInput.defaultProps = {
  disabled: false,
  options: [],
  row: false,
}

export default RadioInput
