import React from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { selectUpgradeModalStatus, setUpgradeModal } from '../../store/uiSlice'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

function UpgradeModal() {
  const classes = useStyles()
  const status = useSelector(selectUpgradeModalStatus)
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  const onClose = () => {
    dispatch(setUpgradeModal(false))
  }

  const handleUpgradeClick = () => {
    onClose()
    history.push('/setting/upgrade')
  }

  return (
    <Dialog
      open={status}
      onClose={onClose}
      fullScreen={!isDesktop}
      classes={{
        paper: classes.paper,
      }}
    >
      <DialogContent className={classes.container}>
        <Typography className={classes.title}>
          Upgrade your plan to gain access to <br /> these extra features
        </Typography>
        <ul className={classes.list}>
          <li>Add and collaborate with unlimited team members</li>
          <li>Generate action lists based on your assessment</li>
          <li>Mark actions as complete</li>
          <li>Assign sections or actions to your team members</li>
        </ul>
        <div className={classes.modalFooter}>
          <Button
            onClick={onClose}
            variant="outlined"
            color="primary"
            size="large"
            className={classes.laterButton}
          >
            Not right now
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.upgradeButton}
            onClick={handleUpgradeClick}
          >
            Upgrade to team plan
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    padding: theme.spacing(4),
    flex: 'initial',
  },
  root: {
    borderRadius: 0,
    maxWidth: 524,
    width: '100%',
    padding: theme.spacing(4),
    overflowY: 'initial',
    [theme.breakpoints.up('md')]: {
      borderRadius: 10,
    },
  },
  modalFooter: {
    textAlign: 'center',
    marginTop: theme.spacing(3),
    position: 'relative',
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: theme.spacing(3),
  },
  list: {
    fontSize: 16,
    listStyleType: 'none',
    '& li': {
      display: 'flex',
      marginBottom: theme.spacing(2),
      alignItems: 'baseline',

      '& > a': {
        color: theme.palette.primary.main,
      },

      '&::before': {
        content: '" "',
        display: 'inline-flex',
        flex: '0 0 10px',
        backgroundColor: '#242ED6',
        marginRight: theme.spacing(2),
        width: 10,
        height: 10,
        borderRadius: 10,
      },
    },
  },
  upgradeButton: {
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      width: '100%',
    },
  },
  laterButton: {
    boxShadow: 'none',
    borderWidth: 2,
    marginRight: 16,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

export default UpgradeModal
