import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { setShowPopover } from '../../store/postSlice'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import ArrowBack from '@material-ui/icons/ArrowBack'

import Share from '../Share'
import DrawerButton from './DrawerButton'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[15],
  },
  right: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    width: 58,
    height: '100%',
    '& > .icon': {
      color: theme.palette.primary.main,
      width: 20,
    },
    '& > span': {
      fontSize: 10,
    },
  },
}))

const MobileBar = ({ post }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const account = useSelector(state => state.account)
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  const handleBackClick = React.useCallback(() => {
    dispatch(setShowPopover({ id: post.id }))
    history.goBack()
  }, [post, dispatch, history])

  if (isDesktop || !account.user) {
    return null
  }

  return (
    <AppBar color="default" className={classes.root}>
      <Toolbar>
        <DrawerButton
          className={classes.button}
          icon={ArrowBack}
          onClick={handleBackClick}
          disabled={!post}
        >
          Back
        </DrawerButton>
        <div className={classes.right}>
          <Share post={post} size="small" />
        </div>
      </Toolbar>
    </AppBar>
  )
}

MobileBar.propTypes = {
  post: PropTypes.object,
}

export default MobileBar
