import * as React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Typography } from '@material-ui/core'

function SettingContainer(props) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>{props.title}</Typography>
      {props.children}
    </div>
  )
}

SettingContainer.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
    maxWidth: 600,
    margin: '0 auto',
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    color: theme.palette.secondary.main,
    fontSize: 16,
    [theme.breakpoints.up('md')]: {
      fontSize: 20,
    },
  },
}))

export default SettingContainer
