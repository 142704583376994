import { createSlice, nanoid } from '@reduxjs/toolkit'
import {
  changePassword,
  login,
  resetPassword,
  saveSurvey,
  signup,
} from './accountSlice'
import { getErrorMessage } from '../utils'

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    notifications: [],
  },
  reducers: {
    enqueueSnackbar: (state, action) => {
      state.notifications.push(action.payload)
    },
    removeSnackbar: (state, action) => {
      state.notifications = state.notifications.filter(
        notification => notification.key !== action.payload.key
      )
    },
    closeSnackbar: (state, action) => {
      state.notifications = state.notifications.map(notification =>
        action.payload.dismissAll || notification.key === action.payload.key
          ? { ...notification, dismissed: true }
          : { ...notification }
      )
    },
  },
  extraReducers: {
    [login.rejected]: (state, action) => {
      const key = nanoid()

      state.notifications.push({
        message: 'Invalid login! Please try again!',
        key: nanoid(),
        options: {
          key,
          variant: 'error',
        },
      })
    },
    [signup.rejected]: (state, action) => {
      const errors = getErrorMessage(action)
      errors.forEach(error => {
        let errorMsg = ''
        switch (error.id) {
          case 'Auth.form.error.email.taken':
            errorMsg = error.message
            break
          default:
            errorMsg = 'Please try again!'
            break
        }
        const key = nanoid()
        state.notifications.push({
          message: errorMsg,
          key,
          options: {
            key,
            variant: 'error',
          },
        })
      })
    },
    [resetPassword.rejected]: (state, action) => {
      const key = nanoid()
      state.notifications.push({
        message: 'Please try again!',
        key,
        options: {
          key,
          variant: 'error',
        },
      })
    },
    [saveSurvey.rejected]: (state, action) => {
      const key = nanoid()
      state.notifications.push({
        message: 'Please try again!',
        key,
        options: {
          key,
          variant: 'error',
        },
      })
    },
    [changePassword.rejected]: state => {
      const key = nanoid()
      state.notifications.push({
        message: 'Old password is invalid.',
        key,
        options: {
          key,
          variant: 'error',
        },
      })
    },
  },
})

export const { enqueueSnackbar, removeSnackbar } = notificationSlice.actions
export default notificationSlice.reducer
