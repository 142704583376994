import React from 'react'
import PropTypes from 'prop-types'
import { alpha, makeStyles } from '@material-ui/core'

import Share from '../Share'
import Excerpt from './Excerpt'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    padding: theme.spacing(2),
  },
}))

const DrawerContent = ({ post }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Excerpt excerpt={post ? post.excerpt : null} />
      <Share post={post} size="small" />
    </div>
  )
}

DrawerContent.propTypes = {
  post: PropTypes.object,
}

export default DrawerContent
