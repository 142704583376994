import React from 'react'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'

import Page from '../layout/Page'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    '& > p': {
      marginBottom: theme.spacing(2),
    },
  },
  title: {
    fontWeight: 600,
    fontSize: 26,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      fontSize: 36,
    },
  },
  subTitle: {
    fontWeight: 600,
    fontSize: 22,
  },
}))

const SubTitle = ({ children }) => {
  const classes = useStyles()

  return (
    <Typography variant="h5" component="h2" className={classes.subTitle}>
      {children}
    </Typography>
  )
}

const PrivacyPolicy = props => {
  const classes = useStyles()

  return (
    <Page title="Privacy Policy">
      <Container maxWidth="md" className={classes.root}>
        <Typography variant="h5" component="h1" className={classes.title}>
          Privacy policy
        </Typography>
        <SubTitle>Introduction</SubTitle>
        <Typography>
          Privacy matters to us because trust is a foundation for a good working
          relationship. This privacy notice explains how we will collect, store
          and use your details. This privacy notice relates to our website, our
          booking process, our training and how we keep in touch with you in the
          future.
        </Typography>
        <SubTitle>Who are we?</SubTitle>
        <Typography>
          ‘Resilience in Construction’ is a trading name of Built Environment
          Support and Training Ltd. Our registered office is 272 Bath Street,
          Glasgow, G2 4JR.
        </Typography>
        <SubTitle> What information do we collect?</SubTitle>
        <Typography>
          During the course booking process we will collect company name, phone
          number, contact name and address.
        </Typography>
        <Typography>
          Any credit or debit card payments and Direct Debits are handled by
          third parties. We do not see your details. We only use providers who
          are GDPR-compliant.
        </Typography>
        <Typography>
          We will collect delegate email addresses in case we need to send them
          a link to online supporting material. Delegates then register for the
          courses with third party platforms which are GDPR-compliant. You can
          unsubscribe from these courses and associated mailing lists at any
          time.
        </Typography>
        <Typography>
          If you call us on our 0845 number, your phone number will be displayed
          and we may call you back on that number. After the booking process we
          do not keep your number without your permission.
        </Typography>
        <Typography>
          If you provide your number as part of the enquiry or booking process
          we will not use it for marketing purposes, nor share it with anyone
          else.
        </Typography>
        <SubTitle>How do we use personal information?</SubTitle>
        <Typography>
          We use your data for:
          <li>
            Personalisation of content, business information or user experience
          </li>
          <li>Account set up and administration</li>
          <li>Delivering marketing and events communication</li>
          <li>Carrying out polls and surveys</li>
          <li>Internal research and development purposes</li>
          <li>Providing goods and services</li>
          <li>Legal obligations (e.g. prevention of fraud)</li>
          <li> Meeting internal audit requirements</li>
        </Typography>
        <SubTitle>
          What legal basis do we have for processing your personal data?
        </SubTitle>
        <Typography>
          All data we collect is based on one or more of the following legal
          grounds:
          <li>Consent</li>
          <li>Contract</li>
          <li>Legitimate interests</li>
          <li>Vital interests</li>
          <li>Public task</li>
          <li>Legal obligation</li>
        </Typography>
        <SubTitle>When do we share personal data?</SubTitle>
        <Typography>
          If you wish to be added to the Construction Training Register, we will
          share your data with the CITB. If we use third-party software, they
          are GDPR-compliant.
        </Typography>
        <SubTitle>Where do we store and process personal data?</SubTitle>
        <Typography>
          We store your data on:
          <li>
            A securely-stored spreadsheet which is only accessible to DCS
            employees and associates
          </li>
          <li>A secure automated training management system</li>
          <li>3rd party software which is GDPR compliant</li>
          <li>Printed hard copy invoices</li>
          <li>Electronic copies of invoices</li>
          <li>
            Correspondence contained within our email inbox, sent items and
            address book.
          </li>
        </Typography>
        <SubTitle>How do we secure personal data?</SubTitle>
        <Typography>
          We store your personal data in a way that:
          <li>Protects data against accidental loss</li>
          <li>Prevents unauthorised access, use, destruction or disclosure</li>
          <li>Ensures business continuity and disaster recovery</li>
          <li>Restricts access to personal information.</li>
        </Typography>
        <SubTitle>How long do we keep your personal data for?</SubTitle>
        <Typography>
          We store customers’ data for at least seven years. This is for
          accounts purposes.
        </Typography>
        <Typography>
          We store marketing data for as long as you wish to remain on our
          online course or marketing lists. You can unsubscribe at any time.
        </Typography>
        <SubTitle>Your rights in relation to personal data</SubTitle>
        <Typography>
          You can:
          <li>Access the details we hold about you at any time</li>
          <li>
            Request correction and deletion (unless we are required by law to
            keep the data)
          </li>
          <li>
            Withdraw consent at any time (you must withdraw consent from CITB
            separately, we have no control over that)
          </li>
          <li>Lodge a complaint with the Information Commissioner’s Office.</li>
        </Typography>
        <SubTitle>How to contact us</SubTitle>
        <Typography>
          If you wish to discuss anything in this privacy policy you can call us
          on <a href="tel:0845 519 563">0845 519 563</a> or email 
          <a href="mailto:saffron@digitalconstructionskills.com">
            saffron@digitalconstructionskills.com
          </a>
          .
        </Typography>
        <SubTitle>Use of cookies and other technologies</SubTitle>
        <Typography>
          A cookie is a small file which asks permission to be placed on your
          computer’s hard drive. Once you agree, the file is added and the
          cookie helps analyse web traffic or lets us know when you visit a
          particular site. Cookies allow web applications to respond to you as
          an individual. The web application can tailor its operations to your
          needs, likes and dislikes by gathering and remembering information
          about your preferences.
        </Typography>
        <Typography>
          We use traffic log cookies to identify which pages are being used.
          This helps us analyse data about web page traffic and improve our
          website in order to tailor it to customer needs. We only use this
          information for statistical analysis purposes; the data is then
          removed from the system.
        </Typography>
        <Typography>
          Overall, cookies help us provide you with a better website, by
          enabling us to monitor which pages you find useful and which you do
          not. A cookie in no way gives us access to your computer or any
          information about you, other than the data you choose to share with
          us.
        </Typography>
        <Typography>
          You can choose to accept or decline cookies. Most web browsers
          automatically accept cookies, but you can usually modify your browser
          setting to decline cookies if you prefer. This may prevent you from
          taking full advantage of the website.
        </Typography>
        <SubTitle>Linking to other websites/third-party content</SubTitle>
        <Typography>
          Our website may contain links to enable you to visit other websites of
          interest easily. However, once you have used these links to leave our
          site, you should note that we do not have any control over that other
          website. Therefore, we cannot be responsible for the protection and
          privacy of any information which you provide whilst visiting such
          sites and such sites are not governed by this privacy statement. You
          should exercise caution and look at the privacy statement applicable
          to the website in question.
        </Typography>
        <SubTitle>Asking for a PDF of this privacy statement</SubTitle>
        <Typography>
          If you would like us to email you a dated PDF version of our privacy
          statement, please email 
          <a href="mailto:webwriter@digitalconstructionskills.com">
            webwriter@digitalconstructionskills.com
          </a>
        </Typography>
      </Container>
    </Page>
  )
}

export default PrivacyPolicy
