import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'
import { dotify } from '../../utils'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: ({ excerpt }) => (excerpt ? theme.spacing(5) : 0),
  },
  title: {
    fontSize: 18,
    fontWeight: 900,
    marginBottom: theme.spacing(1),
  },
  excerpt: {
    fontSize: 14,
  },
  readMore: {
    color: '#393939',
    fontSize: 14,
    textDecoration: 'underline',
    cursor: 'pointer',
    marginTop: theme.spacing(1),
    display: 'block',
  },
}))

const Excerpt = ({ excerpt }) => {
  const classes = useStyles({ excerpt })
  const [readMore, setReadMore] = useState(true)

  const toggleReadMore = () => {
    setReadMore(!readMore)
  }

  return (
    <div className={classes.root}>
      <Typography
        variant="h5"
        component="h5"
        color="secondary"
        className={classes.title}
      >
        About this content
      </Typography>
      {excerpt ? (
        <React.Fragment>
          <Typography className={classes.excerpt}>
            {readMore ? dotify(excerpt, 160) : excerpt}
          </Typography>
          <Typography
            component="a"
            onClick={toggleReadMore}
            className={classes.readMore}
          >
            Read {readMore ? 'more' : 'less'}
          </Typography>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </React.Fragment>
      )}
    </div>
  )
}

Excerpt.propTypes = {
  excerpt: PropTypes.string,
}

export default Excerpt
