import React from 'react'
import { useQuery } from 'react-query'
import { Link, useParams } from 'react-router-dom'
import { makeStyles, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import Avatar from '@material-ui/core/Avatar'
import ReactPlayer from 'react-player/lazy'
import { useSelector } from 'react-redux'
import ReactMarkdown from 'react-markdown/with-html'

import { generateMonogram, getAvatarUrl } from '../utils'
import mentorService from '../services/mentorService'

import Page from '../layout/Page'
import LoadingScreen from '../components/LoadingScreen'
import ResponsiveImage from '../components/ResponsiveImage'
import BookingModal from '../components/Mentor/BookingModal'
import { isAdmin } from '../store/accountSlice'

const MentorSingle = () => {
  const { id } = useParams()
  const classes = useStyles()
  const { isLoading, data: mentor, isError } = useQuery(['mentor', id], () =>
    mentorService.getById(id)
  )
  const isAdminRole = useSelector(isAdmin)

  if (isLoading) {
    return (
      <Page title="Mentors">
        <Container maxWidth="lg" className={classes.root}>
          <LoadingScreen />
        </Container>
      </Page>
    )
  }

  if (isError) {
    return (
      <Page title="Mentors">
        <Container maxWidth="lg" className={classes.root}>
          <p>Something went wrong! Try again later!</p>
        </Container>
      </Page>
    )
  }

  return (
    <Page title={`${mentor?.first_name} ${mentor?.last_name}  - Mentor`}>
      <Container maxWidth="lg" className={classes.root}>
        <Button className={classes.backBtn} component={Link} to="/mentors">
          <ArrowBack />
          Back to Experts
        </Button>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <div className={classes.stickyContainer}>
              <div className={classes.meta}>
                {mentor.avatar ? (
                  <Avatar
                    src={getAvatarUrl(mentor.avatar)}
                    className={classes.avatar}
                  />
                ) : (
                  <Avatar className={classes.avatar}>
                    {mentor.first_name[0]}
                    {mentor.last_name[0]}
                  </Avatar>
                )}
                <Typography className={classes.name}>
                  {mentor.first_name} {mentor.last_name}
                </Typography>
                <div className={classes.categories}>
                  {mentor.categories.map(category => (
                    <span className={classes.label} key={category.id}>
                      {category.title}
                    </span>
                  ))}
                </div>
                <BookingModal
                  className={classes.bookBtn}
                  name={`${mentor.first_name} ${mentor.last_name}`}
                  calendars={mentor.calendars}
                  mentorId={mentor.id}
                />
                {isAdminRole ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    component={Link}
                    className={classes.btn}
                    to={`/setting/team-members`}
                    fullWidth
                  >
                    Purchase Credits
                  </Button>
                ) : null}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography className={classes.title} variant="h4" component="h3">
              Overview
            </Typography>

            {mentor.image ? (
              <div className={classes.thumbnailContainer}>
                <ResponsiveImage
                  image={mentor.image}
                  size="large"
                  className={classes.thumbnail}
                />
              </div>
            ) : null}
            {mentor.video_url ? (
              <div className={classes.wrapper}>
                <ReactPlayer
                  url={mentor.video_url}
                  width="100%"
                  height="100%"
                  controls
                  className={classes.player}
                />
              </div>
            ) : null}
            {mentor.overview ? (
              <div className={classes.overview} style={{ marginBottom: 24 }}>
                <Typography className={classes.overviewTitle}>
                  Experience Overview
                </Typography>
                <ReactMarkdown
                  source={mentor.overview}
                  escapeHtml={false}
                  className={classes.content}
                />
              </div>
            ) : null}

            {mentor.statement ? (
              <div className={classes.overview} style={{ marginBottom: 24 }}>
                <Typography className={classes.overviewTitle}>
                  Expert statement
                </Typography>
                <ReactMarkdown
                  source={mentor.statement}
                  escapeHtml={false}
                  className={classes.content}
                />
              </div>
            ) : null}
            {mentor.testimonials && mentor.testimonials.length > 0 ? (
              <div>
                <Typography className={classes.title}>Testimonials</Typography>
                <Grid spacing={4} container>
                  {mentor.testimonials.map(testimonial => (
                    <Grid xs={12} sm={6} item key={testimonial.id}>
                      <div className={classes.testimonial}>
                        {testimonial.text ? <p>{testimonial.text}</p> : null}
                        <div className={classes.testimonialMeta}>
                          {testimonial.avatar ? (
                            <Avatar
                              src={getAvatarUrl(testimonial.avatar)}
                              className={classes.testimonialAvatar}
                            />
                          ) : (
                            <Avatar className={classes.testimonialAvatar}>
                              {generateMonogram(testimonial.name)}
                            </Avatar>
                          )}
                          <Typography className={classes.testimonialName}>
                            {testimonial.name}
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </div>
            ) : null}
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
  },
  meta: {
    borderRadius: 12,
    backgroundColor: 'white',
    padding: theme.spacing(3),
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  name: {
    fontSize: 28,
    fontWeight: 600,
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      fontSize: 32,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 36,
    },
  },
  stickyContainer: {
    position: 'sticky',
    top: theme.spacing(2) + 51, //toolbar + root paddingTop
    maxWidth: 520,
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
      top: theme.spacing(8) + 84,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 'initial',
      margin: 'inherit',
    },
  },
  avatar: {
    width: 64,
    height: 64,
    backgroundColor: theme.palette.primary.main,
    marginBottom: theme.spacing(3),
    '& svg': {
      fontSize: 48,
      [theme.breakpoints.up('sm')]: {
        fontSize: 64,
      },
    },
  },
  label: {
    fontSize: 12,
    color: '#1E40AF',
    backgroundColor: '#DBEAFE',
    padding: theme.spacing(0, 1),
    borderRadius: 4,
    display: 'inline-flex',
    '& :not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  categories: {
    marginBottom: theme.spacing(3),

    '& > :not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  btn: {
    flex: 1,
    boxShadow: 'none',
    fontSize: 14,
    fontWeight: 500,
    padding: '9px 20px !important',
  },
  bookBtn: {
    flex: 1,
    boxShadow: 'none',
    fontSize: 14,
    fontWeight: 500,
    padding: '9px 20px !important',
    borderColor: theme.palette.primary.main,
    borderWidth: '2px !important',
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  backBtn: {
    marginBottom: theme.spacing(2),
    fontSize: 14,
    borderRadius: 6,
    fontWeight: 500,
    border: 'solid 1px #D1D5DB',
    textDecoration: 'none !important',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
    backgroundColor: 'white',
    padding: '6px 12px',
    '& svg': {
      marginRight: 8,
      width: 16,
      height: 16,
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 24,
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      fontSize: 30,
      marginBottom: theme.spacing(4),
    },
  },
  overview: {
    borderRadius: 12,
    backgroundColor: 'white',
    padding: theme.spacing(3),
  },
  overviewTitle: {
    fontWeight: 600,
    fontSize: 20,
    marginBottom: theme.spacing(2),
  },
  player: {
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: 12,
    overflow: 'hidden',
  },
  wrapper: {
    position: 'relative',
    paddingTop: '56.25%',
    borderRadius: 12,
    marginBottom: 24,
  },
  thumbnail: {
    width: '100%',
    borderRadius: 12,
  },
  thumbnailContainer: {
    position: 'relative',
    width: '100%',
    marginBottom: 24,
  },
  testimonial: {
    borderRadius: 12,
    backgroundColor: 'white',
    padding: theme.spacing(3),
  },
  testimonialAvatar: {
    width: 40,
    height: 40,
    backgroundColor: theme.palette.primary.main,
    '& svg': {
      fontSize: 30,
      [theme.breakpoints.up('sm')]: {
        fontSize: 40,
      },
    },
  },
  testimonialMeta: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(3),
  },
  testimonialName: {
    fontWeight: 600,
    fontSize: 18,
    marginLeft: theme.spacing(1),
  },
  content: {
    overflow: 'hidden',
    '& p': {
      marginBottom: theme.spacing(2),
    },
    '& img': {
      maxWidth: '100%',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(4),
      },
    },
    '& h2': {
      fontSize: 18,
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        fontSize: 20,
        marginBottom: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 24,
      },
    },
    '& ul': {
      marginLeft: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    '& a:link, & a:visited': {
      color: theme.palette.primary.main,
    },
    '& a:hover, & a:focus': {
      fontSize: 18,
      marginTop: theme.spacing(2),
      overflow: 'hidden',
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(3),
        fontSize: 20,
      },
      '& p': {
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          marginBottom: theme.spacing(4),
        },
      },
      '& iframe': {
        margin: '0 auto',
        maxWidth: '100%',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          marginBottom: theme.spacing(4),
        },
      },
      '& div': {
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          marginBottom: theme.spacing(4),
        },
      },
      '& img': {
        maxWidth: '100%',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          marginBottom: theme.spacing(4),
        },
      },
      '& h2': {
        fontSize: 18,
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          fontSize: 20,
          marginBottom: theme.spacing(4),
        },
        [theme.breakpoints.up('md')]: {
          fontSize: 24,
        },
      },
      '& ul': {
        marginLeft: theme.spacing(4),
      },
      '& a:link, & a:visited': {
        color: theme.palette.primary.main,
      },
      '& a:hover, & a:focus': {
        textDecoration: 'none',
      },
      textDecoration: 'none',
    },
  },
}))

export default MentorSingle
