import { useMutation, useQueryClient } from 'react-query'
import actionPlanService from '../services/actionPlanService'

export default function useAssignUserToTask() {
  const queryClient = useQueryClient()

  return useMutation(actionPlanService.putEditWithEmail, {
    onMutate: async ({ id, data }) => {
      await queryClient.cancelQueries('action_plan_assignments')
      const previousValue = queryClient.getQueryData('action_plan_assignments')
      const teamMembers = queryClient.getQueryData('team')

      const newValue = previousValue.map(item => {
        if (item.id === id) {
          const tasks = data.task_to_user.map(task => {
            //if no user, just user id provided, get the user data from the cache
            if (typeof task.user !== 'object' && teamMembers) {
              const user = teamMembers.find(user => user.id === task.user)
              return {
                ...task,
                user,
              }
            }

            return task
          })

          return {
            ...item,
            ...data,
            task_to_user: tasks,
          }
        }
        return item
      })
      queryClient.setQueryData('action_plan_assignments', newValue)

      return { previousValue }
    },
    onError: (err, variables, previousValue) => {
      queryClient.setQueryData('action_plan_assignments', previousValue)
    },
  })
}
