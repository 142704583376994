import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import * as ReactGA from 'react-ga'
import IconButton from '@material-ui/core/IconButton'
import Favorite from '@material-ui/icons/Favorite'
import Reply from '@material-ui/icons/Reply'
import Done from '@material-ui/icons/Done'
import { makeStyles } from '@material-ui/core'
import { darken } from '@material-ui/core/styles/colorManipulator'

import { getPostUrl } from '../utils'
import useMarkPost from '../utils/useMarkPost'

const ShareIcon = props => {
  const classes = useStyles({ active: props.active })

  return (
    <IconButton className={classes.button} onClick={props.onClick}>
      {props.children}
    </IconButton>
  )
}

const Share = ({ size, post }) => {
  const classes = useStyles({ size })
  const account = useSelector(state => state.account)
  const markPost = useMarkPost()

  if (!post) {
    return null
  }

  const shareArray = [
    {
      text: 'Share',
      icon: <Reply />,
      active: false,
      onClick: () => {
        ReactGA.event({
          category: 'Post',
          action: 'Share via email',
        })

        window.open(
          `mailto:?subject=I'd like to share a link with you&body=${
            process.env.REACT_APP_CLIENT_URL
          }${getPostUrl(post)}`
        )
      },
    },
  ]

  if (account.user) {
    shareArray.push({
      text: 'Fav',
      icon: <Favorite />,
      active: post.favourite_by_user,
      onClick: () => {
        ReactGA.event({
          category: 'Post',
          action: 'Add to Favourite',
        })

        markPost.mutate({ type: 'favourite_by_user', id: post.id })
      },
    })

    shareArray.push({
      text: 'Done',
      icon: <Done />,
      active: post.marked_done_by,
      onClick: () => {
        ReactGA.event({
          category: 'Post',
          action: 'Mark as Done',
        })
        markPost.mutate({ type: 'marked_done_by', id: post.id })
      },
    })
  }

  return (
    <div className={classes.root}>
      {shareArray.map(item => (
        <div className={classes.buttonContainer} key={item.text}>
          <ShareIcon
            text={item.text}
            active={item.active}
            onClick={item.onClick}
            key={item.text}
          >
            {item.icon}
          </ShareIcon>
          {size === 'default' ? item.text : null}
        </div>
      ))}
    </div>
  )
}

Share.propTypes = {
  size: PropTypes.oneOf(['small', 'default']),
  post: PropTypes.object,
}

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: ({ size }) => (size === 'small' ? 0 : 12), //label text height
    display: 'flex',
    '& button': {
      padding: ({ size }) => (size ? 5 : 'inherit'),
    },
  },
  button: {
    backgroundColor: ({ active }) =>
      active ? theme.palette.primary.main : '#D7D7D7',
    color: '#fff',
    '&:hover': {
      backgroundColor: ({ active }) =>
        active
          ? darken(theme.palette.primary.main, 0.2)
          : darken('#D7D7D7', 0.2),
    },
  },
  buttonContainer: {
    marginRight: ({ size }) => (size === 'small' ? 10 : theme.spacing(2)),
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    fontSize: 12,
  },
}))

export default React.memo(Share)
