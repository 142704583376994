import React from 'react'
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core'
import { Switch, Route } from 'react-router-dom'

import logo from '../assets/logo.png'
import { PAGE_TITLE } from '../constans'
import HomePageMenu from './Menu/HomePageMenu'
import AccountAvatar from './Menu/AccountAvatar'
import KnowledgeHubMenu from './Menu/KnowledgeHubMenu'
import QuestionnaireMenu from './Menu/QuestionnaireMenu'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[15],
  },
  logoContainer: {
    // flexGrow: 1,
    display: 'flex',
  },
  logo: {
    height: 35,
    [theme.breakpoints.up('sm')]: {
      height: 65,
    },
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'flex-end',
    '& > *': {
      margin: theme.spacing(1) / 2,
      [theme.breakpoints.up('sm')]: {
        margin: theme.spacing(1),
      },
    },
  },
}))

const Header = props => {
  const classes = useStyles()

  return (
    <AppBar color="default" className={classes.root}>
      <Toolbar>
        <div className={classes.logoContainer}>
          <Link to="/">
            <img src={logo} alt={PAGE_TITLE} className={classes.logo} />
          </Link>
        </div>
        <div className={classes.buttonGroup}>
          <Switch>
            <Route path="/" component={HomePageMenu} exact />
            <Route
              path="/questionnaire/intro"
              component={QuestionnaireMenu}
              exact
            />
            <Route path="/setting" component={QuestionnaireMenu} />
            <Route path="/questionnaire/:slug" component={QuestionnaireMenu} />
            <Route path="/tell-us-about-you" render={null} />
            <Route component={KnowledgeHubMenu} />
          </Switch>
          <AccountAvatar />
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default Header
