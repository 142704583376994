import React from 'react'
import { Container, makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'

import PricingColumn from '../Upgrade/PricingColumn'

function PricingTable() {
  const classes = useStyles()
  const history = useHistory()

  const handleEnterprise = () => {
    window.open('mailto:support@RDP.com', '_blank').focus()
  }

  const handleSignUp = () => {
    history.push('/sign-up')
  }

  return (
    <div className={classes.wrapper} id="pricing">
      <Container className={classes.root}>
        <Typography
          component="h3"
          variant="h2"
          color="primary"
          gutterBottom
          className={classes.title}
        >
          Pricing
        </Typography>
        <Typography className={classes.text}>
          Resilience Diagnostic Platform has your business covered regardless of
          your size.
        </Typography>
        <div className={classes.table}>
          <PricingColumn
            title="Individual"
            text="Hit the ground running"
            price="Free"
            priceSuffix=" / forever"
            data={[
              'Single login',
              'Resilience assessment questions covering 12 different areas of business resilience',
              'Access to exclusive content in the knowledge hub',
              'Retake the assessment as many times as you want',
            ]}
            buttonText="Sign up now"
            buttonOnclick={handleSignUp}
          />
          <PricingColumn
            title="Team"
            text="Power up your business by getting the team involved"
            buttonText="Get started"
            buttonOnclick={handleSignUp}
            price="£ 95"
            priceSuffix=" / month"
            data={[
              'Company account with unlimited users',
              'Assign sections or actions to team members ',
              'Different levels of user access',
              'Action plan is generated based on your assessment results',
            ]}
            additionalText="Everything in individual account, plus…"
            popular
          />
          <PricingColumn
            title="Enterprise"
            text="Bring the whole organisation onboard"
            buttonText="Contact Us"
            buttonOnclick={handleEnterprise}
            price="Made just for you"
            data={[
              'Ability to measure resilience in different regions, business units or even across your supply chain',
              'Dashboard to help you analyse where focussed attention and support is most needed across your company or supply chain',
              'Quarterly insights support meeting with RDP',
            ]}
            additionalText="Everything in team account, plus…"
          />
        </div>
      </Container>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    backgroundColor: '#fff',
    borderTop: 'solid 1px #D8D8D8',
    padding: theme.spacing(4, 0, 8, 0),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(7, 0, 14, 0),
    },
  },
  table: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
    '& > :not(:last-child)': {
      marginBottom: theme.spacing(4),
      [theme.breakpoints.up('lg')]: {
        marginRight: theme.spacing(4),
        marginBottom: 0,
      },
    },
  },
  title: {
    fontWeight: 900,
    fontSize: 22,
    textAlign: 'center',
    marginBottom: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      fontSize: 28,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 38,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 50,
    },
  },
  text: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(7),
    },
  },
}))

export default PricingTable
