import { SECTIONS } from './index'
import TrainingIcon from '../assets/training_section_icon.svg'
import MentalHealthIcon from '../assets/mental_health.svg'
import BusinessIcon from '../assets/business.svg'
import DigitalIcon from '../assets/digital.svg'
import DisruptionIcon from '../assets/disruption.svg'
import PaymentIcon from '../assets/payment.svg'
import RiskIcon from '../assets/risk.svg'
import LeadershipIcon from '../assets/leadership.svg'

export const SECTION_LIST = [
  {
    id: SECTIONS.TRAINING,
    title: 'Training and skills',
    color: '#25AA9B',
    icon: TrainingIcon,
    description:
      'Having the right skills and competencies within your company and having the ability to attract and recruit the people with the right skills are important factors in keeping your company strong.',
  },
  {
    id: SECTIONS.MENTAL_HEALTH,
    title: 'Mental health & workforce engagement',
    color: '#04D5D7',
    icon: MentalHealthIcon,
    description:
      'Having the right skills and competencies within your company and having the ability to attract and recruit the people with the right skills are important factors in keeping your company strong.',
  },
  {
    id: SECTIONS.BUSINESS_PLANNING,
    title: 'Business planning and development',
    color: '#03ADE5',
    icon: BusinessIcon,
    description:
      'Having the right skills and competencies within your company and having the ability to attract and recruit the people with the right skills are important factors in keeping your company strong.',
  },
  {
    id: SECTIONS.DIGITAL_ENABLEMENT,
    title: 'Digital enablement',
    color: '#0063FF',
    icon: DigitalIcon,
    description:
      'Having the right skills and competencies within your company and having the ability to attract and recruit the people with the right skills are important factors in keeping your company strong.',
  },
  {
    id: SECTIONS.DISRUPTION,
    title: 'Disruption and change',
    color: '#002A89',
    icon: DisruptionIcon,
    description:
      'Having the right skills and competencies within your company and having the ability to attract and recruit the people with the right skills are important factors in keeping your company strong.',
  },
  {
    id: SECTIONS.PAYMENTS,
    title: 'Payments and cash flow',
    color: '#6C7DAF',
    icon: PaymentIcon,
    description:
      'Having the right skills and competencies within your company and having the ability to attract and recruit the people with the right skills are important factors in keeping your company strong.',
  },
  {
    id: SECTIONS.RISK,
    title: 'Risk',
    color: '#740088',
    icon: RiskIcon,
    description:
      'Having the right skills and competencies within your company and having the ability to attract and recruit the people with the right skills are important factors in keeping your company strong.',
  },
  {
    id: SECTIONS.LEADERSHIP,
    title: 'Leadership and quality',
    color: '#D9B336',
    icon: LeadershipIcon,
    description:
      'Having the right skills and competencies within your company and having the ability to attract and recruit the people with the right skills are important factors in keeping your company strong.',
  },
]
