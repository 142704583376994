import { BookingDTO } from '../types/mentor'
import axios from '../utils/axios'

class CheckoutService {
  getSession = async () => {
    const response = await axios.get('checkout/create-session')

    return response.data
  }
  getCustomerPortal = async () => {
    const response = await axios.get('checkout/customer-portal')

    return response.data
  }
  getCheckoutSession = async () => {
    const response = await axios.post('checkout/create-checkout-session')

    return response.data?.url
  }
  getBookingSession = async (data: BookingDTO) => {
    const response = await axios.post('checkout/create-booking-session', {
      mentorId: data.mentorId,
      calendarId: data.calendarId,
    })

    return response.data?.url
  }

  postHandleBookingByCredit = async (data: BookingDTO) => {
    const response = await axios.post('checkout/handle-booking-by-credit', {
      mentorId: data.mentorId,
      calendarId: data.calendarId,
    })

    return response.data
  }
}

const checkoutService = new CheckoutService()

export default Object.freeze(checkoutService)
