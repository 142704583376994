import React from 'react'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { useDispatch } from 'react-redux'

import { setShowPopover } from '../../store/postSlice'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    padding: theme.spacing(2),
    maxWidth: 290,
    boxShadow: '0 0 8px rgba(0,0,0,0.3)',
    borderRadius: 10,
    fontWeight: 600,
    marginRight: 10,
    marginTop: 10,
    '&:before': {
      content: '" "',
      width: 15,
      height: 15,
      backgroundColor: '#fff',
      display: 'block',
      right: 20,
      top: 6,
      position: 'absolute',
      transform: 'rotate(45deg)',
    },
  },
  closeBtn: {
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: 18,
    marginTop: theme.spacing(1),
  },
  text: {
    fontWeight: 600,
  },
}))

const Popover = props => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const handleOnClose = () => {
    dispatch(setShowPopover({ id: null }))
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.text}>
        Remember to mark as done when this has been completed
      </Typography>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
      <a onClick={handleOnClose} className={classes.closeBtn}>
        Ok
      </a>
    </div>
  )
}

export default Popover
