class ShareService {
  makeShareDialog = url => {
    const y = window.top.outerHeight / 2 + window.top.screenY - 500 / 2
    const x = window.top.outerWidth / 2 + window.top.screenX - 360 / 2

    window.open(
      url,
      'ric-window',
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=360, height=500, top=${y}, left=${x}`
    )
  }

  facebook(url, text) {
    return this.makeShareDialog(
      `http://www.facebook.com/share.php?u=${url}&quote=${text}`
    )
  }

  twitter(url, text) {
    return this.makeShareDialog(
      `https://twitter.com/home?url=${url}&text=${text}`
    )
  }

  linkedin(url, text) {
    return this.makeShareDialog(
      `https://www.linkedin.com/shareArticle?title=${text}&url=${url}`
    )
  }

  email(url, text) {
    return window.open(
      `mailto:?subject=I'd like to share a link with you&body=${url}${text}`,
      '_blank'
    )
  }
}

const shareService = new ShareService()

export default Object.freeze(shareService)
