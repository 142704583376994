import React, { useState } from 'react'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { forgotPassword } from '../../store/accountSlice'

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email')
    .required('Email is required'),
})

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  successMessage: {
    marginTop: theme.spacing(2),
  },
}))

const ForgotPasswordForm = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [passwordEmailSent, setPasswordEmailSent] = useState(false)

  const { register, handleSubmit, errors, formState } = useForm({
    resolver: yupResolver(schema),
  })
  const isSubmitDisabled = !formState.isDirty || formState.isSubmitting

  const onSubmitHandler = async data => {
    await dispatch(forgotPassword(data))
    setPasswordEmailSent(true)
  }

  if (passwordEmailSent) {
    return (
      <Typography
        variant="h4"
        align="center"
        className={classes.successMessage}
      >
        We sent a reset password link to your email
      </Typography>
    )
  }

  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit(onSubmitHandler)}
      noValidate
    >
      <TextField
        label="Email address"
        name="email"
        inputRef={register}
        helperText={errors.email?.message}
        error={!!errors.email}
        aria-errormessage={errors.email?.message}
        aria-invalid={errors.email ? 'true' : 'false'}
        type="email"
        InputLabelProps={{ required: false }}
        InputProps={{ disableUnderline: true }}
        color="secondary"
        variant="filled"
        margin="normal"
        required
        fullWidth
        autoComplete="email"
      />

      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        size="large"
        className={classes.submit}
        disabled={isSubmitDisabled}
      >
        Send email
      </Button>
      <Button
        size="small"
        color="secondary"
        component={Link}
        to="/login"
        fullWidth
      >
        Ready to sign in?
      </Button>
    </form>
  )
}

export default ForgotPasswordForm
