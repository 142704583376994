import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import dataService from '../services/dataService'

// save finished questionnaire
const submitQuestionnaire = createAsyncThunk(
  'questionnaire/submitQuestionnaire',
  async (payload, { rejectWithValue, getState }) => {
    const { answers } = getState().questionnaire

    try {
      return await dataService.saveResult({ result_item: answers })
    } catch (e) {
      return rejectWithValue(e.response.data.message)
    }
  }
)

// save unfinished questionnaire
const saveQuestionnaire = createAsyncThunk(
  'questionnaire/saveQuestionnaire',
  async (payload, { rejectWithValue, getState }) => {
    const {
      answers,
      currentSection,
      currentQuestionIndex,
      globalQuestionCount,
      showSection,
    } = getState().questionnaire

    // save current status of questionnaire
    const newPayload = {
      answers,
      currentSection,
      currentQuestionIndex,
      globalQuestionCount,
      showSection,
    }

    try {
      return await dataService.saveToUser({
        questionnaire_draft: JSON.stringify(newPayload),
      })
    } catch (e) {
      return rejectWithValue(e.response.data.message)
    }
  }
)

const fetchQuestions = createAsyncThunk(
  'questionnaire/fetchQuestions',
  async (payload, { getState, rejectWithValue }) => {
    const { user } = getState().account
    // continue saved user session
    try {
      const questions = await dataService.getQuestions()
      return {
        questions: questions,
        user_draft: user.questionnaire_draft,
      }
    } catch (e) {
      return rejectWithValue(e.response.data.message)
    }
  }
)

const fetchSection = createAsyncThunk(
  'questionnaire/fetchSection',
  async payload => {
    return await dataService.getSections()
  }
)

const fetchScore = createAsyncThunk(
  'questionnaire/fetchScore',
  async (payload, { getState }) => {
    return await dataService.getUserScore()
  }
)

const fetchBenchmark = createAsyncThunk(
  'questionnaire/fetchBenchmark',
  async (payload, { getState }) => {
    return await dataService.getBenchmark()
  }
)

const questionnaireSlice = createSlice({
  name: 'questionnaire',
  initialState: {
    currentSection: null,
    currentQuestionIndex: 0,
    globalQuestionCount: 1,
    showSection: true,
    isFinished: false,
    status: 'idle',
    saveStatus: 'idle',
    sectionStatus: 'idle',
    questions: [],
    sections: [],
    score: {},
    answers: [],
    scoreCreatedAt: null,
    scoreFirst: null,
    scoreAvg: null,
    pdfReport: null,
    filter: {
      benchmark: false,
      initial: false,
    },
  },
  reducers: {
    saveAnswer: (state, action) => {
      const currentSectionIndex = state.sections.findIndex(
        section => section.slug === state.currentSection
      )

      const questionCount = state.questions.filter(
        question => question.section.slug === state.currentSection
      ).length

      // add id and answer id to answers array
      state.answers.push({
        question: action.payload.id,
        answer: action.payload.answer,
      })

      // increment global question counter if its not the last question
      if (state.globalQuestionCount < state.questions.length) {
        state.globalQuestionCount++
      }

      // if it isn't the last question in this section increment the counter
      if (state.currentQuestionIndex < questionCount - 1) {
        state.currentQuestionIndex++
      } else {
        // if it isn't the last section but the last question of the section,
        // reset sectionCount and go to the next section
        if (currentSectionIndex < state.sections.length - 1) {
          state.currentQuestionIndex = 0
          state.currentSection = state.sections[currentSectionIndex + 1].slug
          // display section intro
          state.showSection = true
        } else {
          // last question lets save
          state.showSection = false
          state.isFinished = true
        }
      }
    },
    startSection: (state, action) => {
      const currentSectionIndex = state.sections.findIndex(
        section => section.slug === state.currentSection
      )

      const questionCount = state.questions.filter(
        question => question.section.slug === state.currentSection
      ).length

      // if section has questions show them
      if (questionCount) {
        state.showSection = false
        return
      }

      //if section has no questions and it isn't the last one show the next section
      if (currentSectionIndex < state.sections.length - 1) {
        state.currentSection = state.sections[currentSectionIndex + 1].slug
        // display section intro
        state.showSection = true
      } else {
        // it was the last section, but it has no questions in it
        state.isFinished = true
      }
    },
    toggleFilter: (state, action) => {
      state.filter[action.payload] = !state.filter[action.payload]
    },
  },
  extraReducers: {
    [submitQuestionnaire.pending]: (state, action) => {
      if (state.saveStatus === 'idle') {
        state.saveStatus = 'pending'
      }
    },
    [submitQuestionnaire.fulfilled]: (state, action) => {
      state.status = 'idle'
      state.sectionStatus = 'idle'
      //reset if user wants to fill again
      state.currentQuestionIndex = 0
      state.globalQuestionCount = 1
      state.currentSection = state.sections[0].slug
      state.showSection = true
      state.scoreCreatedAt = null
      state.score = {}
      state.pdfReport = null

      if (state.saveStatus === 'pending') {
        state.saveStatus = 'success'
      }
    },
    [submitQuestionnaire.rejected]: (state, action) => {
      if (state.saveStatus === 'pending') {
        state.saveStatus = 'idle'
      }
    },
    [saveQuestionnaire.pending]: (state, action) => {
      if (state.saveStatus === 'idle') {
        state.saveStatus = 'pending'
      }
    },
    [saveQuestionnaire.fulfilled]: (state, action) => {
      if (state.saveStatus === 'pending') {
        state.saveStatus = 'idle'
      }
    },
    [saveQuestionnaire.rejected]: (state, action) => {
      if (state.saveStatus === 'pending') {
        state.saveStatus = 'idle'
      }
    },
    [fetchQuestions.pending]: state => {
      if (state.status === 'idle') {
        state.status = 'pending'
      }
    },
    [fetchQuestions.fulfilled]: (state, action) => {
      state.questions = action.payload.questions
      const draft = action.payload.user_draft

      if (draft && draft.answers) {
        state.answers = draft.answers
        state.currentSection = draft.currentSection
        state.globalQuestionCount = draft.globalQuestionCount
        state.currentQuestionIndex = draft.currentQuestionIndex
        state.showSection = draft.showSection
      }

      if (state.status === 'pending') {
        state.status = 'success'
      }
    },
    [fetchScore.fulfilled]: (state, action) => {
      //reset questionnaire status
      state.isFinished = false
      state.saveStatus = 'idle'

      if (action.payload) {
        const { created_at, pdf_report, ...scoreObject } = action.payload
        state.score = scoreObject
        state.scoreCreatedAt = created_at
        state.pdfReport = pdf_report
      }
    },
    [fetchBenchmark.fulfilled]: (state, action) => {
      state.scoreAvg = action.payload.avg
      state.scoreFirst = action.payload.first
    },
    [fetchSection.pending]: (state, action) => {
      state.sectionStatus = 'pending'
    },
    [fetchSection.fulfilled]: (state, action) => {
      state.sections = action.payload
      if (!state.currentSection) {
        state.currentSection = state.sections[0].slug
      }
      state.sectionStatus = 'success'
    },
    [fetchSection.rejected]: (state, action) => {
      state.sectionStatus = 'idle'
    },
  },
})

export const {
  saveAnswer,
  startSection,
  toggleFilter,
} = questionnaireSlice.actions

export default questionnaireSlice.reducer

export {
  saveQuestionnaire,
  fetchQuestions,
  submitQuestionnaire,
  fetchScore,
  fetchBenchmark,
  fetchSection,
}

export const selectQuestionBySection = state =>
  state.questionnaire.questions.filter(
    question => question.section.slug === state.questionnaire.currentSection
  )

export const selectCurrentSection = state => ({
  ...state.questionnaire.sections.find(
    section => section.slug === state.questionnaire.currentSection
  ),
  count: state.questionnaire.sections.length,
  index:
    state.questionnaire.sections.findIndex(
      section => section.slug === state.questionnaire.currentSection
    ) + 1,
})

export const selectQuestionCount = state => ({
  count: state.questionnaire.questions.length,
  index: state.questionnaire.globalQuestionCount,
})

export const selectSavingStatus = state => state.questionnaire.saveStatus
