import * as React from 'react'
import {
  makeStyles,
  Toolbar,
  useMediaQuery,
  useTheme,
  Drawer as MuiDrawer,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import List from '@material-ui/core/List'

import {
  selectSettingsDrawerStatus,
  setSettingsDrawer,
} from '../../store/uiSlice'
import { SETTINGS_DRAWER_WIDTH } from '../../constans'

function Drawer() {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const dispatch = useDispatch()
  const status = useSelector(selectSettingsDrawerStatus)
  const role = useSelector(state => state?.account?.user?.role?.type)

  const closeDrawer = () => {
    dispatch(setSettingsDrawer(false))
  }

  const menuItems = [
    {
      name: 'Company Profile',
      href: '/setting/my-company',
      role: ['admin', 'manager'],
    },
    {
      name: 'Your Profile',
      href: '/setting/profile',
      role: ['authenticated', 'manager', 'admin'],
    },
    {
      name: 'Team members',
      href: '/setting/team-members',
      role: ['admin'],
    },
    {
      name: 'Support',
      href: '/setting/support',
      role: ['authenticated', 'manager', 'admin'],
    },
    { name: 'Billing', href: '/setting/billing', role: ['admin'] },
    { name: 'Upgrade', href: '/setting/upgrade', role: ['admin'] },
  ].filter(item => item.role.some(item => item === role))

  return (
    <MuiDrawer
      className={classes.drawer}
      variant={isDesktop ? 'permanent' : 'temporary'}
      classes={{
        paper: classes.drawerPaper,
      }}
      open={status}
      anchor={isDesktop ? 'left' : 'bottom'}
      onClose={closeDrawer}
    >
      <div>
        {isDesktop ? <Toolbar /> : null}
        <List
          component="nav"
          onClick={closeDrawer}
          onKeyDown={closeDrawer}
          style={{ paddingTop: 24 }}
        >
          {menuItems.map(item => (
            <ListItem
              button
              disableRipple
              classes={{ root: classes.listItem }}
              activeClassName={classes.activeListItem}
              component={NavLink}
              to={item.href}
              key={item.href}
            >
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
        </List>
      </div>
    </MuiDrawer>
  )
}

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    maxHeight: '80%',
    zIndex: 'auto',
    borderRight: 0,
    [theme.breakpoints.up('md')]: {
      width: SETTINGS_DRAWER_WIDTH,
      maxHeight: '100%',
    },
  },
  drawer: {
    flexShrink: 0,
    [theme.breakpoints.up('md')]: {
      width: SETTINGS_DRAWER_WIDTH,
    },
  },
  listItem: {
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(4),
    },
  },
  activeListItem: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    '& .MuiListItemText-primary': {
      color: '#fff',
      fontWeight: 'bold',
    },
    '& .MuiIconButton-root': {
      color: '#fff  !important',
    },
  },
}))

export default Drawer
