import React from 'react'
import PropTypes from 'prop-types'

import CheckBoxInput from '../CheckBoxInput'
import { COMPANY_MOTIVATION } from '../../constans'

const StepThree = ({ form }) => {
  const { control, errors, getValues, setValue } = form
  const formData = getValues()

  return (
    <CheckBoxInput
      label="Lastly, what are your main motivations for wanting to improve your business resilience?"
      name="motivations"
      options={COMPANY_MOTIVATION}
      control={control}
      row
      error={errors.motivations}
      rules={{
        required: true,
        validate: {
          minLength: value =>
            value.length > 0 ? true : 'Select at least one motivation!',
        },
      }}
      values={formData.motivations}
      setValue={setValue}
      // selectAll
    />
  )
}

StepThree.propTypes = {
  form: PropTypes.object.isRequired,
}

export default StepThree
