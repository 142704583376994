import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import CheckCircle from '@material-ui/icons/CheckCircle'

function PricingColumn(props) {
  const classes = useStyles({ popular: props.popular })

  return (
    <div className={classes.root}>
      {props.popular ? (
        <div className={classes.popularHeader}>
          <Typography component="h3" className={classes.popularTitle}>
            Most popular
          </Typography>
        </div>
      ) : null}
      <Typography component="h2" className={classes.title}>
        {props.title}
      </Typography>
      <Typography className={classes.text}>{props.text}</Typography>
      {props.buttonText ? (
        <Button
          variant={props.popular ? 'contained' : 'outlined'}
          color="primary"
          size="large"
          style={{ boxShadow: 'none' }}
          onClick={props.buttonOnclick}
        >
          {props.buttonText}
        </Button>
      ) : null}
      <Typography className={classes.price}>
        {props.price}
        <span>{props.priceSuffix}</span>
      </Typography>
      {props.additionalText ? (
        <Typography className={classes.additionalText}>
          {props.additionalText}
        </Typography>
      ) : null}
      <div>
        {props.data?.map((item, index) => (
          <div className={classes.listItem} key={index}>
            <span>
              <CheckCircle />
            </span>
            <Typography>{item}</Typography>
          </div>
        ))}
      </div>
    </div>
  )
}

PricingColumn.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  buttonOnclick: PropTypes.func,
  price: PropTypes.string.isRequired,
  priceSuffix: PropTypes.string,
  data: PropTypes.array.isRequired,
  additionalText: PropTypes.string,
  popular: PropTypes.bool,
}

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: '0 2px 12px rgba(0,0,0,0.1)',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: 10,
    maxWidth: 400,
    width: '100%',
    overflow: 'hidden',
    [theme.breakpoints.up('lg')]: {
      minHeight: ({ popular }) => (popular ? 603 : 'auto'),
    },
    [theme.breakpoints.up('xl')]: {
      minHeight: ({ popular }) => (popular ? 693 : 'auto'),
      padding: theme.spacing(4),
    },
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    fontSize: 24,
    [theme.breakpoints.up('xl')]: {
      fontSize: 32,
    },
  },
  text: {
    fontSize: 14,
    color: '#606060',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  price: {
    fontWeight: 'bold',
    margin: theme.spacing(2, 0),
    fontSize: 24,
    [theme.breakpoints.up('xl')]: {
      fontSize: 32,
    },
    '& span': {
      fontSize: 24,
      opacity: 0.5,
      color: theme.palette.primary.main,
    },
  },
  listItem: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    color: '#606060',
    '&  svg': {
      marginRight: theme.spacing(1),
      color: theme.palette.primary.main,
    },
  },
  additionalText: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  popularTitle: {
    fontSize: 24,
    color: '#D9B336',
    fontWeight: 700,
    textAlign: 'center',
    padding: theme.spacing(2, 0),
  },
  popularHeader: {
    backgroundColor: 'rgba(217,179,54,0.1)',
    margin: theme.spacing(-2, -2, 2, -2),
    [theme.breakpoints.up('xl')]: {
      margin: theme.spacing(-4, -4, 2, -4),
    },
  },
}))

export default PricingColumn
