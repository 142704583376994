import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import DrawerButton from './DrawerButton'
import ArrowBack from '@material-ui/icons/ArrowBack'
import Reply from '@material-ui/icons/Reply'
import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'

import { setShowPopover } from '../../store/postSlice'
import smallLogo from '../../assets/small-logo.svg'
import { PAGE_TITLE } from '../../constans'

const useStyles = makeStyles(theme => ({
  root: {
    width: 60,
    position: 'absolute',
    transform: 'translateY(-50%)',
    top: '50%',
    backgroundColor: 'white',
    boxShadow: '2px 2px 14px 0px rgb(0 0 0 / 0.2)',
    '& > :last-child': {
      //last button bg is white
      backgroundColor: 'white',
    },
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1, 0),
  },
  icon: {
    height: 46,
  },
  button: {
    backgroundColor: '#F7F7F7',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(1.6, 0),
    '& > .icon': {
      color: theme.palette.primary.main,
      width: 20,
    },
    '& > span': {
      fontSize: 12,
    },
  },
}))

const FloatingBar = ({ post, toggleDrawer }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const account = useSelector(state => state.account)
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  const handleBackClick = React.useCallback(() => {
    dispatch(setShowPopover({ id: post.id }))
    history.goBack()
  }, [post, dispatch, history])

  if (!isDesktop) {
    return null
  }

  const buttons = [
    {
      icon: VisibilityOutlined,
      onClick: toggleDrawer,
      text: 'Show',
    },
    {
      icon: Reply,
      onClick: handleBackClick,
      text: 'Share',
    },
  ]

  if (account.user) {
    buttons.unshift({
      icon: ArrowBack,
      onClick: handleBackClick,
      text: 'Back',
    })
  }

  return (
    <div className={classes.root}>
      <Link className={classes.logoContainer} to="/">
        <img src={smallLogo} alt={PAGE_TITLE} className={classes.icon} />
      </Link>
      {buttons.map(button => (
        <DrawerButton
          key={button.text}
          className={classes.button}
          icon={button.icon}
          onClick={button.onClick}
          disabled={!post}
        >
          {button.text}
        </DrawerButton>
      ))}
    </div>
  )
}

FloatingBar.propTypes = {
  post: PropTypes.object,
  toggleDrawer: PropTypes.func.isRequired,
}

export default FloatingBar
