import * as React from 'react'
import { makeStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'

import { SETTINGS_DRAWER_WIDTH } from '../constans'

import ErrorBoundary from '../components/ErrorBoundary'
import Drawer from '../components/Setting/Drawer'

function SettingsLayout({ children }) {
  const classes = useStyles()

  return (
    <>
      <Drawer />
      <Container maxWidth={false} className={classes.root}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </Container>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginLeft: SETTINGS_DRAWER_WIDTH,
      width: `calc(100% - ${SETTINGS_DRAWER_WIDTH}px)`,
      padding: theme.spacing(3, 5, 2, 5),
    },
  },
}))

export default SettingsLayout
