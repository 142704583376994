import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as ReactGA from 'react-ga'
import FocusTrap from 'focus-trap-react'
import { useQuery } from 'react-query'
import { ButtonBase, makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Skeleton from '@material-ui/lab/Skeleton'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import {
  selectActiveSection,
  setActiveSection,
  setCategoryNavStatus,
} from '../../store/postSlice'
import questionService from '../../services/questionService'

const CategoryNav = () => {
  const open = useSelector(state => state.post.showCategoryNav)
  const classes = useStyles({ open })
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const dispatch = useDispatch()
  const activeSection = useSelector(selectActiveSection)
  const { isLoading, data, isError } = useQuery(
    'result',
    questionService.getResult
  )

  const setOpen = status => {
    dispatch(setCategoryNavStatus(status))
  }

  const handleClose = () => {
    if (!open) return

    setOpen(false)
  }

  const toggleCategoryNav = () => {
    setOpen(!open)
  }

  if (!isDesktop) {
    return null
  }

  const handleSectionChange = (sectionItem, enabled) => () => {
    if (!enabled) return
    if (sectionItem.id === activeSection.id) {
      handleClose()
      return
    }

    ReactGA.pageview(`/knowledge-hub/${sectionItem.slug}`)
    dispatch(setActiveSection(sectionItem))
    handleClose()
  }

  if (isLoading) {
    return (
      <div className={classes.container}>
        <Container maxWidth="md">
          <div className={classes.activeButton}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              style={{ height: '100%' }}
            >
              <Skeleton
                animation="wave"
                variant="circle"
                width={44}
                height={44}
                style={{ marginRight: 32 }}
              />
              <Skeleton variant="text" height={30} width={180} />
            </Box>
          </div>
        </Container>
      </div>
    )
  }

  if (isError) {
    return null
  }

  const realData = data
    .map(resultItem => {
      return {
        ...resultItem.section,
        enabled: resultItem.status === 'FINISHED',
      }
    })
    .sort((a, b) => a.id - b.id)

  return (
    <div className={classes.container}>
      <ClickAwayListener onClickAway={handleClose}>
        <Container maxWidth="md">
          <ButtonBase
            className={classes.activeButton}
            onClick={toggleCategoryNav}
          >
            <Box display="flex" flexDirection="row" alignItems="center">
              <img src={activeSection?.icon?.url} alt={activeSection?.title} />
              <Typography className={classes.activeItem}>
                {activeSection?.title}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              color="primary.main"
              fontSize={12}
            >
              {open ? (
                <>
                  <VisibilityOff />
                  Hide sections
                </>
              ) : (
                <>
                  <Visibility />
                  Show sections
                </>
              )}
            </Box>
          </ButtonBase>
          {open ? (
            <FocusTrap
              focusTrapOptions={{
                initialFocus: false,
                clickOutsideDeactivates: true,
              }}
            >
              <div className={classes.menuContainer}>
                {realData.map(sectionItem => (
                  <a
                    key={sectionItem.id}
                    onClick={handleSectionChange(
                      sectionItem,
                      sectionItem.enabled
                    )}
                    className={[
                      classes.menuItem,
                      sectionItem.id === activeSection.id &&
                        classes.activeMenuItem,
                      !sectionItem.enabled && classes.menuItemDisabled,
                    ].join(' ')}
                    href={sectionItem.enabled ? `#${sectionItem.slug}` : null}
                  >
                    {sectionItem.icon ? (
                      <img src={sectionItem.icon.url} alt={sectionItem.title} />
                    ) : null}
                    <span>{sectionItem.title}</span>
                  </a>
                ))}
              </div>
            </FocusTrap>
          ) : null}
        </Container>
      </ClickAwayListener>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    right: 0,
    left: 0,
    top: 'auto',
    zIndex: 10,
    transform: 'translateY(-42px)',
  },
  activeButton: {
    backgroundColor: 'white',
    height: 85,
    width: '100%',
    border: 'solid 2px #e0e0e0',
    borderBottom: ({ open }) =>
      open ? 'solid 1px #D8D8D8' : 'solid 2px #e0e0e0',
    borderBottomLeftRadius: ({ open }) => (open ? 0 : 12),
    borderBottomRightRadius: ({ open }) => (open ? 0 : 12),
    borderTopRightRadius: 12,
    borderTopLeftRadius: 12,
    padding: theme.spacing(0, 3),
    justifyContent: 'space-between',
    '& img': {
      width: 44,
      height: 44,
      marginRight: theme.spacing(4),
    },
  },
  menuContainer: {
    backgroundColor: 'white',
    width: '100%',
    border: 'solid 2px #e0e0e0',
    borderTop: 'none',
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    padding: theme.spacing(4),
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '2px 10px',
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: theme.spacing(1),
    color: '#000000',
    textDecoration: 'none',
    borderRadius: 6,

    '& > img': {
      width: 25,
      height: 25,
    },
    '& > span': {
      marginLeft: theme.spacing(2),
    },
    '&:hover': {
      backgroundColor: '#f4f4f4',
    },
  },
  activeMenuItem: {
    backgroundColor: '#f4f4f4',
  },
  menuItemDisabled: {
    cursor: 'not-allowed',
    filter: 'grayscale(1)',
    color: '#BABABA',
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
  activeItem: {
    fontWeight: 600,
    fontSize: 20,
  },
}))

export default CategoryNav
