import React from 'react'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import User from '@material-ui/icons/Person'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'
import { getAvatarUrl } from '../../utils'

const useStyles = makeStyles(theme => ({
  meta: {
    display: 'flex',
    alignItems: 'center',
  },
  reading: {
    marginLeft: 6,
    fontSize: 16,
    [theme.breakpoints.up('sm')]: {
      fontSize: 20,
      marginLeft: 13,
    },
  },
  avatar: {
    width: 50,
    height: 50,
    [theme.breakpoints.up('sm')]: {
      width: 70,
      height: 70,
    },
    '& svg': {
      fontSize: 30,
      [theme.breakpoints.up('sm')]: {
        fontSize: 40,
      },
    },
  },
}))

const PostMeta = ({ post }) => {
  const classes = useStyles()

  return (
    <div className={classes.meta}>
      {post.avatar ? (
        <Avatar
          alt={post.source}
          src={getAvatarUrl(post.avatar)}
          className={classes.avatar}
        />
      ) : (
        <Avatar className={classes.avatar}>
          <User />
        </Avatar>
      )}

      <Typography className={classes.reading}>
        <b>{post.source}</b>
        <br />
        <span>{post.reading_time}</span>
      </Typography>
    </div>
  )
}

PostMeta.propTypes = {
  post: PropTypes.object.isRequired,
}

export default PostMeta
