import React from 'react'
import { makeStyles } from '@material-ui/core'
import EmailOutlined from '@material-ui/icons/EmailOutlined'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'

import Title from '../../components/Setting/Title'
import Page from '../../layout/Page'

function Support() {
  const classes = useStyles()

  return (
    <Page title="Upgrade">
      <Title title="Support" />
      <Box className={classes.container}>
        <Box alignItems="center" justifyContent="center" display="flex">
          <EmailOutlined style={{ fontSize: 45 }} color="primary" />
        </Box>
        <div className={classes.textContainer}>
          <Typography className={classes.title}>Email us</Typography>
          <Typography style={{ marginBottom: 12 }}>
            We’re here to help you lorem ipsum dolar sit amet
          </Typography>
          <Typography>support@RDP.com</Typography>
        </div>
        <Box alignItems="center" justifyContent="center" display="flex">
          <Button
            variant="outlined"
            color="primary"
            size="small"
            component="a"
            href="mailto:support@RDP.com"
            target="_blank"
          >
            Email
          </Button>
        </Box>
      </Box>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: 'white',
    borderRadius: 8,
    padding: theme.spacing(3),
    display: 'flex',
    maxWidth: 860,
    margin: '0 auto',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 20,
  },
  textContainer: {
    flexGrow: 1,
    marginLeft: theme.spacing(3),
  },
}))

export default Support
