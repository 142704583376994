// import { useDispatch, useSelector } from 'react-redux'
import isFunction from 'lodash/isFunction'

// import { isFreePlan } from '../store/accountSlice'
// import { setUpgradeModal } from '../store/uiSlice'

export default function useUpgrade(callbackFunction) {
  // const isFree = useSelector(isFreePlan)
  // const dispatch = useDispatch()

  return () => {
    if (isFunction(callbackFunction)) {
      callbackFunction()
    }
  }
  //TODO: removed temporarily
  // return () => {
  //   if (isFree) {
  //     dispatch(setUpgradeModal(true))
  //   } else {
  //     if (isFunction(callbackFunction)) {
  //       callbackFunction()
  //     }
  //   }
  // }
}
