import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'

import logo from '../assets/logo.png'
import { PAGE_TITLE } from '../constans'
import ErrorBoundary from '../components/ErrorBoundary'

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#fff',
  },
  root: {
    minHeight: '100vh',
    margin: theme.spacing(0, 2),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0, 4),
    },
  },
  paper: {
    maxWidth: 470,
    margin: theme.spacing(2, 'auto'),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(4, 'auto'),
    },
  },
  logo: {
    maxWidth: '100%',
    height: 'auto',
  },
  logoContainer: {
    maxWidth: 200,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 350,
    },
  },
}))

const SignUpLayout = ({ children }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <div className={classes.paper}>
          <Link to="/" className={classes.logoContainer}>
            <img src={logo} alt={PAGE_TITLE} className={classes.logo} />
          </Link>
          <ErrorBoundary>{children}</ErrorBoundary>
        </div>
      </div>
    </div>
  )
}

export default SignUpLayout
