import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import SadIcon from '@material-ui/icons/SentimentDissatisfied'
import Typography from '@material-ui/core/Typography'

import bookingService from '../services/bookingService'
import Page from '../layout/Page'
import LoadingScreen from '../components/LoadingScreen'

function MentorStripeSessionRedirect() {
  const classes = useStyles()
  const { stripeSessionId } = useParams()
  const session = useQuery(['stripe-session', stripeSessionId], () =>
    bookingService.getBookingByStripeSession(stripeSessionId)
  )

  if (session.isLoading) {
    return (
      <Page title="Redirecting..">
        <LoadingScreen />
      </Page>
    )
  }

  if (session.isError) {
    return (
      <Page title="Error">
        <Container maxWidth="md" className={classes.container}>
          <SadIcon className={classes.errorIcon} />
          <Typography
            component="h1"
            className={classes.generalMessageTitle}
            variant="h5"
          >
            No session found with this ID!
          </Typography>
        </Container>
      </Page>
    )
  }

  return (
    <Page title="Redirecting..">
      <Redirect to={`/mentors/bookings/${session.data.id}`} />
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    textAlign: 'center',
  },
  errorIcon: {
    marginBottom: theme.spacing(4),
    fontSize: '96px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '72px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '48px',
    },
  },
  generalMessageTitle: {
    marginBottom: theme.spacing(2),
    fontWeight: 900,
    fontSize: 30,
    lineHeight: 1,
  },
  errorTitle: {
    marginBottom: theme.spacing(2),
  },
}))

export default MentorStripeSessionRedirect
