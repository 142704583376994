import React from 'react'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core'

import CategoryNav from './CategoryNav'
import bg from '../../assets/knowledge-hub-bg.jpg'

const PostHero = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.overlay} />

      <Container maxWidth="md">
        <div className={classes.wrapper}>
          <Typography component="h1" className={classes.title} variant="h4">
            Knowledge hub
          </Typography>
          <Typography className={classes.summary} variant="body1">
            Each section has it’s own area packed up ways to improve your
            resilience ranging from articles, podcasts, e-learning, education
            and more.
          </Typography>
        </div>
      </Container>
      <CategoryNav />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: `url(${bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
  },
  title: {
    fontWeight: 900,
    fontSize: 28,
    textAlign: 'center',
    color: 'white',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      fontSize: 38,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 40,
      marginBottom: theme.spacing(3),
    },
  },
  summary: {
    color: 'white',
    textAlign: 'center',
    fontSize: 14,
  },
  wrapper: {
    zIndex: 1,
    position: 'relative',
    padding: theme.spacing(4, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(9, 0),
    },
  },
  overlay: {
    backgroundColor: '#000000',
    opacity: 0.6,
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
  },
}))

export default PostHero
