import React from 'react'
import { makeStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#F9F9F9',
    position: 'relative',
    marginBottom: 35,
  },
  textContainer: {
    backgroundColor: '#F9F9F9',
    padding: theme.spacing(4, 0, 6, 0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(9, 0),
    },
  },
  metaContainer: {
    position: 'absolute',
    right: 0,
    left: 16,
    bottom: 0,
    transform: 'translateY(50%)',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      right: 8,
      left: 24,
    },
  },
  title: {
    fontSize: 25,
    fontWeight: 600,
    [theme.breakpoints.up('sm')]: {
      fontSize: 38,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 51,
    },
  },
  thumbnail: {
    height: '100%',
    objectFit: 'cover',
    width: '100%',
  },
  sectionLine: {
    fontSize: 16,
    display: 'flex',
  },
  circle: {
    width: 40,
    height: 40,
    marginRight: 13,
  },
}))

const HeroTitleSkeleton = props => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Container maxWidth="md" style={{ position: 'relative' }}>
        <div className={classes.textContainer}>
          <div className={classes.sectionLine}>
            <Skeleton variant="circle" className={classes.circle} />
            <Skeleton variant="text" width={250} />
          </div>
          <Typography className={classes.title} variant="h4" component="h1">
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </Typography>
        </div>
      </Container>
    </div>
  )
}

export default HeroTitleSkeleton
