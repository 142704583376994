import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import Helmet from 'react-helmet'
import { makeStyles } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import CheckCircle from '@material-ui/icons/CheckCircle'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  checkbox: {
    color: theme.palette.primary.main,
  },
  label: {
    marginBottom: theme.spacing(2),
    marginRight: 0,
  },
  skip: {
    marginLeft: theme.spacing(1),
    boxShadow: 'none',
  },
  sign: {
    display: 'flex',
    borderBottom: 'solid 1px #D8D8D8',
    alignItems: 'center',
    margin: theme.spacing(3, 0),
    paddingBottom: theme.spacing(1),
    '& > p': {
      marginRight: theme.spacing(2),
      fontSize: 14,
      '& > b': {
        fontSize: 18,
        fontWeight: 600,
      },
    },
  },
  signature: {
    fontFamily: "'Mrs Saint Delafield', cursive",
    fontSize: 50,
    lineHeight: 1,
  },
}))

const commitments = [
  {
    text:
      'Measuring our company’s resilience regularly and taking proactive steps towards improving it',
  },
  {
    text:
      'Protecting our employees, clients and supply chain by doing all that we can to prepare for future unknowns',
  },

  {
    text:
      'Sharing our learning experiences so that the wider industry can benefit from the challenges we face and overcome',
  },

  {
    text:
      'Supporting and encouraging our peers, partners, suppliers and sub-contractors, clients and employees in becoming more resilient',
  },
]

const PledgeModalForm = props => {
  const classes = useStyles()
  const companyName = useSelector(state => state.account.user.company_name)
  const userName = useSelector(state => state.account.user.name)
  const { register, handleSubmit, watch, formState } = useForm({
    defaultValues: {
      commitments: [false, false, false, false],
    },
  })
  const formStatus = watch('commitments')
  const disabled = formStatus.reduce((status, item) => {
    if (!item) return true
    return status
  }, false)

  const onSubmit = async () => {
    await props.handleSuccess()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Mrs+Saint+Delafield&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      {commitments.map((item, index) => (
        <FormControlLabel
          key={index}
          control={
            <Checkbox
              icon={<RadioButtonUnchecked />}
              checkedIcon={<CheckCircle />}
              classes={{
                colorPrimary: classes.checkbox,
              }}
              color="primary"
            />
          }
          inputRef={register}
          label={item.text}
          value={index}
          name={`commitments[${index}]`}
          classes={{
            root: classes.label,
          }}
        />
      ))}

      <div className={classes.sign}>
        <Typography>
          On behalf of <br />
          <b>{companyName}</b>
        </Typography>
        <div>
          <Typography className={classes.signature}>{userName}</Typography>
        </div>
      </div>
      <Typography style={{ marginBottom: 24 }}>
        Receive your free resilience action plan template when you sign the
        resilience pledge!
      </Typography>
      <Button
        variant="contained"
        color="primary"
        size="large"
        type="submit"
        disabled={disabled || formState.isSubmitting}
      >
        Make the pledge
      </Button>
      <Button
        variant="text"
        size="large"
        onClick={props.handleClose}
        className={classes.skip}
        disabled={formState.isSubmitting}
      >
        Skip for now
      </Button>
    </form>
  )
}

PledgeModalForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
}

export default PledgeModalForm
