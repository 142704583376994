import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import { SECTION_LIST } from '../../constans/sections'

const useStyles = makeStyles(theme => ({
  section: {
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
      marginBottom: theme.spacing(2),
    },
    '& > img': {
      marginRight: 13,
      width: 40,
      height: 40,
    },
  },
}))

const SectionLine = ({ post }) => {
  const classes = useStyles()
  const section = SECTION_LIST.find(section => section.id === post.section.slug)

  return (
    <div className={classes.section}>
      <img src={section.icon} alt={section.title} />
      {section.title} {post.type ? `/ ${post.type.name}` : null}
    </div>
  )
}

SectionLine.propTypes = {
  post: PropTypes.object.isRequired,
}

export default SectionLine
