import React from 'react'

import Page from '../layout/Page'
import { Container } from '@material-ui/core'

function MentorHowToPrepare() {
  return (
    <Page title="How to Prepare?">
      <Container maxWidth="md"></Container>
    </Page>
  )
}

export default MentorHowToPrepare
