import React from 'react'
import { useQuery } from 'react-query'

import questionService from '../../services/questionService'
import KnowledgeHubMenu from './KnowledgeHubMenu'
import OnlyAssesmentMenu from './OnlyAssesmentMenu'

const QuestionnaireMenu = () => {
  const { isLoading, data, isError } = useQuery(
    'result',
    questionService.getResult
  )

  if (isLoading || isError) return null

  const isAtLeastOneSectionFinished = data.some(
    section => section.status === 'FINISHED'
  )

  if (!isAtLeastOneSectionFinished) return <OnlyAssesmentMenu />

  return <KnowledgeHubMenu />
}

export default QuestionnaireMenu
