import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, makeStyles, Typography } from '@material-ui/core'

import { generateMonogram, getAvatarUrl } from '../../utils'

function UserMeta(props) {
  const classes = useStyles()
  const avatar = getAvatarUrl(props.avatar)
  const monogram = generateMonogram(props.name)

  return (
    <div className={classes.root}>
      <Avatar
        size="small"
        component="span"
        className={classes.iconButton}
        src={avatar}
      >
        {monogram}
      </Avatar>
      <div>
        <Typography className={classes.name}>{props.name}</Typography>
        <Typography className={classes.email}>{props.email}</Typography>
      </div>
    </div>
  )
}

UserMeta.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  avatar: PropTypes.object,
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  iconButton: {
    position: 'relative',
    color: '#fff',
    height: 32,
    width: 32,
    fontWeight: 600,
    marginRight: theme.spacing(2),
    '&.MuiAvatar-colorDefault': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  name: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 0,
    lineHeight: 1,
  },
  email: {
    fontSize: 14,
    lineHeight: 1,
  },
}))

export default UserMeta
