import axios from '../utils/axios'

class UserService {
  changePassword = async (password, oldPassword) => {
    return new Promise((resolve, reject) => {
      axios
        .put('/users/change-password', { password, oldPassword })
        .then(response => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.data.error)
          }
        })
        .catch(error => reject(error))
    })
  }
}

const userService = new UserService()

export default Object.freeze(userService)
