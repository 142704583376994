import React from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { useDispatch } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'

import FormLink from './FormLink'
import { login } from '../../store/accountSlice'
import { getErrorMessage, setFormErrors } from '../../utils'

const schema = Yup.object().shape({
  identifier: Yup.string()
    .email('Must be a valid email')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
})

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(6),
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const LoginForm = ({ onSuccess }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { register, handleSubmit, errors, formState, setError } = useForm({
    resolver: yupResolver(schema),
  })
  const [showPassword, setShowPassword] = React.useState(false)

  const onSubmitHandler = async data => {
    const response = await dispatch(login(data))

    if (response.type === login.rejected.toString()) {
      const message = getErrorMessage(response)
      if (message) {
        setFormErrors(message, setError)
      }
    } else {
      onSuccess()
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const isSubmitDisabled = !formState.isDirty || formState.isSubmitting

  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit(onSubmitHandler)}
      noValidate
    >
      <TextField
        label="Email Address"
        name="identifier"
        inputRef={register}
        helperText={errors.identifier?.message}
        error={!!errors.identifier}
        aria-errormessage={errors.identifier?.message}
        aria-invalid={errors.identifier ? 'true' : 'false'}
        type="email"
        InputLabelProps={{ required: false }}
        InputProps={{ disableUnderline: true }}
        color="secondary"
        variant="filled"
        margin="normal"
        required
        fullWidth
        autoComplete="email"
        autoFocus
      />
      <TextField
        label="Password"
        name="password"
        inputRef={register}
        helperText={errors.password?.message}
        error={!!errors.password}
        aria-errormessage={errors.password?.message}
        aria-invalid={errors.password ? 'true' : 'false'}
        type={showPassword ? 'text' : 'password'}
        autoComplete="current-password"
        InputLabelProps={{ required: false }}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        color="secondary"
        variant="filled"
        margin="normal"
        required
        fullWidth
      />
      <FormLink to="/forgot-password">Forgotten password</FormLink>

      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        size="large"
        className={classes.submit}
        disabled={isSubmitDisabled}
      >
        Sign In
      </Button>
      <Button
        size="small"
        color="secondary"
        component={Link}
        to="/sign-up"
        fullWidth
      >
        New user? Sign up
      </Button>
    </form>
  )
}

LoginForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
}

export default LoginForm
