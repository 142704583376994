import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

import { isManager } from '../../store/accountSlice'

const ManagerGuard = ({ children }) => {
  const isManagerRole = useSelector(isManager)

  if (!isManagerRole) {
    return <Redirect to="/" />
  }

  return children
}

ManagerGuard.propTypes = {
  children: PropTypes.any,
}

export default ManagerGuard
