import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useQuery } from 'react-query'
import { Typography } from '@material-ui/core'

import { isFreePlan } from '../store/accountSlice'
import checkoutService from '../services/checkoutService'
import useUpgrade from '../utils/useUpgrade'

import Title from '../components/Setting/Title'
import LoadingScreen from '../components/LoadingScreen'

function Billing() {
  const isFree = useSelector(isFreePlan)
  const { data: customerPortal, isLoading } = useQuery(
    'customer-portal',
    checkoutService.getCustomerPortal,
    {
      enabled: !isFree,
    }
  )
  const handleFunction = useUpgrade()

  useEffect(() => {
    if (isFree) return
    if (!isLoading && customerPortal?.url) {
      window.history.pushState(
        {},
        '',
        `${process.env.REACT_APP_CLIENT_URL}setting/upgrade`
      )
      window.location.href = customerPortal.url
    }
  })

  useEffect(() => {
    handleFunction()
  }, [handleFunction])

  if (isLoading || customerPortal) {
    return (
      <div>
        <Title title="Billing" />
        <LoadingScreen />
      </div>
    )
  }

  return (
    <div>
      <Title title="Billing" />
      <Typography>No billing data is available</Typography>
    </div>
  )
}

export default Billing
