import React, { useState } from 'react'
import { InlineWidget, useCalendlyEventListener } from 'react-calendly'
import { useMutation, useQuery } from 'react-query'
import { Link, useParams, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Button from '@material-ui/core/Button'

import bookingService from '../services/bookingService'
import Page from '../layout/Page'
import LoadingScreen from '../components/LoadingScreen'

function MentorBooking() {
  const { bookingId } = useParams()
  const user = useSelector(state => state.account.user)
  const [booked, setBooked] = useState(false)

  const booking = useQuery(['booking', bookingId], () =>
    bookingService.getBooking(bookingId)
  )
  const updateBooking = useMutation(bookingService.putUpdateBooking, {
    onSuccess: () => {
      setBooked(true)
    },
  })

  useCalendlyEventListener({
    onEventScheduled: async () => {
      await updateBooking.mutateAsync({ id: bookingId })
    },
  })

  if (booking.isLoading) {
    return (
      <Page title="Loading..">
        <LoadingScreen />
      </Page>
    )
  }

  if (booking.isError) {
    return <div>error</div>
  }

  if (booking.data.isBooked) {
    //redirect to prepare screen
    return <Redirect to="/mentors/how-to-prepare" />
  }

  const calendar = booking.data.mentor.calendars?.find(
    cal => cal.id === booking.data.calendarId
  )

  return (
    <Page title="Book a time slot">
      <InlineWidget
        url={calendar.calendly_link}
        prefill={{
          email: user.email,
          name: user.name,
        }}
      />
      {booked ? (
        <div style={{ textAlign: 'center', margin: '24px 0' }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            component={Link}
            to="/mentors/how-to-prepare"
          >
            How to prepare
          </Button>
        </div>
      ) : null}
    </Page>
  )
}

export default MentorBooking
