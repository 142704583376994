import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'
import { alpha } from '@material-ui/core/styles/colorManipulator'
import { Link } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar'
import User from '@material-ui/icons/Person'
import Popper from '@material-ui/core/Popper'
import Backdrop from '@material-ui/core/Backdrop'

import { getAvatarUrl, getPostUrl } from '../../utils'
import { setShowPopover } from '../../store/postSlice'

import Popover from '../Article/Popover'
import PostShare from './PostShare'
import ResponsiveImage from '../ResponsiveImage'
import { LEVEL_COLORS } from '../../constans'

const PostCard = ({ post }) => {
  const classes = useStyles({
    color: post.type ? post.type.color : '#fff',
    hasThumbnail: !!post.thumbnail,
    level: post.level ? LEVEL_COLORS[post.level] : '#fff',
  })
  const dispatch = useDispatch()
  const anchorRef = React.useRef(null)
  const isPopoverActive = useSelector(state => state.post.showPopover)

  const handleOnClose = () => {
    dispatch(setShowPopover({ id: null }))
  }

  return (
    <Grid component="article" xs={12} sm={6} md={4} item>
      {isPopoverActive === post.id && !post.marked_done_by ? (
        <Backdrop open className={classes.backdrop} onClick={handleOnClose}>
          <Popper
            placement="bottom-end"
            open
            anchorEl={anchorRef.current}
            style={{ zIndex: 11 }}
            modifiers={{
              flip: {
                enabled: false,
              },
            }}
          >
            <Popover />
          </Popper>
        </Backdrop>
      ) : null}
      <div className={classes.root}>
        <Link to={getPostUrl(post)} style={{ textDecoration: 'none' }}>
          <div className={classes.thumbnailContainer}>
            <ResponsiveImage
              size="small"
              image={post.thumbnail}
              className={classes.thumbnail}
            />
            {post.type ? (
              <div className={classes.postType}>
                <div className={classes.iconWrapper}>
                  {post.type && post.type.icon && post.type.icon.url ? (
                    <img src={post.type.icon.url} alt={post.type.name} />
                  ) : null}
                </div>
                <Typography component="span">{post.type.name}</Typography>
              </div>
            ) : null}
            {post.level ? (
              <div className={classes.level}>{post.level}</div>
            ) : null}
          </div>
        </Link>
        <Link to={getPostUrl(post)} className={classes.textLink}>
          <div className={classes.textWrapper}>
            <Typography variant="h4" component="h3">
              <Typography className={classes.title}>{post.title}</Typography>
            </Typography>
            <Typography className={classes.excerpt}>{post.excerpt}</Typography>
            <div className={classes.meta}>
              {post.avatar ? (
                <Avatar alt={post.source} src={getAvatarUrl(post.avatar)} />
              ) : (
                <Avatar>
                  <User />
                </Avatar>
              )}

              <Typography className={classes.footerText}>
                <b>{post.source}</b>
                <br />
                <Typography component="span" variant="body2">
                  {post.reading_time}
                </Typography>
              </Typography>
            </div>
          </div>
        </Link>
        <div className={classes.shareContainer} ref={anchorRef}>
          <PostShare post={post} />
        </div>
      </div>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: '0 2px 12px rgba(0,0,0,0.1)',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    backgroundColor: 'white',
  },
  thumbnail: {
    position: 'absolute',
    objectFit: 'cover',
    width: '100%',
    height: 130,
  },
  thumbnailContainer: {
    position: 'relative',
    height: 130,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: ({ color }) => alpha(color, 0.5),
  },
  postType: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    color: ({ hasThumbnail }) => (hasThumbnail ? '#fff' : '#000'),
  },
  iconWrapper: {
    backgroundColor: ({ color }) => color,
    display: 'flex',
    justifyContent: 'center',
    width: 50,
    height: 50,
    borderRadius: 50,
    marginRight: theme.spacing(1),
    alignItems: 'center',
    '& > img': {
      width: 32,
      height: 32,
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textDecoration: 'none',
    color: '#000',
    [theme.breakpoints.up('sm')]: {
      fontSize: 24,
    },
  },
  textWrapper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  excerpt: {
    marginBottom: 'auto',
    color: '#606060',
    lineHeight: 1.25,
  },
  meta: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  shareContainer: {
    borderTop: 'solid 1px #D8D8D8',
    padding: theme.spacing(2),
  },
  backdrop: {
    zIndex: 9,
  },
  textLink: {
    display: 'flex',
    flex: 1,
    textDecoration: 'none',
  },
  footerText: {
    lineHeight: 1,
    marginLeft: 8,
    color: '#000',
    textDecoration: 'none',
  },
  level: {
    position: 'absolute',
    top: 0,
    right: 0,
    fontSize: 14,
    fontWeight: 'bold',
    padding: theme.spacing(0.5, 1),
    color: '#000000',
    backgroundColor: ({ level }) => level,
  },
}))

PostCard.propTypes = {
  post: PropTypes.object.isRequired,
}

export default PostCard
