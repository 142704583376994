import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(6),
    },
  },
  title: {
    fontWeight: 900,
    fontSize: 18,
    [theme.breakpoints.up('sm')]: {
      fontSize: 28,
    },
  },
  text: {
    fontSize: 14,
  },
}))

const FormHeader = ({ title, text }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography component="h1" className={classes.title}>
        {title}
      </Typography>
      {text ? <Typography className={classes.text}>{text}</Typography> : null}
    </div>
  )
}

FormHeader.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
}

export default FormHeader
