import { Booking } from '../types/mentor'
import axios from '../utils/axios'

class BookingService {
  getBooking = async (id: string) => {
    const response = await axios.get<Booking>(`bookings/${id}`)

    return response.data
  }
  putUpdateBooking = async data => {
    const response = await axios.put(`bookings/${data.id}`, {
      isBooked: true,
    })

    return response.data
  }
  getBookingByStripeSession = async stripeSessionid => {
    const response = await axios.get(
      `bookings/?_where[stripe_session]=${stripeSessionid}`
    )

    if (response.data.length > 0) {
      return response.data[0]
    }

    throw new Error('No session found')
  }
}

const bookingService = new BookingService()

export default Object.freeze(bookingService)
