import React from 'react'

import ChangePasswordForm from '../../components/Setting/ChangePasswordForm'
import ProfileForm from '../../components/Setting/ProfileForm'
import Title from '../../components/Setting/Title'
import Page from '../../layout/Page'

function Profile() {
  return (
    <Page title="Your profile">
      <Title
        title="Manage your profile"
        description="User description goes here"
      />
      <ProfileForm />
      <ChangePasswordForm />
    </Page>
  )
}

export default Profile
