import React, { useEffect } from 'react'
import Container from '@material-ui/core/Container'
import { useForm } from 'react-hook-form'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useMutation } from 'react-query'

import companyService from '../services/companyService'
import { otherRole } from '../config'
import { setCompany, updateUser } from '../store/accountSlice'

import Page from '../layout/Page'
import Title from '../components/InitialSurvey/Title'
import StepOne from '../components/InitialSurvey/StepOne'
import StepTwo from '../components/InitialSurvey/StepTwo'
import StepThree from '../components/InitialSurvey/StepThree'

const InitialSurvey = () => {
  const [step, setStep] = React.useState(1)
  const form = useForm({
    shouldUnregister: false,
    defaultValues: {
      name: '',
      locations: [],
      size: '',
      your_role: '',
      company_role: '',
      activities: [],
      motivations: [],
    },
  })
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const createCompany = useMutation(
    data => companyService.createCompany(data.company),
    {
      onSuccess: async (data, { user }) => {
        dispatch(setCompany(data))

        //add the newly created company to the user
        try {
          await dispatch(
            updateUser({
              ...user,
              company: data.id,
            })
          )
          history.push('/questionnaire/intro')
        } catch {
          setStep(1)
        }
      },
      onError: () => {
        setStep(1)
      },
    }
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [step])

  const renderForm = () => {
    const formComponents = {
      1: <StepOne form={form} />,
      2: <StepTwo form={form} />,
      3: <StepThree form={form} />,
    }
    return formComponents[step]
  }

  const onSubmitHandler = async data => {
    // if "Other" role is selected, we need the specified your_role field
    const role =
      data.company_role === otherRole ? data.your_role : data.company_role

    const newData = {
      name: data.name,
      size: data.size,
      locations: data.locations.join(';'),
      activities: data.activities.join(';'),
      motivations: data.motivations.join(';'),
    }

    createCompany.mutate({ company: newData, user: { company_role: role } })
  }

  const handleSubmitDisabled = () => {
    const values = form.watch()
    switch (step) {
      case 1:
        return !(
          values.name &&
          values.company_role &&
          values.locations?.length &&
          values.size
        )
      case 2:
        return !values.activities || !values.activities.length
      case 3:
        return !values.motivations || !values.motivations.length
      default:
        return true
    }
  }

  const handleNextClick = () => {
    if (step === 3) {
      form.handleSubmit(onSubmitHandler)()
      return false
    }

    setStep(step + 1)
  }

  const handleBackClick = () => {
    setStep(step - 1)
  }

  return (
    <Page title="Tell us about you">
      <Container maxWidth="md">
        <Title
          title="Tell us a little about your company."
          text="This will take 2 minutes and will help us provide the content that is most useful and relevant to you"
        />
        <form className={classes.form}>
          {renderForm()}
          <div className={classes.buttonContainer}>
            {step > 1 ? (
              <Button onClick={handleBackClick} className={classes.backButton}>
                Back
              </Button>
            ) : null}

            <Button
              variant="contained"
              color="primary"
              size="large"
              type="button"
              onClick={handleNextClick}
              className={classes.button}
              disabled={handleSubmitDisabled() || createCompany.isLoading}
            >
              {step === 3
                ? createCompany.isLoading
                  ? 'Saving'
                  : 'Done'
                : 'Next'}
            </Button>
          </div>
        </form>
      </Container>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  form: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  button: {
    marginLeft: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  backButton: {
    fontWeight: 400,
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))

export default InitialSurvey
