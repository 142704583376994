import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}))

const SkeletonPostCard = props => {
  const classes = useStyles()

  return (
    <Grid component="article" xs={12} sm={6} md={4} item>
      <div className={classes.root}>
        <Skeleton variant="rect" height={130} width="100%" animation="wave" />
        <Skeleton height={30} animation="wave" />
        <Skeleton height={30} width="60%" animation="wave" />

        <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
        <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
        <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
        <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
        <Skeleton animation="wave" height={10} width="80%" />
      </div>
    </Grid>
  )
}

export default React.memo(SkeletonPostCard)
