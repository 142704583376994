import React, { useState } from 'react'
import * as ReactGA from 'react-ga'
import { useQuery } from 'react-query'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import SwapHoriz from '@material-ui/icons/SwapHoriz'

import ResultCard from './ResultCard'
import ResultCardSkeleton from './ResultCardSkeleton'
import questionService from '../../services/questionService'

const CardList = () => {
  const classes = useStyles()
  const { isLoading, isError, data } = useQuery(
    'result',
    questionService.getResult
  )
  const [weakToStrong, setFilter] = useState(false)

  const onFilterClickHandler = () => {
    ReactGA.event({
      category: 'ResilienceReport',
      action: 'FilterReport List',
    })
    setFilter(!weakToStrong)
  }

  if (isLoading) {
    return (
      <React.Fragment>
        <div className={classes.detailWrap}>
          <Typography
            color="secondary"
            component="h2"
            className={classes.detailTitle}
          >
            Categories in detail
          </Typography>
          <Button color="secondary">
            Strongest to weakest
            <SwapHoriz />
          </Button>
        </div>
        {[1, 2, 3].map(fakeSection => (
          <ResultCardSkeleton key={fakeSection} />
        ))}
      </React.Fragment>
    )
  }

  if (isError) return null

  const sortedResultList = data
    .filter(result => result.status === 'FINISHED')
    .sort((a, b) => {
      if (weakToStrong) {
        return b.score - a.score
      } else {
        return a.score - b.score
      }
    })

  return (
    <React.Fragment>
      <div className={classes.detailWrap}>
        <Typography
          color="secondary"
          component="h2"
          className={classes.detailTitle}
        >
          Categories in detail
        </Typography>
        <Button color="secondary" onClick={onFilterClickHandler}>
          {weakToStrong ? 'Strongest to weakest' : 'Weakest to strongest'}
          <SwapHoriz />
        </Button>
      </div>
      {sortedResultList.map(result => {
        const { section, ...otherData } = result
        return (
          <ResultCard
            section={section}
            result={otherData}
            key={result.section.id}
          />
        )
      })}
    </React.Fragment>
  )
}

const useStyles = makeStyles(theme => ({
  detailWrap: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  detailTitle: {
    fontSize: 18,
    fontWeight: 900,
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      fontSize: 22,
    },
  },
}))

export default CardList
