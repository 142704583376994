import React from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import SadIcon from '@material-ui/icons/SentimentDissatisfied'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import { isFreePlan } from '../store/accountSlice'
import checkoutService from '../services/checkoutService'

import Title from '../components/Setting/Title'
import PricingColumn from '../components/Upgrade/PricingColumn'
import LoadingScreen from '../components/LoadingScreen'
import Page from '../layout/Page'

function Upgrade() {
  const classes = useStyles()
  const isFree = useSelector(isFreePlan)
  const history = useHistory()

  const { data, isError, isLoading, error } = useQuery(
    'check-out-session',
    checkoutService.getSession,
    {
      enabled: isFree,
    }
  )

  const {
    data: customerPortal,
    isError: isCustomerPortalError,
    isLoading: isCustomerPortalLoading,
    error: customerPortalError,
  } = useQuery('customer-portal', checkoutService.getCustomerPortal, {
    enabled: !isFree,
  })

  if (isLoading || isCustomerPortalLoading) {
    return (
      <div>
        <Title title="Upgrade" />
        <LoadingScreen />
      </div>
    )
  }

  if (isCustomerPortalError || isError) {
    const handleReload = () => {
      window.location.reload()
    }

    return (
      <Container maxWidth="md" className={classes.container}>
        <SadIcon className={classes.errorIcon} />
        <Typography
          component="h1"
          className={classes.generalMessageTitle}
          variant="h5"
        >
          It looks like there has been an error while trying to fulfill your
          request. Sorry about that!
        </Typography>
        <Typography
          component="p"
          className={classes.errorTitle}
          variant="caption"
        >
          {isError
            ? error?.response?.data?.message
            : customerPortalError?.response?.data?.message}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleReload}
        >
          Reload
        </Button>
      </Container>
    )
  }

  const handleTeam = async () => {
    if (!isFree) {
      //open customer portal
      if (customerPortal?.url) {
        window.location.href = customerPortal.url
      }
    } else {
      window.location.href = data?.url
    }
  }

  const handleEnterprise = () => {
    history.push('/setting/support')
  }

  return (
    <Page title="Upgrade">
      <Title title="Upgrade" />
      <div className={classes.pricingTable}>
        <PricingColumn
          title="Individual"
          text="Hit the ground running"
          price="Free"
          priceSuffix=" / forever"
          data={[
            'Single login',
            'Resilience assessment questions covering 12 different areas of business resilience',
            'Access to exclusive content in the knowledge hub',
            'Retake the assessment as many times as you want',
          ]}
        />
        <PricingColumn
          title="Team"
          text="Power up your business by getting the team involved"
          buttonText={isFree ? 'Get started' : 'Manage subscription'}
          buttonOnclick={handleTeam}
          price="£ 95"
          priceSuffix=" / month"
          data={[
            'Company account with unlimited users',
            'Assign sections or actions to team members ',
            'Different levels of user access',
            'Action plan is generated based on your assessment results',
          ]}
          additionalText="Everything in individual account, plus…"
          popular
        />
        <PricingColumn
          title="Enterprise"
          text="Bring the whole organisation onboard"
          buttonText="Contact Us"
          buttonOnclick={handleEnterprise}
          price="Made just for you"
          data={[
            'Ability to measure resilience in different regions, business units or even across your supply chain',
            'Dashboard to help you analyse where focussed attention and support is most needed across your company or supply chain',
            'Quarterly insights support meeting with RDP',
          ]}
          additionalText="Everything in team account, plus…"
        />
      </div>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  pricingTable: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
    '& > :not(:last-child)': {
      marginBottom: theme.spacing(4),
      [theme.breakpoints.up('lg')]: {
        marginRight: theme.spacing(4),
        marginBottom: 0,
      },
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    textAlign: 'center',
  },
  errorIcon: {
    marginBottom: theme.spacing(4),
    fontSize: '96px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '72px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '48px',
    },
  },
  generalMessageTitle: {
    marginBottom: theme.spacing(2),
    fontWeight: 900,
    fontSize: 30,
    lineHeight: 1,
  },
  errorTitle: {
    marginBottom: theme.spacing(2),
  },
}))

export default Upgrade
