import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { SECTIONS } from '../../constans'
import { roundHalf } from '../../utils'

const Graph = props => {
  const filter = useSelector(state => state.questionnaire.filter)

  const graphCurves = {
    [SECTIONS.SUSTAINABILITY]: {
      0: 'M413.1,256c0,53.7-43.3,97.2-96.6,97.2s-96.6-43.5-96.6-97.2c0-44.6,29.9-82.3,70.7-93.7c8.2-2.3,17-4,25.9-4c8.1,0,15.9,1.5,23.4,3.4C382,172.3,413.1,210.5,413.1,256z',
      1: 'M413.1,256c0,53.7-43.3,97.2-96.6,97.2s-96.6-43.5-96.6-97.2c0-44.6,29.9-82.3,70.7-93.7c8.2-2.3,16.7-25,25.6-25c8.1,0,16.2,22.5,23.7,24.4C382,172.3,413.1,210.5,413.1,256z',
      1.5: 'M413.1,256c0,53.7-43.3,97.2-96.6,97.2s-96.6-43.5-96.6-97.2c0-44.6,29.9-82.3,70.7-93.7c8.2-2.3,16.4-35.7,25.4-35.7c8.1,0,16.5,33.2,24,35.1C382,172.3,413.1,210.5,413.1,256z',
      2: 'M413.1 256c0 53.7-43.3 97.2-96.6 97.2s-96.6-43.5-96.6-97.2c0-44.6 29.9-82.3 70.7-93.7 8.2-2.3 16.9-48.2 25.9-48.2 8.1 0 16 45.7 23.5 47.6 42 10.6 73.1 48.8 73.1 94.3z',
      2.5: 'M413.1,256c0,53.7-43.3,97.2-96.6,97.2s-96.6-43.5-96.6-97.2c0-44.6,29.9-82.3,70.7-93.7c8.2-2.3,17.4-58,26.4-58c8.1,0,15.5,55.4,23,57.3C382,172.3,413.1,210.5,413.1,256z',
      3: 'M413.1,256c0,53.7-43.3,97.2-96.6,97.2s-96.6-43.5-96.6-97.2c0-44.6,29.9-82.3,70.7-93.7c8.2-2.3,17.6-70.4,26.6-70.4c8.1,0,15.3,67.9,22.8,69.8C382,172.3,413.1,210.5,413.1,256z',
      3.5: 'M413.1,256c0,53.7-43.3,97.2-96.6,97.2s-96.6-43.5-96.6-97.2c0-44.6,29.9-82.3,70.7-93.7c8.2-2.3,17.4-82.5,26.4-82.5c8.1,0,15.5,79.9,23,81.8C382,172.3,413.1,210.5,413.1,256z',
      4: 'M413.1,256c0,53.7-43.3,97.2-96.6,97.2s-96.6-43.5-96.6-97.2c0-44.6,29.9-82.3,70.7-93.7c8.2-2.3,16.7-93,25.6-93c8.1,0,16.2,90.5,23.7,92.4C382,172.3,413.1,210.5,413.1,256z',
      4.5: 'M413.1,256c0,53.7-43.3,97.2-96.6,97.2s-96.6-43.5-96.6-97.2c0-44.6,29.9-82.3,70.7-93.7c8.2-2.3,16.4-105.3,25.4-105.3c8.1,0,16.5,102.8,24,104.7C382,172.3,413.1,210.5,413.1,256z',
      5: 'M413.1,256c0,53.7-43.3,97.2-96.6,97.2s-96.6-43.5-96.6-97.2c0-44.6,29.9-82.3,70.7-93.7c8.2-2.3,16.7-117.3,25.6-117.3c8.1,0,16.2,114.8,23.7,116.7C382,172.3,413.1,210.5,413.1,256z',
    },
    [SECTIONS.QUALITY_MANAGEMENT]: {
      0: 'M400,304.3c-26.8,46.5-86.1,62.5-132.3,35.9s-61.9-86-35.1-132.5c22.3-38.7,67.1-56.3,108.1-45.7c8.3,2.1,16.1,5.4,23.8,9.9c7,4,13.7,8.9,19.3,14.2C415,216.2,422.8,264.9,400,304.3z',
      1: 'M400,304.3c-26.8,46.5-86.1,62.5-132.3,35.9s-61.9-86-35.1-132.5c22.3-38.7,67.1-56.3,108.1-45.7c8.3,2.1,27.8-15.1,35.6-10.6c7,4,2,29.4,7.5,34.8C415,216.2,422.8,264.9,400,304.3z',
      1.5: 'M400,304.3c-26.8,46.5-86.1,62.5-132.3,35.9s-61.9-86-35.1-132.5c22.3-38.7,67.1-56.3,108.1-45.7c8.3,2.1,31.9-22.1,39.6-17.6c7,4-2.1,36.4,3.5,41.8C415,216.2,422.8,264.9,400,304.3z',
      2: 'M400 304.3c-26.8 46.5-86.1 62.5-132.3 35.9s-61.9-86-35.1-132.5c22.3-38.7 67.1-56.3 108.1-45.7 8.3 2.1 38.5-31.3 46.3-26.8 7 4-8.7 45.5-3.2 50.9 31.2 30.1 39 78.8 16.2 118.2z',
      2.5: 'M400,304.3c-26.8,46.5-86.1,62.5-132.3,35.9s-61.9-86-35.1-132.5c22.3-38.7,67.1-56.3,108.1-45.7c8.3,2.1,44-43.1,51.8-38.6c7,4-14.3,57.4-8.7,62.8C415,216.2,422.8,264.9,400,304.3z',
      3: 'M400,304.3c-26.8,46.5-86.1,62.5-132.3,35.9s-61.9-86-35.1-132.5c22.3-38.7,67.1-56.3,108.1-45.7c8.3,2.1,49.3-52.3,57.1-47.8c7,4-19.6,66.5-14,71.9C415,216.2,422.8,264.9,400,304.3z',
      3.5: 'M400,304.3c-26.8,46.5-86.1,62.5-132.3,35.9s-61.9-86-35.1-132.5c22.3-38.7,67.1-56.3,108.1-45.7c8.3,2.1,55-62,62.7-57.5c7,4-25.2,76.2-19.6,81.6C415,216.2,422.8,264.9,400,304.3z',
      4: 'M400,304.3c-26.8,46.5-86.1,62.5-132.3,35.9s-61.9-86-35.1-132.5c22.3-38.7,67.1-56.3,108.1-45.7c8.3,2.1,59.6-72.8,67.4-68.3c7,4-29.9,87-24.3,92.4C415,216.2,422.8,264.9,400,304.3z',
      4.5: 'M400,304.3c-26.8,46.5-86.1,62.5-132.3,35.9s-61.9-86-35.1-132.5c22.3-38.7,67.1-56.3,108.1-45.7c8.3,2.1,65.3-81.7,73.1-77.2c7,4-35.5,96-30,101.3C415,216.2,422.8,264.9,400,304.3z',
      5: 'M400,304.3c-26.8,46.5-86.1,62.5-132.3,35.9s-61.9-86-35.1-132.5c22.3-38.7,67.1-56.3,108.1-45.7c8.3,2.1,72.8-92.7,80.5-88.2c7,4-43,107-37.4,112.3C415,216.2,422.8,264.9,400,304.3z',
    },
    [SECTIONS.TRAINING]: {
      0: 'M364.6,339.6c-46.5,26.8-105.8,11.1-132.5-35.1S221.5,199.1,268,172.2c38.7-22.3,86.2-15.2,116.5,14.4c6.1,6,11.1,12.8,15.6,20.6c4,7,7.6,14.4,9.7,21.8C421.5,270.7,404,316.8,364.6,339.6z',
      1: 'M364.6,339.6c-46.5,26.8-105.8,11.1-132.5-35.1s-10.6-105.4,35.9-132.3c38.7-22.3,86.2-15.2,116.5,14.4c6.1,6,29,2.1,33.5,9.9c4,7-10.3,25.1-8.2,32.5C421.5,270.7,404,316.8,364.6,339.6z',
      1.5: 'M364.6,339.6c-46.5,26.8-105.8,11.1-132.5-35.1s-10.6-105.4,35.9-132.3c38.7-22.3,86.2-15.2,116.5,14.4c6.1,6,38-3.1,42.5,4.7c4,7-19.4,30.3-17.3,37.7C421.5,270.7,404,316.8,364.6,339.6z',
      2: 'M364.6,339.6c-46.5,26.8-105.8,11.1-132.5-35.1s-10.6-105.4,35.9-132.3c38.7-22.3,86.2-15.2,116.5,14.4c6.1,6,48.3-9,52.8-1.2c4,7-29.7,36.2-27.6,43.7C421.5,270.7,404,316.8,364.6,339.6z',
      2.5: 'M364.6,339.6c-46.5,26.8-105.8,11.1-132.5-35.1s-10.6-105.4,35.9-132.3c38.7-22.3,86.2-15.2,116.5,14.4c6.1,6,57.4-14.2,61.9-6.4c4,7-38.8,41.4-36.7,48.9C421.5,270.7,404,316.8,364.6,339.6z',
      3: 'M364.6,339.6c-46.5,26.8-105.8,11.1-132.5-35.1s-10.6-105.4,35.9-132.3c38.7-22.3,86.2-15.2,116.5,14.4c6.1,6,68.7-20.1,73.2-12.4c4,7-50.1,47.4-48,54.8C421.5,270.7,404,316.8,364.6,339.6z',
      3.5: 'M364.6,339.6c-46.5,26.8-105.8,11.1-132.5-35.1s-10.6-105.4,35.9-132.3c38.7-22.3,86.2-15.2,116.5,14.4c6.1,6,77.2-25.6,81.7-17.8c4,7-58.6,52.8-56.5,60.3C421.5,270.7,404,316.8,364.6,339.6z',
      4: 'M364.6,339.6c-46.5,26.8-105.8,11.1-132.5-35.1s-10.6-105.4,35.9-132.3c38.7-22.3,86.2-15.2,116.5,14.4c6.1,6,88.4-32,92.8-24.2c4,7-69.7,59.2-67.6,66.7C421.5,270.7,404,316.8,364.6,339.6z',
      4.5: 'M364.6,339.6c-46.5,26.8-105.8,11.1-132.5-35.1s-10.6-105.4,35.9-132.3c38.7-22.3,86.2-15.2,116.5,14.4c6.1,6,100.7-39.1,105.2-31.3c4,7-82,66.3-79.9,73.8C421.5,270.7,404,316.8,364.6,339.6z',
      5: 'M364.6,339.6c-46.5,26.8-105.8,11.1-132.5-35.1s-10.6-105.4,35.9-132.3c38.7-22.3,86.2-15.2,116.5,14.4c6.1,6,109.3-44.1,113.8-36.3c4,7-90.6,71.3-88.5,78.7C421.5,270.7,404,316.8,364.6,339.6z',
    },
    [SECTIONS.PROFITABILITY]: {
      0: 'M316.2,352.4c-53.7,0-97.2-43.3-97.2-96.6s43.5-96.6,97.2-96.6c44.6,0,82.3,29.9,93.7,70.7c2.3,8.2,4.1,16.9,4.1,25.8c0,8.1-1.6,16-3.5,23.5C400,321.3,361.7,352.4,316.2,352.4z',
      1: 'M316.2,352.4c-53.7,0-97.2-43.3-97.2-96.6s43.5-96.6,97.2-96.6c44.6,0,82.3,29.9,93.7,70.7c2.3,8.2,23.5,16.4,23.5,25.4c0,8.1-21,16.5-22.9,24C400,321.3,361.7,352.4,316.2,352.4z',
      1.5: 'M316.2,352.4c-53.7,0-97.2-43.3-97.2-96.6s43.5-96.6,97.2-96.6c44.6,0,82.3,29.9,93.7,70.7c2.3,8.2,36.4,17.2,36.4,26.1c0,8.1-33.9,15.7-35.8,23.2C400,321.3,361.7,352.4,316.2,352.4z',
      2: 'M316.2,352.4c-53.7,0-97.2-43.3-97.2-96.6s43.5-96.6,97.2-96.6c44.6,0,82.3,29.9,93.7,70.7c2.3,8.2,46.3,16.1,46.3,25.1c0,8.1-43.8,16.8-45.7,24.3C400,321.3,361.7,352.4,316.2,352.4z',
      2.5: 'M316.2,352.4c-53.7,0-97.2-43.3-97.2-96.6s43.5-96.6,97.2-96.6c44.6,0,82.3,29.9,93.7,70.7c2.3,8.2,59.4,17.2,59.4,26.1c0,8.1-56.9,15.7-58.8,23.2C400,321.3,361.7,352.4,316.2,352.4z',
      3: 'M316.2,352.4c-53.7,0-97.2-43.3-97.2-96.6s43.5-96.6,97.2-96.6c44.6,0,82.3,29.9,93.7,70.7c2.3,8.2,70.5,16.4,70.5,25.4c0,8.1-68,16.5-69.9,24C400,321.3,361.7,352.4,316.2,352.4z',
      3.5: 'M316.2,352.4c-53.7,0-97.2-43.3-97.2-96.6s43.5-96.6,97.2-96.6c44.6,0,82.3,29.9,93.7,70.7c2.3,8.2,81.9,17.2,81.9,26.1c0,8.1-79.3,15.7-81.2,23.2C400,321.3,361.7,352.4,316.2,352.4z',
      4: 'M316.2,352.4c-53.7,0-97.2-43.3-97.2-96.6s43.5-96.6,97.2-96.6c44.6,0,82.3,29.9,93.7,70.7c2.3,8.2,92.2,16.4,92.2,25.4c0,8.1-89.6,16.5-91.5,24C400,321.3,361.7,352.4,316.2,352.4z',
      4.5: 'M316.2,352.4c-53.7,0-97.2-43.3-97.2-96.6s43.5-96.6,97.2-96.6c44.6,0,82.3,29.9,93.7,70.7c2.3,8.2,105.4,17.2,105.4,26.1c0,8.1-102.9,15.7-104.8,23.2C400,321.3,361.7,352.4,316.2,352.4z',
      5: 'M316.2,352.4c-53.7,0-97.2-43.3-97.2-96.6s43.5-96.6,97.2-96.6c44.6,0,82.3,29.9,93.7,70.7c2.3,8.2,116.3,15.9,116.3,24.8c0,8.1-113.8,17-115.7,24.5C400,321.3,361.7,352.4,316.2,352.4z',
    },
    [SECTIONS.MENTAL_HEALTH]: {
      0: 'M267.9,339.3c-46.5-26.8-62.5-86.1-35.9-132.3s86-61.9,132.5-35.1c38.7,22.3,56.3,67.1,45.7,108.1c-2.1,8.3-7.1,19.3-11.5,27.1c-4.1,7.2-7.1,8.5-12.7,15.9C356,354.2,307.4,362.1,267.9,339.3z',
      1: 'M267.9,339.3c-46.5-26.8-62.5-86.1-35.9-132.3s86-61.9,132.5-35.1c38.7,22.3,56.3,67.1,45.7,108.1c-2.1,8.3,15.8,28.5,8.8,34.1c-7.3,5.9-30.8,2.3-33,8.9C356,354.2,307.4,362.1,267.9,339.3z',
      1.5: 'M267.9,339.3c-46.5-26.8-62.5-86.1-35.9-132.3s86-61.9,132.5-35.1c38.7,22.3,56.3,67.1,45.7,108.1c-2.1,8.3,24.7,33.7,17.7,39.3c-7.3,5.9-39.7-2.9-41.9,3.7C356,354.2,307.4,362.1,267.9,339.3z',
      2: 'M267.9,339.3c-46.5-26.8-62.5-86.1-35.9-132.3s86-61.9,132.5-35.1c38.7,22.3,56.3,67.1,45.7,108.1c-2.1,8.3,33.8,38.9,26.8,44.5c-7.3,5.9-48.8-8.1-51-1.5C356,354.2,307.4,362.1,267.9,339.3z',
      2.5: 'M267.9,339.3c-46.5-26.8-62.5-86.1-35.9-132.3s86-61.9,132.5-35.1c38.7,22.3,56.3,67.1,45.7,108.1c-2.1,8.3,45.8,46.1,38.8,51.7c-7.3,5.9-60.8-15.3-63-8.7C356,354.2,307.4,362.1,267.9,339.3z',
      3: 'M267.9,339.3c-46.5-26.8-62.5-86.1-35.9-132.3s86-61.9,132.5-35.1c38.7,22.3,56.3,67.1,45.7,108.1c-2.1,8.3,55.3,51.3,48.3,56.9c-7.3,5.9-70.3-20.5-72.5-13.9C356,354.2,307.4,362.1,267.9,339.3z',
      3.5: 'M267.9,339.3c-46.5-26.8-62.5-86.1-35.9-132.3s86-61.9,132.5-35.1c38.7,22.3,56.3,67.1,45.7,108.1c-2.1,8.3,63.7,56.1,56.7,61.7c-7.3,5.9-78.7-25.3-80.9-18.7C356,354.2,307.4,362.1,267.9,339.3z',
      4: 'M267.9,339.3c-46.5-26.8-62.5-86.1-35.9-132.3s86-61.9,132.5-35.1c38.7,22.3,56.3,67.1,45.7,108.1c-2.1,8.3,74.2,62.1,67.2,67.7c-7.3,5.9-89.2-31.3-91.4-24.7C356,354.2,307.4,362.1,267.9,339.3z',
      4.5: 'M267.9,339.3c-46.5-26.8-62.5-86.1-35.9-132.3s86-61.9,132.5-35.1c38.7,22.3,56.3,67.1,45.7,108.1c-2.1,8.3,83.4,67.5,76.4,73.1c-7.3,5.9-98.4-36.7-100.6-30.1C356,354.2,307.4,362.1,267.9,339.3z',
      5: 'M267.9,339.3c-46.5-26.8-62.5-86.1-35.9-132.3s86-61.9,132.5-35.1c38.7,22.3,56.3,67.1,45.7,108.1c-2.1,8.3,95,74.1,88,79.7c-7.3,5.9-110-43.3-112.2-36.7C356,354.2,307.4,362.1,267.9,339.3z',
    },
    [SECTIONS.BUSINESS_PLANNING]: {
      0: 'M232.7,303.8c-26.8-46.5-11.1-105.8,35.1-132.5s105.4-10.6,132.3,35.9c22.3,38.7,15.2,86.2-14.4,116.5c-6,6.1-9,8.8-16.8,13.3c-7,4-18.2,9.8-25.6,11.9C301.5,360.8,255.5,343.3,232.7,303.8z',
      1: 'M232.7,303.8c-26.8-46.5-11.1-105.8,35.1-132.5s105.4-10.6,132.3,35.9c22.3,38.7,15.2,86.2-14.4,116.5c-6,6.1-1,31.5-8.8,36c-7,4-26.2-12.9-33.6-10.8C301.5,360.8,255.5,343.3,232.7,303.8z',
      1.5: 'M232.7,303.8c-26.8-46.5-11.1-105.8,35.1-132.5s105.4-10.6,132.3,35.9c22.3,38.7,15.2,86.2-14.4,116.5c-6,6.1,3.1,38.7-4.7,43.2c-7,4-30.3-20.1-37.7-18C301.5,360.8,255.5,343.3,232.7,303.8z',
      2: 'M232.7,303.8c-26.8-46.5-11.1-105.8,35.1-132.5s105.4-10.6,132.3,35.9c22.3,38.7,15.2,86.2-14.4,116.5c-6,6.1,10.5,50.8,2.7,55.3c-7,4-37.7-32.2-45.1-30.1C301.5,360.8,255.5,343.3,232.7,303.8z',
      2.5: 'M232.7,303.8c-26.8-46.5-11.1-105.8,35.1-132.5s105.4-10.6,132.3,35.9c22.3,38.7,15.2,86.2-14.4,116.5c-6,6.1,15.3,59.7,7.5,64.2c-7,4-42.5-41.1-49.9-39C301.5,360.8,255.5,343.3,232.7,303.8z',
      3: 'M232.7,303.8c-26.8-46.5-11.1-105.8,35.1-132.5s105.4-10.6,132.3,35.9c22.3,38.7,15.2,86.2-14.4,116.5c-6,6.1,20.5,68.7,12.7,73.2c-7,4-47.7-50.1-55.1-48C301.5,360.8,255.5,343.3,232.7,303.8z',
      3.5: 'M232.7,303.8c-26.8-46.5-11.1-105.8,35.1-132.5s105.4-10.6,132.3,35.9c22.3,38.7,15.2,86.2-14.4,116.5c-6,6.1,26.3,78.8,18.5,83.3c-7,4-53.5-60.2-60.9-58.1C301.5,360.8,255.5,343.3,232.7,303.8z',
      4: 'M232.7,303.8c-26.8-46.5-11.1-105.8,35.1-132.5s105.4-10.6,132.3,35.9c22.3,38.7,15.2,86.2-14.4,116.5c-6,6.1,31.3,88,23.5,92.5c-7,4-58.5-69.4-65.9-67.3C301.5,360.8,255.5,343.3,232.7,303.8z',
      4.5: 'M232.7,303.8c-26.8-46.5-11.1-105.8,35.1-132.5s105.4-10.6,132.3,35.9c22.3,38.7,15.2,86.2-14.4,116.5c-6,6.1,38,99,30.2,103.5c-7,4-65.2-80.4-72.6-78.3C301.5,360.8,255.5,343.3,232.7,303.8z',
      5: 'M232.7,303.8c-26.8-46.5-11.1-105.8,35.1-132.5s105.4-10.6,132.3,35.9c22.3,38.7,15.2,86.2-14.4,116.5c-6,6.1,43.3,108.1,35.5,112.6c-7,4-70.5-89.5-77.9-87.4C301.5,360.8,255.5,343.3,232.7,303.8z',
    },
    [SECTIONS.DIGITAL_ENABLEMENT]: {
      0: 'M219.9,255.5c0-53.7,43.3-97.2,96.6-97.2s96.6,43.5,96.6,97.2c0,44.6-29.9,82.3-70.7,93.7c-8.2,2.3-17.2,3.2-26.2,3.2c-8.1,0-15.7-0.7-23.2-2.6C251,339.2,219.9,301,219.9,255.5z',
      1: 'M219.9,255.5c0-53.7,43.3-97.2,96.6-97.2s96.6,43.5,96.6,97.2c0,44.6-29.9,82.3-70.7,93.7c-8.2,2.3-17.2,23.8-26.2,23.8c-8.1,0-15.7-21.3-23.2-23.2C251,339.2,219.9,301,219.9,255.5z',
      1.5: 'M219.9,255.5c0-53.7,43.3-97.2,96.6-97.2s96.6,43.5,96.6,97.2c0,44.6-29.9,82.3-70.7,93.7c-8.2,2.3-17.3,35.1-26.3,35.1c-8.1,0-15.6-32.6-23.1-34.5C251,339.2,219.9,301,219.9,255.5z',
      2: 'M219.9,255.5c0-53.7,43.3-97.2,96.6-97.2s96.6,43.5,96.6,97.2c0,44.6-29.9,82.3-70.7,93.7c-8.2,2.3-17.2,45.7-26.2,45.7c-8.1,0-15.7-43.2-23.2-45.1C251,339.2,219.9,301,219.9,255.5z',
      2.5: 'M219.9,255.5c0-53.7,43.3-97.2,96.6-97.2s96.6,43.5,96.6,97.2c0,44.6-29.9,82.3-70.7,93.7c-8.2,2.3-16.9,59.6-25.9,59.6c-8.1,0-16-57.1-23.5-59C251,339.2,219.9,301,219.9,255.5z',
      3: 'M219.9,255.5c0-53.7,43.3-97.2,96.6-97.2s96.6,43.5,96.6,97.2c0,44.6-29.9,82.3-70.7,93.7c-8.2,2.3-16.9,69.6-25.9,69.6c-8.1,0-16-67.1-23.5-69C251,339.2,219.9,301,219.9,255.5z',
      3.5: 'M219.9,255.5c0-53.7,43.3-97.2,96.6-97.2s96.6,43.5,96.6,97.2c0,44.6-29.9,82.3-70.7,93.7c-8.2,2.3-17.3,82.2-26.3,82.2c-8.1,0-15.6-79.7-23.1-81.6C251,339.2,219.9,301,219.9,255.5z',
      4: 'M219.9,255.5c0-53.7,43.3-97.2,96.6-97.2s96.6,43.5,96.6,97.2c0,44.6-29.9,82.3-70.7,93.7c-8.2,2.3-17.2,91.9-26.2,91.9c-8.1,0-15.7-89.4-23.2-91.3C251,339.2,219.9,301,219.9,255.5z',
      4.5: 'M219.9,255.5c0-53.7,43.3-97.2,96.6-97.2s96.6,43.5,96.6,97.2c0,44.6-29.9,82.3-70.7,93.7c-8.2,2.3-16.4,105.2-25.4,105.2c-8.1,0-16.5-102.7-24-104.6C251,339.2,219.9,301,219.9,255.5z',
      5: 'M219.9,255.5c0-53.7,43.3-97.2,96.6-97.2s96.6,43.5,96.6,97.2c0,44.6-29.9,82.3-70.7,93.7c-8.2,2.3-17.2,116-26.2,116c-8.1,0-15.7-113.5-23.2-115.4C251,339.2,219.9,301,219.9,255.5z',
    },
    [SECTIONS.DISRUPTION]: {
      0: 'M234,207.2c26.8-46.5,85.1-62.5,131.3-35.9s61.9,86,35.1,132.5c-22.3,38.7-67.1,56.3-108.1,45.7c-8.3-2.1-20.1-7.6-27.9-12.1c-7-4-9.6-6.6-15.2-12C218.1,295.3,211.2,246.6,234,207.2z',
      1: 'M234,207.2c26.8-46.5,85.1-62.5,131.3-35.9s61.9,86,35.1,132.5c-22.3,38.7-67.1,56.3-108.1,45.7c-8.3-2.1-27.3,12-35.1,7.5c-7-4-2.4-26.2-8-31.6C218.1,295.3,211.2,246.6,234,207.2z',
      1.5: 'M234,207.2c26.8-46.5,85.1-62.5,131.3-35.9s61.9,86,35.1,132.5c-22.3,38.7-67.1,56.3-108.1,45.7c-8.3-2.1-32.4,20.6-40.2,16.1c-7-4,2.7-34.8-2.9-40.2C218.1,295.3,211.2,246.6,234,207.2z',
      2: 'M234,207.2c26.8-46.5,85.1-62.5,131.3-35.9s61.9,86,35.1,132.5c-22.3,38.7-67.1,56.3-108.1,45.7c-8.3-2.1-38.4,31.1-46.2,26.6c-7-4,8.7-45.3,3.1-50.7C218.1,295.3,211.2,246.6,234,207.2z',
      2.5: 'M234,207.2c26.8-46.5,85.1-62.5,131.3-35.9s61.9,86,35.1,132.5c-22.3,38.7-67.1,56.3-108.1,45.7c-8.3-2.1-44.2,41-52,36.5c-7-4,14.5-55.2,8.9-60.6C218.1,295.3,211.2,246.6,234,207.2z',
      3: 'M234,207.2c26.8-46.5,85.1-62.5,131.3-35.9s61.9,86,35.1,132.5c-22.3,38.7-67.1,56.3-108.1,45.7c-8.3-2.1-44.2,41-52,36.5c-7-4,14.5-55.2,8.9-60.6C218.1,295.3,211.2,246.6,234,207.2z',
      3.5: 'M234,207.2c26.8-46.5,85.1-62.5,131.3-35.9s61.9,86,35.1,132.5c-22.3,38.7-67.1,56.3-108.1,45.7c-8.3-2.1-55.7,60.8-63.5,56.3c-7-4,26-75,20.4-80.4C218.1,295.3,211.2,246.6,234,207.2z',
      4: 'M234,207.2c26.8-46.5,85.1-62.5,131.3-35.9s61.9,86,35.1,132.5c-22.3,38.7-67.1,56.3-108.1,45.7c-8.3-2.1-59.6,71-67.4,66.5c-7-4,29.9-85.2,24.3-90.6C218.1,295.3,211.2,246.6,234,207.2z',
      4.5: 'M234,207.2c26.8-46.5,85.1-62.5,131.3-35.9s61.9,86,35.1,132.5c-22.3,38.7-67.1,56.3-108.1,45.7c-8.3-2.1-67.5,81.2-75.3,76.7c-7-4,37.8-95.4,32.2-100.8C218.1,295.3,211.2,246.6,234,207.2z',
      5: 'M234,207.2c26.8-46.5,85.1-62.5,131.3-35.9s61.9,86,35.1,132.5c-22.3,38.7-67.1,56.3-108.1,45.7c-8.3-2.1-73.4,91.3-81.2,86.8c-7-4,43.7-105.5,38.1-110.9C218.1,295.3,211.2,246.6,234,207.2z',
    },
    [SECTIONS.RISK]: {
      0: 'M268.4,171.9c46.5-26.8,105.8-11.1,132.5,35.1s10.6,105.4-35.9,132.3c-38.7,22.3-86.2,15.2-116.5-14.4c-6.1-6-11.3-13.4-15.8-21.1c-4-7-7.3-13.9-9.4-21.3C211.5,240.8,229,194.7,268.4,171.9z',
      1: 'M268.4,171.9c46.5-26.8,105.8-11.1,132.5,35.1s10.6,105.4-35.9,132.3c-38.7,22.3-86.2,15.2-116.5-14.4c-6.1-6-28.4-3.5-32.9-11.2c-4-7,9.8-23.8,7.7-31.2C211.5,240.8,229,194.7,268.4,171.9z',
      1.5: 'M268.4,171.9c46.5-26.8,105.8-11.1,132.5,35.1s10.6,105.4-35.9,132.3c-38.7,22.3-86.2,15.2-116.5-14.4c-6.1-6-38.7,1.7-43.2-6c-4-7,20.1-29,18-36.4C211.5,240.8,229,194.7,268.4,171.9z',
      2: 'M268.4,171.9c46.5-26.8,105.8-11.1,132.5,35.1s10.6,105.4-35.9,132.3c-38.7,22.3-86.2,15.2-116.5-14.4c-6.1-6-49,7.6-53.5-0.1c-4-7,30.4-34.9,28.3-42.3C211.5,240.8,229,194.7,268.4,171.9z',
      2.5: 'M268.4,171.9c46.5-26.8,105.8-11.1,132.5,35.1s10.6,105.4-35.9,132.3c-38.7,22.3-86.2,15.2-116.5-14.4c-6.1-6-59.2,13.5-63.7,5.8c-4-7,40.6-40.8,38.5-48.2C211.5,240.8,229,194.7,268.4,171.9z',
      3: 'M268.4,171.9c46.5-26.8,105.8-11.1,132.5,35.1s10.6,105.4-35.9,132.3c-38.7,22.3-86.2,15.2-116.5-14.4c-6.1-6-68.6,18.8-73.1,11.1c-4-7,50-46.1,47.9-53.5C211.5,240.8,229,194.7,268.4,171.9z',
      3.5: 'M268.4,171.9c46.5-26.8,105.8-11.1,132.5,35.1s10.6,105.4-35.9,132.3c-38.7,22.3-86.2,15.2-116.5-14.4c-6.1-6-78.4,24.5-82.9,16.8c-4-7,59.8-51.8,57.7-59.2C211.5,240.8,229,194.7,268.4,171.9z',
      4: 'M268.4,171.9c46.5-26.8,105.8-11.1,132.5,35.1s10.6,105.4-35.9,132.3c-38.7,22.3-86.2,15.2-116.5-14.4c-6.1-6-90,31.1-94.5,23.4c-4-7,71.4-58.4,69.3-65.8C211.5,240.8,229,194.7,268.4,171.9z',
      4.5: 'M268.4,171.9c46.5-26.8,105.8-11.1,132.5,35.1s10.6,105.4-35.9,132.3c-38.7,22.3-86.2,15.2-116.5-14.4c-6.1-6-100.3,37.1-104.8,29.4c-4-7,81.7-64.4,79.6-71.8C211.5,240.8,229,194.7,268.4,171.9z',
      5: 'M268.4,171.9c46.5-26.8,105.8-11.1,132.5,35.1s10.6,105.4-35.9,132.3c-38.7,22.3-86.2,15.2-116.5-14.4c-6.1-6-109.8,42.5-114.3,34.8c-4-7,91.2-69.8,89.1-77.2C211.5,240.8,229,194.7,268.4,171.9z',
    },
    [SECTIONS.PAYMENTS]: {
      0: 'M316.8,159.1c53.7,0,97.2,43.3,97.2,96.6s-43.5,96.6-97.2,96.6c-44.6,0-82.3-29.9-93.7-70.7c-2.3-8.2-3.6-21-3.6-30c0-8.1,1.1-11.9,3-19.4C233,190.3,271.3,159.1,316.8,159.1z',
      1: 'M316.8,159.1c53.7,0,97.2,43.3,97.2,96.6s-43.5,96.6-97.2,96.6c-44.6,0-82.3-29.9-93.7-70.7c-2.3-8.2-24.1-17.4-24.1-26.4c0-8.1,21.6-15.5,23.5-23C233,190.3,271.3,159.1,316.8,159.1z',
      1.5: 'M316.8,159.1c53.7,0,97.2,43.3,97.2,96.6s-43.5,96.6-97.2,96.6c-44.6,0-82.3-29.9-93.7-70.7C220.8,273.4,187,265,187,256c0-8.1,33.6-16.3,35.5-23.8C233,190.3,271.3,159.1,316.8,159.1z',
      2: 'M316.8,159.1c53.7,0,97.2,43.3,97.2,96.6s-43.5,96.6-97.2,96.6c-44.6,0-82.3-29.9-93.7-70.7c-2.3-8.2-46.6-17.4-46.6-26.4c0-8.1,44.1-15.5,46-23C233,190.3,271.3,159.1,316.8,159.1z',
      2.5: 'M316.8,159.1c53.7,0,97.2,43.3,97.2,96.6s-43.5,96.6-97.2,96.6c-44.6,0-82.3-29.9-93.7-70.7c-2.3-8.2-36.1-17.4-36.1-26.4c0-8.1,33.6-15.5,35.5-23C233,190.3,271.3,159.1,316.8,159.1z',
      3: 'M316.8,159.1c53.7,0,97.2,43.3,97.2,96.6s-43.5,96.6-97.2,96.6c-44.6,0-82.3-29.9-93.7-70.7c-2.3-8.2-69.6-16.9-69.6-25.9c0-8.1,67.1-16,69-23.5C233,190.3,271.3,159.1,316.8,159.1z',
      3.5: 'M316.8,159.1c53.7,0,97.2,43.3,97.2,96.6s-43.5,96.6-97.2,96.6c-44.6,0-82.3-29.9-93.7-70.7c-2.3-8.2-60-16.7-60-25.7c0-8.1,57.5-16.2,59.4-23.7C233,190.3,271.3,159.1,316.8,159.1z',
      4: 'M316.8,159.1c53.7,0,97.2,43.3,97.2,96.6s-43.5,96.6-97.2,96.6c-44.6,0-82.3-29.9-93.7-70.7c-2.3-8.2-92.7-17.4-92.7-26.4c0-8.1,90.2-15.5,92.1-23C233,190.3,271.3,159.1,316.8,159.1z',
      4.5: 'M316.8,159.1c53.7,0,97.2,43.3,97.2,96.6s-43.5,96.6-97.2,96.6c-44.6,0-82.3-29.9-93.7-70.7C220.8,273.4,116,265,116,256c0-8.1,104.6-16.3,106.5-23.8C233,190.3,271.3,159.1,316.8,159.1z',
      5: 'M316.8,159.1c53.7,0,97.2,43.3,97.2,96.6s-43.5,96.6-97.2,96.6c-44.6,0-82.3-29.9-93.7-70.7c-2.3-8.2-117-17.5-117-26.5c0-8.1,114.5-15.4,116.4-22.9C233,190.3,271.3,159.1,316.8,159.1z',
    },
    [SECTIONS.HEALTH_SAFETY]: {
      0: 'M365.1,172.2c46.5,26.8,62.5,86.1,35.9,132.3s-86,61.9-132.5,35.1c-38.7-22.3-56.3-67.1-45.7-108.1c2.1-8.3,5.3-16.1,9.8-23.8c4-7,9-13.7,14.4-19.3C277,157.3,325.6,149.5,365.1,172.2z',
      1: 'M365.1,172.2c46.5,26.8,62.5,86.1,35.9,132.3s-86,61.9-132.5,35.1c-38.7-22.3-56.3-67.1-45.7-108.1c2.1-8.3-12.7-27.2-8.2-34.9c4-7,27-2.6,32.4-8.2C277,157.3,325.6,149.5,365.1,172.2z',
      1.5: 'M365.1,172.2c46.5,26.8,62.5,86.1,35.9,132.3s-86,61.9-132.5,35.1c-38.7-22.3-56.3-67.1-45.7-108.1c2.1-8.3-23.3-33.3-18.8-41c4-7,37.6,3.5,43-2.1C277,157.3,325.6,149.5,365.1,172.2z',
      2: 'M365.1 172.2c46.5 26.8 62.5 86.1 35.9 132.3s-86 61.9-132.5 35.1c-38.7-22.3-56.3-67.1-45.7-108.1 2.1-8.3-30.4-38.6-25.9-46.3 4-7 44.7 8.8 50.1 3.2 30-31.1 78.6-38.9 118.1-16.2z',
      2.5: 'M365.1,172.2c46.5,26.8,62.5,86.1,35.9,132.3s-86,61.9-132.5,35.1c-38.7-22.3-56.3-67.1-45.7-108.1c2.1-8.3-42.1-44.1-37.6-51.8c4-7,56.4,14.3,61.8,8.7C277,157.3,325.6,149.5,365.1,172.2z',
      3: 'M365.1,172.2c46.5,26.8,62.5,86.1,35.9,132.3s-86,61.9-132.5,35.1c-38.7-22.3-56.3-67.1-45.7-108.1c2.1-8.3-51.7-49.7-47.2-57.4c4-7,66,19.9,71.4,14.3C277,157.3,325.6,149.5,365.1,172.2z',
      3.5: 'M365.1,172.2c46.5,26.8,62.5,86.1,35.9,132.3s-86,61.9-132.5,35.1c-38.7-22.3-56.3-67.1-45.7-108.1c2.1-8.3-64.2-56.9-59.7-64.6c4-7,78.5,27.1,83.9,21.5C277,157.3,325.6,149.5,365.1,172.2z',
      4: 'M365.1,172.2c46.5,26.8,62.5,86.1,35.9,132.3s-86,61.9-132.5,35.1c-38.7-22.3-56.3-67.1-45.7-108.1c2.1-8.3-72.1-61.4-67.6-69.1c4-7,86.4,31.6,91.8,26C277,157.3,325.6,149.5,365.1,172.2z',
      4.5: 'M365.1,172.2c46.5,26.8,62.5,86.1,35.9,132.3s-86,61.9-132.5,35.1c-38.7-22.3-56.3-67.1-45.7-108.1c2.1-8.3-83.2-67.8-78.7-75.5c4-7,97.5,38,102.9,32.4C277,157.3,325.6,149.5,365.1,172.2z',
      5: 'M365.1,172.2c46.5,26.8,62.5,86.1,35.9,132.3s-86,61.9-132.5,35.1c-38.7-22.3-56.3-67.1-45.7-108.1c2.1-8.3-93.1-73.5-88.6-81.2c4-7,107.4,43.7,112.8,38.1C277,157.3,325.6,149.5,365.1,172.2z',
    },
    [SECTIONS.LEADERSHIP]: {
      0: 'M401.2,207.2c26.8,46.5,10.9,105.9-35.5,132.7c-46.5,26.8-105.9,10.9-132.7-35.6c-22.3-38.7-15.1-86.3,14.7-116.6c6-6.1,12.4-11,20.3-15.5c7-4.1,14.9-7.7,22.4-9.9C332.2,150.3,378.4,167.8,401.2,207.2z',
      1: 'M401.2,207.2c26.8,46.5,10.9,105.9-35.5,132.7c-46.5,26.8-105.9,10.9-132.7-35.6c-22.3-38.7-15.1-86.3,14.7-116.6c6-6.1,1.7-29.8,9.6-34.3c7-4.1,25.6,11.1,33.1,8.9C332.2,150.3,378.4,167.8,401.2,207.2z',
      1.5: 'M401.2,207.2c26.8,46.5,10.9,105.9-35.5,132.7c-46.5,26.8-105.9,10.9-132.7-35.6c-22.3-38.7-15.1-86.3,14.7-116.6c6-6.1-3.6-38.9,4.3-43.4c7-4.1,30.9,20.2,38.4,18C332.2,150.3,378.4,167.8,401.2,207.2z',
      2: 'M401.2,207.2c26.8,46.5,10.9,105.9-35.5,132.7c-46.5,26.8-105.9,10.9-132.7-35.6c-22.3-38.7-15.1-86.3,14.7-116.6c6-6.1-9.4-49-1.5-53.5c7-4.1,36.7,30.3,44.2,28.1C332.2,150.3,378.4,167.8,401.2,207.2z',
      2.5: 'M401.2,207.2c26.8,46.5,10.9,105.9-35.5,132.7c-46.5,26.8-105.9,10.9-132.7-35.6c-22.3-38.7-15.1-86.3,14.7-116.6c6-6.1-15.7-59.9-7.8-64.4c7-4.1,43,41.2,50.5,39C332.2,150.3,378.4,167.8,401.2,207.2z',
      3: 'M401.2,207.2c26.8,46.5,10.9,105.9-35.5,132.7c-46.5,26.8-105.9,10.9-132.7-35.6c-22.3-38.7-15.1-86.3,14.7-116.6c6-6.1-20.1-68.8-12.2-73.3c7-4.1,47.4,50.1,54.9,47.9C332.2,150.3,378.4,167.8,401.2,207.2z',
      3.5: 'M401.2,207.2c26.8,46.5,10.9,105.9-35.5,132.7c-46.5,26.8-105.9,10.9-132.7-35.6c-22.3-38.7-15.1-86.3,14.7-116.6c6-6.1-26.7-78.9-18.8-83.4c7-4.1,54,60.2,61.5,58C332.2,150.3,378.4,167.8,401.2,207.2z',
      4: 'M401.2,207.2c26.8,46.5,10.9,105.9-35.5,132.7c-46.5,26.8-105.9,10.9-132.7-35.6c-22.3-38.7-15.1-86.3,14.7-116.6c6-6.1-32.9-89.5-25-94c7-4.1,60.2,70.8,67.7,68.6C332.2,150.3,378.4,167.8,401.2,207.2z',
      4.5: 'M401.2,207.2c26.8,46.5,10.9,105.9-35.5,132.7c-46.5,26.8-105.9,10.9-132.7-35.6c-22.3-38.7-15.1-86.3,14.7-116.6c6-6.1-39.4-100.7-31.5-105.2c7-4.1,66.7,82,74.2,79.8C332.2,150.3,378.4,167.8,401.2,207.2z',
      5: 'M401.2,207.2c26.8,46.5,10.9,105.9-35.5,132.7c-46.5,26.8-105.9,10.9-132.7-35.6c-22.3-38.7-15.1-86.3,14.7-116.6c6-6.1-44.5-109.5-36.6-114c7-4.1,71.8,90.8,79.3,88.6C332.2,150.3,378.4,167.8,401.2,207.2z',
    },
  }

  /**
   *
   * @param sectionName
   * @param {(score|benchmark)}type
   * @returns {number}
   */
  const getScore = (sectionName, type) => {
    if (props.loading) return 0
    if (!props[type]) return 0

    const actualSection = props[type].find(
      result => result.section.slug === sectionName
    )

    if (!actualSection) return 0

    return roundHalf(actualSection.score / 20)
  }

  const getCurve = (categoryName, type) => {
    let score = getScore(categoryName, type)

    return graphCurves[categoryName][score]
  }

  const sum = Object.values(SECTIONS).reduce((sum, section) => {
    const actualSection = props.score.find(
      result => result.section.slug === section && result.status === 'FINISHED'
    )

    if (actualSection) {
      return sum + actualSection.score
    }
    return sum
  }, 0)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 654 543"
      xmlSpace="preserve"
    >
      <style>
        <style type="text/css">
          {`.st0{fill:none;stroke:#8C8C8C;stroke-width:0.6074;}
            .st1{fill:#E5E4E4;}
            .st2{font-family:Nunito Sans,sans-serif;font-weight:900;}
            .st3{font-size:12.1478px;}
            .st4{font-size:10.1232px;}
            .st5{fill:none;stroke:#8C8C8C;stroke-width:0.61;stroke-linecap:square;}
            .st6{fill:#BADC68;}
            .st7{font-size:14px;}
            .st8{fill:#002A89;}
            .st9{letter-spacing:1;}
            .st10{fill:#25AA9B;}
            .st11{fill:#11A160;}
            .st12{fill:#04D5D7;}
            .st13{fill:#7EC1B1;}
            .st14{fill:#36BEEB;}
            .st15{fill:#6C7DAF;}
            .st16{fill:#FC8630;}
            .st17{fill:#740088;}
            .st18{font-size:15.3446px;}
            .st19{fill:#D9B336;}
            .st20{fill:#0063FF;}
            .st21{fill:none;stroke:url(#score-pulse-1_1_);stroke-width:6;}
            .st22{fill:none;stroke:url(#score-pulse-2_1_);stroke-width:6;}
            .st23{fill:none;stroke:url(#score-pulse-3_1_);stroke-width:6;}
            .st24{fill:none;stroke:url(#score-pulse-4_1_);stroke-width:6;}
            .st25{fill:none;stroke:url(#score-pulse-5_1_);stroke-width:6;}
            .st26{fill:none;stroke:url(#score-pulse-6_1_);stroke-width:6;}
            .st27{fill:none;stroke:url(#score-pulse-7_1_);stroke-width:6;}
            .st28{fill:none;stroke:url(#score-pulse-8_1_);stroke-width:6;}
            .st29{fill:none;stroke:url(#score-pulse-9_1_);stroke-width:6;}
            .st30{fill:none;stroke:url(#score-pulse-10_1_);stroke-width:6;}
            .st31{fill:none;stroke:url(#score-pulse-11_1_);stroke-width:6;}
            .st32{fill:none;stroke:url(#score-pulse-12_1_);stroke-width:6;}
            .cls-21 {
              font-size: 35px;
              fill: #001f66;
              font-weight: 600;
              font-family: Nunito Sans,sans-serif;
          }
          .animate {transition: 0.9s;}`}
        </style>
      </style>
      <g id="Symbols">
        <g id="Radial-Graph-12-points" transform="translate(-58 -77)">
          <g id="Base-score-guides" transform="translate(58 77)">
            <path
              id="rings-5"
              className="st0"
              d="M506.6 260.3c0 102.6-83.2 185.9-185.8 185.9S135 362.9 135 260.3 218.2 74.4 320.8 74.4s185.8 83.2 185.8 185.9z"
            />
            <path
              id="rings-6"
              className="st0"
              d="M530.9 260.2c0 116-94.1 210.1-210.1 210.1s-210.1-94.1-210.1-210.1S204.8 50.1 320.8 50.1s210.1 94.1 210.1 210.1z"
            />
            <path
              id="rings-4"
              className="st0"
              d="M485 260.3c0 90.2-73.1 163.2-163.2 163.2s-163.2-73.1-163.2-163.2S231.7 97 321.8 97 485 170.1 485 260.3z"
            />
            <path
              id="rings-3"
              className="st0"
              d="M460.5 260.3c0 77.2-62.5 139.7-139.7 139.7s-139.7-62.5-139.7-139.7c0-77.2 62.5-139.7 139.7-139.7s139.7 62.5 139.7 139.7z"
            />
            <path
              id="rings-2"
              className="st0"
              d="M438 260.3c0 65.1-52.5 117.8-117.2 117.8s-117.2-52.8-117.2-117.8 52.5-117.8 117.2-117.8S438 195.2 438 260.3z"
            />
            <path
              id="rings-1"
              className="st0"
              d="M417.5 260.3c0 53.7-43.3 97.2-96.6 97.2s-96.6-43.5-96.6-97.2 43.3-97.2 96.6-97.2 96.6 43.5 96.6 97.2z"
            />
            <g id="Grid-score-E">
              <text
                transform="translate(422.826 256.407)"
                className="st1 st2 st3"
              >
                {'1'}
              </text>
              <text
                transform="translate(446.538 256.407)"
                className="st1 st2 st3"
              >
                {'2'}
              </text>
              <text
                transform="translate(470.25 256.407)"
                className="st1 st2 st3"
              >
                {'3'}
              </text>
              <text
                transform="translate(493.962 256.407)"
                className="st1 st2 st3"
              >
                {'4'}
              </text>
              <text
                transform="translate(517.792 256.407)"
                className="st1 st2 st4"
              >
                {'5'}
              </text>
            </g>
            <g id="Grid-score-W">
              <text
                transform="translate(213.07 272.194)"
                className="st1 st2 st3"
              >
                {'1'}
              </text>
              <text
                transform="translate(189.359 272.194)"
                className="st1 st2 st3"
              >
                {'2'}
              </text>
              <text
                transform="translate(165.647 272.194)"
                className="st1 st2 st3"
              >
                {'3'}
              </text>
              <text
                transform="translate(141.935 272.194)"
                className="st1 st2 st3"
              >
                {'4'}
              </text>
            </g>
            <g id="Grid-score-N">
              <text
                transform="translate(309.715 156.37)"
                className="st1 st2 st3"
              >
                {'1'}
              </text>
              <text
                transform="translate(309.715 135.394)"
                className="st1 st2 st3"
              >
                {'2'}
              </text>
              <text
                transform="translate(309.715 114.418)"
                className="st1 st2 st3"
              >
                {'3'}
              </text>
              <text
                transform="translate(309.715 89.794)"
                className="st1 st2 st3"
              >
                {'4'}
              </text>
              <text
                transform="translate(309.833 65.906)"
                className="st1 st2 st4"
              >
                {'5'}
              </text>
            </g>
            <g id="Grid-score-S">
              <text
                transform="translate(324.307 462.802)"
                className="st1 st2 st3"
              >
                {'5'}
              </text>
              <text
                transform="translate(324.307 441.826)"
                className="st1 st2 st3"
              >
                {'4'}
              </text>
              <text
                transform="translate(324.307 420.85)"
                className="st1 st2 st3"
              >
                {'3'}
              </text>
              <text
                transform="translate(324.307 396.226)"
                className="st1 st2 st3"
              >
                {'2'}
              </text>
              <text
                transform="translate(324.425 372.338)"
                className="st1 st2 st4"
              >
                {'1'}
              </text>
            </g>
            <g id="New-lines" transform="translate(110.72 49.987)">
              <path
                id="_x31_2-oclock-line"
                className="st0"
                d="M210.1 113.4L209.9 0"
              />
              <path
                id="_x31_1-oclock-line"
                className="st5"
                d="M162 127.1l-57-98.3"
              />
              <path
                id="_x31_0-oclock-line"
                className="st5"
                d="M126.8 162.2l-98.7-56.8"
              />
              <path
                id="_x39_-oclock-line"
                className="st0"
                d="M113.9 211.1L0 210.8"
              />
              <path
                id="_x38_-oclock-line"
                className="st5"
                d="M125.6 258.7l-97.5 56.1"
              />
              <path
                id="_x37_-oclock-line"
                className="st5"
                d="M161.5 294L105 391.4"
              />
              <path
                id="_x36_-oclock-line"
                className="st0"
                d="M210 307.2l.3 113.4"
              />
              <path
                id="_x35_-oclock-line"
                className="st5"
                d="M315.1 391.4L258.7 294"
              />
              <path
                id="_x34_-oclock-line"
                className="st5"
                d="M392.1 314.8l-97.9-56.3"
              />
              <path
                id="_x33_-oclock-line"
                className="st0"
                d="M306.2 209.6l113.9.2"
              />
              <path
                id="_x32_-oclock-line"
                className="st5"
                d="M392.1 105.4l-98.5 56.7"
              />
              <path
                id="_x31_-oclock-line"
                className="st5"
                d="M315.1 28.8L258 127.4"
              />
            </g>
            <g id="Labels">
              <g id="Group">
                <text
                  transform="translate(271.874 15)"
                  className="st2 st7"
                  fill="#badc68"
                >
                  {'Sustainability'}
                </text>
                <text
                  transform="translate(306.188 36)"
                  className="st8 st2 st7 st9"
                >
                  {getScore(SECTIONS.SUSTAINABILITY, 'score')}/5
                </text>
              </g>
              <g id="Training-and-skills-">
                <text
                  transform="translate(496.045 128)"
                  className="st2 st7"
                  fill="#25aa9b"
                >
                  {'Training and skills'}
                </text>
                <text
                  transform="translate(545.188 148.115)"
                  className="st8 st2 st7 st9"
                >
                  {getScore(SECTIONS.TRAINING, 'score')}/5
                </text>
              </g>
              <g id="Quality-Management">
                <text
                  transform="translate(438.395 25)"
                  className="st11 st2 st7"
                >
                  {'Quality'}
                </text>
                <text
                  transform="translate(417.782 44)"
                  className="st11 st2 st7"
                >
                  {'Management'}
                </text>
                <text
                  transform="translate(450.38 65)"
                  className="st8 st2 st7 st9"
                >
                  {getScore(SECTIONS.QUALITY_MANAGEMENT, 'score')}/5
                </text>
              </g>
              <g id="Mental-health">
                <text
                  transform="translate(518.64 378)"
                  className="st12 st2 st7"
                >
                  {'Mental health &'}
                </text>
                <text
                  transform="translate(539.165 397)"
                  className="st12 st2 st7"
                >
                  {'workforce'}
                </text>
                <text transform="translate(531.2 416)" className="st12 st2 st7">
                  {'engagement'}
                </text>
                <text
                  transform="translate(561.027 436.286)"
                  className="st8 st2 st7 st9"
                >
                  {getScore(SECTIONS.MENTAL_HEALTH, 'score')}/5
                </text>
              </g>
              <g id="Profitability">
                <text
                  transform="translate(548.18 249)"
                  className="st2 st7"
                  fill="#7ec1b1"
                >
                  {'Profitability'}
                </text>
                <text
                  transform="translate(576.027 275.286)"
                  className="st8 st2 st7 st9"
                >
                  {getScore(SECTIONS.PROFITABILITY, 'score')}/5
                </text>
              </g>
              <g id="Disruption-and-change">
                <text
                  transform="translate(175.42 504.8)"
                  className="st8 st2 st7 st9"
                >
                  {getScore(SECTIONS.DISRUPTION, 'score')}/5
                </text>
                <text
                  transform="translate(152.222 464.8)"
                  className="st8 st2 st7"
                >
                  {'Disruption'}
                </text>
                <text
                  transform="translate(149.142 483.8)"
                  className="st8 st2 st7"
                >
                  {'and change'}
                </text>
              </g>
              <g id="Business-planning-and-development">
                <text
                  transform="translate(450.38 521)"
                  className="st8 st2 st7 st9"
                >
                  {getScore(SECTIONS.BUSINESS_PLANNING, 'score')}/5
                </text>
                <text
                  transform="translate(401.386 481)"
                  className="st14 st2 st7"
                >
                  {'Business planning'}
                </text>
                <text
                  transform="translate(403.278 500)"
                  className="st14 st2 st7"
                >
                  {'and development'}
                </text>
              </g>
              <g id="Payments-and-cash-flow">
                <text
                  transform="translate(37.38 288)"
                  className="st8 st2 st7 st9"
                >
                  {getScore(SECTIONS.PAYMENTS, 'score')}/5
                </text>
                <text transform="translate(1.907 248)" className="st15 st2 st7">
                  {'Payments and'}
                </text>
                <text
                  transform="translate(18.844 267)"
                  className="st15 st2 st7"
                >
                  {'cash flow'}
                </text>
              </g>
              <g id="Health-_x26_-Safety">
                <text
                  transform="translate(95.873 149.36)"
                  className="st8 st2 st7 st9"
                >
                  {getScore(SECTIONS.HEALTH_SAFETY, 'score')}/5
                </text>
                <text
                  transform="translate(79.594 109.36)"
                  className="st16 st2 st7"
                >
                  {'Health &'}
                </text>
                <text
                  transform="translate(88.147 128.36)"
                  className="st16 st2 st7"
                >
                  {'Safety'}
                </text>
              </g>
              <g id="Risk">
                <text
                  transform="translate(84.38 393)"
                  className="st8 st2 st7 st9"
                >
                  {getScore(SECTIONS.RISK, 'score')}/5
                </text>
                <text
                  transform="translate(83.46 372)"
                  className="st2 st7"
                  fill="#740088"
                >
                  {'Risk'}
                </text>
              </g>
              <g id="Leadership-and-quality">
                <text
                  transform="translate(177.072 62)"
                  className="st8 st2 st9"
                  fontSize={15.345}
                >
                  {getScore(SECTIONS.LEADERSHIP, 'score')}/5
                </text>
                <text
                  transform="translate(153.508 21)"
                  className="st19 st2 st7"
                >
                  {'Leadership'}
                </text>
                <text
                  transform="translate(152.618 40)"
                  className="st19 st2 st7"
                >
                  {'and quality'}
                </text>
              </g>
              <g id="Digital-enablement">
                <text
                  transform="translate(305.38 539)"
                  className="st8 st2 st7 st9"
                >
                  {getScore(SECTIONS.DIGITAL_ENABLEMENT, 'score')}/5
                </text>
                <text
                  transform="translate(296.037 499)"
                  className="st20 st2 st7"
                >
                  {'Digital'}
                </text>
                <text
                  transform="translate(276.623 518)"
                  className="st20 st2 st7"
                >
                  {'enablement'}
                </text>
              </g>
              <g id="body-line-1" className="cls-2">
                <text
                  className="cls-21"
                  transform={`translate(${sum ? 286 : 296} 270)`}
                >
                  {Math.round(sum / 12)}%
                </text>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Symbols_1_">
        <g id="Radial-Graph-12-points_1_" transform="translate(-199 -142)">
          <g id="Pulse-lines" transform="translate(203.592 147.057)">
            <linearGradient
              id="score-pulse-1_1_"
              gradientUnits="userSpaceOnUse"
              x1={-632.091}
              y1={805.076}
              x2={-632.091}
              y2={805.529}
              gradientTransform="matrix(193.2428 0 0 -239.0472 122463.453 192677.781)"
            >
              <stop offset={0} stopColor="#fff" stopOpacity={0} />
              <stop offset={0.564} stopColor="#b9dc65" />
              <stop offset={1} stopColor="#b9dc66" />
            </linearGradient>
            <path
              id="score-pulse-1"
              d={getCurve(SECTIONS.SUSTAINABILITY, 'score')}
              fill="none"
              stroke="url(#score-pulse-1_1_)"
              strokeWidth={6}
              className="animate"
            />
            <linearGradient
              id="score-pulse-2_1_"
              gradientUnits="userSpaceOnUse"
              x1={-631.762}
              y1={806.905}
              x2={-631.76}
              y2={807.325}
              gradientTransform="matrix(167.3531 96.6214 118.5828 -205.3915 10375.547 227000.266)"
            >
              <stop offset={0} stopColor="#fff" stopOpacity={0} />
              <stop offset={0.5} stopColor="#10a05f" />
              <stop offset={1} stopColor="#11a261" />
            </linearGradient>
            <path
              id="score-pulse-2"
              d={getCurve(SECTIONS.QUALITY_MANAGEMENT, 'score')}
              fill="none"
              stroke="url(#score-pulse-2_1_)"
              strokeWidth={6}
              className="animate"
            />
            <linearGradient
              id="score-pulse-3_1_"
              gradientUnits="userSpaceOnUse"
              x1={-632.595}
              y1={808.347}
              x2={-632.595}
              y2={808.856}
              gradientTransform="matrix(96.6214 167.3531 225.5396 -130.2154 -120853.117 211368.86)"
            >
              <stop offset={0} stopColor="#fff" stopOpacity={0} />
              <stop offset={0.564} stopColor="#25aa9b" />
              <stop offset={1} stopColor="#25aa9b" />
            </linearGradient>
            <path
              id="score-pulse-3"
              d={getCurve(SECTIONS.TRAINING, 'score')}
              fill="none"
              stroke="url(#score-pulse-3_1_)"
              strokeWidth={6}
              className="animate"
            />
            <linearGradient
              id="score-pulse-4_1_"
              gradientUnits="userSpaceOnUse"
              x1={-634.365}
              y1={809.759}
              x2={-634.365}
              y2={810.276}
              gradientTransform="matrix(0 193.2428 237.1397 0 -191675.266 122842.156)"
            >
              <stop offset={0} stopColor="#fff" stopOpacity={0} />
              <stop offset={0.411} stopColor="#8ad4c2" />
              <stop offset={1} stopColor="#89d2c0" />
            </linearGradient>
            <path
              id="score-pulse-4"
              d={getCurve(SECTIONS.PROFITABILITY, 'score')}
              fill="none"
              stroke="url(#score-pulse-4_1_)"
              strokeWidth={6}
              className="animate"
            />
            <linearGradient
              id="score-pulse-5_1_"
              gradientUnits="userSpaceOnUse"
              x1={-636.597}
              y1={809.381}
              x2={-636.598}
              y2={809.873}
              gradientTransform="matrix(-96.6214 167.3531 247.2754 142.7645 -261291.156 -8734.833)"
            >
              <stop offset={0} stopColor="#fff" stopOpacity={0} />
              <stop offset={0.443} stopColor="#04d5d7" />
              <stop offset={1} stopColor="#04d5d7" />
            </linearGradient>
            <path
              id="score-pulse-5"
              d={getCurve(SECTIONS.MENTAL_HEALTH, 'score')}
              fill="none"
              stroke="url(#score-pulse-5_1_)"
              strokeWidth={6}
              className="animate"
            />
            <linearGradient
              id="score-pulse-6_1_"
              gradientUnits="userSpaceOnUse"
              x1={-638.693}
              y1={809.307}
              x2={-638.692}
              y2={809.757}
              gradientTransform="matrix(-167.3531 96.6214 120.0456 207.925 -203707.39 -106278.047)"
            >
              <stop offset={0} stopColor="#fff" stopOpacity={0} />
              <stop offset={0.635} stopColor="#03ade5" />
              <stop offset={1} stopColor="#03ade5" />
            </linearGradient>
            <path
              id="score-pulse-6"
              d={getCurve(SECTIONS.BUSINESS_PLANNING, 'score')}
              fill="none"
              stroke="url(#score-pulse-6_1_)"
              strokeWidth={6}
              className="animate"
            />
            <linearGradient
              id="score-pulse-7_1_"
              gradientUnits="userSpaceOnUse"
              x1={-640.094}
              y1={807.725}
              x2={-640.094}
              y2={808.225}
              gradientTransform="matrix(-193.2428 0 0 260.4307 -123376.977 -210067.828)"
            >
              <stop offset={0} stopColor="#fff" stopOpacity={0} />
              <stop offset={0.536} stopColor="#0063ff" />
              <stop offset={1} stopColor="#0063ff" />
            </linearGradient>
            <path
              id="score-pulse-7"
              d={getCurve(SECTIONS.DIGITAL_ENABLEMENT, 'score')}
              fill="none"
              stroke="url(#score-pulse-7_1_)"
              strokeWidth={6}
              className="animate"
            />
            <linearGradient
              id="score-pulse-8_1_"
              gradientUnits="userSpaceOnUse"
              x1={-640.441}
              y1={806.123}
              x2={-640.441}
              y2={806.597}
              gradientTransform="matrix(-166.6031 -96.1884 -141.046 244.2988 7292.382 -258237.781)"
            >
              <stop offset={0} stopColor="#fff" stopOpacity={0} />
              <stop offset={0.504} stopColor="#002a89" />
              <stop offset={1} stopColor="#002a89" />
            </linearGradient>
            <path
              id="score-pulse-8"
              d={getCurve(SECTIONS.DISRUPTION, 'score')}
              fill="none"
              stroke="url(#score-pulse-8_1_)"
              strokeWidth={6}
              className="animate"
            />
            <linearGradient
              id="score-pulse-9_1_"
              gradientUnits="userSpaceOnUse"
              x1={-639.589}
              y1={804.23}
              x2={-639.589}
              y2={804.591}
              gradientTransform="matrix(-96.6214 -167.3531 -185.0896 106.8615 87339.18 -192703.11)"
            >
              <stop offset={0} stopColor="#fff" stopOpacity={0} />
              <stop offset={0.784} stopColor="#740088" />
              <stop offset={1} stopColor="#740088" />
            </linearGradient>
            <path
              id="score-pulse-9"
              d={getCurve(SECTIONS.RISK, 'score')}
              fill="none"
              stroke="url(#score-pulse-9_1_)"
              strokeWidth={6}
              className="animate"
            />
            <linearGradient
              id="score-pulse-10_1_"
              gradientUnits="userSpaceOnUse"
              x1={-637.82}
              y1={803.491}
              x2={-637.82}
              y2={803.974}
              gradientTransform="matrix(0 -193.2428 -260.4307 0 209533 -122998.273)"
            >
              <stop offset={0} stopColor="#fff" stopOpacity={0} />
              <stop offset={0.497} stopColor="#8b98c0" />
              <stop offset={1} stopColor="#7d8ab2" />
            </linearGradient>
            <path
              id="score-pulse-10"
              d={getCurve(SECTIONS.PAYMENTS, 'score')}
              fill="none"
              stroke="url(#score-pulse-10_1_)"
              strokeWidth={6}
              className="animate"
            />
            <linearGradient
              id="score-pulse-11_1_"
              gradientUnits="userSpaceOnUse"
              x1={-635.587}
              y1={803.021}
              x2={-635.587}
              y2={803.405}
              gradientTransform="matrix(96.6214 -167.3531 -204.7746 -118.2267 226124.406 -11197.393)"
            >
              <stop offset={0} stopColor="#fff" stopOpacity={0} />
              <stop offset={0.646} stopColor="#ff6b00" />
              <stop offset={1} stopColor="#ff6b00" />
            </linearGradient>
            <path
              id="score-pulse-11"
              d={getCurve(SECTIONS.HEALTH_SAFETY, 'score')}
              fill="none"
              stroke="url(#score-pulse-11_1_)"
              strokeWidth={6}
              className="animate"
            />
            <linearGradient
              id="score-pulse-12_1_"
              gradientUnits="userSpaceOnUse"
              x1={-633.504}
              y1={803.905}
              x2={-633.504}
              y2={804.122}
              gradientTransform="matrix(168.2192 -97.1214 -130.2154 -225.5396 211541.453 120000.008)"
            >
              <stop offset={0} stopColor="#fff" stopOpacity={0} />
              <stop offset={1} stopColor="#d9b336" />
            </linearGradient>
            <path
              id="score-pulse-12"
              d={getCurve(SECTIONS.LEADERSHIP, 'score')}
              fill="none"
              stroke="url(#score-pulse-12_1_)"
              strokeWidth={6}
              className="animate"
            />
            {filter.initial
              ? Object.values(SECTIONS).map(section => (
                  <path
                    key={section}
                    fill="none"
                    strokeWidth={6}
                    stroke="black"
                    d={getCurve(section, 'initial')}
                    className="animate"
                  />
                ))
              : null}
            {filter.benchmark
              ? Object.values(SECTIONS).map(section => (
                  <path
                    key={section}
                    fill="none"
                    strokeWidth={6}
                    stroke="red"
                    d={getCurve(section, 'avg')}
                    className="animate"
                  />
                ))
              : null}
          </g>
        </g>
      </g>
    </svg>
  )
}

Graph.propTypes = {
  score: PropTypes.arrayOf(
    PropTypes.shape({
      section: PropTypes.object.isRequired,
      score: PropTypes.number,
      status: PropTypes.oneOf(['SKIPPED', 'LATER', 'FINISHED', 'ASSIGNED']),
    })
  ).isRequired,
  avg: PropTypes.arrayOf(
    PropTypes.shape({
      section: PropTypes.shape({
        id: PropTypes.number.isRequired,
        slug: PropTypes.string.isRequired,
      }),
      score: PropTypes.number,
    })
  ).isRequired,
  initial: PropTypes.arrayOf(
    PropTypes.shape({
      section: PropTypes.shape({
        id: PropTypes.number.isRequired,
        slug: PropTypes.string.isRequired,
      }),
      score: PropTypes.number,
    })
  ).isRequired,
  loading: PropTypes.bool,
}

export default Graph
