import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { makeStyles, Typography } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import { getAvatarUrl } from '../../utils'
import BookingModal from '../Mentor/BookingModal'

const MentorItem = props => {
  const classes = useStyles()

  return (
    <Grid component="article" xs={12} sm={12} md={6} lg={4} item>
      <div className={classes.container}>
        <div className={classes.meta}>
          {props.avatar ? (
            <Avatar
              src={getAvatarUrl(props.avatar)}
              className={classes.avatar}
            />
          ) : (
            <Avatar className={classes.avatar}>
              {props.firstName[0]}
              {props.lastName[0]}
            </Avatar>
          )}
          <Typography className={classes.name}>
            {props.firstName}
            {props.lastName}
          </Typography>
        </div>
        <div className={classes.categories}>
          {props.categories.map(category => (
            <span className={classes.label} key={category.id}>
              {category.title}
            </span>
          ))}
        </div>
        <div className={classes.btns}>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            component={Link}
            className={classes.btn}
            to={`/mentors/${props.id}`}
          >
            View profile
          </Button>
          <BookingModal
            className={classes.bookBtn}
            name={`${props.firstName} ${props.lastName}`}
            calendars={props.calendars}
            mentorId={props.id}
          />
        </div>
      </div>
    </Grid>
  )
}

MentorItem.propTypes = {
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  avatar: PropTypes.object,
  calendars: PropTypes.arrayOf(
    PropTypes.shape({
      duration: PropTypes.number.isRequired,
      credit: PropTypes.string.isRequired,
      calendly_link: PropTypes.string.isRequired,
      one_off_price: PropTypes.number.isRequired,
    })
  ).isRequired,
}

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: 'white',
    borderRadius: 8,
    padding: theme.spacing(3),
    boxShadow:
      '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    width: 40,
    height: 40,
    backgroundColor: theme.palette.primary.main,
    '& svg': {
      fontSize: 30,
      [theme.breakpoints.up('sm')]: {
        fontSize: 40,
      },
    },
  },
  meta: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  name: {
    fontWeight: 600,
    fontSize: 18,
    marginLeft: theme.spacing(1),
  },
  label: {
    fontSize: 12,
    color: '#1E40AF',
    backgroundColor: '#DBEAFE',
    padding: theme.spacing(0, 1),
    borderRadius: 4,
    '& :not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  categories: {
    marginBottom: theme.spacing(3),

    '& > :not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  btns: {
    display: 'flex',
    marginTop: 'auto',
  },
  btn: {
    flex: 1,
    boxShadow: 'none',
    fontSize: 14,
    fontWeight: 500,
    padding: '9px 20px !important',
  },
  bookBtn: {
    flex: 1,
    boxShadow: 'none',
    fontSize: 14,
    fontWeight: 500,
    padding: '9px 20px !important',
    borderColor: theme.palette.primary.main,
    borderWidth: '2px !important',
    marginLeft: theme.spacing(1),
  },
}))

export default MentorItem
