import React from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core'

import Header from './Header'
import Footer from './Footer'
import ErrorBoundary from '../components/ErrorBoundary'
import PledgeModal from '../pages/PledgeModal'
import UpgradeModal from '../components/Setting/UpgradeModal'

const useStyles = makeStyles(theme => ({
  main: {
    flex: '1 0 auto;',
  },
}))

const Layout = props => {
  const { main } = useStyles()
  return (
    <React.Fragment>
      <Header />
      <Toolbar />
      <ErrorBoundary>
        <main className={main}>{props.children}</main>
        <PledgeModal />
        <UpgradeModal />
      </ErrorBoundary>
      <Footer />
    </React.Fragment>
  )
}

export default Layout
