import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import { makeStyles } from '@material-ui/core'

import { shuffle } from '../../utils'
import Label from '../Label'

const Question = ({ question, onAnswer, selectedAnswer }) => {
  const classes = useStyles()
  const { question: title, answer } = question
  // randomize the answer array
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const randomOptions = useMemo(() => shuffle([...answer]), [
    question.question,
    answer,
  ])

  return (
    <FormControl component="fieldset" className={classes.root}>
      <Label>{title}</Label>
      <RadioGroup
        aria-label={title}
        className={classes.radios}
        onChange={onAnswer}
        value={selectedAnswer?.answer || ''}
      >
        {randomOptions.map(option => (
          <FormControlLabel
            classes={{ label: classes.radioLabel }}
            key={option.id}
            value={option.id}
            className={classes.radio}
            control={<Radio color="primary" />}
            label={option.text}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

Question.propTypes = {
  question: PropTypes.shape({
    question: PropTypes.string.isRequired,
    answer: PropTypes.arrayOf(
      PropTypes.shape({
        score: PropTypes.number.isRequired,
        text: PropTypes.string.isRequired,
      })
    ),
  }),
  onAnswer: PropTypes.func.isRequired,
  selectedAnswer: PropTypes.shape({
    answer: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
  }),
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  radios: {
    '& > :not(:last-child)': {
      marginBottom: 12,
    },
  },
  radio: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
    borderRadius: 6,
    boxShadow: '0 2px 10px rgb(0 0 0 / 0.12)',
    padding: theme.spacing(2, 2, 2, 1),
    margin: 0,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'white',
    transition: theme.transitions.create('border'),
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  },
  radioLabel: {
    fontSize: 14,
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
    },
  },
}))

export default Question
