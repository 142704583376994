import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'

import NextButton from './NextButton'
import BackButton from './BackButton'

const useStyles = makeStyles(theme => ({
  root: {
    borderTop: 'solid 1px #6D6D6D',
    paddingTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(6),
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(10),
    },
  },
  button: {
    fontSize: 12,
    borderRadius: 6,
    fontWeight: 400,
    border: 'solid 1px #6D6D6D',
    textDecoration: 'none !important',
    boxShadow: '0 0 4px rgba(0,0,0,0.2)',
    padding: 12,
    '&.Mui-disabled': {
      color: '#6D6D6D',
    },
  },
}))

const PostNavLine = ({ post }) => {
  const classes = useStyles()
  const user = useSelector(state => state.account.user)

  return (
    <div className={classes.root}>
      <BackButton post={post} className={classes.button} />
      {user ? (
        <NextButton
          className={classes.button}
          createdAt={post.created_at}
          section={post.section ? post.section.slug : null}
        />
      ) : null}
    </div>
  )
}

PostNavLine.propTypes = {
  post: PropTypes.object.isRequired,
}

export default React.memo(PostNavLine)
