import React from 'react'
import PropTypes from 'prop-types'

import TextInput from '../TextInput'
import SelectInput from '../SelectInput'
import CheckBoxInput from '../CheckBoxInput'
import RadioInput from '../RadioInput'
import { otherRole } from '../../config'
import { COMPANY_LOCATIONS, COMPANY_SIZE } from '../../constans'

const StepOne = ({ form }) => {
  const { register, control, errors, formState, watch } = form

  const isOtherRole = watch('company_role') === otherRole

  const disableRole = !formState.dirtyFields.name || formState.isValid
  const disableLocations =
    disableRole ||
    (isOtherRole
      ? !formState.dirtyFields.your_role
      : !formState.dirtyFields.company_role)
  const disableCompanySize =
    disableLocations || !formState.dirtyFields.locations

  return (
    <div>
      <TextInput
        label="Your company name"
        name="name"
        placeholder="eg. Global construction limited"
        inputRef={register({
          required: 'Fill your company name',
        })}
        error={errors.name}
      />
      <SelectInput
        label="What is your role in your company?"
        name="company_role"
        placeholder="Select from the list"
        values={[
          'Owner',
          'Director',
          'Senior decision maker',
          'Influencer to one of the above',
          otherRole,
        ]}
        control={control}
        disabled={disableRole}
      />
      {isOtherRole ? (
        <TextInput
          label="Your role"
          name="your_role"
          placeholder="Your role"
          inputRef={register({
            required: 'Fill your role',
          })}
          error={errors.your_role}
          disabled={disableRole}
        />
      ) : null}
      <CheckBoxInput
        label="Where does your company work?"
        name="locations"
        options={COMPANY_LOCATIONS}
        disabled={disableLocations}
        control={control}
        error={errors.locations}
        rules={{
          required: true,
          validate: {
            minLength: value =>
              value.length > 0 ? true : 'Select at least one location!',
          },
        }}
      />
      <RadioInput
        label="What size is your company?"
        name="size"
        options={COMPANY_SIZE}
        disabled={disableCompanySize}
        error={errors.size}
        control={control}
      />
    </div>
  )
}

StepOne.propTypes = {
  form: PropTypes.object.isRequired,
}

export default StepOne
