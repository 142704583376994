import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  ButtonBase,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { useMutation } from 'react-query'

import { setSettingsDrawer } from '../../store/uiSlice'
import checkoutService from '../../services/checkoutService'

function TeamHeader() {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const dispatch = useDispatch()
  const buy = useMutation(checkoutService.getCheckoutSession)
  const account = useSelector(state => state.account)
  const availableCredits = account.company.credit || 0

  const handleBuyCredit = async () => {
    const d = await buy.mutateAsync()
    window.location.href = d
  }

  const handleDrawer = () => {
    dispatch(setSettingsDrawer(true))
  }

  if (isDesktop) {
    return (
      <div className={classes.root}>
        <div className={classes.meta}>
          <Typography component="h1" className={classes.title}>
            Team members
          </Typography>
          <Typography className={classes.ds}>
            Invite members of your team to work with you on improving the
            resilience of your company
          </Typography>
        </div>
        <div className={classes.creditContainer}>
          Credits
          <div className={classes.credits}>{availableCredits}</div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleBuyCredit}
            disabled={buy.isLoading}
            size="medium"
          >
            Buy Credits
          </Button>
        </div>
      </div>
    )
  }

  return (
    <ButtonBase className={classes.root} onClick={handleDrawer} disableRipple>
      <div className={classes.icon}>
        <KeyboardArrowDown />
      </div>
      <div className={classes.meta}>
        <Typography component="h1" className={classes.title}>
          Team members
        </Typography>
        <Typography className={classes.ds}>
          Invite members of your team to work with you on improving the
          resilience of your company
        </Typography>
      </div>
    </ButtonBase>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 0),
    borderBottom: 'solid 1px #D8D8D8',
    display: 'flex',
    width: '100%',
    textAlign: 'left',
    marginBottom: theme.spacing(4),
  },
  meta: {
    flex: 1,
  },
  title: {
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    fontSize: 20,
    [theme.breakpoints.up('md')]: {
      fontSize: 28,
    },
  },
  ds: {
    fontSize: 16,
    [theme.breakpoints.up('md')]: {
      fontSize: 20,
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    '& svg': {
      width: 32,
      height: 32,
    },
  },
  credits: {
    backgroundColor: '#F3F4F6',
    border: 'solid 1px #D1D5DB',
    borderRadius: 6,
    padding: '6px 16px',
    margin: theme.spacing(0, 2),
    color: '#6B7280',
  },
  creditContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default TeamHeader
