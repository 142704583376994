import React, { useState } from 'react'
import { Collapse, makeStyles } from '@material-ui/core'
import { Form, Formik, Field } from 'formik'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'

import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import { Alert } from '@material-ui/lab'

import { changePassword } from '../../store/accountSlice'
import { getErrorMessage, setFormErrors } from '../../utils'
import SettingContainer from './SettingContainer'
import FormikTextField from '../FormikTextField'

const schema = Yup.object().shape({
  old_password: Yup.string().required('Password is required'),
  new_password: Yup.string()
    .min(6, 'Password must contain at least 6 characters')
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
      'Password must contain at least 6 characters, one number, one uppercase letter'
    ),
  passwordAgain: Yup.string()
    .required()
    .label('Confirm password')
    .oneOf([Yup.ref('new_password'), null], 'Passwords must match'),
})

function ChangePasswordForm() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [oldPw, setOldPwVisibility] = useState(false)
  const [newPw, setNewPwVisibility] = useState(false)
  const [newPwAgain, setNewPwAgainVisibility] = useState(false)
  const [changed, setChanged] = useState(false)

  const initialValues = {
    old_password: '',
    new_password: '',
    passwordAgain: '',
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const handleSubmit = async (data, formikBag) => {
    const response = await dispatch(
      changePassword({
        password: data.new_password,
        oldPassword: data.old_password,
      })
    )

    if (response.type === changePassword.rejected.toString()) {
      const message = getErrorMessage(response)
      if (message) {
        setFormErrors(message, formikBag?.setFieldError)
      }
    } else {
      setChanged(true)
      formikBag.resetForm()
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors, dirty }) => (
        <Form noValidate className={classes.root}>
          <SettingContainer title="Security">
            <Collapse in={changed}>
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setChanged(false)
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                You changed your password!
              </Alert>
            </Collapse>
            <Field
              component={FormikTextField}
              label="Old password"
              name="old_password"
              helperText={errors.old_password}
              error={!!errors.old_password}
              aria-errormessage={errors.old_password}
              aria-invalid={errors.old_password ? 'true' : 'false'}
              type={oldPw ? 'text' : 'password'}
              InputLabelProps={{ required: false }}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setOldPwVisibility(!oldPw)}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {oldPw ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              color="secondary"
              variant="filled"
              margin="normal"
              required
              fullWidth
              disabled={isSubmitting}
            />
            <Field
              component={FormikTextField}
              label="New password"
              name="new_password"
              helperText={errors.new_password}
              error={!!errors.new_password}
              aria-errormessage={errors.new_password}
              aria-invalid={errors.new_password ? 'true' : 'false'}
              type={newPw ? 'text' : 'password'}
              autoComplete="new-password"
              InputLabelProps={{ required: false }}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setNewPwVisibility(!newPw)}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {newPw ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              color="secondary"
              variant="filled"
              margin="normal"
              required
              fullWidth
              disabled={isSubmitting}
            />
            <Field
              component={FormikTextField}
              label="Confirm new password"
              name="passwordAgain"
              helperText={errors.passwordAgain}
              error={!!errors.passwordAgain}
              aria-errormessage={errors.passwordAgain}
              aria-invalid={errors.passwordAgain ? 'true' : 'false'}
              type={newPwAgain ? 'text' : 'password'}
              autoComplete="new-password"
              InputLabelProps={{ required: false }}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setNewPwAgainVisibility(!newPwAgain)}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {newPwAgain ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              color="secondary"
              variant="filled"
              margin="normal"
              required
              fullWidth
              disabled={isSubmitting}
            />
            <div className={classes.submitContainer}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                disabled={isSubmitting || !dirty}
              >
                Update
              </Button>
            </div>
          </SettingContainer>
        </Form>
      )}
    </Formik>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
  },
  submitContainer: {
    marginTop: theme.spacing(2),
    textAlign: 'right',
  },
}))

export default ChangePasswordForm
