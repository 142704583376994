export const PAGE_TITLE = 'Resilience in Construction'
export const SECTIONS = {
  TRAINING: 'training_and_skills',
  MENTAL_HEALTH: 'mental_health',
  BUSINESS_PLANNING: 'business_planning',
  DIGITAL_ENABLEMENT: 'digital_enablement',
  DISRUPTION: 'disruption',
  PAYMENTS: 'payments',
  RISK: 'risk',
  LEADERSHIP: 'leadership',
  SUSTAINABILITY: 'sustainability',
  QUALITY_MANAGEMENT: 'quality_management',
  HEALTH_SAFETY: 'health_safety',
  PROFITABILITY: 'profitability',
}
export const SETTINGS_DRAWER_WIDTH = 291
export const COMPANY_LOCATIONS = [
  'England',
  'Wales',
  'Republic of Ireland',
  'Scotland',
  'Northern Ireland',
  'Internationally',
]

export const COMPANY_SIZE = [
  '0-10 employees',
  '11-100 employees',
  '101-250 employees',
  '251 or more employees',
]

export const COMPANY_ACTIVITY = [
  'Civil engineering (including groundworks, highways, structures and rail)',
  'Building (including steel framed buildings and housing)',
  'M&E (including water, utilities and wind farms)',
  'Trades (e.g shop fitting, joinery, plumbing, electrical, bricklaying, glazing)',
  'Specialist activities (e.g. demolition, asbestos removal, cladding)',
]

export const COMPANY_MOTIVATION = [
  'COVID has made me reflect on what more we should be doing',
  'I’m in a position of responsibility and I’m worried about the future of the company',
  'It’s the right thing to do by our employees, clients and suppliers',
  'Show our employees we are taking it seriously so they don’t need to worry about job security',
  'To give confidence to our customers, potential customers and supply chain',
  'To ensure we remain competitive',
  'To help us prepare for future sudden disruption',
  'To understand our level of resilience in relation to similar size companies',
]

export const USER_ROLE = [
  { id: 1, name: 'User' },
  { id: 3, name: 'Manager' },
  { id: 5, name: 'Admin' },
]

export const IMAGE_SIZES = {
  thumbnail: 245,
  small: 500,
  medium: 750,
  large: 1000,
}

export const LEVEL_COLORS = {
  beginner: '#C0FF84',
  medium: '#FFB500',
  advanced: '#B20000',
}
