import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as ReactGA from 'react-ga'
import { useQuery } from 'react-query'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { makeStyles } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

import { selectActiveSection, setActiveSection } from '../../store/postSlice'
import questionService from '../../services/questionService'

const CategoryMobileNav = props => {
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()
  const activeSection = useSelector(selectActiveSection)
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const { isLoading, data } = useQuery('result', questionService.getResult)

  useEffect(() => {
    if (isLoading || !data) return

    const finishedResult = data.filter(
      resultItem => resultItem.status === 'FINISHED'
    )
    const orderedResult = finishedResult.sort(
      (a, b) => a.section.id - b.section.id
    )
    if (!activeSection && orderedResult.length > 0) {
      dispatch(setActiveSection(orderedResult[0].section))
    }
  }, [activeSection, data, isLoading, dispatch])

  const handleCategoryChange = (event, newSection) => {
    if (newSection.id === activeSection.id) return

    ReactGA.pageview(`/knowledge-hub/${newSection.slug}`)
    dispatch(setActiveSection(newSection))
  }

  if (isDesktop || isLoading || !activeSection) {
    return null
  }

  return (
    <Tabs
      value={activeSection}
      onChange={handleCategoryChange}
      scrollButtons="on"
      TabIndicatorProps={{
        style: {
          height: 0,
        },
      }}
      className={classes.mobileNavContainer}
      variant="scrollable"
    >
      {data.map(resultItem => (
        <Tab
          label={resultItem.section.title}
          key={resultItem.section.id}
          value={resultItem.section}
          className={classes.mobileNavItem}
          disabled={resultItem.status !== 'FINISHED'}
          icon={
            <img
              src={resultItem.section?.icon?.url}
              alt={resultItem.section.title}
              className={[
                classes.mobileNavIcon,
                resultItem.status !== 'FINISHED' && classes.imageDisabled,
              ].join(' ')}
            />
          }
        />
      ))}
    </Tabs>
  )
}

const useStyles = makeStyles(theme => ({
  mobileNavItem: {
    textTransform: 'none',
    fontSize: 14,
    lineHeight: 1,
    '&.Mui-selected': {
      fontWeight: 600,
    },
  },
  imageDisabled: {
    filter: 'grayscale(1)',
  },
  mobileNavContainer: {
    zIndex: 9,
    position: 'sticky',
    top: 51,
    [theme.breakpoints.up('sm')]: {
      top: 84,
    },
    backgroundColor: 'white',
  },
  mobileNavIcon: {
    width: 30,
    marginRight: 8,
    marginBottom: '0 !important',
  },
}))

export default CategoryMobileNav
