import React from 'react'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

import HeroBanner from '../components/HomePage/HeroBanner'
import SplitContent from '../components/HomePage/SplitContent'
import EvaluateImage from '../assets/evaluation.png'
import TabletImage from '../assets/tablet.png'
import PhoneImage from '../assets/phone.png'
import Feature from '../components/HomePage/Feature'
import Page from '../layout/Page'
import PricingTable from '../components/HomePage/PricingTable'
import WeWork from '../components/HomePage/WeWork'

const HomePage = props => {
  const classes = useStyles()

  return (
    <Page title="Home">
      <HeroBanner />
      <Feature />
      <div id="platform">
        <SplitContent
          image={{ src: EvaluateImage }}
          title="Evaluate your current resilience across 12 key areas"
        >
          We’ll take you through a series of questions which will help you
          identify your areas of strength and weakness. This will help you
          reflect on your current position and help us provide you with the most
          relevant knowledge.
        </SplitContent>
        <SplitContent
          image={{ src: PhoneImage, position: 'bottom' }}
          color="gray"
          title="Shareable resilience report and action plan template"
          reverse
        >
          Your results will be presented in a clear report which you can
          download as a PDF to share with others in your company. You can also
          download an action plan template to help you identify the steps you
          need to take.
        </SplitContent>
        <SplitContent
          image={{ src: TabletImage }}
          title="Improve your knowledge with our knowledge hub and forum"
        >
          Our knowledge hub is filled with fantastic content including articles,
          videos, podcasts, eLearning modules, toolkits, and information about
          funding, support services and other resources. Join our forum to
          connect with peers and experts.
        </SplitContent>
      </div>
      <WeWork />
      <PricingTable />
      <div className={classes.ctaContainer}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          component={Link}
          to="sign-up"
        >
          Get started
        </Button>
      </div>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  ctaContainer: {
    paddingBottom: 32,
    textAlign: 'center',
    paddingTop: 32,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}))

export default HomePage
