import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import { PAGE_TITLE } from '../constans'

const Page = ({ title = '', children, post }) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>
          {title} | {PAGE_TITLE}
        </title>
        {post ? (
          <meta
            property="og:url"
            content={`${process.env.REACT_APP_CLIENT_URL}knowledge-hub/${post.slug}`}
          />
        ) : null}
        {post ? <meta property="og:type" content="article" /> : null}
        {post ? <meta property="og:title" content={post.title} /> : null}
        {post ? (
          <meta property="og:description" content={post.excerpt} />
        ) : null}
      </Helmet>
      {children}
    </React.Fragment>
  )
}

Page.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  post: PropTypes.object,
}

export default Page
