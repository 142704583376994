import { useMutation, useQueryClient } from 'react-query'
import assignmentService from '../services/assignmentService'

export default function useAssignToSection() {
  const queryClient = useQueryClient()

  return useMutation(assignmentService.saveAssignment, {
    onMutate: async ({ content_id, ...rest }) => {
      await queryClient.cancelQueries('assignments')

      const previousValue = queryClient.getQueryData('assignments')

      const newValue = previousValue.map(item => {
        if (item.id === content_id)
          return {
            ...item,
            ...rest,
          }
        return item
      })
      queryClient.setQueryData('assignments', newValue)

      return { previousValue }
    },
    onError: (err, variables, previousValue) => {
      queryClient.setQueryData('assignments', previousValue)
    },
    onSuccess: async () => {
      await queryClient.refetchQueries(['assignments'])
    },
  })
}
