import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useQuery } from 'react-query'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

import questionService from '../services/questionService'
import Page from '../layout/Page'
import IntroList from '../components/Questionnaire/IntroList'
import IntroModal from '../components/Questionnaire/IntroModal'
import ProgressSidebar from '../components/Questionnaire/ProgressSidebar'
import ResultModal from '../components/Questionnaire/ResultModal'
import AssignSectionModal from '../components/AssignSectionModal'

const QuestionnaireIntro = () => {
  const classes = useStyles()
  const user = useSelector(state => state.account.user)
  const [modalStatus, setModalStatus] = useState(false)
  const [resultStatus, setResultStatus] = useState(false)
  const location = useLocation()
  const { isLoading, data } = useQuery('result', questionService.getResult)

  useEffect(() => {
    if (!location.state?.success) return

    setResultStatus(location.state?.success)
    //remove success state on next re-render
    window.history.replaceState({}, document.title)
  }, [location.state])

  return (
    <Page title="Questionnaire Intro">
      <IntroModal status={modalStatus} onClose={() => setModalStatus(false)} />
      <AssignSectionModal />
      <ResultModal
        status={resultStatus}
        onClose={() => setResultStatus(false)}
      />
      {!isLoading ? <ProgressSidebar result={data} /> : null}

      <Container className={classes.container}>
        <Box marginBottom={6} marginTop={6}>
          <Typography className={classes.title}>Hello, {user.name}</Typography>
          <Typography className={classes.text}>
            Work your way through the 12 key resilience areas and launch your
            test results
            <Button
              variant="text"
              onClick={() => setModalStatus(true)}
              className={classes.modalButton}
            >
              How it works
            </Button>
          </Typography>
        </Box>
        <IntroList openResult={setResultStatus} />
      </Container>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 28,
    color: theme.palette.primary.dark,
    fontWeight: 700,
  },
  text: {
    fontSize: 20,
    display: 'inline',
  },
  modalButton: {
    color: theme.palette.primary.main,
  },
  container: {
    maxWidth: 950,
    //for sidebar
    '@media (min-width: 1280px)': {
      maxWidth: 850,
      marginBottom: theme.spacing(10),
    },
    '@media (min-width: 1400px)': {
      maxWidth: 950,
    },
  },
}))

export default QuestionnaireIntro
