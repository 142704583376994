import axios from '../utils/axios'

class AssignmentService {
  getAssignmentBySections = async () => {
    const response = await axios.get(`assignments/sections`)

    return response.data
  }
  saveAssignment = async data => {
    const response = await axios.post('/assignments', data)

    return response.data
  }
  deleteAssignment = async ({ section }) => {
    const response = await axios.delete(
      `/assignments/delete-by-section/${section}`
    )

    return response.data
  }
  getAssignmentByPostId = async postId => {
    const response = await axios.get(`assignments/post/${postId}`)

    return response.data
  }
}

const assignmentService = new AssignmentService()

export default Object.freeze(assignmentService)
