import React from 'react'
import PropTypes from 'prop-types'

import TopicItem from './TopicItem'

function TopicList(props) {
  return (
    <div>
      {props.data?.map(topic => {
        return (
          <TopicItem
            id={topic.id}
            key={topic.id}
            tasks={topic.task_to_user}
            status={topic.status}
            title={topic?.action_plan_topic?.title}
            text={topic?.action_plan_topic?.text}
            counter={topic.task_to_user.length}
            finishedCounter={
              topic.task_to_user.filter(task => task.status === 'FINISHED')
                .length
            }
            sectionId={props.sectionId}
          />
        )
      })}
    </div>
  )
}

TopicList.propTypes = {
  data: PropTypes.array.isRequired,
  sectionId: PropTypes.number.isRequired,
}
export default TopicList
