import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import { ButtonBase, makeStyles, Typography } from '@material-ui/core'

import assignmentService from '../../services/assignmentService'

import RadialProgress from './RadialProgress'
import ViewReportModal from './ViewReportModal'

function ProgressSidebar({ result }) {
  const classes = useStyles()
  const history = useHistory()
  const [modal, setModal] = useState(false)
  const { data } = useQuery(
    'assignments',
    assignmentService.getAssignmentBySections
  )

  const numberOfSections = result.length
  const completedSections = result.filter(r => r.status === 'FINISHED').length
  const laterSections = result.filter(r => r.status === 'LATER').length

  const viewReportModal = () => {
    if (completedSections < numberOfSections) {
      setModal(true)
    } else {
      history.push('/resilience-report')
    }
  }

  const assignedSections = data?.reduce((count, assignment) => {
    const resultItem = result.find(r => r.section.id === assignment.id)

    if (
      resultItem.status === 'SKIPPED' &&
      (assignment.assigned_user || assignment.assisgned_email)
    ) {
      count++
    }

    return count
  }, 0)

  return (
    <aside className={classes.root}>
      <ViewReportModal onClose={() => setModal(false)} status={modal} />
      <div className={classes.progress}>
        <RadialProgress value={(completedSections / numberOfSections) * 100} />
      </div>
      <div className={classes.container}>
        <Typography className={classes.title}>
          {completedSections} of {numberOfSections} completed
        </Typography>
        <div className={classes.details}>
          <Typography>{completedSections} completed</Typography>
          <Typography>{laterSections} saved for later</Typography>
          <Typography>{assignedSections} assigned</Typography>
        </div>
        <ButtonBase className={classes.button} onClick={viewReportModal}>
          View report
        </ButtonBase>
      </div>
    </aside>
  )
}

ProgressSidebar.propTypes = {
  result: PropTypes.arrayOf(
    PropTypes.shape({
      section: PropTypes.object.isRequired,
      score: PropTypes.number,
      status: PropTypes.oneOf(['SKIPPED', 'LATER', 'FINISHED', 'ASSIGNED']),
    })
  ),
}
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 12,
    padding: theme.spacing(4, 3),
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2, 2, 4, 2),
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.up('lg')]: {
      width: 225,
      flexDirection: 'column',
      position: 'fixed',
      right: 0,
      top: 150,
      borderRadius: '12px 0 0 12px',
      zIndex: 2,
      margin: 0,
    },
  },
  container: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  details: {
    backgroundColor: '#666DE6',
    borderRadius: 5,
    padding: theme.spacing(2),
    '& > p': {
      fontSize: 14,
      color: 'white',
    },
  },
  title: {
    fontSize: 18,
    color: 'white',
    fontWeight: 600,
    marginBottom: theme.spacing(1),
    textAlign: 'center',
  },
  button: {
    backgroundColor: 'white',
    fontSize: 16,
    color: theme.palette.primary.light,
    fontWeight: 700,
    padding: '19px 32px',
    borderRadius: 8,
    width: '100%',
    marginTop: theme.spacing(3),
  },
  progress: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(3),
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: 0,
    },
  },
}))

export default ProgressSidebar
