import * as React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import {
  Button,
  ButtonBase,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'

import { setSettingsDrawer } from '../../store/uiSlice'

function Title(props) {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const dispatch = useDispatch()

  const handleDrawer = () => {
    dispatch(setSettingsDrawer(true))
  }

  if (isDesktop) {
    return (
      <div className={classes.root}>
        <div className={classes.meta}>
          <Typography component="h1" className={classes.title}>
            {props.title}
          </Typography>
          <Typography className={classes.ds}>{props.description}</Typography>
        </div>
        {props.actionTitle && typeof props.actionHandler === 'function' ? (
          <div>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={props.actionHandler}
              disabled={props.actionDisabled}
            >
              {props.actionTitle}
            </Button>
          </div>
        ) : null}
      </div>
    )
  }

  return (
    <ButtonBase className={classes.root} onClick={handleDrawer} disableRipple>
      <div className={classes.icon}>
        <KeyboardArrowDown />
      </div>
      <div className={classes.meta}>
        <Typography component="h1" className={classes.title}>
          {props.title}
        </Typography>
        <Typography className={classes.ds}>{props.description}</Typography>
      </div>
    </ButtonBase>
  )
}

Title.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  actionTitle: PropTypes.string,
  actionHandler: PropTypes.func,
  actionDisabled: PropTypes.bool,
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 0),
    borderBottom: 'solid 1px #D8D8D8',
    display: 'flex',
    width: '100%',
    textAlign: 'left',
    marginBottom: theme.spacing(4),
  },
  meta: {
    flex: 1,
  },
  title: {
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    fontSize: 20,
    [theme.breakpoints.up('md')]: {
      fontSize: 28,
    },
  },
  ds: {
    fontSize: 16,
    [theme.breakpoints.up('md')]: {
      fontSize: 20,
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    '& svg': {
      width: 32,
      height: 32,
    },
  },
}))

export default Title
