import React, { Fragment } from 'react'
import { Switch, Route } from 'react-router-dom'

import GuestGuard from './components/Auth/GuestGuard'
import AuthGuard from './components/Auth/AuthGuard'
import QuestionnaireGuard from './components/Auth/QuestionnaireGuard'

import Layout from './layout/Layout'
import SignUpLayout from './layout/SignUpLayout'
import SettingsLayout from './layout/SettingsLayout'

import HomePage from './pages/HomePage'
import TermsAndConditions from './pages/TermsAndConditions'
import PrivacyPolicy from './pages/PrivacyPolicy'
import Login from './pages/Login'
import SignUp from './pages/SignUp'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import QuestionnaireIntro from './pages/QuestionnaireIntro'
import Article from './pages/Article'
import OffsiteContent from './pages/OffsiteContent'
import KnowledgeHub from './pages/KnowledgeHub'
import InitialSurvey from './pages/InitialSurvey'
import ResilienceReport from './pages/ResilienceReport'
import ResiliencePledge from './pages/ResiliencePledge'
import NotFound from './pages/NotFound'
import Profile from './pages/setting/Profile'
import CompanyProfile from './pages/setting/CompanyProfile'
import ManagerGuard from './components/Auth/ManagerGuard'
import AdminGuard from './components/Auth/AdminGuard'
import TeamMembers from './pages/setting/TeamMembers'
import QuestionList from './pages/QuestionList'
import Support from './pages/setting/Support'
import ActionPlan from './pages/ActionPlan'
import Upgrade from './pages/Upgrade'
import Billing from './pages/Billing'
import Mentors from './pages/Mentors'
import MentorSingle from './pages/MentorSingle'
import MentorBooking from './pages/MentorBooking'
import MentorStripeSessionRedirect from './pages/MentorStripeSessionRedirect'
import MentorHowToPrepare from './pages/MentorHowToPrepare'

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: HomePage,
    guard: GuestGuard,
    layout: Layout,
  },
  {
    exact: true,
    path: '/terms-and-conditions',
    component: TermsAndConditions,
    layout: Layout,
  },
  {
    exact: true,
    path: '/privacy-policy',
    component: PrivacyPolicy,
    layout: Layout,
  },
  {
    exact: true,
    path: '/login',
    component: Login,
    guard: GuestGuard,
  },
  {
    exact: true,
    path: '/sign-up',
    component: SignUp,
    guard: GuestGuard,
    layout: SignUpLayout,
  },
  {
    exact: true,
    path: '/forgot-password',
    component: ForgotPassword,
    guard: GuestGuard,
    layout: SignUpLayout,
  },
  {
    exact: true,
    path: '/reset-password',
    component: ResetPassword,
    guard: GuestGuard,
    layout: SignUpLayout,
  },
  {
    path: '/questionnaire',
    guard: AuthGuard,
    layout: Layout,
    routes: [
      {
        exact: true,
        path: '/questionnaire/intro',
        component: QuestionnaireIntro,
      },
      {
        path: '/questionnaire/:slug',
        component: QuestionList,
      },
    ],
  },
  {
    exact: true,
    layout: Layout,
    path: '/knowledge-hub/action-plan',
    component: ActionPlan,
    guard: AuthGuard,
  },
  {
    path: '/knowledge-hub/:slug',
    layout: Layout,
    component: Article,
  },
  {
    path: '/offsite-content/:slug',
    component: OffsiteContent,
  },
  {
    path: '*',
    guard: AuthGuard,
    layout: Layout,
    routes: [
      {
        exact: true,
        path: '/knowledge-hub',
        component: KnowledgeHub,
        guard: QuestionnaireGuard,
      },
      {
        path: '/tell-us-about-you',
        component: InitialSurvey,
      },
      {
        path: '/resilience-report',
        component: ResilienceReport,
        guard: QuestionnaireGuard,
      },
      {
        path: '/resilience-pledge',
        guard: AdminGuard,
        routes: [
          {
            path: '/resilience-pledge',
            component: ResiliencePledge,
            guard: QuestionnaireGuard,
          },
        ],
      },
      {
        path: '/resilience-pledge',
        guard: AdminGuard,
        routes: [
          {
            path: '/resilience-pledge',
            component: ResiliencePledge,
            guard: QuestionnaireGuard,
          },
        ],
      },
      {
        path: '/mentors',
        guard: AuthGuard,
        routes: [
          {
            path: '/mentors/how-to-prepare',
            component: MentorHowToPrepare,
            guard: QuestionnaireGuard,
          },
          {
            path: '/mentors/bookings/redirect/:stripeSessionId',
            component: MentorStripeSessionRedirect,
            guard: QuestionnaireGuard,
          },
          {
            path: '/mentors/bookings/:bookingId',
            component: MentorBooking,
            guard: QuestionnaireGuard,
          },
          {
            path: '/mentors/:id',
            component: MentorSingle,
            guard: QuestionnaireGuard,
          },
          {
            path: '/mentors',
            component: Mentors,
            guard: QuestionnaireGuard,
          },
        ],
      },
      {
        path: '/setting',
        layout: SettingsLayout,
        routes: [
          {
            exact: true,
            path: '/setting/profile',
            component: Profile,
          },
          {
            exact: true,
            path: '/setting/my-company',
            component: CompanyProfile,
            guard: ManagerGuard,
          },
          {
            exact: true,
            path: '/setting/team-members',
            component: TeamMembers,
            guard: AdminGuard,
          },
          {
            exact: true,
            path: '/setting/support',
            component: Support,
          },
          {
            exact: true,
            path: '/setting/upgrade',
            component: Upgrade,
          },
          {
            path: '/setting/billing',
            component: Billing,
          },
        ],
      },
      {
        path: '*',
        component: NotFound,
      },
    ],
  },
]

const renderRoutes = routes =>
  routes ? (
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment
        const Layout = route.layout || Fragment
        const Component = route.component

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        )
      })}
    </Switch>
  ) : null

const Routes = () => {
  return renderRoutes(routesConfig)
}

export default Routes
