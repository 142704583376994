import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(3),
    },
  },
  title: {
    fontWeight: 900,
    fontSize: 24,
    [theme.breakpoints.up('sm')]: {
      fontSize: 28,
    },
  },
  text: {
    fontSize: 14,
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
    },
  },
}))

const Title = ({ title, text }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography component="h1" className={classes.title} color="secondary">
        {title}
      </Typography>
      {text ? (
        <Typography className={classes.text} component="p">
          {text}
        </Typography>
      ) : null}
    </div>
  )
}

Title.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
}

export default Title
