import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'

import accountSlice from './accountSlice'
import questionnaireSlice from './questionnaireSlice'
import postSlice from './postSlice'
import notificationSlice from './notificationSlice'
import uiSlice from './uiSlice'
import mentorSlice from './mentorSlice'

const combinedReducer = combineReducers({
  account: accountSlice,
  questionnaire: questionnaireSlice,
  post: postSlice,
  notification: notificationSlice,
  ui: uiSlice,
  mentor: mentorSlice,
})

const rootReducer = (state, action) => {
  if (action.type === 'account/logout') {
    state = undefined
  }
  return combinedReducer(state, action)
}

export default configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware()],
})
