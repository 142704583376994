import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core'

import questionService from '../services/questionService'
import { selectActiveSection, setActiveSection } from '../store/postSlice'

import PostHero from '../components/KnowledgeHub/PostHero'
import CategoryMobileNav from '../components/KnowledgeHub/CategoryMobileNav'
import PostArchive from '../components/KnowledgeHub/PostArchive'
import PledgeCTAModal from './PledgeCTAModal'
import Page from '../layout/Page'
import ArchiveHeader from '../components/KnowledgeHub/ArchiveHeader'
import PostFilter from '../components/KnowledgeHub/PostFilter'

const KnowledgeHub = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const activeSection = useSelector(selectActiveSection)
  const isOnboarded = useSelector(state => !!state.account.company)
  const companyScore = useSelector(state => state.account?.company?.score)
  const isQuestionnaireDone = companyScore?.some(
    item => item.status === 'FINISHED'
  )

  const { isLoading, data } = useQuery('result', questionService.getResult, {
    enabled: isOnboarded && isQuestionnaireDone,
  })

  // set default section
  useEffect(() => {
    if (!isOnboarded || !isQuestionnaireDone || isLoading || !data) return

    const finishedResult = data.filter(
      resultItem => resultItem.status === 'FINISHED'
    )
    const orderedResult = finishedResult.sort(
      (a, b) => a.section.id - b.section.id
    )
    if (!activeSection && orderedResult.length > 0) {
      dispatch(setActiveSection(orderedResult[0].section))
    }
  }, [
    activeSection,
    dispatch,
    data,
    isLoading,
    isOnboarded,
    isQuestionnaireDone,
  ])

  const articleViews = useSelector(state => state.account.user.article_views)
  const pledgeCounter = useSelector(
    state => state.account.user.pledge_cta_counter
  )
  const questionnaireCounter = useSelector(
    state => state.account.user.questionnaire_counter
  )

  // user read 3 articles and filled questionnaire once and zero popup shown
  const firstPopUp =
    articleViews === 3 && questionnaireCounter === 1 && pledgeCounter === 0

  // user filled questionnaire x time and read 3 articles
  const afterEveryQuestionnairePopUp =
    pledgeCounter < questionnaireCounter && articleViews >= 3

  // the first popup didnt appear due to lack of article reading, but not the first pledge
  const dontReadArticlePopUp =
    pledgeCounter > 1 &&
    pledgeCounter <= questionnaireCounter &&
    articleViews < 3

  return (
    <Page title={isLoading ? 'Knowledge Hub' : activeSection?.title}>
      <PostHero />
      <CategoryMobileNav />
      <section
        className={classes.root}
        style={{ border: `solid 5px ${activeSection?.color}` }}
      >
        <Container maxWidth="lg">
          <ArchiveHeader />
          <PostFilter />
          <PostArchive />
        </Container>
      </section>
      {firstPopUp || afterEveryQuestionnairePopUp || dontReadArticlePopUp ? (
        <PledgeCTAModal />
      ) : null}
    </Page>
  )
}
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 0,
    [theme.breakpoints.up('md')]: {
      paddingTop: 42, //category-nav-overlapping
    },
  },
}))

export default KnowledgeHub
