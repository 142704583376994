import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import * as ReactGA from 'react-ga'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import DownloadIcon from '@material-ui/icons/SaveAlt'
import ShareIcon from '@material-ui/icons/Reply'
import { useTheme } from '@material-ui/core/styles'
import { useQuery } from 'react-query'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Page from '../layout/Page'

import RHubPreview from '../assets/graphic_hubmobile.png'

import CardList from '../components/ResilienceReport/CardList'
import SplitContent from '../components/HomePage/SplitContent'
import GraphFilter from '../components/ResilienceReport/GraphFilter'
import Graph from '../components/ResilienceReport/Graph'
import SavedForLater from '../components/ResilienceReport/SavedForLater'
import questionService from '../services/questionService'

const ResilienceReport = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  const pdfReport = useSelector(state => state.account.company.pdf_report)
  const { isLoading, data: score } = useQuery(
    'result',
    questionService.getResult,
    {
      placeholderData: [],
    }
  )

  const { data: benchmark } = useQuery(
    'benchmark',
    questionService.getBenchmark,
    {
      placeholderData: {
        initial: [],
        avg: [],
      },
    }
  )

  const handleDownloadClick = () => {
    ReactGA.event({
      category: 'ResilienceReport',
      action: 'Download Report',
    })

    window.open(
      `${process.env.REACT_APP_API_URL}companies/${pdfReport}/report.pdf?download=1`,
      '_blank'
    )
  }

  const handleShareClick = () => {
    ReactGA.event({
      category: 'ResilienceReport',
      action: 'Share Report',
    })

    window.open(
      `mailto:?subject=Full%20resilience%20report&body=${process.env.REACT_APP_API_URL}companies/${pdfReport}/report.pdf`,
      '_blank'
    )
  }

  return (
    <Page title="Full resilience report">
      <Container maxWidth="lg" className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={7}>
            <div className={classes.stickyContainer}>
              <Graph
                score={score}
                initial={benchmark.initial}
                avg={benchmark.avg}
                loading={isLoading}
              />
              <GraphFilter />
            </div>
          </Grid>
          <Grid item xs={12} md={5}>
            <Typography
              color="primary"
              variant="h5"
              component="h1"
              className={classes.title}
            >
              Full resilience report
            </Typography>
            <Typography color="secondary" style={{ marginBottom: 16 }}>
              <u>
                <b>How does the radial graph work?</b>
              </u>
            </Typography>
            <Typography style={{ marginBottom: 16 }}>
              The radial graph gives you a simple visual representation of your
              score in each of the 12 different areas. It can display:
            </Typography>
            <ul className={classes.list}>
              <li>Your most recent score (varied colour line)</li>
              <li>
                Your starting score so you can see how much you’ve improved
                since you first took the assessment (Black line)
              </li>
              <li>
                The industry average for companies in the same size band as you,
                based on how they have answered the questions (Red line)
              </li>
            </ul>
            <div style={{ marginBottom: 44 }}>
              <Typography variant="body1" style={{ marginBottom: 16 }}>
                To view your starting score and/or the industry average, tick
                the boxes.
              </Typography>
              <Typography variant="body1" style={{ marginBottom: 16 }}>
                Your score for each section is converted in to a star rating out
                of five. This is the score shown on the graph. This corresponds
                to the star rating shown in the report.
              </Typography>
              <Typography variant="body1" style={{ marginBottom: 16 }}>
                You can view your report below or download a PDF. This allows
                you to share the overview with others in your company.
              </Typography>
              <Typography variant="body1">
                To learn more about how you can improve your score in each area,
                continue to the knowledge hub to see articles, videos, podcasts,
                eLearning modules and other useful resources.
              </Typography>
            </div>
            <div className={classes.buttonContainer}>
              <Button
                disabled={!pdfReport}
                variant="outlined"
                onClick={handleDownloadClick}
                className={classes.downloadButton}
                startIcon={<DownloadIcon />}
              >
                Download
              </Button>
              <Button
                variant="outlined"
                onClick={handleShareClick}
                className={classes.downloadButton}
                startIcon={<ShareIcon />}
              >
                Share
              </Button>
              {isDesktop ? (
                <Button
                  component={Link}
                  to="/questionnaire/intro"
                  size="large"
                  color="primary"
                  variant="contained"
                  style={{ marginLeft: 'auto', padding: '12px 24px' }}
                >
                  Retake the test
                </Button>
              ) : null}
            </div>

            <CardList />

            <SavedForLater />
          </Grid>
        </Grid>
      </Container>
      <SplitContent
        color="gray"
        title="Next steps"
        image={{ src: RHubPreview, alt: 'Knowledge hub', position: 'bottom' }}
        reverse
      >
        <Typography
          component="h3"
          variant="h5"
          color="secondary"
          className={classes.splitSubTitle}
        >
          Head to the knowledge hub and start your resilience improvements by
          working through some useful content.
        </Typography>
        <Typography
          component="p"
          className={classes.splitBody}
          color="secondary"
        >
          Each section has it’s own area packed with ways to improve your
          resilience ranging from articles, podcasts, e-learning, education and
          more.
        </Typography>
        <div className={classes.ctaButtons}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            component={Link}
            to="knowledge-hub"
            className={classes.khButton}
          >
            View knowledge hub
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            component={Link}
            to="/questionnaire/intro"
            className={classes.takeTest}
          >
            Retake the assessment
          </Button>
        </div>
      </SplitContent>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
  },
  title: {
    fontWeight: 900,
    fontSize: 24,
    marginBottom: 8,
    [theme.breakpoints.up('sm')]: {
      fontSize: 28,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 32,
    },
  },
  list: {
    margin: 0,
    listStyleType: 'none',
    '& > li': {
      position: 'relative',
      paddingLeft: 18,
      fontSize: 16,
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
      '&:before': {
        content: '" "',
        left: 0,
        top: 6,
        position: 'absolute',
        width: 10,
        height: 10,
        borderRadius: 10,
        backgroundColor: theme.palette.secondary.main,
        display: 'block',
      },
    },
  },
  stickyContainer: {
    position: 'sticky',
    top: theme.spacing(2) + 51, //toolbar + root paddingTop
    maxWidth: 520,
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
      top: theme.spacing(8) + 84,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 'initial',
      margin: 'inherit',
    },
  },
  splitSubTitle: {
    fontWeight: 900,
    marginBottom: theme.spacing(2),
    fontSize: 18,
    [theme.breakpoints.up('sm')]: {
      fontSize: 22,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 24,
    },
  },
  splitBody: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
      marginBottom: theme.spacing(5),
    },
  },
  downloadButton: {
    borderWidth: 0,
    borderRadius: 18,
    boxShadow: '0 2px 12px rgba(0,0,0,0.2)',
    color: theme.palette.secondary.main,
    backgroundColor: 'white',
    '&.Mui-disabled': {
      borderWidth: 0,
      color: '#6D6D6D',
    },
  },
  buttonContainer: {
    marginBottom: 44,
    display: 'flex',
    alignItems: 'center',
    '& > :not(:last-child)': {
      marginRight: 20,
    },
  },
  takeTest: {
    boxShadow: 'none',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  ctaButtons: {
    '& > a': {
      paddingRight: 32,
      paddingLeft: 32,
      marginBottom: theme.spacing(2),
      marginRight: 0,
      '&:not(:last-child)': {
        [theme.breakpoints.up('md')]: {
          marginRight: theme.spacing(2),
        },
      },
    },
  },
  khButton: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}))

export default ResilienceReport
