import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core'

function RadialProgress(props) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="static"
        className={classes.bottom}
        size={158}
        value={100}
      />
      <CircularProgress
        variant="static"
        value={props.value}
        className={classes.top}
        size={158}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography className={classes.label}>
          {Math.round(props.value)}%
        </Typography>
      </Box>
    </div>
  )
}

RadialProgress.propTypes = {
  value: PropTypes.number.isRequired,
}

const useStyles = makeStyles(theme => ({
  bottom: {
    color: 'white',
  },
  label: {
    color: 'white',
    fontSize: 19,
    fontWeight: 'bold',
  },
  top: {
    color: '#62F47F',
    position: 'absolute',
    left: 0,
  },
  root: {
    position: 'relative',
    display: 'inline-flex',
  },
}))

export default RadialProgress
