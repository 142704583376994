import React from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { makeStyles, Typography } from '@material-ui/core'

import questionService from '../../services/questionService'

function SavedForLater() {
  const classes = useStyles()
  const { isLoading, isError, data } = useQuery(
    'result',
    questionService.getResult
  )

  if (isLoading || isError) return null

  const sortedResultList = data.filter(result => result.status === 'LATER')

  return (
    <div className={classes.root}>
      <Typography color="secondary" variant="h5" className={classes.title}>
        Resilience sections saved for later
      </Typography>
      {sortedResultList.map(result => (
        <Typography className={classes.result} key={result?.section?.id}>
          <img src={result?.section?.icon?.url} alt={result?.section?.title} />
          {result.section.title}
        </Typography>
      ))}
      <Button
        component={Link}
        to="/questionnaire/intro"
        size="large"
        color="primary"
        variant="contained"
        fullWidth
      >
        Back to assessment
      </Button>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 10,
    backgroundColor: '#f0f0f0',
    padding: theme.spacing(3),
  },
  title: {
    fontSize: 16,
    fontWeight: 900,
    color: '#000',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
    },
  },
  result: {
    display: 'flex',
    fontSize: 18,
    color: '#001F66',
    fontWeight: 900,
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    '& img': {
      marginRight: theme.spacing(1),
      width: 45,
      height: 45,
    },
  },
}))

export default SavedForLater
