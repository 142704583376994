import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core'
import FormGroup from '@material-ui/core/FormGroup'
import Typography from '@material-ui/core/Typography'

import { toggleFilter } from '../../store/questionnaireSlice'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  wrapper: {
    borderRadius: 5,
    borderColor: '#EDEDED',
    backgroundColor: 'white',
    padding: theme.spacing(1),
    boxShadow: '0 0 4px rgba(0,0,0,0.2)',
  },
  label: {
    color: 'black',
    fontSize: 10,
  },
  title: {
    fontSize: 10,
    color: '#000',
    fontWeight: 'bold',
  },
  checkbox: {
    paddingTop: 4,
    paddingBottom: 4,
  },
}))

const GraphFilter = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const filter = useSelector(state => state.questionnaire.filter)

  const handleOnClick = useCallback(
    type => {
      return () => {
        dispatch(toggleFilter(type))
      }
    },
    [dispatch]
  )

  return (
    <div className={classes.root}>
      <FormGroup className={classes.wrapper}>
        <Typography className={classes.title}>Filter your result</Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={filter.benchmark}
              onChange={handleOnClick('benchmark')}
              name="benchmark"
              color="primary"
              classes={{
                root: classes.checkbox,
              }}
            />
          }
          label="Industry average"
          classes={{ label: classes.label }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={filter.initial}
              onChange={handleOnClick('initial')}
              name="initial"
              color="primary"
              classes={{
                root: classes.checkbox,
              }}
            />
          }
          label="Your starting point"
          classes={{ label: classes.label }}
        />
      </FormGroup>
    </div>
  )
}

export default GraphFilter
