import React from 'react'

import Page from '../layout/Page'
import FormHeader from '../components/Auth/FormHeader'
import ForgotPasswordForm from '../components/Auth/ForgotPasswordForm'

const ForgotPassword = () => {
  return (
    <Page title="Reset your password">
      <FormHeader title="Reset your password" />
      <ForgotPasswordForm />
    </Page>
  )
}

export default ForgotPassword
