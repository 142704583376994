import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import ArrowBack from '@material-ui/icons/ArrowBack'
import VisibilityOff from '@material-ui/icons/VisibilityOffOutlined'

import smallLogo from '../../assets/small-logo.svg'
import { PAGE_TITLE } from '../../constans'
import { setShowPopover } from '../../store/postSlice'
import DrawerButton from './DrawerButton'

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.mixins.toolbar,
    alignItems: 'center',
    display: 'flex',
  },
  icon: {
    height: 58,
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    margin: theme.spacing(1, 0, 1, 1),
  },
  title: {
    fontSize: 34,
    color: theme.palette.secondary.main,
    marginLeft: theme.spacing(1),
    '& > span': {
      fontWeight: 600,
    },
  },
  button: {
    backgroundColor: '#F7F7F7',
    display: 'flex',
    flexDirection: 'column',
    width: 50,
    '& > .icon': {
      color: theme.palette.primary.main,
      width: 20,
    },
    '& > span': {
      fontSize: 10,
    },
  },
  buttonContainer: {
    ...theme.mixins.toolbar,
    height: '100%',
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
}))

const DrawerHeader = ({ post, toggleDrawer }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const account = useSelector(state => state.account)

  const handleBackClick = React.useCallback(() => {
    dispatch(setShowPopover({ id: post.id }))
    if (history.action === 'PUSH') {
      history.goBack()
    } else {
      history.push('/knowledge-hub')
    }
  }, [post, dispatch, history])

  return (
    <div className={classes.root}>
      <Link className={classes.logoContainer} to="/">
        <img src={smallLogo} alt={PAGE_TITLE} className={classes.icon} />
        <Typography className={classes.title}>
          RI<span>C</span>
        </Typography>
      </Link>
      <div className={classes.buttonContainer}>
        {account.user ? (
          <DrawerButton
            className={classes.button}
            icon={ArrowBack}
            onClick={handleBackClick}
            disabled={!post}
          >
            Back
          </DrawerButton>
        ) : null}
        <DrawerButton
          className={classes.button}
          icon={VisibilityOff}
          onClick={toggleDrawer}
          disabled={!post}
        >
          Hide
        </DrawerButton>
      </div>
    </div>
  )
}

DrawerHeader.propTypes = {
  post: PropTypes.object,
  toggleDrawer: PropTypes.func.isRequired,
}

export default DrawerHeader
