import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import { useDispatch, useSelector } from 'react-redux'
import CheckCircle from '@material-ui/icons/CheckCircle'
import Button from '@material-ui/core/Button'

import PledgeImage from '../assets/pledge.png'
import { logPledgeCTAView } from '../store/accountSlice'
import { setPledgeModal } from '../store/uiSlice'

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 20,
    maxWidth: 1050,
  },
  content: {
    borderRadius: 20,
    background: 'white',
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      padding: theme.spacing(0, 7, 7, 7),
    },
    '&:first-child': {
      paddingTop: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(5),
      },
    },
  },
  logoContainer: {
    textAlign: 'center',
  },
  subTitle: {
    fontWeight: 600,
    fontSize: 22,
    lineHeight: 1.3,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(5),
      fontSize: 24,
    },
  },
  title: {
    fontWeight: 900,
    fontSize: 24,
    lineHeight: 1.3,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      fontSize: 28,
    },
  },
  positiveTitle: {
    marginBottom: theme.spacing(2),
    fontSize: 18,
    fontWeight: 600,
  },
  positive: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    fontSize: 20,
    [theme.breakpoints.up('md')]: {
      fontSize: 22,
    },
    '& > svg': {
      marginRight: theme.spacing(0.5),
    },
  },
  skip: {
    boxShadow: 'none',
    marginTop: theme.spacing(1),
  },
  list: {
    backgroundColor: '#EEEEEE',
    padding: theme.spacing(2, 4),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      padding: theme.spacing(2, 7, 2, 7),
    },
    '& > div': {
      flex: 1,
    },
    '& div:not(:last-child)': {
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(0),
      },
    },
    '& p': {
      textAlign: 'center',
      maxWidth: 270,
      margin: '0 auto',
    },
  },
  positiveContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
}))

const PledgeCtaModal = props => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(true)
  const companyName = useSelector(state => state.account.user.company_name)
  const pledge = useSelector(state => state.account.user.pledge)

  const handleClose = () => {
    dispatch(logPledgeCTAView())
    setOpen(false)
  }

  const handleOnSuccessClick = () => {
    handleClose()
    dispatch(setPledgeModal(true))
  }

  // dont show popup if user already pledged
  if (pledge) {
    return null
  }

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      aria-labelledby="Resilience Pledge CTA Modal"
      classes={{
        paper: classes.root,
      }}
      scroll="body"
    >
      <DialogContent style={{ padding: 0 }}>
        <div className={classes.content}>
          <div style={{ flex: 1 }}>
            <Typography className={classes.title} variant="h5" color="primary">
              It looks like you are doing some great work!
            </Typography>
            <Typography className={classes.subTitle} variant="h5">
              We think it is a good time to ask you to make your resilience
              pledge.
            </Typography>
            <Typography className={classes.positiveTitle}>
              Take the pledge and make a positive statement to:
            </Typography>
            <div className={classes.positiveContainer}>
              {[
                'Current and future employees',
                'Clients',
                'Suppliers',
                'Investors',
              ].map(item => (
                <Typography className={classes.positive} key={item}>
                  <CheckCircle color="primary" />
                  {item}
                </Typography>
              ))}
            </div>
          </div>
          <div className={classes.logoContainer}>
            <img src={PledgeImage} alt="Resilience Pledge" />
          </div>
        </div>
        <div className={classes.list}>
          <div>
            <Typography color="secondary">
              <b>Benchmark {companyName}’s</b> position in the community and
              help build a stronger industry
            </Typography>
          </div>
          <div>
            <Typography color="secondary">
              Use <b>Digital Contstruction accreditation</b> to promote and
              showcase
            </Typography>
          </div>
          <div>
            <Typography color="secondary">
              <b>Get access to exclusive content</b>, tips and resources
            </Typography>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 47,
            marginBottom: 16,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleOnSuccessClick}
          >
            Review the pledge
          </Button>
          <Button
            variant="text"
            size="large"
            onClick={handleClose}
            className={classes.skip}
          >
            Skip for now
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default PledgeCtaModal
