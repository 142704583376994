import React, { useEffect } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { useQuery, useQueryClient } from 'react-query'

import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import PictureAsPdf from '@material-ui/icons/PictureAsPdf'
import Button from '@material-ui/core/Button'

import { logPostView } from '../store/postSlice'
import usePostQueryKey from '../utils/usePostsQueryKey'
import postService from '../services/postService'
import { iOS } from '../utils'

import LoadingScreen from '../components/LoadingScreen'
import DrawerContent from '../components/OffsiteContent/DrawerContent'
import DrawerHeader from '../components/OffsiteContent/DrawerHeader'
import FloatingBar from '../components/OffsiteContent/FloatingBar'
import Page from '../layout/Page'
import MobileBar from '../components/OffsiteContent/MobileBar'

const drawerWidth = 250

const OffsiteContent = props => {
  const [open, setOpen] = React.useState(true)
  const classes = useStyles({ open })
  const { slug } = useParams()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const queryKey = usePostQueryKey()

  const { data: post, isLoading, isError } = useQuery(
    ['post', slug],
    () => postService.getPostBySlug(slug),
    {
      initialData: () => {
        const pages = queryClient.getQueryData(queryKey)?.pages
        if (!pages) return

        const allPost = _.flatten(pages)

        return allPost?.find(d => d.slug === slug)
      },
    }
  )

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const account = useSelector(state => state.account)

  useEffect(() => {
    dispatch(logPostView())
  }, [dispatch])

  if (isError) {
    return <Redirect to="/" />
  }

  if (isLoading) {
    return <LoadingScreen />
  }

  const toggleDrawer = () => {
    setOpen(!open)
  }

  const isPdf = url => {
    if (!url) return false

    return !!url.toLowerCase().match(/\.(pdf)/g)
  }

  const pdfOpenButton = post => (
    <div className={classes.pdfdownload}>
      <PictureAsPdf className={classes.pdfIcon} />
      <Button
        color="primary"
        variant="contained"
        href={post.content_url}
        target="_blank"
      >
        View the PDF
      </Button>
    </div>
  )

  return (
    <Page post={post} title={post ? post.title : ''}>
      <div
        style={{
          display: 'flex',
          height: '100%',
          marginTop:
            !isDesktop && account.user ? theme.mixins.toolbar.minHeight : 0,
        }}
      >
        {isDesktop ? (
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <DrawerHeader post={post} toggleDrawer={toggleDrawer} />
            <DrawerContent post={post} />
          </Drawer>
        ) : null}
        <FloatingBar post={post} toggleDrawer={toggleDrawer} />
        <MobileBar post={post} />
        <section className={classes.content}>
          {post ? (
            //display view pdf button on ios, because it renders just the first page of the pdf as an img
            isPdf(post.content_url) ? (
              iOS() ? (
                pdfOpenButton(post)
              ) : (
                <object data={post.content_url} width="100%" height="100%">
                  {pdfOpenButton(post)}
                </object>
              )
            ) : (
              <embed src={post.content_url} width="100%" height="100%" />
            )
          ) : (
            <LoadingScreen />
          )}
        </section>
      </div>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    boxShadow: '2px 2px 14px 0px rgb(0 0 0 / 0.2)',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    overflow: 'hidden',
    transition: ({ open }) =>
      open
        ? theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          })
        : theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
    marginLeft: ({ open }) => (open ? 0 : -drawerWidth),
  },
  pdfdownload: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pdfIcon: {
    fontSize: 60,
    color: '#d7d7d7',
    marginBottom: theme.spacing(2),
  },
}))

export default OffsiteContent
