import React from 'react'
import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { getIn } from 'formik'

import { TextField } from '@material-ui/core'

/**
 * Wrapper for: TextField (MUI)
 * Required for: Formik
 *
 * Wrapper components for MUI input fields are required
 * to sync changes to the input field and to render
 * errors correctly.
 */
function FormikTextField({ field, form: { touched, errors }, ...rest }) {
  const hasError = useMemo(() => {
    return (
      (touched[field.name] && Boolean(errors[field.name])) ||
      Boolean(getIn(errors, field.name))
    )
  }, [field, touched, errors])

  const errorText = useMemo(() => {
    return errors[field.name] || getIn(errors, field.name)
  }, [field, errors])

  return (
    <TextField
      {...field}
      {...rest}
      error={hasError}
      helperText={hasError ? errorText : undefined}
    />
  )
}

FormikTextField.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
}

export default FormikTextField
