import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

const QuestionnaireGuard = ({ children }) => {
  const isOnboarded = useSelector(state => !!state.account.company)
  const companyScore = useSelector(state => state.account?.company?.score)
  const isQuestionnaireDone = companyScore?.some(
    item => item.status === 'FINISHED'
  )

  if (!isOnboarded) {
    return <Redirect to="/tell-us-about-you" />
  }

  if (!isQuestionnaireDone) {
    return <Redirect to="/questionnaire/intro" />
  }

  return children
}

QuestionnaireGuard.propTypes = {
  children: PropTypes.any,
}

export default QuestionnaireGuard
