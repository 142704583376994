import React from 'react'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PersonIcon from '@material-ui/icons/Person'
import Button from '@material-ui/core/Button'
import { Avatar, makeStyles } from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'

import { generateMonogram } from '../../utils'
import { logout, selectUserAvatar } from '../../store/accountSlice'

const useStyles = makeStyles(theme => ({
  iconButton: {
    color: theme.palette.common.white,
    height: 24,
    width: 24,
    fontSize: 12,
    fontWeight: 600,
    display: 'inline-flex',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&.MuiAvatar-colorDefault': {
      backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
      height: 40,
      width: 40,
    },
  },
}))

const AccountAvatar = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const account = useSelector(state => state.account)
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const avatar = useSelector(selectUserAvatar)

  const handleLogout = () => {
    setOpen(false)
    dispatch(logout())
    history.push('/login')
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  if (account.user) {
    const monogram = generateMonogram(account.user.name)

    return (
      <React.Fragment>
        <Avatar
          ref={anchorRef}
          color="secondary"
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          size="small"
          component="span"
          className={classes.iconButton}
          src={avatar}
        >
          <span>{monogram}</span>
        </Avatar>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="menu-list-grow" autoFocusItem={open}>
                    <MenuItem component={Link} to="/setting/profile">
                      Account
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Hidden smUp>
        <IconButton
          color="secondary"
          aria-label="login"
          size="small"
          component={Link}
          to="/login"
          className={classes.iconButton}
        >
          <PersonIcon style={{ fontSize: 16 }} />
        </IconButton>
      </Hidden>
      <Hidden xsDown>
        <Button
          color="primary"
          variant="text"
          size="small"
          component={Link}
          to="/login"
          className={classes.button}
        >
          Login
        </Button>
      </Hidden>
    </React.Fragment>
  )
}

export default AccountAvatar
