import React from 'react'
import { Link as RRDLink } from 'react-router-dom'
import { Link } from 'react-scroll'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core'

const HomePageMenu = () => {
  const classes = useStyles()

  return (
    <React.Fragment>
      <div className={classes.menu}>
        <Link
          to="platform"
          className={classes.item}
          offset={-84}
          spy
          hashSpy
          smooth
        >
          The Platform
        </Link>
        <Link
          to="our-partners"
          className={classes.item}
          offset={-84}
          spy
          hashSpy
          smooth
        >
          Our partners
        </Link>
        <Link
          to="pricing"
          className={classes.item}
          offset={-84}
          spy
          hashSpy
          smooth
        >
          Pricing
        </Link>
      </div>
      <Button
        variant="contained"
        size="small"
        color="primary"
        component={RRDLink}
        to="/login"
        className={classes.button}
      >
        Take the test
      </Button>
    </React.Fragment>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  button: {
    padding: '3px 8px',
    fontSize: 10,
    [theme.breakpoints.up('sm')]: {
      padding: '6px 16px',
      fontSize: 16,
    },
  },
  menu: {
    flexGrow: 1,
    textAlign: 'center',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  item: {
    fontSize: 14,
    padding: theme.spacing(1, 1.5),
    color: theme.palette.secondary.light,
    textDecoration: 'none',
    cursor: 'pointer',
    // '&.active': {
    //   fontWeight: 600,
    //   position: 'relative',
    //   '&::after': {
    //     content: '" "',
    //     height: 3,
    //     bottom: -10,
    //     left: 0,
    //     right: 0,
    //     position: 'absolute',
    //     backgroundColor: theme.palette.secondary.main,
    //   },
    // },
  },
}))

export default HomePageMenu
