import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation, useQuery } from 'react-query'
import get from 'lodash/get'

import {
  Avatar,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Typography,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import PersonOutline from '@material-ui/icons/PersonOutline'

import { closeKnowledgeHubAssignModal } from '../store/uiSlice'
import companyService from '../services/companyService'
import assignmentService from '../services/assignmentService'
import LoadingScreen from './LoadingScreen'

function AssignContentModal() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const modal = useSelector(state => state.ui.assign_knowledge_content_modal)
  const [selectedUsers, setSelectedUsers] = useState([])
  const { isLoading, data } = useQuery('team', companyService.getTeamMembers)
  const {
    isLoading: assignIsLoading,
    data: assignItem,
    isError: assignIsError,
  } = useQuery(
    ['assignment', 'post', modal.contentId],
    () => assignmentService.getAssignmentByPostId(modal.contentId),
    {
      enabled: modal.status,
    }
  )
  const assignMutation = useMutation(assignmentService.saveAssignment)

  useEffect(() => {
    if (assignIsLoading || assignIsError) return
    const assignedUsers = get(assignItem, 'users', []).map(user => user.id)
    setSelectedUsers(assignedUsers)
  }, [assignIsLoading, assignItem, assignIsError])

  const onClose = () => dispatch(closeKnowledgeHubAssignModal())

  const UserList = () => {
    const handleUserSelect = userId => () => {
      //if user already selected remove it
      if (selectedUsers.indexOf(userId) > -1) {
        const newList = selectedUsers.filter(item => item !== userId)
        setSelectedUsers(newList)
      } else {
        setSelectedUsers([...selectedUsers, userId])
      }
    }

    return (
      <FormGroup>
        {data?.map(user => (
          <FormControlLabel
            key={user.id}
            control={
              <Checkbox
                checked={selectedUsers.indexOf(user.id) > -1}
                onChange={handleUserSelect(user.id)}
              />
            }
            label={user.name}
            classes={{ label: classes.checkboxLabel }}
          />
        ))}
      </FormGroup>
    )
  }

  const handleOnSubmit = async () => {
    await assignMutation.mutateAsync({
      type: 'post',
      content_id: modal.contentId,
      user: selectedUsers,
    })
    onClose()
  }

  return (
    <Dialog
      open={modal.status}
      onClose={onClose}
      classes={{ paper: classes.root }}
      maxWidth="lg"
      disableBackdropClick={assignMutation.isLoading}
      disableEscapeKeyDown={assignMutation.isLoading}
    >
      <DialogContent className={classes.content}>
        <div className={classes.header}>
          <Avatar className={classes.avatar} color="primary">
            <PersonOutline />
          </Avatar>
        </div>
        <Typography className={classes.title}>
          Who would you like to assign this to?
        </Typography>
        {isLoading ? <LoadingScreen /> : <UserList />}

        {/*<Typography className={classes.inviteTitle}>*/}
        {/*  Invite via email*/}
        {/*</Typography>*/}
        {/*<TextField*/}
        {/*  label="Enter an email address"*/}
        {/*  name="email"*/}
        {/*  type="email"*/}
        {/*  InputLabelProps={{ required: false }}*/}
        {/*  InputProps={{ disableUnderline: true }}*/}
        {/*  color="secondary"*/}
        {/*  variant="filled"*/}
        {/*  margin="normal"*/}
        {/*  fullWidth*/}
        {/*/>*/}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          onClick={onClose}
          style={{ boxShadow: 'none' }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          disabled={assignMutation.isLoading || assignIsLoading}
          onClick={handleOnSubmit}
        >
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    overflowY: 'visible',
    maxWidth: 524,
    width: '100%',
  },
  content: {
    marginTop: theme.spacing(3),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    width: 56,
    height: 56,
    margin: '0 auto',
    '& > svg': {
      fontSize: 40,
    },
  },
  header: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    transform: 'translateY(-50%)',
  },
  title: {
    // marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    textAlign: 'center',
    fontWeight: 700,
    fontSize: 20,
  },
  checkboxLabel: {
    fontSize: 14,
    color: '#525252',
  },
  dialogActions: {
    justifyContent: 'center',
    padding: theme.spacing(0, 4, 4, 4),
  },
  inviteTitle: {
    color: '#545454',
    marginTop: theme.spacing(3),
    fontWeight: 800,
  },
}))

export default AssignContentModal
