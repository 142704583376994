import React from 'react'
import * as Yup from 'yup'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import { resetPassword } from '../../store/accountSlice'
import { getErrorMessage, setFormErrors } from '../../utils'

const schema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Password must contain at least 6 characters')
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
      'Password must contain at least 6 characters, one number, one uppercase letter'
    ),
})

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  successMessage: {
    marginTop: theme.spacing(2),
  },
}))

const ResetPasswordForm = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const query = new URLSearchParams(useLocation().search)
  const { register, handleSubmit, errors, formState, setError } = useForm({
    resolver: yupResolver(schema),
  })
  const [showPassword, setShowPassword] = React.useState(false)
  const isSubmitDisabled = !formState.isDirty || formState.isSubmitting

  const onSubmitHandler = async data => {
    const response = await dispatch(
      resetPassword({
        password: data.password,
        code: query.get('code'),
      })
    )

    if (response.type === resetPassword.rejected.toString()) {
      const message = getErrorMessage(response)
      if (message) {
        setFormErrors(message, setError)
      }
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit(onSubmitHandler)}
      noValidate
    >
      <TextField
        label="Your new password"
        name="password"
        inputRef={register}
        helperText={errors.password?.message}
        error={!!errors.password}
        aria-errormessage={errors.password?.message}
        aria-invalid={errors.password ? 'true' : 'false'}
        type={showPassword ? 'text' : 'password'}
        autoComplete="current-password"
        InputLabelProps={{ required: false }}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        color="secondary"
        variant="filled"
        margin="normal"
        required
        fullWidth
        autoFocus
      />

      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        size="large"
        className={classes.submit}
        disabled={isSubmitDisabled}
      >
        Save
      </Button>
      <Button
        size="small"
        color="secondary"
        component={Link}
        to="/login"
        fullWidth
      >
        Ready to sign in?
      </Button>
    </form>
  )
}

export default ResetPasswordForm
