import React from 'react'
import PropTypes from 'prop-types'
import {
  makeStyles,
  Dialog,
  Typography,
  Button,
  DialogContent,
} from '@material-ui/core'
import { Link } from 'react-router-dom'

function IntroModal(props) {
  const classes = useStyles()

  return (
    <Dialog open={props.status} onClose={props.onClose}>
      <DialogContent className={classes.container}>
        <Typography className={classes.title}>How it works</Typography>
        <ul className={classes.list}>
          <li>12 Areas in total to complete</li>
          <li>You can complete them in any order you want.</li>
          <li>
            Save any sections for later that you need to come back to or skip
            because they are not relevant to your company
          </li>
          <li>
            <p>
              Team account members can assign to other team members to complete.{' '}
              <Link to="/setting/upgrade">Find out about team membership</Link>
            </p>
          </li>
          <li>
            You can launch your report at any time. When you do, any incomplete
            tasks will be automatically moved to save for later status and
            excluded from the report.
          </li>
        </ul>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={props.onClose}
        >
          Close
        </Button>
      </DialogContent>
    </Dialog>
  )
}

IntroModal.propTypes = {
  status: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

const useStyles = makeStyles(theme => ({
  root: {},
  title: {
    fontSize: 17,
    fontWeight: 700,
    textDecoration: 'underline',
    marginBottom: theme.spacing(3),
    color: theme.palette.primary.main,
  },
  container: {
    padding: theme.spacing(4),
  },
  list: {
    fontSize: 16,
    listStyleType: 'none',
    '& li': {
      display: 'flex',
      marginBottom: theme.spacing(2),
      alignItems: 'baseline',

      '& > a': {
        color: theme.palette.primary.main,
      },

      '&::before': {
        content: '" "',
        display: 'inline-flex',
        flex: '0 0 10px',
        backgroundColor: '#242ED6',
        marginRight: theme.spacing(2),
        width: 10,
        height: 10,
        borderRadius: 10,
      },
    },
  },
}))

export default IntroModal
