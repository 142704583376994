import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    textDecoration: 'underline',
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.common.black,
  },
}))

const FormLink = ({ to, children }) => {
  const { root } = useStyles()

  return (
    <Link to={to} className={root}>
      {children}
    </Link>
  )
}

FormLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.any,
}

export default FormLink
