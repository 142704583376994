import * as React from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  makeStyles,
  Typography,
  Avatar,
  CircularProgress,
} from '@material-ui/core'

function ProfilePicture(props) {
  const classes = useStyles()
  const fileInputRef = React.useRef(null)

  const handleFileSelect = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  return (
    <div className={classes.root}>
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={props.addHandler}
        style={{
          display: 'none',
        }}
      />
      <div className={classes.iconContainer}>
        <Avatar
          onClick={handleFileSelect}
          size="small"
          component="span"
          className={classes.iconButton}
          src={props.loading ? null : props.avatar}
        >
          {props.loading ? (
            <CircularProgress size={20} thickness={5} color="inherit" />
          ) : (
            props.short
          )}
        </Avatar>
      </div>
      <div className={classes.meta}>
        <Typography className={classes.name}>{props.name}</Typography>
        <div className={classes.buttonGroup}>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={handleFileSelect}
          >
            Add photo
          </Button>
          {props.avatar ? (
            <Button
              onClick={props.removeHandler}
              size="small"
              variant="outlined"
              color="primary"
            >
              Remove photo
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  )
}

ProfilePicture.propTypes = {
  name: PropTypes.string.isRequired,
  short: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  removeHandler: PropTypes.func.isRequired,
  addHandler: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  iconButton: {
    position: 'relative',
    cursor: 'pointer',
    color: '#fff',
    fontSize: 26,
    height: 60,
    width: 60,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&.MuiAvatar-colorDefault': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonGroup: {
    '& > :not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  meta: {
    marginLeft: theme.spacing(2),
  },
  name: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
}))

export default ProfilePicture
