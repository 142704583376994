import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useInView } from 'react-intersection-observer'
import flatten from 'lodash/flatten'
import { useInfiniteQuery } from 'react-query'

import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

import { selectActiveSection } from '../../store/postSlice'
import postService from '../../services/postService'
import { postsPerPage } from '../../config'
import usePostQueryKey from '../../utils/usePostsQueryKey'

import PostCard from './PostCard'
import SkeletonPostCard from './SkeletonPostCard'
import DotsLoading from '../DotsLoading'
import EmptyContent from '../EmptyContent'
import AssignContentModal from '../AssignContentModal'

const PostArchive = () => {
  const classes = useStyles()
  const activeSection = useSelector(selectActiveSection)
  const filter = useSelector(state => state.post.filter)
  const queryKey = usePostQueryKey()

  const {
    data,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isError,
    fetchNextPage,
  } = useInfiniteQuery(
    queryKey,
    ({ pageParam = 0 }) =>
      postService.getPosts({ section: activeSection?.slug, filter }, pageParam),
    {
      enabled: !!activeSection,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length < postsPerPage) return false
        return flatten(allPages).length
      },
    }
  )

  const { ref, inView } = useInView({
    rootMargin: '100px 0px 0px 0px',
  })

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage()
    }
  }, [inView, hasNextPage, fetchNextPage])

  if ((isFetching && !isFetchingNextPage) || !activeSection) {
    return (
      <div className={classes.root}>
        <Grid component="section" container spacing={5}>
          {[1, 2, 3].map(key => (
            <SkeletonPostCard key={key} />
          ))}
        </Grid>
      </div>
    )
  }

  if (isError) return null

  const posts = flatten(data.pages)

  if (posts.length < 1) {
    return (
      <div className={classes.root}>
        <Grid component="section" container spacing={5}>
          <EmptyContent text="Sorry, No Posts Matched Your Criteria!" />
        </Grid>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <AssignContentModal />
      <Grid component="section" container spacing={5}>
        {posts.map(post => (
          <PostCard post={post} key={post.id} />
        ))}
      </Grid>
      <div ref={ref}>
        {isFetchingNextPage ? (
          <div className={classes.loadingContainer}>
            <DotsLoading />
          </div>
        ) : null}
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(6),
    },
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px 0',
  },
}))

export default React.memo(PostArchive)
