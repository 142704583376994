import React from 'react'
import { useQuery } from 'react-query'
import { Container, Grid, makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

import homePageService from '../../services/homePageService'
import ResponsiveImage from '../ResponsiveImage'

function WeWork() {
  const classes = useStyles()
  const logos = useQuery(['home', 'logos'], homePageService.getLogos)

  console.log(logos)
  if (!logos.data || logos.isLoading) {
    return null
  }

  return (
    <div className={classes.wrapper} id="our-partners">
      <Container>
        <Typography
          component="h3"
          variant="h2"
          color="primary"
          gutterBottom
          className={classes.title}
        >
          Thanks to our partners
        </Typography>
        <Typography className={classes.text}>
          This platform is a collaboration of the construction industry’s
          leading experts. It has been developed in consultation with industry
          stakeholders, support service providers and companies like yours. The
          guidance provided is based on industry best practice and ties into the
          BSI Resilience Framework. The content in the knowledge hub has been
          created especially, by a wide range of organisations and independent
          experts and made available to you thanks to the following partners:
        </Typography>
        <Grid container className={classes.logoContainer} spacing={4}>
          {logos.data?.logos?.map((item, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <ResponsiveImage size="small" image={item.logo} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    backgroundColor: '#fff',
    borderTop: 'solid 1px #D8D8D8',
    padding: theme.spacing(4, 0),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(7, 0),
    },
  },
  title: {
    fontWeight: 900,
    fontSize: 22,
    textAlign: 'center',
    marginBottom: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      fontSize: 28,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 38,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 50,
    },
  },
  text: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    maxWidth: 1000,
    margin: '0 auto',
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(7),
    },
  },
  logoContainer: {
    '& > div': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& img': {
        maxWidth: '100%',
        height: 'auto',
      },
    },
  },
}))

export default WeWork
