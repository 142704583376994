import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Typography } from '@material-ui/core'
import SentimentDissatisfiedTwoTone from '@material-ui/icons/SentimentDissatisfiedTwoTone'

function EmptyContent(props) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <SentimentDissatisfiedTwoTone />
      <Typography>{props.text}</Typography>
    </div>
  )
}

EmptyContent.propTypes = {
  text: PropTypes.string.isRequired,
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    textAlign: 'center',
    padding: theme.spacing(4, 0),
    color: '#606060',
    '& > p': {
      fontSize: 14,
    },
  },
}))

export default EmptyContent
