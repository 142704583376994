import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

import { isAdmin } from '../../store/accountSlice'
import { setAssignModal } from '../../store/uiSlice'
import questionService from '../../services/questionService'
import assignmentService from '../../services/assignmentService'

import IntroItem from './IntroItem'

function IntroList(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const user = useSelector(state => state.account.user)
  const isAdminRole = useSelector(isAdmin)
  const { isLoading, data } = useQuery('result', questionService.getResult)
  const { isLoading: assignmentLoading, data: assignments } = useQuery(
    'assignments',
    assignmentService.getAssignmentBySections
  )

  const history = useHistory()
  const queryClient = useQueryClient()

  const modifySection = useMutation(questionService.saveAnswerBySection, {
    onMutate: async ({ sectionId, data }) => {
      await queryClient.cancelQueries('result')

      const previousValue = queryClient.getQueryData('result')
      const allResultButActualSection = previousValue.filter(
        result => result.section.id !== sectionId
      )
      const actualSection = previousValue.find(
        result => result.section.id === sectionId
      )
      queryClient.setQueryData('result', [
        ...allResultButActualSection,
        { ...actualSection, ...data },
      ])

      return { previousValue }
    },
    onError: (err, variables, previousValue) => {
      queryClient.setQueryData('result', previousValue)
    },
    onSuccess: async () => {
      // await queryClient.refetchQueries(['result'])
    },
  })
  const handleAssignContent = value => dispatch(setAssignModal(value))

  if (isLoading || assignmentLoading) {
    return (
      <>
        <Skeleton className={classes.skeleton} variant="rect" />
        <Skeleton className={classes.skeleton} variant="rect" />
        <Skeleton className={classes.skeleton} variant="rect" />
      </>
    )
  }

  // if an user is admin or manager can see all the sections
  const filteredData = data

  const sortedSectionList = [
    ...filteredData.filter(result => result.status === 'SKIPPED'),
    ...filteredData.filter(result => result.status === 'LATER'),
    ...filteredData.filter(result => result.status === 'ASSIGNED'),
    ...filteredData.filter(result => result.status === 'FINISHED'),
  ]

  const handleStart = slug => {
    if (!slug) return

    history.push(`/questionnaire/${slug}`)
  }

  const getAssignedUser = sectionId => {
    const assignItem = assignments?.find(item => item.id === sectionId)
    if (!assignItem) return null

    if (assignItem.assigned_user) {
      return assignItem.assigned_user
    }

    return assignItem.assigned_email
  }

  const canIEditThisSection = sectionId => {
    if (isAdminRole) return true

    const assignItem = assignments?.find(item => item.id === sectionId)
    if (!assignItem) return false

    if (assignItem.assigned_user && assignItem.assigned_user.id === user.id) {
      return true
    }

    return false
  }

  return (
    <div>
      {sortedSectionList.map(result => (
        <IntroItem
          title={result.section.title}
          text={result.section.description}
          color={result.section.color}
          icon={result.section.icon}
          key={result.section.id}
          status={result.status}
          score={result.score}
          assignedTo={getAssignedUser(result.section.id)}
          onStart={() => handleStart(result.section.slug)}
          onAssign={() =>
            handleAssignContent({
              status: true,
              sectionId: result.section.id,
              value: getAssignedUser(result.section.id),
            })
          }
          onSaveLater={() =>
            modifySection.mutate({
              sectionId: result.section.id,
              data: {
                answers: [],
                status: 'LATER',
              },
            })
          }
          onReinstate={() =>
            modifySection.mutate({
              sectionId: result.section.id,
              data: {
                answers: [],
                status: 'SKIPPED',
              },
            })
          }
          onResult={() => props.openResult(result.section.id)}
          canEdit={canIEditThisSection(result.section.id)}
        />
      ))}
    </div>
  )
}

IntroList.propTypes = {
  openResult: PropTypes.func.isRequired,
}

const useStyles = makeStyles(theme => ({
  root: {},
  skeleton: {
    borderRadius: 12,
    height: 210,
    marginBottom: theme.spacing(4),
  },
}))

export default memo(IntroList)
