import React, { useEffect } from 'react'
import { makeStyles, Typography } from '@material-ui/core'

import useUpgrade from '../../utils/useUpgrade'

import InviteTeamMemberForm from '../../components/Setting/InviteTeamMemberForm'
import TeamList from '../../components/Setting/TeamList'
import Page from '../../layout/Page'
import TeamHeader from '../../components/Setting/TeamHeader'

function TeamMembers() {
  const classes = useStyles()
  const handleFunction = useUpgrade()

  useEffect(() => {
    handleFunction()
  }, [handleFunction])

  return (
    <Page title="Team members">
      <TeamHeader />
      <TeamList />
      <Typography className={classes.subTitle}>
        Invite a new team member
      </Typography>
      <InviteTeamMemberForm />
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  subTitle: {
    fontSize: 14,
    color: theme.palette.primary.dark,
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  titleContainer: {
    display: 'flex',
  },
}))

export default TeamMembers
