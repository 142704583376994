import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import { setPledgeModal } from '../../store/uiSlice'
import { isAdmin } from '../../store/accountSlice'

const useStyles = makeStyles(theme => ({
  menuItem: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    fontSize: 14,
  },
  menuListItem: {
    color: theme.palette.secondary.main,
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  active: {
    fontWeight: 600,
    position: 'relative',
    '&::after': {
      content: '" "',
      height: 3,
      bottom: -10,
      left: 0,
      right: 0,
      position: 'absolute',
      backgroundColor: theme.palette.secondary.main,
    },
  },
}))

const MenuItem = ({ children, ...props }) => {
  const classes = useStyles()

  return (
    <Typography
      {...props}
      component={NavLink}
      className={classes.menuItem}
      activeClassName={classes.active}
    >
      {children}
    </Typography>
  )
}

const KnowledgeHubMenu = () => {
  const theme = useTheme()
  const classes = useStyles()
  const dispatch = useDispatch()
  const account = useSelector(state => state.account)
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const [openMobileMenu, setMobileMenu] = useState(false)
  const isAdminRole = useSelector(isAdmin)

  if (!account.user) {
    return null
  }

  const handlePledgeModalClick = event => {
    if (!account.user.pledge && isAdminRole) {
      dispatch(setPledgeModal(true))
      event.preventDefault()
    }
  }

  if (isDesktop) {
    return (
      <React.Fragment>
        <MenuItem to="/questionnaire/intro">Assessment</MenuItem>
        <MenuItem to="/resilience-report">Resilience Report</MenuItem>
        <MenuItem to="/knowledge-hub">Knowledge hub</MenuItem>
        <MenuItem to="/mentors">Mentors</MenuItem>
        {isAdminRole ? (
          <MenuItem to="/resilience-pledge" onClick={handlePledgeModalClick}>
            Resilience pledge
          </MenuItem>
        ) : null}
        <Typography
          href="https://www.linkedin.com/groups/8981940/"
          target="_blank"
          component="a"
          className={classes.menuItem}
        >
          Group Forum
        </Typography>
      </React.Fragment>
    )
  }

  const toggleMobileMenu = event => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setMobileMenu(!openMobileMenu)
  }

  return (
    <React.Fragment>
      <IconButton
        color="secondary"
        onClick={toggleMobileMenu}
        size="small"
        component="span"
      >
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        anchor="right"
        open={openMobileMenu}
        onClose={toggleMobileMenu}
        onOpen={toggleMobileMenu}
      >
        <List
          role="presentation"
          onClick={toggleMobileMenu}
          onKeyDown={toggleMobileMenu}
        >
          <ListItem
            button
            component={NavLink}
            to="/questionnaire/intro"
            className={classes.menuListItem}
            activeClassName="Mui-selected"
          >
            <ListItemText primary="Assessment" color="primary" />
          </ListItem>
          <ListItem
            button
            component={NavLink}
            to="/resilience-report"
            className={classes.menuListItem}
            activeClassName="Mui-selected"
          >
            <ListItemText primary="Resilience Report" color="primary" />
          </ListItem>
          <ListItem
            button
            component={NavLink}
            to="/knowledge-hub"
            className={classes.menuListItem}
            activeClassName="Mui-selected"
          >
            <ListItemText primary="Knowledge hub" color="primary" />
          </ListItem>
          <ListItem
            button
            component={NavLink}
            to="/mentors"
            className={classes.menuListItem}
            activeClassName="Mui-selected"
          >
            <ListItemText primary="Mentors" color="primary" />
          </ListItem>
          {isAdminRole ? (
            <ListItem
              button
              component={NavLink}
              to="/resilience-pledge"
              onClick={handlePledgeModalClick}
              className={classes.menuListItem}
              activeClassName="Mui-selected"
            >
              <ListItemText primary="Resilience pledge" color="primary" />
            </ListItem>
          ) : null}
          <ListItem
            button
            href="https://www.linkedin.com/groups/8981940/"
            target="_blank"
            component="a"
            className={classes.menuListItem}
          >
            <ListItemText primary="Group Forum" color="primary" />
          </ListItem>
        </List>
      </SwipeableDrawer>
    </React.Fragment>
  )
}

export default KnowledgeHubMenu
