import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from 'react-query'
import { FormGroup, makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import FilterCheckbox from '../KnowledgeHub/FilterCheckbox'
import dataService from '../../services/dataService'
import { resetFilter, setFilter, toggleFilter } from '../../store/mentorSlice'

function FilterContent() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const globalFilter = useSelector(state => state.mentor.filter)
  const filterStatus = useSelector(state => state.mentor.openFilter)

  const { isLoading, data } = useQuery('sections', dataService.getSections)

  const [filter, setLocalFilter] = useState({
    speciality: globalFilter.speciality,
  })

  // reset filter to store's version, if filter collapses or opens
  useEffect(() => {
    setLocalFilter(globalFilter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStatus])

  const isChecked = (type, itemId) => {
    return filter[type].some(item => item === itemId)
  }

  const changeFilterOption = (type, option) => () => {
    if (isChecked(type, option.value)) {
      setLocalFilter(prev => ({
        ...prev,
        [type]: prev[type].filter(item => item !== option.value),
      }))
    } else {
      setLocalFilter(prev => ({
        ...prev,
        [type]: [...prev[type], option.value],
      }))
    }
  }

  const applyHandle = () => {
    dispatch(setFilter(filter))
    dispatch(toggleFilter())
  }

  const resetFilters = () => {
    dispatch(resetFilter())
    dispatch(toggleFilter())
  }

  if (isLoading) {
    return (
      <Box className={classes.filterContainer} justifyContent="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <div className={classes.filterContainer}>
      <div className={classes.filterOptionContainer}>
        <div style={{ minWidth: 190 }}>
          <Typography className={classes.filterOptionLabel}>Level</Typography>
          <FormGroup>
            {data.map(section => (
              <FilterCheckbox
                key={section.id}
                label={section.title}
                onChange={changeFilterOption('speciality', {
                  value: section.id,
                  label: section.title,
                })}
                checked={isChecked('speciality', section.id)}
              />
            ))}
          </FormGroup>
        </div>
      </div>
      <Box display="flex" flexDirection="column" marginTop="auto">
        <Button
          variant="outlined"
          color="primary"
          size="small"
          className={classes.applyButton}
          onClick={applyHandle}
        >
          Apply filters
        </Button>
        <Button
          variant="outlined"
          size="small"
          color="secondary"
          className={classes.resetButton}
          onClick={resetFilters}
        >
          Reset filters
        </Button>
      </Box>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  filterContainer: {
    position: 'relative',
    boxShadow: '0 2px 12px rgba(0,0,0,0.1)',
    backgroundColor: 'white',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  filterOptionContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
    '& > div': {
      marginBottom: theme.spacing(2),
    },
  },
  applyButton: {
    fontSize: 14,
    fontWeight: 900,
    padding: '4px 32px !important',
    marginBottom: theme.spacing(1),
  },
  resetButton: {
    fontSize: 14,
    fontWeight: 900,
    padding: '4px 32px !important',
    borderColor: '#F3F3F3',
    backgroundColor: '#F3F3F3',
    color: '#2F2F2F',
  },
  filterOptionLabel: {
    fontWeight: 900,
    marginBottom: theme.spacing(1),
  },
  contentTypeFilter: {
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridColumnGap: '1em',
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
}))

export default FilterContent
