import { createSlice } from '@reduxjs/toolkit'

const initialFilterStatus = {
  speciality: [],
}

const mentorSlice = createSlice({
  name: 'mentor',
  initialState: {
    openFilter: false,
    filter: initialFilterStatus,
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload
    },
    toggleFilter: state => {
      state.openFilter = !state.openFilter
    },
    resetFilter: (state, action) => {
      state.filter = initialFilterStatus
    },
  },
})

export const {
  setActiveSection,
  setShowPopover,
  setFilter,
  setCategoryNavStatus,
  toggleFilter,
  resetFilter,
} = mentorSlice.actions

export default mentorSlice.reducer

const selectActiveSection = state => state.mentor.activeSection

export { selectActiveSection }
