import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core'

function FilterCheckbox(props) {
  const classes = useStyles()

  return (
    <FormControlLabel
      className={classes.root}
      control={
        <Checkbox
          size="small"
          color="default"
          checked={props.checked}
          onChange={props.onChange}
          className={classes.checkbox}
        />
      }
      label={props.label}
    />
  )
}

FilterCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

const useStyles = makeStyles(theme => ({
  checkbox: {
    padding: '4px 9px',
    color: '#000000',
  },
}))

export default FilterCheckbox
