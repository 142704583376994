import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import GetAppIcon from '@material-ui/icons/GetAppOutlined'
import Grid from '@material-ui/core/Grid'
import RpCard from '../components/ResiliencePledge/RPCard'
import Facebook from '@material-ui/icons/Facebook'
import Twitter from '@material-ui/icons/Twitter'
import LinkedIn from '@material-ui/icons/LinkedIn'
import Reply from '@material-ui/icons/Reply'
import InputBase from '@material-ui/core/InputBase'
import Button from '@material-ui/core/Button'

import { setPledgeModal } from '../store/uiSlice'
import shareService from '../services/shareService'
import { pledgeDownloadUrl } from '../config'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 22,
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      fontSize: 28,
      marginBottom: theme.spacing(4),
    },
  },
  text: {
    fontSize: 18,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
  description: {
    paddingBottom: theme.spacing(3),
    borderBottom: 'solid 1px black',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(3),
      paddingBottom: theme.spacing(5),
    },
  },
  buttonContainer: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
}))

const ResiliencePledge = props => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const pledge = useSelector(state => state.account.user.pledge)
  const companyName = useSelector(state => state.account.user.company_name)
  const messageInput = useRef()

  if (!pledge) {
    dispatch(setPledgeModal(true))
    return null
  }
  const downloadActions = [
    { onClick: () => window.open(pledgeDownloadUrl), icon: <GetAppIcon /> },
  ]
  const shareActions = [
    {
      onClick: () =>
        shareService.facebook(
          process.env.REACT_APP_CLIENT_URL,
          messageInput.current.value
        ),
      icon: <Facebook />,
    },
    {
      onClick: () =>
        shareService.twitter(
          process.env.REACT_APP_CLIENT_URL,
          messageInput.current.value
        ),
      icon: <Twitter />,
    },
    {
      onClick: () =>
        shareService.linkedin(
          process.env.REACT_APP_CLIENT_URL,
          messageInput.current.value
        ),
      icon: <LinkedIn />,
    },
    {
      onClick: () =>
        shareService.email(
          process.env.REACT_APP_CLIENT_URL,
          messageInput.current.value
        ),
      icon: <Reply />,
    },
  ]

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Typography className={classes.title} component="h1">
        You have made the Resilience in Construction pledge! Start telling
        others about your commitment!
      </Typography>
      <Typography className={classes.description}>
        We need your help to spread the message by sharing your commitment to
        improving your resilience and make XR Construction a leader in driving
        resilience across the industry!
      </Typography>
      <Grid container spacing={6} style={{ marginBottom: 36 }}>
        <Grid item md={4} sm={12}>
          <Typography className={classes.text}>
            <b>Download RDP logos and resilience action plan template</b>
          </Typography>
          <RpCard footerText="Download" action={downloadActions}>
            <Typography style={{ fontSize: 18 }}>For use across</Typography>
            <ul>
              <li>Website </li>
              <li>Social </li>
              <li>Email </li>
              <li>Newsletters</li>
            </ul>
          </RpCard>
        </Grid>
        <Grid item md={8} sm={12}>
          <Typography className={classes.text}>
            <b>Share your pledge</b> (You can edit the message)
          </Typography>
          <RpCard footerText="Share" action={shareActions}>
            <InputBase
              multiline
              inputRef={messageInput}
              defaultValue={`${companyName} just took the pledge to measuring our company’s resilience regularly and taking proactive steps towards improving it!

20 minutes could change the future of your company

Take the resilience test`}
            />
          </RpCard>
        </Grid>
      </Grid>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          component={Link}
          to="/knowledge-hub"
        >
          Back to knowledge hub
        </Button>
      </div>
    </Container>
  )
}

export default ResiliencePledge
