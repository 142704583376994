import React from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { Link } from 'react-router-dom'

// import Supporter from './Supporter'

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 900,
    fontSize: 28,
    [theme.breakpoints.up('sm')]: {
      fontSize: 38,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 48,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 71,
    },
  },
  subTitle: {
    fontSize: 16,
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.375rem',
    },
    letterSpacing: -0.06,
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
}))

const HeroBanner = () => {
  const classes = useStyles()

  return (
    <div className={classes.heroContent}>
      <Container maxWidth="lg">
        <Typography
          component="h1"
          variant="h1"
          align="center"
          color="secondary"
          gutterBottom
          className={classes.title}
        >
          The next 20 minutes will change the future of your company!
        </Typography>
        <Typography
          variant="h6"
          align="center"
          color="primary"
          paragraph
          className={classes.subTitle}
        >
          Use the construction industry resilience app to guage, track and
          improve your company’s resilience
        </Typography>
        <div className={classes.heroButtons} align="center">
          <Button
            variant="contained"
            color="primary"
            size="large"
            component={Link}
            to="sign-up"
          >
            Get started
          </Button>
        </div>
        {/*<Supporter />*/}
      </Container>
    </div>
  )
}

export default HeroBanner
