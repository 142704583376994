import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Rating from '@material-ui/lab/Rating'

function Score(props) {
  const classes = useStyles({ color: props.color })

  return (
    <Rating
      name="half-rating-read"
      value={props.score}
      precision={0.5}
      readOnly
      classes={{
        iconEmpty: classes.ratingIcon,
        iconFilled: classes.ratingIconFilled,
      }}
    />
  )
}

Score.propTypes = {
  score: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
}

const useStyles = makeStyles(theme => ({
  ratingIcon: {
    color: '#ECECEC',
  },
  ratingIconFilled: {
    color: props => props.color,
  },
}))

export default Score
