import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from 'react-query'
import { FormGroup, makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import { resetFilter, setFilter, toggleFilter } from '../../store/postSlice'
import postService from '../../services/postService'
import FilterCheckbox from './FilterCheckbox'

function FilterContent() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const globalFilter = useSelector(state => state.post.filter)
  const filterStatus = useSelector(state => state.post.openFilter)
  const { data: postTypes, isFetching } = useQuery(
    'postTypes',
    postService.getPostTypes,
    {
      enabled: filterStatus,
      placeholderData: [],
    }
  )

  const [filter, setLocalFilter] = useState({
    level: globalFilter.level,
    postType: [],
    status: [],
  })

  // reset filter to store's version, if filter collapses or opens
  useEffect(() => {
    setLocalFilter(globalFilter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStatus])

  const levelTypes = [
    { label: 'Beginner', value: 'beginner' },
    { label: 'Medium', value: 'medium' },
    { label: 'Advanced', value: 'advanced' },
  ]

  const statuses = [
    { label: 'Done', value: '_where[marked_done_by]=1' },
    { label: 'Favourites', value: 'favourite_by_user=1' },
    { label: 'Still to do', value: '_where[marked_done_by]=0' },
  ]

  const isChecked = (type, itemId) => {
    return filter[type].some(item => item === itemId)
  }

  const changeFilterOption = (type, option) => () => {
    if (isChecked(type, option.value)) {
      setLocalFilter(prev => ({
        ...prev,
        [type]: prev[type].filter(item => item !== option.value),
      }))
    } else {
      setLocalFilter(prev => ({
        ...prev,
        [type]: [...prev[type], option.value],
      }))
    }
  }

  // const toggleLevel = () => {
  //   if (filter.level.length === levelTypes.length) {
  //     setLocalFilter(prev => ({
  //       ...prev,
  //       level: [],
  //     }))
  //   } else {
  //     setLocalFilter(prev => ({
  //       ...prev,
  //       level: levelTypes.map(item => item.value),
  //     }))
  //   }
  // }

  const applyHandle = () => {
    dispatch(setFilter(filter))
    dispatch(toggleFilter())
  }

  const resetFilters = () => {
    dispatch(resetFilter())
    dispatch(toggleFilter())
  }

  if (isFetching) {
    return (
      <Box className={classes.filterContainer} justifyContent="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <div className={classes.filterContainer}>
      <div className={classes.filterOptionContainer}>
        <div style={{ minWidth: 190 }}>
          <Typography className={classes.filterOptionLabel}>Level</Typography>
          <FormGroup>
            {/*<FilterCheckbox*/}
            {/*  label="All"*/}
            {/*  onChange={toggleLevel}*/}
            {/*  checked={filter.level.length === levelTypes.length}*/}
            {/*/>*/}
            {levelTypes.map(level => (
              <FilterCheckbox
                key={level.value}
                label={level.label}
                onChange={changeFilterOption('level', level)}
                checked={isChecked('level', level.value)}
              />
            ))}
          </FormGroup>
        </div>
        <div>
          <Typography className={classes.filterOptionLabel}>
            Content types
          </Typography>
          <FormGroup className={classes.contentTypeFilter}>
            {postTypes?.map(postType => (
              <FilterCheckbox
                key={postType.id}
                label={postType.name}
                onChange={changeFilterOption('postType', {
                  label: postType.name,
                  value: postType.id,
                })}
                checked={isChecked('postType', postType.id)}
              />
            ))}
          </FormGroup>
        </div>
        <div>
          <Typography className={classes.filterOptionLabel}>Status</Typography>
          <FormGroup>
            {statuses.map(status => (
              <FilterCheckbox
                key={status.value}
                label={status.label}
                onChange={changeFilterOption('status', status)}
                checked={isChecked('status', status.value)}
              />
            ))}
          </FormGroup>
        </div>
      </div>
      <Box display="flex" flexDirection="column" marginTop="auto">
        <Button
          variant="outlined"
          color="primary"
          size="small"
          className={classes.applyButton}
          onClick={applyHandle}
        >
          Apply filters
        </Button>
        <Button
          variant="outlined"
          size="small"
          color="secondary"
          className={classes.resetButton}
          onClick={resetFilters}
        >
          Reset filters
        </Button>
      </Box>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  filterContainer: {
    position: 'relative',
    boxShadow: '0 2px 12px rgba(0,0,0,0.1)',
    backgroundColor: 'white',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  filterOptionContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
    '& > div': {
      marginBottom: theme.spacing(2),
    },
  },
  applyButton: {
    fontSize: 14,
    fontWeight: 900,
    padding: '4px 32px !important',
    marginBottom: theme.spacing(1),
  },
  resetButton: {
    fontSize: 14,
    fontWeight: 900,
    padding: '4px 32px !important',
    borderColor: '#F3F3F3',
    backgroundColor: '#F3F3F3',
    color: '#2F2F2F',
  },
  filterOptionLabel: {
    fontWeight: 900,
    marginBottom: theme.spacing(1),
  },
  contentTypeFilter: {
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridColumnGap: '1em',
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
}))

export default FilterContent
