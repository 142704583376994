import React from 'react'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import red from '../../assets/Avoid-redundancies.svg'
import protect from '../../assets/Protect-your-employees.svg'
import damage from '../../assets/avoid-damage-to-reputation.svg'
import retain from '../../assets/retaining-good-people.svg'
import dark from '../../assets/Avoid-being-in-the-dark.svg'
import cred from '../../assets/maintain-credibilty.svg'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(3, 0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4, 0),
    },
  },
  title: {
    fontWeight: 900,
    color: theme.palette.secondary.light,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  description: {
    fontSize: 14,
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
    },
  },
  image: {
    width: 54,
    height: 54,
    margin: '0 auto',
    display: 'block',
  },
  caption: {
    margin: theme.spacing(2, 'auto', 0, 'auto'),
    fontWeight: 600,
    textAlign: 'center',
    maxWidth: 130,
    color: theme.palette.grey[900],
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  features: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3),
    },
  },
}))

const featureArray = [
  {
    image: red,
    title: 'Avoid redundancies',
  },
  {
    image: protect,
    title: 'Protect your employees',
  },
  {
    image: damage,
    title: 'Avoid damage to reputation',
  },
  {
    image: retain,
    title: 'Retain good people',
  },
  {
    image: dark,
    title: 'Avoid being in the dark',
  },
  {
    image: cred,
    title: 'Avoid losing credibility',
  },
]
const Feature = props => {
  const classes = useStyles()

  return (
    <section className={classes.root}>
      <Container maxWidth="lg">
        <Typography
          variant="h5"
          align="center"
          component="h2"
          className={classes.title}
        >
          It’s never been more important to improve your company’s resilience
        </Typography>
        <Typography className={classes.description} align="center">
          Our report and resilience knowledge hub can help you:
        </Typography>
        <Grid
          container
          justify="center"
          spacing={3}
          className={classes.features}
        >
          {featureArray.map(feature => (
            <Grid item key={feature.title} xs={4} md={2}>
              <img
                src={feature.image}
                alt={feature.title}
                className={classes.image}
              />
              <Typography className={classes.caption} noWrap={false}>
                {feature.title}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Container>
    </section>
  )
}

export default Feature
