import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles'

const OpenSans = ['Open Sans', 'sans-serif'].join(',')
const NunitoSans = ['Nunito Sans', 'sans-serif'].join(',')

const defaultTheme = createMuiTheme()

export default createMuiTheme({
  overrides: {
    MuiContainer: {
      maxWidthLg: {
        maxWidth: 1450,
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        borderRadius: 8,
      },
      sizeSmall: {
        fontSize: 16,
      },
      sizeLarge: {
        fontSize: 18,
        padding: '12px 48px',
        fontWeight: 700,
        boxShadow: '0 14px 36px -8px rgba(0,0,0,0.4)',
      },
      textSecondary: {
        color: 'black !important',
      },
      text: {
        fontSize: 16,
        textDecoration: 'underline !important',
        fontWeight: 'bold',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      outlined: {
        '&.Mui-disabled': {
          borderColor: 'transparent',
          color: '#00000042',
          '& .MuiAvatar-root': {
            opacity: 0.5,
          },
        },
      },
      outlinedPrimary: {
        borderColor: defaultTheme.palette.primary.main,
        borderWidth: '2px !important',
        padding: '10px 48px !important',
        '&.Mui-disabled': {
          borderColor: '#3f51b54d',
          color: '#242ed69e',
        },
      },
      containedPrimary: {
        borderColor: defaultTheme.palette.primary.main,
      },
    },
    MuiFilledInput: {
      root: {
        borderRadius: '10px !important',
        border: 'solid 1px black',
        backgroundColor: defaultTheme.palette.common.white,
        '&:hover': {
          backgroundColor: defaultTheme.palette.common.white,
        },
        '&$focused': {
          backgroundColor: defaultTheme.palette.common.white,
        },
        '&$error': {
          borderColor: defaultTheme.palette.error.main,
        },
      },
      input: {
        backgroundColor: defaultTheme.palette.common.white,
        padding: '29px 12px 12px',
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 18,
        color: defaultTheme.palette.grey[700],
        '&$error': {
          color: defaultTheme.palette.grey[700],
        },
      },
      filled: {
        transform: 'translate(12px, 22px) scale(1)',
        '&$shrink': {
          transform: 'translate(12px, 10px) scale(0.66)',
        },
      },
      shrink: {
        fontWeight: 600,
        color: '#001F66',
        '&$error': {
          color: '#001F66',
        },
      },
    },
    MuiInputBase: {
      input: {
        borderRadius: 10,
      },
    },
    MuiTab: {
      wrapper: {
        flexDirection: 'row',
        textAlign: 'left',
      },
      labelIcon: {
        minHeight: 'auto',
      },
    },
  },
  palette: {
    primary: {
      main: '#242ED6',
    },
    secondary: {
      light: '#002A89',
      main: '#001F66',
    },
    grey: {
      50: '#F0F0F0',
      900: '#222',
    },
    action: {
      disabledBackground: '#242ed64d',
      disabled: '#fff',
    },
    background: {
      default: '#f4f4f4',
    },
  },
  typography: {
    fontFamily: OpenSans,
    h1: {
      fontFamily: NunitoSans,
    },
    h2: {
      fontFamily: NunitoSans,
    },
    h5: {
      fontFamily: NunitoSans,
    },
    button: {
      fontFamily: OpenSans,
    },
  },
  shadows: [
    'none',
    '0 1px 2px 0 rgba(63,63,68,0.15)',
    '0 2px 2px -2px rgba(0,0,0,0.1)',
    '0 3px 4px -2px rgba(0,0,0,0.1)',
    '0 3px 4px -2px rgba(0,0,0,0.1)',
    '0 4px 6px -2px rgba(0,0,0,0.1)',
    '0 4px 6px -2px rgba(0,0,0,0.1)',
    '0 4px 8px -2px rgba(0,0,0,0.1)',
    '0 5px 8px -2px rgba(0,0,0,0.1)',
    '0 6px 12px -4px rgba(0,0,0,0.1)',
    '0 7px 12px -4px rgba(0,0,0,0.1)',
    '0 6px 16px -4px rgba(0,0,0,0.1)',
    '0 7px 16px -4px rgba(0,0,0,0.1)',
    '0 8px 18px -8px rgba(0,0,0,0.1)',
    '0 9px 18px -8px rgba(0,0,0,0.1)',
    '0 10px 20px -8px rgba(0,0,0,0.1)',
    '0 11px 20px -8px rgba(0,0,0,0.1)',
    '0 12px 22px -8px rgba(0,0,0,0.1)',
    '0 13px 22px -8px rgba(0,0,0,0.1)',
    '0 14px 24px -8px rgba(0,0,0,0.1)',
    '0 16px 28px -8px rgba(0,0,0,0.1)',
    '0 18px 30px -8px rgba(0,0,0,0.1)',
    '0 20px 32px -8px rgba(0,0,0,0.1)',
    '0 22px 34px -8px rgba(0,0,0,0.1)',
    '0 24px 36px -8px rgba(0,0,0,0.1)',
  ],
  mixins: {
    toolbar: {
      minHeight: 51,
      [defaultTheme.breakpoints.up('sm')]: {
        minHeight: 84,
      },
    },
  },
})

export const globalStyle = {
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    'input:-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 30px white inset !important',
    },
    'input:-webkit-autofill:hover': {
      '-webkit-box-shadow': '0 0 0 30px white inset !important',
    },
    'input:-webkit-autofill:focus': {
      '-webkit-box-shadow': '0 0 0 30px white inset !important',
    },
    'input:-webkit-autofill:active': {
      '-webkit-box-shadow': '0 0 0 30px white inset !important',
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%',
    },
    body: {
      height: '100%',
      width: '100%',
    },
    '#root': {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  },
}
