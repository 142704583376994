import axios from '../utils/axios'
import { postsPerPage } from '../config'

class PostService {
  /**
   *
   * @param {Object} options
   * @param {String} options.section
   * @param {Object} options.filter
   * @param {Array.<String>} options.filter.level
   * @param {Array.<Number>} options.filter.postType
   * @param {Array.<String>} options.filter.status
   * @param {Number} pageParam
   * @returns {Promise<any>}
   */
  getPosts = async (options, pageParam) => {
    const filter = [
      `_start=${pageParam}`,
      `_limit=${postsPerPage}`,
      `_sort=priority:DESC`,
    ]

    if (options?.filter?.level) {
      options.filter.level.forEach(item => filter.push(`_where[level]=${item}`))
    }

    if (options?.filter?.postType) {
      options.filter.postType.forEach(postTypeId =>
        filter.push(`type_in=${postTypeId}`)
      )
    }

    if (options?.filter?.status) {
      const isAllSelected =
        options.filter.status.filter(statusItem =>
          statusItem.includes('marked_done_by')
        ).length === 2

      // marked_done=1 and marked_done=0 => no filter needed
      if (isAllSelected) {
        options.filter.status
          .filter(statusItem => !statusItem.includes('marked_done_by'))
          .forEach(status => filter.push(status))
      } else {
        options.filter.status.forEach(status => filter.push(status))
      }
    }

    const response = await axios.get(
      `/posts?_where[section.slug]=${options.section}&${filter.join('&')}`
    )

    return response.data
  }

  /**
   *
   * @param {Number} id
   * @param {(marked_done_by|favourite_by_user)} type
   * @returns {Promise<any>}
   */
  marPost = async ({ id, type }) => {
    const response = await axios.put(`/posts/mark/${id}`, { [type]: true })

    return response.data
  }

  getPostTypes = async () => {
    const response = await axios.get('post-types')

    return response.data
  }

  /**
   *
   * @param {String} slug
   * @returns {Promise<void>}
   */
  getPostBySlug = async slug => {
    const response = await axios.get(`/posts/${slug}`)

    return response.data
  }
}

const postService = new PostService()

export default Object.freeze(postService)
