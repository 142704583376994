import React from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core'
import { Link, useHistory } from 'react-router-dom'

import logo from '../assets/logo.png'
import { PAGE_TITLE } from '../constans'
import FormHeader from '../components/Auth/FormHeader'
import LoginForm from '../components/Auth/LoginForm'
import Page from '../layout/Page'
import loginBg from '../assets/login-bg-2.jpg'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${loginBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'right',
  },
  paper: {
    margin: theme.spacing(8, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(8, 4),
    },
  },
  logo: {
    maxWidth: '100%',
    height: 'auto',
  },
  logoContainer: {
    maxWidth: 200,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 350,
    },
  },
}))

const Login = props => {
  const classes = useStyles()
  const history = useHistory()

  const onSuccess = () => {
    history.push('/')
  }

  return (
    <Page title="Login">
      <Grid container component="main" className={classes.root}>
        <Grid item xs={12} sm={7} md={4} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Link to="/" className={classes.logoContainer}>
              <img src={logo} alt={PAGE_TITLE} className={classes.logo} />
            </Link>
            <FormHeader title="Sign in" />
            <LoginForm onSuccess={onSuccess} />
          </div>
        </Grid>
        <Grid item xs={false} sm={5} md={8} className={classes.image} />
      </Grid>
    </Page>
  )
}

export default Login
