import React from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

function SectionHeader({ section }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Box display="flex" alignItems="center" marginBottom={1.5}>
        <Box display="flex" alignItems="center" flex={1}>
          <Typography
            component="h1"
            variant="h5"
            className={classes.title}
            style={{ color: section.color, lineHeight: 1 }}
          >
            {section.title}
          </Typography>
        </Box>
        <Box display="flex" marginLeft="auto" alignItems="center">
          <Typography className={classes.counter} variant="h5" component="p">
            {section?.action_plan_topics?.length} Actions
          </Typography>
          {section.icon && section.icon.url ? (
            <img
              src={section.icon.url}
              alt={section.title}
              className={classes.icon}
            />
          ) : null}
        </Box>
      </Box>
    </div>
  )
}

SectionHeader.propTypes = {
  section: PropTypes.object,
}

const useStyles = makeStyles(theme => ({
  root: {
    borderBottom: 'solid 1px #000',
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(4),
      paddingTop: theme.spacing(3),
    },
  },
  icon: {
    height: 40,
    width: 40,
    marginLeft: theme.spacing(2),
  },
  title: {
    fontSize: 20,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: 32,
    },
  },
  counter: {
    fontSize: 20,
    fontWeight: 'bold',
  },
}))

export default SectionHeader
