import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { Dialog, DialogTitle, makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'

import BookingForm from './BookingForm'

function BookingModal(props) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const me = useSelector(state => state?.account?.user)
  const availableCredit = me.credit || 0
  const onClose = () => {
    setOpen(false)
  }

  const onOpen = () => {
    setOpen(true)
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        className={props.className}
        onClick={onOpen}
      >
        Book a call
      </Button>
      <Dialog open={open} onClose={onClose} maxWidth="lg">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <DialogTitle>Book Call with {props.name}</DialogTitle>
          <div style={{ marginLeft: 'auto', padding: '16px 24px' }}>
            <div style={{ display: 'flex' }}>
              <span className={classes.prefix}>Credits</span>
              <input
                type="text"
                value={availableCredit}
                className={classes.input}
                disabled
              />
            </div>
          </div>
        </div>
        <BookingForm
          calendars={props.calendars}
          onClose={onClose}
          mentorId={props.mentorId}
        />
      </Dialog>
    </>
  )
}

BookingModal.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  calendars: PropTypes.arrayOf(
    PropTypes.shape({
      duration: PropTypes.number.isRequired,
      credit: PropTypes.string.isRequired,
      calendly_link: PropTypes.string.isRequired,
      one_off_price: PropTypes.number.isRequired,
    })
  ).isRequired,
  mentorId: PropTypes.number.isRequired,
}

BookingModal.defaultProps = {
  calendars: [],
}

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(4),
    position: 'relative',
  },
  role: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 0,
    lineHeight: 1,
  },
  selectButton: {
    border: 'solid 1px #D8D8D8',
    borderRadius: 4,
    fontSize: 14,
    padding: '7px 11px',
    flex: 1,
    marginRight: theme.spacing(3),
    maxWidth: 247,
    width: '100%',
    justifyContent: 'flex-start',
    '& svg': {
      marginLeft: 'auto',
    },
    '&.Mui-disabled': {
      opacity: 0.5,
    },
    '& > .SelectButton-text': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
  overlay: {
    background: '#f4f4f4b5',
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    zIndex: 1,
  },
  credits: {
    backgroundColor: '#F3F4F6',
    border: 'solid 1px #D1D5DB',
    borderRadius: 6,
    padding: '6px 16px',
    margin: theme.spacing(0, 2),
    color: '#6B7280',
  },
  creditContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  prefix: {
    border: 'solid 1px #D1D5DB',
    borderRight: 0,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    fontSize: 16,
    backgroundColor: '#F9FAFB',
    padding: '8px 12px',
    color: '#6B7280',
  },
  input: {
    border: 'solid 1px #D1D5DB',
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    fontSize: 16,
    maxWidth: 80,
    padding: '8px 12px',
  },
}))

export default BookingModal
