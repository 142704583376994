import { createSlice } from '@reduxjs/toolkit'

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    show_pledge_modal: false,
    settings_drawer: false,
    upgrade_modal: false,
    assign_modal: {
      status: false,
      sectionId: null,
      value: null,
    },
    action_plan_modal: {
      status: false,
      taskId: null,
      topicId: null,
      tasks: [],
      sectionId: null,
    },
    assign_knowledge_content_modal: {
      status: false,
      contentId: null,
    },
  },
  reducers: {
    setPledgeModal: (state, action) => {
      state.show_pledge_modal = action.payload
    },
    setSettingsDrawer: (state, action) => {
      state.settings_drawer = action.payload
    },
    setUpgradeModal: (state, action) => {
      state.upgrade_modal = action.payload
    },
    setAssignModal: (state, action) => {
      state.assign_modal = action.payload
    },
    closeAssignModal: state => {
      state.assign_modal = {
        status: false,
        sectionId: null,
        value: null,
      }
    },
    setActionPlanModal: (state, action) => {
      state.action_plan_modal = action.payload
    },
    closeActionPlanModal: state => {
      state.action_plan_modal = {
        status: false,
        taskId: null,
        topicId: null,
        tasks: [],
        sectionId: null,
      }
    },
    setKnowledgeHubAssignModal: (state, action) => {
      state.assign_knowledge_content_modal = action.payload
    },
    closeKnowledgeHubAssignModal: state => {
      state.assign_knowledge_content_modal = {
        status: false,
        contentId: null,
      }
    },
  },
})

export const selectSettingsDrawerStatus = state => state.ui.settings_drawer
export const selectUpgradeModalStatus = state => state.ui.upgrade_modal
export const {
  setPledgeModal,
  setSettingsDrawer,
  setUpgradeModal,
  setAssignModal,
  closeAssignModal,
  setActionPlanModal,
  closeActionPlanModal,
  setKnowledgeHubAssignModal,
  closeKnowledgeHubAssignModal,
} = uiSlice.actions
export default uiSlice.reducer
