import React from 'react'

import Page from '../layout/Page'
import FormHeader from '../components/Auth/FormHeader'
import ResetPasswordForm from '../components/Auth/ResetPasswordForm'

const ResetPassword = () => {
  return (
    <Page title="Set new password">
      <FormHeader title="Set new password" />
      <ResetPasswordForm />
    </Page>
  )
}

export default ResetPassword
