/**
 * Return style based on image position in SplitContent component
 * @param options
 * @param options.position
 * @param options.top
 * @param options.bottom
 * @param options.default
 * @return {null|number|string}
 */

import { USER_ROLE } from '../constans'

export const SCImagePositionToStyle = (options = { position: 'center' }) => {
  if (!options) {
    return null
  }

  switch (options.position) {
    case 'bottom':
      return options.bottom
    case 'top':
      return options.top
    default:
      return options.default
  }
}

/**
 * Generate monogram from username
 * @param text
 * @returns {string}
 */
export const generateMonogram = text => {
  if (!text) return ''

  return text
    .split(/\s/)
    .reduce((response, word) => (response += word.slice(0, 1)), '')
    .toString()
}

export const setFormErrors = (errors, setError) => {
  if (!errors || !Array.isArray(errors)) {
    return false
  }

  errors.forEach(error => {
    switch (error.id) {
      case 'Auth.form.error.email.taken':
        setError('email', error.message)
        break
      case 'Auth.form.error.invalid':
        setError('password', { message: 'Email or password invalid.' })
        break
      case 'Auth.form.error.user.not-exist':
        setError('email', { message: error.message, shouldFocus: true })
        break
      case 'Auth.form.error.code.provide':
        setError('password', {
          message: 'Sorry, this change password link is not valid.',
        })
        break
      case 'Auth.form.error.oldPassword.invalid':
        setError('old_password', 'Sorry, your old password is invalid.')
        break
      default:
        return
    }
  })
}

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
export const shuffle = a => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[a[i], a[j]] = [a[j], a[i]]
  }
  return a
}

export const roundHalf = num => {
  return Math.round(num * 2) / 2
}

export const getErrorMessage = response => {
  if (
    !response ||
    !response.payload ||
    !response.payload[0] ||
    !response.payload[0].messages
  ) {
    return null
  }

  return response.payload[0].messages
}

/**
 *
 * @param post
 * @returns {string|null}
 */
export const getPostUrl = post => {
  if (!post) {
    return null
  }

  if (post.content_url) {
    return `/offsite-content/${post.slug}`
  }

  return `/knowledge-hub/${post.slug}`
}

/**
 * Returns the dotified (short) version of a string.
 *
 * @param {string} string The string to dotify.
 * @param {number} maxLength The maximum length of the dotified string.
 */
export const dotify = (string = '', maxLength = 0) => {
  if (!maxLength) return string
  const stringified = `${string}`
  let sub = stringified.slice(0, maxLength)
  if (sub.length < stringified.length) {
    sub = sub.concat('...')
  }

  return sub
}

export const getAvatarUrl = imageObj => {
  if (!imageObj) return null

  if (
    imageObj.formats &&
    imageObj.formats.thumbnail &&
    imageObj.formats.thumbnail.url
  ) {
    return imageObj.formats.thumbnail.url
  }

  return imageObj.url
}

export const iOS = () => {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

export const getUserAndCompany = payload => {
  if (!payload) {
    return { user: null, company: null }
  }

  const { company, ...user } = payload
  return {
    user,
    company,
  }
}

export const getUserRoleByRoleId = roleId => {
  const actualRole = USER_ROLE.find(role => role.id === roleId)
  if (actualRole) {
    return actualRole.name
  }

  return USER_ROLE[0].name
}

export const generateStatusForActionPlanTopic = tasks => {
  const numberOfTasks = tasks.length
  const skippedTaskNumber = tasks.filter(task => task.status === 'SKIPPED')
    .length
  const finishedTaskNumber = tasks.filter(task => task.status === 'FINISHED')
    .length
  const todoTaskNumber = tasks.filter(task => task.status === 'LATER').length

  if (numberOfTasks === skippedTaskNumber) return 'SKIPPED'

  if (numberOfTasks === finishedTaskNumber) return 'FINISHED'

  if (finishedTaskNumber > 0 && todoTaskNumber < numberOfTasks)
    return 'PROGRESS'

  return 'LATER'
}
