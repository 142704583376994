import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from 'react-query'

import {
  Avatar,
  Dialog,
  DialogContent,
  makeStyles,
  Typography,
} from '@material-ui/core'
import PersonOutline from '@material-ui/icons/PersonOutline'

import companyService from '../../services/companyService'
import { closeActionPlanModal } from '../../store/uiSlice'
import LoadingScreen from '../LoadingScreen'
import AssignForm from './AssignForm'
import useAssignUserToTask from '../../utils/useAssignUserToTask'
import { generateStatusForActionPlanTopic } from '../../utils'

function AssignModal() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const modal = useSelector(state => state.ui.action_plan_modal)
  const { isLoading, data } = useQuery('team', companyService.getTeamMembers)
  const mutateAssignment = useAssignUserToTask()

  const onClose = () => {
    dispatch(closeActionPlanModal())
  }

  const handleSubmit = async value => {
    const modifiedTasks = modal?.tasks.map(task => {
      if (task.id === modal.taskId) {
        return {
          ...task,
          status: 'ASSIGNED',
          user: parseInt(value.user),
        }
      }

      return task
    })

    mutateAssignment.mutate({
      id: modal.topicId,
      userId: parseInt(value.user),
      data: {
        task_to_user: modifiedTasks,
        status: generateStatusForActionPlanTopic(modifiedTasks),
      },
    })
    onClose()
  }

  const filteredData = data?.filter(user => {
    if (user.role.id === 5 || user.role.id === 3) return true

    return user.assigned_sections
      .split(';')
      .some(id => id === modal.sectionId?.toString())
  })

  return (
    <Dialog
      open={modal.status}
      onClose={onClose}
      classes={{ paper: classes.root }}
      maxWidth="lg"
      disableBackdropClick={mutateAssignment.isLoading}
      disableEscapeKeyDown={mutateAssignment.isLoading}
    >
      <DialogContent className={classes.content}>
        <div className={classes.header}>
          <Avatar className={classes.avatar} color="primary">
            <PersonOutline />
          </Avatar>
        </div>
        <Typography className={classes.title}>Assign to team member</Typography>
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <AssignForm handleSubmit={handleSubmit} team={filteredData} />
        )}
      </DialogContent>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    overflowY: 'visible',
    maxWidth: 524,
    width: '100%',
  },
  content: {
    marginTop: theme.spacing(3),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    width: 56,
    height: 56,
    margin: '0 auto',
    '& > svg': {
      fontSize: 40,
    },
  },
  header: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    transform: 'translateY(-50%)',
  },
  title: {
    marginBottom: theme.spacing(4),
    textAlign: 'center',
    fontWeight: 700,
    fontSize: 20,
  },
}))

export default AssignModal
