import React from 'react'

import Page from '../layout/Page'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    '& > p': {
      marginBottom: theme.spacing(2),
    },
  },
  title: {
    fontWeight: 600,
    fontSize: 26,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      fontSize: 36,
    },
  },
}))

const TermsAndConditions = props => {
  const classes = useStyles()
  return (
    <Page title="Terms and conditions">
      <Container maxWidth="md" className={classes.root}>
        <Typography variant="h5" component="h1" className={classes.title}>
          Terms & conditions of platform usage
        </Typography>
        <Typography>
          ‘Resilience In Construction’ is a trading name of Built Environment
          Support and Training Ltd.
        </Typography>
        <Typography>
          Welcome to our platform. If you continue to browse and use this
          website you are agreeing to comply with and be bound by the following
          terms and conditions of use which, together with our 
          <Link to="/privacy-policy">privacy policy</Link>, govern Built
          Environment Support and Training Ltd’s relationship with you in
          relation to this platform.
        </Typography>
        <Typography>
          The terms ‘Built Environment Support and Training Ltd’ and ‘us’ and
          ‘we’ refer to the owner of the website whose office is 272 Bath
          Street, Glasgow, G2 4JR.
        </Typography>
        <Typography>
          The use of this platform is subject to the following terms of use:
        </Typography>
        <Typography>
          The content of the pages of this website is for your general
          information and use only. It is subject to change without notice.
        </Typography>
        <Typography>
          Neither we nor any third parties provide any warranty or guarantee as
          to the accuracy, timeliness, performance, completeness or suitability
          of the information and materials found or offered on this platform for
          any particular purpose. You acknowledge that such information and
          materials may contain inaccuracies or errors and we expressly exclude
          liability for any such inaccuracies or errors to the fullest extent
          permitted by law.
        </Typography>
        <Typography>
          Your use of any information or materials on this platform is entirely
          at your own risk, for which we shall not be liable. It shall be your
          own responsibility to ensure that any products, services or
          information available through this platform meet your specific
          requirements.
        </Typography>
        <Typography>
          This website contains material which is owned by or licensed to us.
          This material includes, but is not limited to, the design, layout,
          look, appearance and graphics. Reproduction is prohibited other than
          in accordance with the copyright notice, which forms part of these
          terms and conditions.
        </Typography>
        <Typography>
          All trademarks reproduced in this platform, which are not the property
          of, or licensed to the operator, are acknowledged on the platform.
        </Typography>
        <Typography>
          Unauthorised use of this platform may give rise to a claim for damages
          and/or be a criminal offence.
        </Typography>
        <Typography>
          This platform includes links to other websites. These links are
          provided for your convenience to provide further information. They do
          not signify that we endorse the website(s). We have no responsibility
          for the content of the linked website(s).
        </Typography>
        <Typography>
          Your use of this website and any dispute arising out of such use of
          the website is subject to the laws of Scotland.
        </Typography>
        <Typography>
          If you would like us to email you a dated PDF version of our privacy
          statement, please email 
          <a href="mailto:webwriter@digitalconstructionskills.com">
            webwriter@digitalconstructionskills.com
          </a>
          .
        </Typography>
      </Container>
    </Page>
  )
}

export default TermsAndConditions
