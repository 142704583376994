import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

import { isAdmin } from '../../store/accountSlice'

const AdminGuard = ({ children }) => {
  const isAdminRole = useSelector(isAdmin)

  if (!isAdminRole) {
    return <Redirect to="/" />
  }

  return children
}

AdminGuard.propTypes = {
  children: PropTypes.any,
}

export default AdminGuard
