import React, { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import { useSelector } from 'react-redux'

import {
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  ButtonBase,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Skeleton from '@material-ui/lab/Skeleton'

import companyService from '../../services/companyService'
import dataService from '../../services/dataService'
import { getUserRoleByRoleId } from '../../utils'

import UserMeta from './UserMeta'
import ChangeUserModal from './ChangeUserModal'
import { isFreePlan } from '../../store/accountSlice'
import AssignCreditForm from './AssignCreditForm'

function TeamList() {
  const classes = useStyles()
  const currentUser = useSelector(state => state.account.user)
  const [deleteDialog, setDeleteDialog] = useState(null)
  const [changeDialog, setChangeDialog] = useState(null)
  const [assignDialog, setAssignDialog] = useState(null)
  const isFree = useSelector(isFreePlan)

  const { isLoading, data } = useQuery('team', companyService.getTeamMembers)
  const { isLoading: sectionIsLoading, data: sections } = useQuery(
    'sections',
    dataService.getSections
  )

  const queryClient = useQueryClient()
  const deleteUser = useMutation(companyService.deleteMember, {
    onMutate: async userId => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries('team')

      // Snapshot the previous value
      const previousValue = queryClient.getQueryData('team')

      queryClient.setQueryData('team', (old = []) =>
        old.filter(teamMember => teamMember.id !== userId)
      )
      return { previousValue }
    },
    onError: (err, variables, previousValue) => {
      queryClient.setQueryData('team', previousValue)
    },
  })

  const closeDeleteDialog = () => setDeleteDialog(null)
  const closeAssignDialog = () => setAssignDialog(null)
  const openDeleteDialog = user => setDeleteDialog(user)
  const handleUserDelete = async () => {
    await deleteUser.mutate(deleteDialog.id)
    closeDeleteDialog()
  }

  const closeChangeDialog = () => setChangeDialog(null)
  const openChangeDialog = user => setChangeDialog(user)

  const getUserSelectedSections = selectedSectionIds => {
    const sectionIds = selectedSectionIds.split(';')
    if (!sectionIds || sectionIsLoading) return null

    const selectedData = sections.filter(item =>
      sectionIds.some(id => parseInt(id) === item.id)
    )

    return selectedData.map(item => item.title).join(', ')
  }

  if (isLoading) {
    return (
      <TableContainer component={Paper} className={classes.root}>
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                <Skeleton height={34} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <Skeleton height={34} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <>
      <TableContainer component={Paper} className={classes.root}>
        {isFree ? <div className={classes.overlay} /> : null}
        <Table className={classes.table} aria-label="team members table">
          <TableBody>
            {data?.map(user => (
              <TableRow key={user.id}>
                <TableCell component="th" scope="row">
                  <UserMeta
                    email={user.email}
                    avatar={user.avatar}
                    name={user.name}
                  />
                </TableCell>
                <TableCell align="right">
                  <Typography className={classes.role}>
                    {user.company_role}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <div className={classes.creditContainer}>
                    <div className={classes.credits}>{user.credit || 0}</div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setAssignDialog(user)}
                      size="medium"
                    >
                      Assign
                    </Button>
                  </div>
                </TableCell>
                <TableCell align="right">
                  {format(parseISO(user.created_at), "d MMM yyyy 'at' HH:mm")}
                </TableCell>
                <TableCell align="right">
                  <ButtonBase
                    className={classes.selectButton}
                    disableRipple
                    disabled={user.id === currentUser.id}
                    onClick={() => openChangeDialog(user)}
                  >
                    <span className="SelectButton-text">
                      {getUserRoleByRoleId(user.role.id)}
                      {user.assigned_sections
                        ? ` - ${getUserSelectedSections(
                            user.assigned_sections
                          )}`
                        : null}
                    </span>
                    <KeyboardArrowDown />
                  </ButtonBase>
                </TableCell>
                <TableCell align="right" size="small">
                  <IconButton
                    aria-label="delete"
                    disabled={user.id === currentUser.id}
                    className={classes.deleteButton}
                    onClick={() => openDeleteDialog(user)}
                  >
                    <DeleteOutline />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={!!deleteDialog} onClose={closeDeleteDialog}>
        <DialogTitle>Are you sure you want to delete this user?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The user will be deleted immediately. You can't undo this action.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleUserDelete}
            variant="outlined"
            color="primary"
            size="large"
            style={{ boxShadow: 'none' }}
          >
            Delete
          </Button>
          <Button
            onClick={closeDeleteDialog}
            color="primary"
            variant="contained"
            autoFocus
            size="large"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {assignDialog ? (
        <Dialog open={!!assignDialog} onClose={closeAssignDialog}>
          <DialogTitle>Assign Credits</DialogTitle>
          <DialogContent>
            <UserMeta
              email={assignDialog.company_role}
              avatar={assignDialog.avatar}
              name={assignDialog.name}
            />
            <DialogContentText style={{ marginTop: 16 }}>
              Assigning credits will allow this team member to book mentoring
              sessions.
            </DialogContentText>
            <AssignCreditForm
              onClose={closeAssignDialog}
              userId={assignDialog.id}
            />
          </DialogContent>
        </Dialog>
      ) : null}

      {!!changeDialog ? (
        <ChangeUserModal onClose={closeChangeDialog} user={changeDialog} />
      ) : null}
    </>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(4),
    position: 'relative',
  },
  role: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 0,
    lineHeight: 1,
  },
  selectButton: {
    border: 'solid 1px #D8D8D8',
    borderRadius: 4,
    fontSize: 14,
    padding: '7px 11px',
    flex: 1,
    marginRight: theme.spacing(3),
    maxWidth: 247,
    width: '100%',
    justifyContent: 'flex-start',
    '& svg': {
      marginLeft: 'auto',
    },
    '&.Mui-disabled': {
      opacity: 0.5,
    },
    '& > .SelectButton-text': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
  overlay: {
    background: '#f4f4f4b5',
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    zIndex: 1,
  },
  credits: {
    backgroundColor: '#F3F4F6',
    border: 'solid 1px #D1D5DB',
    borderRadius: 6,
    padding: '6px 16px',
    margin: theme.spacing(0, 2),
    color: '#6B7280',
  },
  creditContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default TeamList
