import React from 'react'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { setShowPopover } from '../../store/postSlice'

function BackButton({ post, ...props }) {
  const history = useHistory()
  const dispatch = useDispatch()

  const handleBackClick = () => {
    if (!post.marked_done_by) {
      dispatch(setShowPopover({ id: post.id }))
    }
    if (history.action === 'PUSH') {
      history.goBack()
    } else {
      history.push('/knowledge-hub')
    }
  }

  return (
    <Button {...props} onClick={handleBackClick}>
      Back
    </Button>
  )
}

export default BackButton
