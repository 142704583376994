import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

const useStyles = makeStyles(theme => ({
  menuItem: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    fontSize: 14,
  },
  menuListItem: {
    color: theme.palette.secondary.main,
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  active: {
    fontWeight: 600,
    position: 'relative',
    '&::after': {
      content: '" "',
      height: 3,
      bottom: -10,
      left: 0,
      right: 0,
      position: 'absolute',
      backgroundColor: theme.palette.secondary.main,
    },
  },
}))

const MenuItem = ({ children, ...props }) => {
  const classes = useStyles()

  return (
    <Typography
      {...props}
      component={NavLink}
      className={classes.menuItem}
      activeClassName={classes.active}
    >
      {children}
    </Typography>
  )
}

const OnlyAssesmentMenu = () => {
  const theme = useTheme()
  const classes = useStyles()
  const account = useSelector(state => state.account)
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const [openMobileMenu, setMobileMenu] = useState(false)

  if (!account.user) {
    return null
  }

  if (isDesktop) {
    return (
      <React.Fragment>
        <MenuItem to="/questionnaire/intro">Assessment</MenuItem>
      </React.Fragment>
    )
  }

  const toggleMobileMenu = event => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setMobileMenu(!openMobileMenu)
  }

  return (
    <React.Fragment>
      <IconButton
        color="secondary"
        onClick={toggleMobileMenu}
        size="small"
        component="span"
      >
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        anchor="right"
        open={openMobileMenu}
        onClose={toggleMobileMenu}
        onOpen={toggleMobileMenu}
      >
        <List
          role="presentation"
          onClick={toggleMobileMenu}
          onKeyDown={toggleMobileMenu}
        >
          <ListItem
            button
            component={NavLink}
            to="/questionnaire/intro"
            className={classes.menuListItem}
            activeClassName="Mui-selected"
          >
            <ListItemText primary="Assessment" color="primary" />
          </ListItem>
        </List>
      </SwipeableDrawer>
    </React.Fragment>
  )
}

export default OnlyAssesmentMenu
