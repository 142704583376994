import jwtDecode from 'jwt-decode'
import * as Sentry from '@sentry/react'

import axios from '../utils/axios'

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          this.setSession(null)

          if (onLogout) {
            onLogout()
          }
        }

        return Promise.reject(error)
      }
    )
  }

  handleAuthentication() {
    const accessToken = this.getAccessToken()

    if (!accessToken) {
      return
    }

    try {
      if (this.isValidToken(accessToken)) {
        this.setSession(accessToken)
      } else {
        this.setSession(null)
      }
    } catch (e) {
      this.setSession(null)
    }
  }

  loginWithEmailAndPassword = (identifier, password) =>
    new Promise((resolve, reject) => {
      axios
        .post('/auth/local', { identifier, password })
        .then(response => {
          if (response.data.user) {
            this.setSession(response.data.jwt)
            resolve(response.data.user)
          } else {
            reject(response.data.error)
          }
        })
        .catch(error => {
          Sentry.captureException(error)
          reject(error)
        })
    })

  register = (
    first_name,
    last_name,
    email,
    password,
    marketing,
    company,
    company_role
  ) =>
    new Promise((resolve, reject) => {
      axios
        .post('/auth/local/register', {
          name: `${first_name} ${last_name}`,
          first_name,
          last_name,
          username: email,
          email,
          password,
          company,
          company_role,
          marketing_email: marketing,
        })
        .then(response => {
          if (response.data.user) {
            this.setSession(response.data.jwt)
            resolve(response.data.user)
          } else {
            reject(response.data.error)
          }
        })
        .catch(error => {
          Sentry.captureException(error)
          reject(error)
        })
    })

  sendForgotPasswordEmail = email =>
    new Promise((resolve, reject) => {
      axios
        .post('/auth/forgot-password', {
          email,
        })
        .then(response => {
          if (response.data.ok) {
            resolve()
          } else {
            reject(response.data.error)
          }
        })
        .catch(error => {
          Sentry.captureException(error)
          reject(error)
        })
    })

  resetPassword = (password, code) =>
    new Promise((resolve, reject) => {
      axios
        .post('/auth/reset-password', {
          password,
          passwordConfirmation: password,
          code,
        })
        .then(response => {
          if (response.data.user) {
            this.setSession(response.data.jwt)
            resolve(response.data.user)
          } else {
            reject(response.data.error)
          }
        })
        .catch(error => {
          Sentry.captureException(error)
          reject(error)
        })
    })

  loginInWithToken = () =>
    new Promise((resolve, reject) => {
      axios
        .get('/users/me')
        .then(response => {
          if (response.data.id) {
            resolve(response.data)
          } else {
            reject(response.data.error)
          }
        })
        .catch(error => {
          Sentry.captureException(error)
          reject(error)
        })
    })

  logout = () => {
    this.setSession(null)
  }

  setSession = accessToken => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken)
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
      localStorage.removeItem('accessToken')
      delete axios.defaults.headers.common.Authorization
    }
  }

  getAccessToken = () => localStorage.getItem('accessToken')

  isValidToken = accessToken => {
    if (!accessToken) {
      return false
    }

    const decoded = jwtDecode(accessToken)
    const currentTime = Date.now() / 1000

    return decoded.exp > currentTime
  }

  isAuthenticated = () => !!this.getAccessToken()
}

const authService = new AuthService()

export default authService
