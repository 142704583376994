import React from 'react'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

import { SCImagePositionToStyle } from '../../utils'

const useStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1,
    backgroundColor: props =>
      props.color === 'gray'
        ? theme.palette.grey[50]
        : theme.palette.common.white,
  },
  root: {
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: props => (props.reverse ? 'row-reverse' : 'row'),
    },
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      alignItems: props =>
        SCImagePositionToStyle({
          position: props.image.position,
          top: 'flex-start',
          bottom: 'flex-end',
          default: 'center',
        }),
    },
  },
  image: {
    maxHeight: 150,
    margin: theme.spacing(4, 0, 0, 0),
    [theme.breakpoints.up('sm')]: {
      maxHeight: 'none',
      maxWidth: '100%',
      margin: props =>
        SCImagePositionToStyle({
          position: props.image.position,
          top: theme.spacing(0, 0, 6, 0),
          bottom: theme.spacing(6, 0, 0, 0),
          default: theme.spacing(6, 0),
        }),
    },
    [theme.breakpoints.up('md')]: {
      margin: props =>
        SCImagePositionToStyle({
          position: props.image.position,
          top: theme.spacing(0, 0, 10, 0),
          bottom: theme.spacing(10, 0, 0, 0),
          default: theme.spacing(10, 0),
        }),
    },
  },
  title: {
    fontWeight: 900,
    fontSize: 22,
    [theme.breakpoints.up('sm')]: {
      fontSize: 28,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 38,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 50,
    },
  },
  text: {
    color: props =>
      props.color === 'gray'
        ? theme.palette.grey[900]
        : theme.palette.common.black,
  },
  textContainer: {
    flexDirection: 'column',
  },
  textGrid: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2, 0, 4, 0),
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
      margin: theme.spacing(6, 0),
      paddingRight: props => (props.reverse ? theme.spacing(3) : 0),
      paddingLeft: props => (props.reverse ? 0 : theme.spacing(3)),
    },
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(10, 0),
    },
  },
}))

const SplitContent = props => {
  const classes = useStyles(props)

  return (
    <section className={classes.container}>
      <Container maxWidth="lg">
        <Grid container className={classes.root}>
          {props.image && props.image.src ? (
            <Grid item sm className={classes.imageContainer}>
              <img
                src={props.image.src}
                alt={props.image.alt}
                className={classes.image}
              />
            </Grid>
          ) : null}
          <Grid item sm className={classes.textGrid}>
            <div className={classes.textContainer}>
              {props.title ? (
                <Typography
                  component="h3"
                  variant="h2"
                  color="primary"
                  gutterBottom
                  className={classes.title}
                >
                  {props.title}
                </Typography>
              ) : null}
              <Typography className={classes.text} component="div">
                {props.children}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  )
}

SplitContent.propTypes = {
  title: PropTypes.string,
  reverse: PropTypes.bool,
  color: PropTypes.oneOf(['gray', 'white']),
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    position: PropTypes.oneOf(['bottom', 'top', 'center']),
  }),
}

SplitContent.defaultProps = {
  reverse: false,
  color: 'white',
}

export default SplitContent
