import axios from '../utils/axios'

class ActionPlanService {
  getList = async () => {
    const response = await axios.get(`action-plan-assignments/list`)

    return response.data
  }
  putEdit = async ({ id, data }) => {
    const response = await axios.put(`action-plan-assignments/${id}`, data)

    return response.data
  }
  putEditWithEmail = async ({ id, userId, data }) => {
    const response = await axios.put(
      `action-plan-assignments/assign-user/${id}/${userId}`,
      data
    )

    return response.data
  }
}

const actionPlanService = new ActionPlanService()

export default Object.freeze(actionPlanService)
