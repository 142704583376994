import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import format from 'date-fns/format'
import { makeStyles, Checkbox, Typography } from '@material-ui/core'
import CheckCircle from '@material-ui/icons/CheckCircle'
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked'

import { isManager } from '../../store/accountSlice'
/* eslint jsx-a11y/anchor-is-valid: 0 */

function Task(props) {
  const classes = useStyles({
    skipped: props.status === 'SKIPPED',
  })
  const isManagerRole = useSelector(isManager)
  const user = useSelector(state => state.account.user)

  const ActionLine = () => {
    switch (props.status) {
      case 'FINISHED':
        return (
          <>
            <span className={classes.mutedText}>
              Completed by {props.assignedUser?.name}{' '}
              {format(new Date(props.finishedAt), 'd MMM yyyy')}
            </span>
          </>
        )
      case 'ASSIGNED':
        if (!isManagerRole)
          return (
            <span className={classes.mutedText}>
              Assigned to {props.assignedUser?.name}
            </span>
          )
        return (
          <>
            <span className={classes.mutedText}>
              Assigned to {props.assignedUser?.name}
            </span>
            <a onClick={props.onUnAssign} className={classes.blueLink}>
              Unassign
            </a>
            <a onClick={props.onSkip} className={classes.blackLink}>
              Skip
            </a>
          </>
        )
      case 'SKIPPED':
        if (!isManagerRole) return null
        return (
          <>
            <a onClick={props.onReinstate} className={classes.blackLink}>
              Reinstate task
            </a>
          </>
        )
      case 'LATER':
        if (!isManagerRole) return null
        return (
          <>
            <a onClick={props.onAssign} className={classes.blueLink}>
              Assign
            </a>
            <a onClick={props.onSkip} className={classes.blackLink}>
              Skip
            </a>
          </>
        )
      default:
        return null
    }
  }

  const isDisabled = () => {
    if (props.status === 'SKIPPED') return true
    if (props.assignedUser?.id === user.id && props.status === 'ASSIGNED')
      return false
    if (props.assignedUser?.id === user.id && props.status === 'FINISHED')
      return false
    if (isManagerRole) return false
    return true
  }

  return (
    <div className={classes.root}>
      <div>
        <Checkbox
          classes={{ disabled: classes.disabledCheckbox }}
          icon={<RadioButtonUnchecked />}
          checkedIcon={<CheckCircle />}
          checked={props.status === 'FINISHED'}
          disabled={isDisabled()}
          onClick={
            props.status === 'FINISHED' ? props.onRemoveFinish : props.onFinish
          }
        />
      </div>
      <div className={classes.textContainer}>
        <Typography className={classes.task}>{props.task}</Typography>
        <div className={classes.btnContainer}>
          <ActionLine />
        </div>
      </div>
    </div>
  )
}

Task.propTypes = {
  task: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['SKIPPED', 'LATER', 'FINISHED', 'ASSIGNED']),
  assignedUser: PropTypes.object,
  finishedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onReinstate: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired,
  onAssign: PropTypes.func.isRequired,
  onUnAssign: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  onRemoveFinish: PropTypes.func.isRequired,
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginBottom: theme.spacing(1),
  },
  textContainer: {
    paddingTop: 9,
  },
  blackLink: {
    textDecoration: 'underline',
    color: '#000',
    fontSize: 14,
    cursor: 'pointer',
  },
  blueLink: {
    textDecoration: 'underline',
    color: theme.palette.primary.main,
    fontSize: 14,
    cursor: 'pointer',
  },
  mutedText: {
    color: '#858585',
    fontSize: 14,
    textDecoration: 'underline',
  },
  btnContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > :not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  disabledCheckbox: {
    color: '#e0e0e0 !important',
  },
  task: {
    color: ({ skipped }) => (skipped ? '#b2b2b2' : '#000'),
  },
}))

export default memo(Task)
