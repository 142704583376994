import React, { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import { IMAGE_SIZES } from '../constans'

function ResponsiveImage({ image, size, ...rest }) {
  const srcset = useMemo(() => {
    if (!image || !image.formats) {
      return []
    }

    const maxImageWidth = IMAGE_SIZES[size] || 9999
    // use image formats only if smaller than the size prop image width
    const imageSizes = Object.keys(image.formats).filter(
      format => image.formats[format].width <= maxImageWidth
    )

    return imageSizes.map(format => {
      return `${image.formats[format].url} ${image.formats[format].width}w`
    })
  }, [image, size])

  // if the file is svg skip srcset
  if (image && image.ext === '.svg') {
    return (
      /* eslint-disable-next-line jsx-a11y/alt-text */
      <img src={image.url} {...rest} />
    )
  }

  if (!image) {
    return null
  }

  if (!get(image, `formats[${size}].url`, null)) {
    srcset.push(`${image.url} ${image.width}w`)
  }

  const maxImage = get(image, `formats[${size}]`, image)

  return (
    /* eslint-disable-next-line jsx-a11y/alt-text */
    <img
      src={maxImage.url}
      srcSet={srcset.join(',')}
      sizes={`(max-width: ${maxImage.width}px) 100vw, ${maxImage.width}px`}
      {...rest}
    />
  )
}

ResponsiveImage.propTypes = {
  image: PropTypes.object,
  size: PropTypes.oneOf(['large', 'medium', 'small', 'thumbnail']),
}

export default memo(ResponsiveImage)
