import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import InputBase from '@material-ui/core/InputBase'
import { makeStyles } from '@material-ui/core'

import Label from './Label'

const useStyles = makeStyles(theme => ({
  root: {
    transition: theme.transitions.create('opacity'),
    opacity: disabled => (disabled ? 0.2 : 1),
    marginBottom: theme.spacing(2),
  },
  input: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
    borderRadius: 6,
    boxShadow: '0 2px 10px rgb(0 0 0 / 0.12)',
    padding: theme.spacing(3 / 2, 2),
  },
}))

const TextInput = ({ label, placeholder, inputRef, disabled, name, error }) => {
  const classes = useStyles(disabled)

  return (
    <div className={classes.root}>
      <Label>{label}</Label>
      {error ? error.message : null}
      <FormControl className={classes.input} disabled={disabled}>
        <InputBase placeholder={placeholder} inputRef={inputRef} name={name} />
      </FormControl>
    </div>
  )
}

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  inputRef: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.object,
}

TextInput.defaultProps = {
  disabled: false,
}

export default TextInput
