import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import Block from '@material-ui/icons/Block'

function CardLabel(props) {
  const classes = useStyles()

  if (props.status === 'LATER')
    return (
      <div className={classes.doneTextContainer}>
        <div
          className={classes.doneText}
          style={{ backgroundColor: '#FDD9B7' }}
        >
          <Block /> Saved for later
        </div>
      </div>
    )

  if (props.status === 'FINISHED')
    return (
      <div className={classes.doneTextContainer}>
        <div
          className={classes.doneText}
          style={{ backgroundColor: '#62F47F' }}
        >
          <CheckCircleOutline /> Done
        </div>
      </div>
    )

  return null
}

CardLabel.propTypes = {
  status: PropTypes.string.isRequired,
}

const useStyles = makeStyles(theme => ({
  doneTextContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  doneText: {
    transform: 'translateY(-50%)',
    fontWeight: 900,
    borderRadius: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0.5, 2),
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
}))

export default CardLabel
