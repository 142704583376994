import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import difference from 'lodash/difference'

import FormControl from '@material-ui/core/FormControl'
import {
  Avatar,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Input,
  ListItemText,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { Skeleton } from '@material-ui/lab'

import { generateMonogram, getAvatarUrl } from '../../utils'
import companyService from '../../services/companyService'
import dataService from '../../services/dataService'
import assignmentService from '../../services/assignmentService'
import useAssignToSection from '../../utils/useAssignToSection'

function ChangeUserModal(props) {
  const classes = useStyles()
  const avatar = getAvatarUrl(props.user?.avatar)
  const monogram = generateMonogram(props.user?.name)
  const [value, setValue] = useState(props.user?.role?.id)
  const [selectedSection, setSelectedSection] = useState([])
  const [initialSelectedSections, setInitialSelectedSections] = useState([])

  const queryClient = useQueryClient()
  const assignMutation = useAssignToSection()
  const deleteAssignment = useMutation(assignmentService.deleteAssignment, {
    onMutate: async ({ section }) => {
      await queryClient.cancelQueries('assignments')

      const previousValue = queryClient.getQueryData('assignments')

      const newValue = previousValue.map(item => {
        if (item.id === section)
          return {
            ...item,
            assigned_user: null,
          }
        return item
      })
      queryClient.setQueryData('assignments', newValue)

      return { previousValue }
    },
    onError: (err, variables, previousValue) => {
      queryClient.setQueryData('assignments', previousValue)
    },
    onSuccess: async () => {
      await queryClient.refetchQueries(['assignments'])
    },
  })
  const updateUser = useMutation(companyService.updateMember, {
    onMutate: async ({ userId, data }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries('team')

      // Snapshot the previous value
      const previousValue = queryClient.getQueryData('team')
      const newValue = [...previousValue]
      const updatedUser = newValue.find(user => user.id === userId)

      if (!updatedUser) return { previousValue }

      updatedUser.role.id = data.role
      updatedUser.assigned_sections = data.assigned_sections
      queryClient.setQueryData('team', newValue)
      props.onClose()
      return { previousValue }
    },
    onError: (err, variables, previousValue) => {
      queryClient.setQueryData('team', previousValue)
    },
    // onSettled: () => props.onClose(),
  })
  const { isLoading, data } = useQuery('sections', dataService.getSections)
  const { isLoading: assignmentLoading, data: assignments } = useQuery(
    'assignments',
    assignmentService.getAssignmentBySections
  )

  useEffect(() => {
    setValue(props.user?.role?.id)

    if (props.user?.assigned_sections) {
      const initialData = props.user?.assigned_sections
        .split(';')
        .filter(item => item !== '')
        .map(item => parseInt(item))

      setSelectedSection(initialData)
      setInitialSelectedSections(initialData)
    }
  }, [props.user])

  const handleChange = event => {
    setValue(parseInt(event.target.value))

    //if its not a user role
    if (parseInt(event.target.value) !== 1) {
      setSelectedSection([])
    }
  }

  const handleSectionChange = event => {
    setSelectedSection(event.target.value)
  }

  const handleUserChange = async () => {
    const newAdded = difference(selectedSection, initialSelectedSections)
    const removed = difference(initialSelectedSections, selectedSection)

    const addedItemPromises = newAdded.map(section => {
      return assignMutation.mutateAsync({
        type: 'section',
        content_id: section,
        user: props.user.id,
      })
    })

    const removedItemPromises = removed.map(section => {
      return deleteAssignment.mutateAsync({
        section: section,
      })
    })

    await Promise.all([...addedItemPromises, ...removedItemPromises])
    await updateUser.mutate({
      userId: props.user.id,
      data: { role: value, assigned_sections: selectedSection.join(';') },
    })
  }

  const isAssignedSection = sectionId => {
    if (assignmentLoading) return true
    const actualAssignment = assignments.find(
      assignment => assignment.id === sectionId
    )

    if (actualAssignment.assigned_user || actualAssignment.assigned_email)
      return true

    return false
  }

  const loading =
    updateUser.isLoading ||
    assignMutation.isLoading ||
    deleteAssignment.isLoading
  return (
    <Dialog
      open={!!props.user}
      onClose={props.onClose}
      classes={{ paper: classes.root }}
      disableBackdropClick={loading}
      disableEscapeKeyDown={loading}
    >
      <DialogContent>
        <div className={classes.header}>
          <Avatar
            size="small"
            component="span"
            className={classes.iconButton}
            src={avatar}
          >
            {monogram}
          </Avatar>
          <Typography>Change role</Typography>
        </div>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="role"
            name="role"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value={5}
              control={<Radio />}
              classes={{ root: classes.labelRoot }}
              disabled={updateUser.isLoading}
              label={
                <div>
                  <Typography style={{ fontSize: 14, fontWeight: 600 }}>
                    Admin
                  </Typography>
                  <Typography style={{ fontSize: 14, lineHeight: 1.2 }}>
                    Admins can manage memberships and users
                  </Typography>
                </div>
              }
            />
            <FormControlLabel
              value={3}
              control={<Radio />}
              classes={{ root: classes.labelRoot }}
              disabled={updateUser.isLoading}
              label={
                <div>
                  <Typography style={{ fontSize: 14, fontWeight: 600 }}>
                    Manager
                  </Typography>
                  <Typography style={{ fontSize: 14, lineHeight: 1.2 }}>
                    Can complete assessments, generate action plans, access
                    reports, update company profile, data entered and can
                    complete tasks.
                  </Typography>
                </div>
              }
            />
            <FormControlLabel
              value={1}
              control={<Radio />}
              disabled={updateUser.isLoading}
              label={
                <div>
                  <Typography style={{ fontSize: 14, fontWeight: 600 }}>
                    User
                  </Typography>
                  <Typography style={{ fontSize: 14, lineHeight: 1.2 }}>
                    Can complete only assigned section, view and complete tasks.
                  </Typography>
                </div>
              }
            />
          </RadioGroup>
        </FormControl>
        <FormControl style={{ marginLeft: 30, display: 'flex' }}>
          {isLoading ? (
            <Skeleton height={67} />
          ) : (
            <Select
              multiple
              displayEmpty
              value={selectedSection}
              onChange={handleSectionChange}
              disabled={loading || value !== 1}
              input={<Input className={classes.input} disableUnderline />}
              renderValue={selected => {
                if (selected.length === 0) {
                  return <em>Choose at least one section</em>
                }
                // get actual data for selected id-s
                const selectedData = data.filter(item =>
                  selected.some(id => id === item.id)
                )

                return selectedData.map(item => item.title).join(', ')
              }}
            >
              {data.map(section => (
                <MenuItem
                  key={section.id}
                  value={section.id}
                  disabled={
                    isAssignedSection(section.id) &&
                    !(initialSelectedSections.indexOf(section.id) > -1)
                  }
                >
                  <Checkbox
                    checked={selectedSection.indexOf(section.id) > -1}
                  />
                  <ListItemText primary={section.title} />
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', marginTop: 40 }}>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          onClick={props.onClose}
          style={{ boxShadow: 'none' }}
          disabled={loading}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleUserChange}
          disabled={
            loading || (value === 1 && selectedSection.length === 0) //if user and selected no section
          }
        >
          Save changes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ChangeUserModal.propTypes = {
  user: PropTypes.object,
  onClose: PropTypes.func.isRequired,
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    maxWidth: 480,
    width: '100%',
  },
  iconButton: {
    position: 'relative',
    color: '#fff',
    height: 32,
    width: 32,
    fontWeight: 600,
    marginRight: theme.spacing(2),
    '&.MuiAvatar-colorDefault': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  header: {
    textAlign: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    display: 'flex',
    '& p': {
      fontWeight: 600,
      fontSize: 20,
      marginTop: theme.spacing(2),
    },
  },
  labelRoot: {
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
  },
  input: {
    border: 'solid 1px #D8D8D8',
    borderRadius: 4,
    fontSize: 14,
    padding: '7px 11px',
    marginTop: 6,
    justifyContent: 'flex-start',
    '& svg': {
      marginLeft: 'auto',
    },
    '&.Mui-disabled': {
      opacity: 0.5,
    },
    '&.Mui-focused .MuiSelect-root': {
      backgroundColor: 'white',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
}))

export default ChangeUserModal
