import React from 'react'
import PropTypes from 'prop-types'

import CheckBoxInput from '../CheckBoxInput'
import { COMPANY_ACTIVITY } from '../../constans'

const StepTwo = ({ form }) => {
  const { control, errors } = form

  return (
    <CheckBoxInput
      label="What are your main areas of activity?"
      name="activities"
      options={COMPANY_ACTIVITY}
      control={control}
      row
      error={errors.activities}
      rules={{
        required: true,
        validate: {
          minLength: value =>
            value.length > 0 ? true : 'Select at least one activity!',
        },
      }}
    />
  )
}

StepTwo.propTypes = {
  form: PropTypes.object.isRequired,
}

export default StepTwo
