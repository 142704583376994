import React from 'react'
import * as Sentry from '@sentry/react'
import { withStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import SadIcon from '@material-ui/icons/SentimentDissatisfied'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

const styles = theme => ({
  root: {
    height: '100%',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    textAlign: 'center',
  },
  errorIcon: {
    marginBottom: theme.spacing(4),
    fontSize: '96px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '72px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '48px',
    },
  },
  generalMessageTitle: {
    marginBottom: theme.spacing(4),
    fontWeight: 900,
    fontSize: 30,
    lineHeight: 1,
  },
})

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error)
  }

  render() {
    const { classes } = this.props

    const handleReload = () => {
      window.location.reload()
    }

    if (this.state.hasError) {
      return (
        <Box
          py={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
          className={classes.root}
        >
          <Container maxWidth="md" className={classes.container}>
            <SadIcon className={classes.errorIcon} />
            <Typography
              component="h1"
              className={classes.generalMessageTitle}
              variant="h5"
            >
              It looks like there has been an error while trying to fulfill your
              request. Sorry about that!
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleReload}
            >
              Reload
            </Button>
          </Container>
        </Box>
      )
    }

    return this.props.children
  }
}

export default withStyles(styles)(ErrorBoundary)
