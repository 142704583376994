import React from 'react'
import { Container } from '@material-ui/core'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

import actionPlanService from '../services/actionPlanService'
import questionService from '../services/questionService'
import { isFreePlan, isAdmin } from '../store/accountSlice'

import Page from '../layout/Page'
import Hero from '../components/ActionPlan/Hero'
import LoadingScreen from '../components/LoadingScreen'
import TopicList from '../components/ActionPlan/TopicList'
import AssignModal from '../components/ActionPlan/AssignModal'
import SectionHeader from '../components/ActionPlan/SectionHeader'
import EmptyContent from '../components/EmptyContent'

function ActionPlan() {
  const isFree = useSelector(isFreePlan)
  const { isLoading, data, isError } = useQuery(
    'action_plan_assignments',
    actionPlanService.getList
  )
  const {
    isLoading: sectionsLoading,
    data: result,
    isError: isSectionsError,
  } = useQuery('result', questionService.getResult)

  const user = useSelector(state => state.account.user)
  const isAdminRole = useSelector(isAdmin)

  if (isLoading || sectionsLoading) return <LoadingScreen />
  if (isError || isSectionsError) return null
  if (isFree) return <Redirect to="/setting/upgrade" />

  const sections = result
    .map(resultItem => ({
      ...resultItem.section,
      action_plan_topics: data.filter(
        assignment => assignment.section.id === resultItem.section.id
      ),
    }))
    .filter(section => {
      // if admin return all non-empty sections
      if (isAdminRole) {
        return section.action_plan_topics.length > 0
      }

      //if user, return sections which contains at least one task assigned to the current user
      return section.action_plan_topics.some(topic => {
        return topic.task_to_user.some(task => task?.user?.id === user.id)
      })
    })

  return (
    <Page title="Action Plan">
      <Hero />
      <AssignModal />
      <Container maxWidth="lg">
        {sections.map(section => (
          <div key={section.id}>
            <SectionHeader section={section} />
            <TopicList
              data={section.action_plan_topics}
              sectionId={section.id}
            />
          </div>
        ))}
        {sections?.length < 1 ? (
          <EmptyContent text="There is no Action is assigned to you!" />
        ) : null}
      </Container>
    </Page>
  )
}

export default ActionPlan
