import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import usePostQueryKey from './usePostsQueryKey'
import postService from '../services/postService'

export default function useMarkPost() {
  const queryClient = useQueryClient()
  const queryKey = usePostQueryKey()
  const { slug } = useParams()

  return useMutation(postService.marPost, {
    onMutate: async ({ id, type }) => {
      const previousArchiveQuery = queryClient.getQueryData(queryKey)
      const previousPostQuery = slug
        ? queryClient.getQueryData(['post', slug])
        : undefined

      queryClient.setQueryData(['post', slug], data => {
        if (!data) return

        return {
          ...data,
          [type]: !data[type],
        }
      })

      queryClient.setQueryData(queryKey, data => {
        if (!data) return
        //iterate over pages, and also iterate over all the sub pages to find the actual post
        const newPagesArray = data?.pages.map(page => {
          const updatedIndex = page.findIndex(r => r.id === id)
          if (updatedIndex < 0) return page

          const newPageArray = [...page]
          newPageArray[updatedIndex] = {
            ...newPageArray[updatedIndex],
            [type]: !newPageArray[updatedIndex][type],
          }
          return newPageArray
        })

        return {
          pages: newPagesArray,
          pageParams: data.pageParams,
        }
      })
      return { previousArchiveQuery, previousPostQuery }
    },
    onError: (err, variables, previousData) => {
      queryClient.setQueryData(queryKey, previousData.previousArchiveQuery)
      queryClient.setQueryData(['post', slug], previousData.previousPostQuery)
    },
  })
}
