import React from 'react'
import * as Yup from 'yup'
import { FastField, Form, Formik } from 'formik'
import { useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'

import { isFreePlan } from '../../store/accountSlice'
import useInviteTeamMember from '../../utils/useInviteTeamMember'

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter a valid email address'),
})

function InviteTeamMemberForm() {
  const classes = useStyles()
  const company = useSelector(state => state?.account?.company)
  const isFree = useSelector(isFreePlan)

  const inviteMutation = useInviteTeamMember()

  const initialValues = {
    email: '',
  }

  const handleSubmit = async (data, formikBag) => {
    await inviteMutation.mutateAsync({
      email: data.email,
      companyId: company.id,
    })
    formikBag.resetForm()
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors, dirty }) => (
        <Form noValidate className={classes.root}>
          {isFree ? <div className={classes.overlay} /> : null}
          <div className={classes.formContainer}>
            <p>To:</p>
            <FastField
              label="Email"
              name="email"
              type="email"
              placeholder="Enter your team member email address"
              required
              className={errors.email ? classes.inputError : classes.input}
              disabled={isSubmitting}
            />
            <Button
              variant="contained"
              color="primary"
              size="small"
              type="submit"
              className={classes.button}
              disabled={isSubmitting || !dirty}
            >
              Invite
            </Button>
          </div>
          {errors.email ? (
            <p className={classes.error}>{errors.email}</p>
          ) : null}
        </Form>
      )}
    </Formik>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'white',
    padding: theme.spacing(3, 2),
    position: 'relative',
  },
  formContainer: {
    display: 'flex',
    alignItems: 'center',

    '& > p': {
      paddingRight: theme.spacing(2),
    },
  },
  button: {
    fontSize: 18,
    fontWeight: 'bold',
    paddingLeft: 32,
    paddingRight: 32,
  },
  input: {
    border: 'solid 1px #D8D8D8',
    borderRadius: 4,
    fontSize: 14,
    padding: 11,
    flex: 1,
    marginRight: theme.spacing(3),
  },
  inputError: {
    border: `solid 1px ${theme.palette.error.main}`,
    borderRadius: 4,
    fontSize: 14,
    padding: 11,
    flex: 1,
    marginRight: theme.spacing(3),
  },
  error: {
    fontSize: 12,
    color: theme.palette.error.main,
    marginLeft: 37,
    marginBottom: 0,
  },
  overlay: {
    background: '#f4f4f4b5',
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    zIndex: 1,
  },
}))

export default InviteTeamMemberForm
