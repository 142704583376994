import React from 'react'
import { useQuery } from 'react-query'
import Grid from '@material-ui/core/Grid'
import { useSelector } from 'react-redux'

import mentorService from '../../services/mentorService'
import LoadingScreen from '../LoadingScreen'
import MentorItem from './MentorItem'

const MentorArchive = () => {
  const filter = useSelector(state => state.mentor.filter)
  const mentors = useQuery(['mentors', filter], () =>
    mentorService.getAll(filter)
  )

  if (mentors.isLoading) {
    return <LoadingScreen />
  }

  if (mentors.isError) {
    return <p>Something went wrong. Try again later!</p>
  }

  return (
    <Grid component="section" container spacing={3}>
      {mentors.data.map(mentorItem => (
        <MentorItem
          key={mentorItem.id}
          id={mentorItem.id}
          firstName={mentorItem.first_name}
          lastName={mentorItem.last_name}
          categories={mentorItem.categories}
          avatar={mentorItem.avatar}
          calendars={mentorItem.calendars}
        />
      ))}
    </Grid>
  )
}

export default MentorArchive
