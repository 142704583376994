import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import ButtonBase from '@material-ui/core/ButtonBase'

import pledgeImg from '../../assets/pledge.png'

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: '2px 2px 14px 0px rgb(0 0 0 / 0.2)',
    backgroundColor: 'white',
  },
  contentWrapper: {
    display: 'flex',
  },
  content: {
    padding: theme.spacing(1),
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    '& > ul': {
      marginLeft: theme.spacing(3),
    },
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    height: 60,
    backgroundColor: '#EDEDED',
    [theme.breakpoints.up('md')]: {
      height: 75,
    },
  },
  footerTitle: {
    fontSize: 18,
    fontWeight: 600,
    marginLeft: theme.spacing(2),
  },
  actions: {
    height: '100%',
    marginLeft: 'auto',
  },
  button: {
    height: '100%',
    borderLeft: 'solid 2px #D8D8D8',
    padding: theme.spacing(0, 1),
    '& > svg': {
      width: 30,
      height: 30,
      [theme.breakpoints.up('md')]: {
        width: 40,
        height: 40,
      },
    },
    '&:hover': {
      backgroundColor: '#D8D8D8',
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 3),
    },
  },
  imgContainer: {
    maxWidth: 160,
  },
}))

const FooterItem = ({ children, onClick }) => {
  const classes = useStyles()
  return (
    <ButtonBase onClick={onClick} className={classes.button}>
      {children}
    </ButtonBase>
  )
}

const RpCard = ({ children, footerText, action }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.contentWrapper}>
        <div className={classes.content}>{children}</div>
        <div className={classes.imgContainer}>
          <img
            src={pledgeImg}
            alt="Resilience Pledge"
            style={{ maxWidth: '100%' }}
          />
        </div>
      </div>
      <div className={classes.footer}>
        <Typography className={classes.footerTitle}>{footerText}</Typography>
        {action ? (
          <div className={classes.actions}>
            {action.map((item, index) => (
              <FooterItem key={index} onClick={item.onClick}>
                {item.icon}
              </FooterItem>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  )
}

RpCard.propTypes = {
  footerText: PropTypes.string,
  action: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func.isRequired,
      icon: PropTypes.object.isRequired,
    })
  ),
}

export default RpCard
