import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { makeStyles } from '@material-ui/core'
import { Controller } from 'react-hook-form'

import Label from './Label'

const useStyles = makeStyles(theme => ({
  root: {
    transition: theme.transitions.create('opacity'),
    opacity: disabled => (disabled ? 0.2 : 1),
    marginBottom: theme.spacing(2),
  },
  select: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
    borderRadius: 6,
    boxShadow: '0 2px 10px rgb(0 0 0 / 0.12)',
    padding: theme.spacing(3 / 2, 2),
    '& > .Mui-focused .MuiSelect-root': {
      backgroundColor: 'white',
    },
  },
}))

const SelectInput = ({
  label,
  placeholder,
  disabled,
  values,
  name,
  control,
}) => {
  const classes = useStyles(disabled)

  return (
    <div className={classes.root}>
      <Label>{label}</Label>
      <FormControl className={classes.select} disabled={disabled}>
        <Controller
          name={name}
          control={control}
          as={
            <Select displayEmpty disableUnderline IconComponent={ExpandMore}>
              <MenuItem value="">
                <em>{placeholder}</em>
              </MenuItem>
              {values.map(value => (
                <MenuItem value={value} key={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          }
        />
      </FormControl>
    </div>
  )
}

SelectInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  values: PropTypes.array.isRequired,
  control: PropTypes.object.isRequired,
}

SelectInput.defaultProps = {
  disabled: false,
  values: [],
}

export default SelectInput
