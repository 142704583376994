import axios from '../utils/axios'

class SectionService {
  getSectionBySlug = async slug => {
    if (!slug) return []

    const response = await axios.get(`post-sections?slug=${slug}`)

    if (response.data && response.data.length > 0) {
      return response.data[0]
    }

    return []
  }

  getSections = async () => {
    const response = await axios.get(`post-sections?_sort=created_at`)

    return response.data
  }
}

const sectionService = new SectionService()

export default Object.freeze(sectionService)
