import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Box, useMediaQuery, useTheme } from '@material-ui/core'

import Page from '../../layout/Page'
import ExitModal from './ExitModal'

const QuestionHeader = ({ section, loading, total = 0, current = 0 }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  if (loading) return null

  return (
    <Page title={section.title}>
      <div className={classes.root}>
        <Box display="flex" alignItems="center" marginBottom={1.5}>
          <Box display="flex" alignItems="center" flex={1}>
            {section.icon && section.icon.url ? (
              <img
                src={section.icon.url}
                alt={section.title}
                className={classes.icon}
              />
            ) : null}
            <Typography
              component="h1"
              variant="h5"
              className={classes.title}
              style={{ color: section.color, lineHeight: 1 }}
            >
              {section.title}
            </Typography>
          </Box>
          <Box display="flex" marginLeft="auto" alignItems="center">
            <Typography className={classes.counter} variant="h5" component="p">
              {isDesktop ? 'Question' : null} {current} of {total}
            </Typography>
            <ExitModal />
          </Box>
        </Box>
      </div>
    </Page>
  )
}

QuestionHeader.propTypes = {
  section: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
}

const useStyles = makeStyles(theme => ({
  root: {
    borderBottom: 'solid 1px #000',
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(4),
      paddingTop: theme.spacing(3),
    },
  },
  icon: {
    height: 40,
    width: 40,
    marginRight: theme.spacing(2),
  },
  title: {
    fontSize: 20,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: 32,
    },
  },
  counter: {
    fontSize: 14,
    marginRight: theme.spacing(3),
  },
}))

export default memo(QuestionHeader)
