import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

import { DialogContent, DialogContentText, makeStyles } from '@material-ui/core'
import { Alert, ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import checkoutService from '../../services/checkoutService'
import { setUser } from '../../store/accountSlice'
import { MentorCalendarItem, BookingDTO } from '../../types/mentor'
import { RootState } from '../../types/redux-root-state'

type BookingFormProps = {
  calendars: MentorCalendarItem[]
  onClose: () => void
  mentorId: number
}

function BookingForm(props: BookingFormProps) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const queryClient = useQueryClient()
  const me = useSelector((state: RootState) => state?.account?.user)
  const oneOff = useMutation<any, any, BookingDTO>(
    checkoutService.getBookingSession
  )
  const bookingByCredit = useMutation<any, any, BookingDTO>(
    checkoutService.postHandleBookingByCredit,
    {
      onSuccess: async data => {
        dispatch(
          setUser({
            ...me,
            // credit: parseInt(me.credit) - parseInt(data.price),
            credit: me.credit - parseInt(data.price),
          })
        )
        props.onClose()
        queryClient.setQueryData(['booking', data.id], data)
        history.push(`/mentors/bookings/${data.id}`)
      },
    }
  )
  const availableCredits = me.credit || 0

  const [selectedCalendar, setCalendar] = useState<MentorCalendarItem | null>(
    props.calendars?.length > 0 ? props.calendars[0] : null
  )

  const notEnoughCredit = availableCredits < (selectedCalendar?.credit || 0)
  const handleDurationChange = (e: any, value: MentorCalendarItem) => {
    setCalendar(value)
  }

  const handleOneOffPurchase = async () => {
    if (!selectedCalendar) return

    const link = await oneOff.mutateAsync({
      calendarId: selectedCalendar.id,
      mentorId: props.mentorId,
    })
    //redirect to stripe
    if (link) {
      window.location.href = link.toString()
    }
  }

  const handleBookingByCredit = async () => {
    if (!selectedCalendar) return

    await bookingByCredit.mutateAsync({
      calendarId: selectedCalendar.id,
      mentorId: props.mentorId,
    })
  }

  return (
    <DialogContent>
      <DialogContentText>
        How long would you like to book? The duration will dictate the cost.
      </DialogContentText>
      <ToggleButtonGroup
        value={selectedCalendar}
        exclusive
        onChange={handleDurationChange}
        aria-label="duration"
      >
        {props.calendars.map((calendar, index) => (
          <ToggleButton
            value={calendar}
            className={classes.toggleBtn}
            key={`${calendar.calendly_link}_${index}`}
            disabled={oneOff.isLoading || bookingByCredit.isLoading}
          >
            {calendar.duration} Min
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {Boolean(selectedCalendar?.call_name) ? (
        <Typography color="secondary" style={{ marginTop: 8, fontWeight: 600 }}>
          {selectedCalendar?.call_name}
        </Typography>
      ) : null}
      <DialogContentText style={{ marginTop: 16 }}>
        Book using credits for a discount, or select a one off purchase if you
        don’t want to purchase credits
      </DialogContentText>
      {notEnoughCredit ? (
        <Alert severity="warning">
          You don’t have have enough credits. Contact your team admin to request
          more credits.
        </Alert>
      ) : null}
      {props.calendars.length < 1 ? (
        <Alert severity="warning" style={{ marginBottom: 24 }}>
          This Mentor haven't added a calendar yet! Please consider choosing
          somebody else.
        </Alert>
      ) : null}
      {selectedCalendar ? (
        <div style={{ marginTop: 24, display: 'flex', marginBottom: 16 }}>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            className={classes.btnOutlined}
            onClick={handleOneOffPurchase}
            disabled={
              !selectedCalendar ||
              oneOff.isLoading ||
              bookingByCredit.isLoading ||
              parseInt(selectedCalendar?.one_off_price) === 0
            }
          >
            One Off Purchase of £{selectedCalendar?.one_off_price}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.btnBooking}
            onClick={handleBookingByCredit}
            disabled={
              !selectedCalendar ||
              notEnoughCredit ||
              oneOff.isLoading ||
              bookingByCredit.isLoading
            }
          >
            {parseInt(selectedCalendar.credit) === 0
              ? `Book a free call`
              : `Book Using ${selectedCalendar.credit} Credits`}
          </Button>
        </div>
      ) : null}
    </DialogContent>
  )
}

const useStyles = makeStyles(theme => ({
  btnOutlined: {
    boxShadow: 'none',
    marginRight: 16,
    fontSize: 14,
    minWidth: 'auto',
    padding: '5px 16px !important',
  },
  btnBooking: {
    boxShadow: 'none',
    fontSize: 14,
    padding: '5px 16px !important',
    flex: 1,
  },
  toggleBtn: {
    fontSize: 14,
    fontWeight: 600,
    color: '#374151',
    textTransform: 'none',
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
      },
    },
  },
}))

export default BookingForm
