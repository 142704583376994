import React from 'react'
import { Container, makeStyles, Typography } from '@material-ui/core'

import Page from '../layout/Page'
import PostHero from '../components/Mentors/PostHero'
import HowToBook from '../components/Mentors/HowToBook'
import MentorArchive from '../components/Mentors/MentorArchive'
import MentorFilter from '../components/Mentors/MentorFilter'

const Mentors = () => {
  const classes = useStyles()

  return (
    <Page title="Mentors">
      <PostHero />
      <HowToBook />
      <Container maxWidth="lg" className={classes.container}>
        <MentorFilter />
        <Typography className={classes.title} variant="h4" component="h3">
          View Experts
        </Typography>
        <MentorArchive />
      </Container>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.primary.main,
    fontSize: 24,
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      fontSize: 30,
      marginBottom: theme.spacing(4),
    },
  },
  container: {
    marginBottom: theme.spacing(3),
  },
}))

export default Mentors
