import React, { useEffect, useState } from 'react'
import * as ReactGA from 'react-ga'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import get from 'lodash/get'
import { useDispatch } from 'react-redux'

import {
  Box,
  ButtonBase,
  CircularProgress,
  makeStyles,
} from '@material-ui/core'
import Container from '@material-ui/core/Container'
import ArrowForward from '@material-ui/icons/ArrowForward'
import ArrowBack from '@material-ui/icons/ArrowBack'
import Typography from '@material-ui/core/Typography'
import { alpha } from '@material-ui/core/styles/colorManipulator'

import sectionService from '../services/sectionService'
import questionService from '../services/questionService'
import { setCompany } from '../store/accountSlice'

import GraphIcon from '../assets/graoh.svg'
import Question from '../components/Questionnaire/Question'
import QuestionHeader from '../components/Questionnaire/QuestionHeader'
import DotsLoading from '../components/DotsLoading'
import Page from '../layout/Page'

function QuestionList() {
  const classes = useStyles()
  const { slug } = useParams()
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const [answers, setAnswers] = useState([])
  const [actualQuestion, setActualQuestion] = useState(0)
  const [submitting, setSubmitting] = useState(false)
  const history = useHistory()

  const { data, isLoading } = useQuery(['questions', slug], () =>
    questionService.getQuestionsBySlug(slug)
  )

  const { data: section, isLoading: isSectionLoading } = useQuery(
    ['sections', slug],
    () => sectionService.getSectionBySlug(slug),
    {
      initialData: () =>
        queryClient.getQueryData('sections')?.find(d => d.slug === slug),
    }
  )

  const saveSection = useMutation(questionService.saveAnswerBySection, {
    onSuccess: async data => {
      await queryClient.refetchQueries('result')
      await queryClient.refetchQueries('benchmark')
      dispatch(setCompany(data))
      history.push({
        pathname: '/questionnaire/intro',
        state: {
          success: section.id,
        },
      })
    },
  })

  const numberOfQuestions = data?.questions?.length || 0
  const question = get(data, `questions[${actualQuestion}]`)
  const answer = answers.find(aws => aws.id === question.id)
  const hasNextQuestion = actualQuestion < numberOfQuestions - 1
  const hasPrevious = actualQuestion > 0

  useEffect(() => {
    if (!submitting) return

    saveSection.mutate({
      sectionId: section?.id,
      data: {
        answers: answers,
        status: 'FINISHED',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitting, answers, section])

  const handleAnswer = async event => {
    ReactGA.event({
      category: 'questionnaire',
      action: 'Answer Question',
      label: question.question,
    })

    if (answer) {
      const newAnswers = answers.filter(aws => aws.id !== question.id)
      setAnswers([
        ...newAnswers,
        {
          id: question.id,
          answer: parseInt(event.target.value),
        },
      ])
    } else {
      await setAnswers([
        ...answers,
        {
          id: question.id,
          answer: parseInt(event.target.value),
        },
      ])
    }

    if (hasNextQuestion) {
      setActualQuestion(actualQuestion + 1)
    } else {
      setSubmitting(true)
    }
  }

  const handleNextClick = () => {
    setActualQuestion(actualQuestion + 1)
  }
  const handlePreviousClick = () => {
    setActualQuestion(actualQuestion - 1)
  }

  if (numberOfQuestions < 1) return null

  return (
    <Page title={`${section?.name}`}>
      <Container maxWidth="md" className={classes.root}>
        {saveSection.isLoading ? (
          <Box className={classes.loader}>
            <img src={GraphIcon} alt="Calculating result" />
            <Typography component="h4" className={classes.subTitle}>
              Calculating your results…
            </Typography>
            <Typography
              component="h1"
              color="secondary"
              className={classes.title}
            >
              Building your report…
            </Typography>
            <DotsLoading />
          </Box>
        ) : null}
        <QuestionHeader
          section={section}
          loading={isSectionLoading}
          total={numberOfQuestions}
          current={actualQuestion + 1}
        />
        {isLoading ? (
          <Box display="flex" justifyContent="center" m={2}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Question
              question={question}
              onAnswer={handleAnswer}
              selectedAnswer={answer}
            />
            <Box display="flex" justifyContent="space-between" mt={4}>
              <ButtonBase
                onClick={handlePreviousClick}
                disabled={!hasPrevious || saveSection.isLoading}
                className={classes.btn}
              >
                <ArrowBack /> Back
              </ButtonBase>
              <ButtonBase
                onClick={handleNextClick}
                disabled={
                  answers.length <= actualQuestion ||
                  saveSection.isLoading ||
                  answers.length === numberOfQuestions
                }
                className={classes.btn}
              >
                Next <ArrowForward />
              </ButtonBase>
            </Box>
          </>
        )}
      </Container>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 33, //height of progress bar
    marginBottom: theme.spacing(4),
    position: 'relative',
  },
  btn: {
    color: 'black',
    fontSize: 14,
    padding: '15px 32px',
    border: 'solid 1px',
    borderRadius: 8,
    fontWeight: 700,
    '&.Mui-disabled': {
      opacity: 0.5,
    },
  },
  title: {
    fontWeight: 600,
    fontSize: 24,
    [theme.breakpoints.up('sm')]: {
      fontSize: 28,
    },
    marginBottom: theme.spacing(2),
  },
  subTitle: {
    fontWeight: 600,
    color: alpha(theme.palette.secondary.main, 0.6),
    fontSize: 12,
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
    },
  },
  loader: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 2,
    backgroundColor: '#f4f4f4f2',
    textAlign: 'center',
  },
}))

export default QuestionList
