import React from 'react'
import PropTypes from 'prop-types'
import ButtonBase from '@material-ui/core/ButtonBase'
import Typography from '@material-ui/core/Typography'

const DrawerButton = ({
  onClick,
  icon: Icon,
  children,
  disabled,
  className,
}) => {
  return (
    <ButtonBase className={className} onClick={onClick} disabled={disabled}>
      <Icon className="icon" />
      <Typography component="span">{children}</Typography>
    </ButtonBase>
  )
}

DrawerButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.any.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string.isRequired,
}

export default DrawerButton
