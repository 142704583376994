import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import {
  DialogActions,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'

import { closeAssignModal } from '../store/uiSlice'

const schema = Yup.object().shape({
  user: Yup.string(),
  email: Yup.string().email('Must be a valid email!'),
})

function AssignSectionForm(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const modal = useSelector(state => state.ui.assign_modal)

  const initialValues = {
    user: modal.value?.id ? modal.value.id.toString() : '-1',
    email: typeof modal.value === 'string' ? modal.value : '',
  }

  const onClose = () => dispatch(closeAssignModal())

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={props.handleSubmit}
    >
      {({
        isSubmitting,
        handleChange,
        errors,
        dirty,
        setFieldValue,
        values,
      }) => (
        <Form noValidate className={classes.form}>
          <FormControl component="fieldset" className={classes.formControl}>
            <RadioGroup
              aria-label="user"
              name="user"
              value={values.user}
              onChange={e => {
                handleChange(e)
                //if user selected, don't allow to set email also
                setFieldValue('email', '')
              }}
            >
              {props.team?.map(user => (
                <FormControlLabel
                  key={user.id}
                  value={user.id.toString()}
                  control={<Radio />}
                  classes={{
                    root: classes.labelRoot,
                    label: classes.labelText,
                  }}
                  label={
                    <Typography
                      style={{
                        color:
                          values?.user?.toString() === user.id.toString()
                            ? '#000'
                            : '#969191',
                      }}
                    >
                      {user.name}
                      <Typography component="span">
                        {user.company_role}
                      </Typography>
                    </Typography>
                  }
                />
              ))}
              <FormControlLabel
                value="-1"
                control={<Radio />}
                classes={{
                  root: classes.labelRoot,
                  label: classes.labelText,
                }}
                label={
                  <Typography
                    style={{
                      color:
                        values?.user?.toString() === '-1' ? '#000' : '#969191',
                    }}
                  >
                    Not assigned
                  </Typography>
                }
              />
            </RadioGroup>
            <Typography className={classes.inviteTitle}>
              Invite by email
            </Typography>
            <input
              name="email"
              type="email"
              placeholder="Enter new team member email address"
              className={errors.email ? classes.inputError : classes.input}
              disabled={isSubmitting}
              value={values.email}
              onChange={e => {
                handleChange(e)
                //if email is set, don't allow to set user also
                setFieldValue('user', '')
              }}
            />
            {errors.email ? (
              <p className={classes.error}>{errors.email}</p>
            ) : null}
          </FormControl>
          <DialogActions className={classes.dialogActions}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={onClose}
              style={{ boxShadow: 'none' }}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              disabled={isSubmitting || !dirty}
            >
              Assign
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  )
}

AssignSectionForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  team: PropTypes.array.isRequired,
}

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(3),
  },
  labelRoot: {
    width: '100%',
  },
  labelText: {
    flexGrow: 1,
    width: '100%',
    '& > p': {
      fontWeight: 700,
      fontSize: 14,
      display: 'flex',
    },
    '& > p > span': {
      fontSize: 14,
      fontWeight: 500,
      marginLeft: 'auto',
    },
  },
  dialogActions: {
    justifyContent: 'center',
    padding: theme.spacing(0, 0, 3, 0),
  },
  input: {
    border: 'solid 1px #D8D8D8',
    borderRadius: 4,
    fontSize: 14,
    padding: 11,
    flex: 1,
  },
  inputError: {
    border: `solid 1px ${theme.palette.error.main}`,
    borderRadius: 4,
    fontSize: 14,
    padding: 11,
    flex: 1,
  },
  inviteTitle: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: 14,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    borderTop: 'solid 1px #D8D8D8',
    paddingTop: theme.spacing(3),
  },
  error: {
    fontSize: 12,
    color: theme.palette.error.main,
    marginBottom: 0,
  },
}))

export default AssignSectionForm
