import React from 'react'
import { alpha } from '@material-ui/core/styles/colorManipulator'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  '@keyframes blink': {
    '0%': {
      opacity: 0.2,
    },
    '20%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0.2,
    },
  },
  saving: {
    '& span': {
      width: 12,
      height: 12,
      borderRadius: 12,
      display: 'inline-block',
      backgroundColor: alpha(theme.palette.secondary.main, 0.6),
      animationName: '$blink',
      animationDuration: '1.4s',
      animationIterationCount: 'infinite',
      animationFillMode: 'both',
    },
    '& :not(:last-child)': {
      marginRight: 5,
    },
    '& :nth-child(2)': {
      animationDelay: '.2s',
    },
    '& :nth-child(3)': {
      animationDelay: '.4s',
    },
  },
}))

const DotsLoading = props => {
  const classes = useStyles()

  return (
    <Typography className={classes.saving}>
      <span></span>
      <span></span>
      <span></span>
    </Typography>
  )
}

export default DotsLoading
