import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import Page from '../layout/Page'

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    height: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  text: {
    margin: theme.spacing(1, 0, 6, 0),
  },
}))

const NotFound = props => {
  const classes = useStyles()

  return (
    <Page title="Page Not Found">
      <Container maxWidth="md" className={classes.root}>
        <Typography variant="h1" component="h1" color="primary">
          404
        </Typography>
        <Typography variant="h5" component="h2" className={classes.text}>
          Sorry, we couldn't find that page.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          component={Link}
          to="/"
        >
          Go back
        </Button>
      </Container>
    </Page>
  )
}

export default NotFound
