import axios from '../utils/axios'

class HomePageService {
  getLogos = async () => {
    const response = await axios.get('/home-page')
    return response.data
  }
}

const homePageService = new HomePageService()

export default homePageService
