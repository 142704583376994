import React from 'react'
import PropTypes from 'prop-types'
import * as ReactGA from 'react-ga'
import IconButton from '@material-ui/core/IconButton'
import Favorite from '@material-ui/icons/Favorite'
import Reply from '@material-ui/icons/Reply'
import Done from '@material-ui/icons/Done'
import PersonOutline from '@material-ui/icons/PersonOutline'
import { makeStyles } from '@material-ui/core'
import { darken } from '@material-ui/core/styles/colorManipulator'

import { getPostUrl } from '../../utils'
import useMarkPost from '../../utils/useMarkPost'
import { useDispatch, useSelector } from 'react-redux'
import { setKnowledgeHubAssignModal } from '../../store/uiSlice'
import useUpgrade from '../../utils/useUpgrade'
import { isManager } from '../../store/accountSlice'

const ShareIcon = props => {
  const classes = useStyles({ active: props.active })

  return (
    <IconButton
      className={classes.button}
      onClick={props.onClick}
      size="medium"
    >
      {props.children}
    </IconButton>
  )
}

const PostShare = ({ post }) => {
  const classes = useStyles()
  const markPost = useMarkPost()
  const dispatch = useDispatch()
  const isManagerRole = useSelector(isManager)
  const upgrade = useUpgrade(() =>
    dispatch(
      setKnowledgeHubAssignModal({
        status: true,
        contentId: post.id,
      })
    )
  )

  if (!post) {
    return null
  }

  const shareArray = [
    {
      text: 'Share',
      icon: <Reply />,
      active: false,
      onClick: () => {
        ReactGA.event({
          category: 'Post',
          action: 'Share via email',
        })

        window.open(
          `mailto:?subject=I'd like to share a link with you&body=${
            process.env.REACT_APP_CLIENT_URL
          }${getPostUrl(post)}`
        )
      },
    },
    {
      text: 'Fav',
      icon: <Favorite />,
      active: post.favourite_by_user,
      onClick: () => {
        ReactGA.event({
          category: 'Post',
          action: 'Add to Favourite',
        })
        markPost.mutate({ type: 'favourite_by_user', id: post.id })
      },
    },
    {
      text: 'Done',
      icon: <Done />,
      active: post.marked_done_by,
      onClick: () => {
        ReactGA.event({
          category: 'Post',
          action: 'Mark as Done',
        })
        markPost.mutate({ type: 'marked_done_by', id: post.id })
      },
    },
  ]

  if (isManagerRole) {
    shareArray.unshift({
      text: 'Assign',
      icon: <PersonOutline />,
      active: false,
      onClick: () => {
        ReactGA.event({
          category: 'Post',
          action: 'Post assigned',
        })
        upgrade()
      },
    })
  }

  return (
    <div className={classes.root}>
      {shareArray.map(item => (
        <ShareIcon
          text={item.text}
          active={item.active}
          onClick={item.onClick}
          key={item.text}
        >
          {item.icon}
        </ShareIcon>
      ))}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 0,
    display: 'flex',
    justifyContent: 'space-around',
    '& button': {
      padding: 5,
    },
  },
  button: {
    backgroundColor: ({ active }) =>
      active ? theme.palette.primary.main : '#D7D7D7',
    color: '#fff',
    '&:hover': {
      backgroundColor: ({ active }) =>
        active
          ? darken(theme.palette.primary.main, 0.2)
          : darken('#D7D7D7', 0.2),
    },
  },
}))

PostShare.propTypes = {
  post: PropTypes.object,
}

export default React.memo(PostShare)
