import axios from 'axios'
import * as Sentry from '@sentry/react'

const instance = axios.create({ baseURL: process.env.REACT_APP_API_URL })

instance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 500) {
      Sentry.captureException(error)
    }

    return Promise.reject(error)
  }
)

export default instance
