import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
}))

const Label = ({ children }) => {
  const { root } = useStyles()
  return (
    <Typography color="secondary" component="h4" className={root}>
      {children}
    </Typography>
  )
}

Label.propTypes = {
  children: PropTypes.any,
}

export default Label
